/*
	- image preview
*/

.um .um-single-image-preview,
.um .um-single-file-preview {
	margin-bottom: 20px;
}

.um .um-single-image-preview > img {
	max-height: 300px;
	display: inline-block;
	overflow: hidden;
	margin: auto;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.um-single-image-preview,
.um-single-file-preview {
	display: none;
	text-align: center;
	position: relative;
}

.um-single-image-preview.show,
.um-single-file-preview.show {display: block}

.um-single-image-preview > img {
	max-width: 100%;
	max-height: inherit;
}

.um-single-image-preview a.cancel,
.um-single-file-preview a.cancel {
	position: absolute;
	top: -15px;
	right: -15px;
	width: 30px;
	height: 30px;
	-moz-border-radius: 999px;
	-webkit-border-radius: 999px;
	border-radius: 999px;
	background: #ddd;
	cursor: pointer;
	text-decoration: none !important;
	z-index: 666;
	opacity: 0.75;
}

.um-single-image-preview a.cancel i,
.um-single-file-preview a.cancel i {
	font-size: 16px;
	color: #888;
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	line-height: 30px;
	overflow: hidden;
}
.um-single-image-preview a.cancel i:before,
.um-single-file-preview a.cancel i:before{line-height:inherit}

/*
	- file info
*/

.um-single-fileinfo {
	padding: 26px 0 20px 0;
	display: inline-block;
	max-width: 100%;
}

.um-single-fileinfo span.icon {
	font-size: 40px;
	display: inline-block;
	width: 100px;
	height: 70px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #666;
	color: #fff !important;
}
.um-single-fileinfo span.icon i {
	display: block;
	height: 70px;
	line-height: 70px;
	overflow: hidden;
}
.um-single-fileinfo span.icon i:before{line-height:inherit}

.um-single-fileinfo span.filename {
	display: block;
	font-size: 16px;
	color: #888;
	padding-top: 5px;
}

.um-single-fileinfo a {
	text-decoration: none !important;
	opacity: 0.9;
}
.um-single-fileinfo a:hover {
	opacity: 1
}

/*
	- upload progress
*/

div.um-modal .upload-statusbar {
	margin: 20px 0 0 0;
}

div.um-modal .upload-filename {
	display: none !important;
}

div.um-modal .upload-progress {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

div.um-modal .upload-bar {
	width: 0;
	height: 10px;
	color: #666;
	background: #7ACF58;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

/*
	- drag and drop area
*/

div.um-modal .ajax-upload-dragdrop {
	width: 100%;
	text-align: center;
	vertical-align:middle;
	padding: 20px;
	box-sizing: border-box;
	border: 2px dashed #ddd;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	font-size: 18px;
	line-height: 1em;
}

div.um-modal .ajax-upload-dragdrop span.str {
	display: block;
	margin: 0 0 15px 0;
}

div.um-modal .ajax-upload-dragdrop span.icon {
	display: block;
	font-size: 80px;
	line-height: 80px;
	vertical-align: middle;
	margin-bottom: 10px;
}

div.um-modal .ajax-upload-dragdrop span.help {
	display: block;
	font-size: 14px;
	color: #aaa;
	margin: 0 0 12px 0;
}

/*
	- upload button
*/

div.um-modal .upload {
	cursor: pointer !important;
	line-height: 34px !important;
	height: 34px;
	display: inline-block;
	text-decoration: none;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 0 20px;
	color: #fff;
	vertical-align: middle;
	font-size: 14px;
	box-sizing: border-box;
	transition: 0.2s;
}
  
div.um-modal .upload:hover {
	background: #44b0ec;
}

.um-modal input[type="file"]::-webkit-file-upload-button { cursor: pointer }