@charset "utf-8";
/* Table Of Contents bootstrap overrides vertical margins and paddings general styles styled lists icons buttons common sections styles: light section - .ls grey section - .ls.ms dark sections - .ds color section - .cs boxed layout template styles main menu (min 768px) styles main menu mobile styles subpages widgets shortcodes utilites plugins - Owl Carousel - FlexSlider - prettyPhoto - Easy pieChart - toTop */
/*
**bootstrap overrides
*/
.modal-backdrop.in{
	filter: alpha(opacity=80);
	opacity: .8;
}

/*bootstrap breadcrumbs*/
.ds .breadcrumb .last-item{
	color: #ffffff;
}

.breadcrumb{
	margin: 5px 0 10px 0;
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	color: black;
	font-size: 12px;
	font-weight: 500;
	line-height: 40px;
	text-transform: uppercase;
}

.breadcrumb .last-item{
	color: #1f2732;
}

.breadcrumb > .active{
	color: inherit;
	opacity: 0.9;
}

.breadcrumb li{
	display: inline-block;
}

.breadcrumb li a{
	color: black;
}

.breadcrumb li a:hover{
	color: #ff497c;
}

.ds .breadcrumb > li + li:before{
	color: #ffffff;
}

.ds .breadcrumb li a:hover{
	color: #ffffff;
}

.breadcrumb > li + li:before{
	font-family: FontAwesome;
	color: #1f2732;
	padding: 0 18px 0 16px;
	content: "\f111";
	font-size: 5px;
	position: relative;
	top: -2px;
}

@media (min-width: 1400px) {
	.breadcrumb > li + li:before{
		padding: 0 34px 0 34px;
	}
}

/* pagination */
.pagination{
	margin: 0;
	display: block;
	line-height: 0;
}

.pagination > li{
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.pagination > li a.prev,
.pagination > li span.prev{
	font-size: 0;
}

.pagination > li a.prev:before,
.pagination > li span.prev:before{
	font-size: 12px;
	font-family: FontAwesome;
	content: "\f104";
	position: relative;
	top: 0;
	color: #ffffff;
}

.pagination > li a.next,
.pagination > li span.next{
	font-size: 0;
}

.pagination > li a.next:before,
.pagination > li span.next:before{
	font-size: 12px;
	font-family: FontAwesome;
	content: "\f105";
	position: relative;
	top: 0;
	color: #ffffff;
}

.pagination > li:last-child{
	margin-right: 0;
}

.pagination > li > a,
.pagination > li > span{
	text-align: center;
	padding: 0;
	color: #1f2732;
	background-color: transparent;
	border: 4px solid #f4f4f5;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	line-height: 54px;
	margin-left: 0;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span{
	border-radius: 50%;
	background-color: black;
	border: 4px solid black;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus{
	background-color: #f4f4f5;
	border-color: #f4f4f5;
	color: #ffffff;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.pagination > .disabled > a,
.pagination > .disabled > span,
.pagination > .disabled > a:hover,
.pagination > .disabled > span:hover,
.pagination > .disabled > a:focus,
.pagination > .disabled > span:focus{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus{
	background-color: black;
	border-color: black !important;
	color: #ffffff !important;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus{
	background-color: #1f2732;
	border-color: #1f2732;
	color: #ffffff !important;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span{
	background-color: black;
	border-color: black !important;
}

/*forms*/
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea,
select,
.form-control{
	height: 60px;
	background-color: #f4f4f5;
	border: 1px solid transparent;
	border-radius: 0;
	font-weight: bold;
	box-shadow: none;
	color: #7f7f7f;
	padding-left: 28px;
	padding-right: 30px;
	padding-top: 10px;
	padding-bottom: 11px;
	font-size: 16px;
	line-height: 24px;
}

input[type="text"] + .theme_button,
input[type="email"] + .theme_button,
input[type="url"] + .theme_button,
input[type="password"] + .theme_button,
input[type="search"] + .theme_button,
input[type="tel"] + .theme_button,
input[type="number"] + .theme_button,
textarea + .theme_button,
select + .theme_button,
.form-control + .theme_button{
	position: absolute;
	padding: 0;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	box-shadow: none;
}

input[type="text"] + .theme_button i,
input[type="email"] + .theme_button i,
input[type="url"] + .theme_button i,
input[type="password"] + .theme_button i,
input[type="search"] + .theme_button i,
input[type="tel"] + .theme_button i,
input[type="number"] + .theme_button i,
textarea + .theme_button i,
select + .theme_button i,
.form-control + .theme_button i{
	font-size: 1em;
	top: 0;
}

textarea,
textarea.form-control{
	height: auto;
	padding-top: 17px;
	border-radius: 0;
}

.fields_with_shadow input[type="text"],
.fields_with_shadow input[type="email"],
.fields_with_shadow input[type="url"],
.fields_with_shadow input[type="password"],
.fields_with_shadow input[type="search"],
.fields_with_shadow input[type="tel"],
.fields_with_shadow input[type="number"],
.fields_with_shadow textarea,
.fields_with_shadow select,
.fields_with_shadow .form-control{
	background-color: transparent;
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.ds .fields_with_shadow input[type="text"],
.ds .fields_with_shadow input[type="email"],
.ds .fields_with_shadow input[type="url"],
.ds .fields_with_shadow input[type="password"],
.ds .fields_with_shadow input[type="search"],
.ds .fields_with_shadow input[type="tel"],
.ds .fields_with_shadow input[type="number"],
.ds .fields_with_shadow textarea,
.ds .fields_with_shadow select,
.ds .fields_with_shadow .form-control{
	box-shadow: 0 0px 10px 0px rgba(255, 255, 255, 0.1);
}

.form-group{
	position: relative;
}

.form-group:last-child{
	margin-bottom: 0;
}

.text-left .form-group .form-control{
	text-align: left !important;
}

/* select styling */
select.form-control{
	color: #7f7f7f;
	background-color: #ffffff;
}

select.form-control option{
	font-weight: 400;
}

select.form-control option.default-selected{
	background-color: #f4f4f5;
}

.form-control.invalid{
	box-shadow: 0 0 0 2px black;
}

.form-control:focus{
	box-shadow: none;
	border-color: #7f7f7f;
}

.ls .form-control:focus{
	border-color: #dedfe1;
}

.form-control::-moz-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.form-control:-ms-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.form-control::-webkit-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.form-control:-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

.form-control::-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

.form-control:-ms-input-placeholder{
	color: #7f7f7f;
}

.form-control::-webkit-input-placeholder{
	color: #7f7f7f;
}

.form-control:focus:-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

.form-control:focus::-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

.form-control:focus:-ms-input-placeholder{
	color: transparent !important;
}

.form-control:focus::-webkit-input-placeholder{
	color: transparent !important;
}

/*icons for forms */
form label + [class*="icon2-"]{
	position: absolute;
	font-size: 24px;
	color: #7f7f7f;
	left: 10px;
	top: 19px;
}

form label + [class*="icon2-"] + .form-control{
	padding-left: 45px;
}

.form-group label + [class*="icon2-"] + .form-control,
.form-group label + [class*="fa-"] + .form-control{
	padding-right: 45px;
}

.form-group label + [class*="icon2-"],
.form-group label + [class*="fa-"]{
	position: absolute;
	font-size: 14px;
	right: 25px;
	top: 23px;
	width: 14px;
	text-align: center;
}

.form-group [class*="icon2-"],
.form-group [class*="fa-"]{
	color: #a0ce4e;
	-webkit-transition: all 0.15s ease-in-out 0s;
	transition: all 0.15s ease-in-out 0s;
}

.form-group:hover [class*="icon2-"],
.form-group:hover [class*="fa-"]{
	color: #a0ce4e;
}

.form-group label + [class*="icon2-"].color1,
.form-group label + [class*="fa-"].color1{
	color: black;
}

label{
	font-weight: normal;
}

.form-control + [class*="icon2-"]{
	position: absolute;
	font-size: 24px;
	left: 20px;
	top: 50%;
	color: black;
}

.form-wrapper.with_background,
.form-wrapper.muted_background,
.form-wrapper.cs,
.form-wrapper.ds,
.form-wrapper.ls{
	padding: 35px;
}

.form-wrapper.columns_padding_15 [class*="col-"]{
	margin-top: 15px;
	margin-bottom: 15px;
}

/* Form with background */
.form-wrapper.with_padding{
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.form-wrapper.with_padding{
		padding: 35px;
	}
}

.form-wrapper.with_padding .wrap-forms-buttons{
	margin-top: 20px;
}

.form-wrapper.with_padding .wrap-forms-buttons .theme_button{
	margin-bottom: 0;
}

.form-wrapper.light_form{
	background-color: rgba(255, 255, 255, 0.4);
}

.form-wrapper.dark_form{
	background-color: rgba(0, 0, 0, 0.4);
}

.form-wrapper.color_form{
	background-color: rgba(255, 73, 124, 0.4);
}

/* Contact form 7 */
form.wpcf7-form .footer-form .wpcf7-form-control{
	text-align: center;
}

@media (min-width: 480px) {
	form.wpcf7-form .footer-form{
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	form.wpcf7-form .footer-form .one-half{
		width: 48.5%;
	}

	form.wpcf7-form .footer-form .footer_contact-form-submit{
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}
}

form.wpcf7-form .ajax-loader{
	display: none;
}

/*carousel*/
.carousel{
	overflow: hidden;
}

.carousel-indicators li{
	width: 10px;
	height: 10px;
	border-width: 0;
	background-color: #1f2732;
	margin: 0 3px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.carousel-indicators li:hover{
	opacity: 0.8;
}

.carousel-indicators .active{
	width: 10px;
	height: 10px;
	margin: 0 3px;
	background-color: black;
}

.carousel-control.left,
.carousel-control.right{
	border: 1px solid #ffffff;
	color: #ffffff;
	border-radius: 0;
	width: 50px;
	height: 50px;
	left: -50px;
	top: 50%;
	margin-top: -25px;
	text-shadow: none;
	opacity: 1;
	background-image: none;
	filter: alpha(opacity=100);
	text-align: center;
}

.carousel-control.right{
	right: -50px;
}

.carousel:hover .carousel-control{
	left: 50px;
}

.carousel:hover .carousel-control.right{
	left: auto;
	right: 50px;
}

.carousel-control.left span,
.carousel-control.right span{
	font-family: 'rt-icons-2';
	width: 50px;
	height: 50px;
	margin: 0;
	font-size: 16px;
	left: 0;
	top: 0;
	line-height: 50px;
}

.carousel-control .icon-prev:before{
	content: "\e7c4";
}

.carousel-control .icon-next:before{
	content: "\e7c5";
}

/* collapse accordion */
.panel-heading .panel-title{
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
}

.panel-heading .panel-title > a{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2em;
	line-height: 1;
	display: block;
	padding: 23px 50px 23px 30px;
	margin: -2px;
	background-color: black;
	border: none;
	color: #ffffff;
	border-radius: 30px;
	word-wrap: break-word;
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.ds .panel-heading .panel-title > a.collapsed:hover,
.ds .panel-heading .panel-title > a:hover{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed:hover i,
.panel-heading .panel-title > a:hover i{
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed{
	color: #ffffff;
	background-color: #1f2732;
}

.ds .panel-heading .panel-title > a.collapsed{
	background-color: #a0ce4e;
}

.panel-heading .panel-title > a.collapsed i{
	color: black;
}

.panel-heading .panel-title > a:after{
	content: '-';
	position: absolute;
	text-align: center;
	right: 0;
	top: 0;
	width: 50px;
	height: 60px;
	line-height: 60px;
	font-size: 14px;
	font-weight: 400;
}

.panel-heading .panel-title > a.collapsed:after{
	content: '+';
}

.panel-heading .panel-title > a > i{
	position: relative;
	width: 2em;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

.panel{
	box-shadow: none;
}

.panel-group .panel{
	border-radius: 5px;
	background-color: transparent;
}

.panel-default > .panel-heading{
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-default{
	border: none;
}

.panel-group .panel + .panel{
	margin-top: 20px;
}

.panel-body{
	border-top: none !important;
}

.panel-body ul{
	margin-top: 20px;
	margin-bottom: 20px;
}

.panel-body .media-left{
	padding-right: 15px;
}

.panel-body .media-left .img-circle{
	max-width: 50px;
}

.panel-body .media-left img{
	max-width: 80px;
}

.panel-group .panel-heading + .panel-collapse .panel-body{
	padding: 20px;
	border-radius: 0;
}

@media (min-width: 400px) {
	.panel-group .panel-heading + .panel-collapse .panel-body{
		padding: 30px 30px 10px 30px;
	}
}

.collapse-unstyled .panel h4{
	margin: 0 0 0.5em;
}

.collapse-unstyled .panel h4 a:after{
	color: black;
	padding: 0 5px;
	display: inline-block;
	font-family: 'FontAwesome';
	content: "\f107";
}

.collapse-unstyled .panel h4 a.collapsed:after{
	content: "\f105";
}

.collapse-unstyled .panel-content{
	padding: 10px 20px;
}

.ds .accordion2 .panel-heading .panel-title > a{
	color: #ffffff;
}

/* Accordion type 2 */
.accordion2 .panel-heading .panel-title{
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
}

.accordion2 .panel-heading .panel-title > a{
	font-size: 16px;
	text-transform: none;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 1;
	display: block;
	padding: 22px 25px 21px 5px;
	margin: -2px;
	background-color: transparent;
	color: #1f2732;
	border-top: 1px solid #dedfe1;
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	word-wrap: break-word;
}

.accordion2 .panel-heading .panel-title > a.collapsed:hover,
.accordion2 .panel-heading .panel-title > a:hover{
	background-color: transparent;
	border-color: #dedfe1;
	color: black;
}

.ds .accordion2 .panel-heading .panel-title > a.collapsed:hover,
.ds .accordion2 .panel-heading .panel-title > a:hover{
	background-color: black;
	border-color: #dedfe1;
	color: #ffffff;
}

.accordion2 .panel-heading .panel-title > a.collapsed:hover i,
.accordion2 .panel-heading .panel-title > a:hover i{
	color: black;
}

.accordion2 .panel-heading .panel-title > a.collapsed{
	color: #1f2732;
	background-color: transparent;
}

.ds .accordion2 .panel-heading .panel-title > a.collapsed{
	background-color: transparent;
	color: #ffffff;
}

.accordion2 .panel-heading .panel-title > a.collapsed i{
	color: black;
}

.accordion2 .panel-heading .panel-title > a:after{
	content: '-';
	position: absolute;
	text-align: right;
	right: 0;
	top: 0;
	width: 20px;
	height: 60px;
	line-height: 60px;
	font-size: 16px;
	font-weight: 600;
}

.accordion2 .panel-heading .panel-title > a.collapsed:after{
	content: '+';
}

.accordion2 .panel-heading .panel-title > a > i{
	position: relative;
	width: 2em;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

.accordion2 .panel-group .panel:last-of-type .panel-heading .panel-title > a{
	border-bottom: 1px solid #dedfe1;
}

.accordion2 .panel{
	box-shadow: none;
}

.accordion2 .panel-group .panel{
	border-radius: 5px;
	background-color: transparent;
}

.accordion2 .panel-default > .panel-heading{
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.accordion2 .panel-default{
	border: none;
}

.accordion2 .panel-group .panel + .panel{
	margin-top: 0;
}

.accordion2 .panel-body{
	border-top: none !important;
}

.accordion2 .panel-body ul{
	margin-top: 20px;
	margin-bottom: 20px;
}

.accordion2 .panel-body .media-left{
	padding-right: 15px;
}

.accordion2 .panel-body .media-left .img-circle{
	max-width: 50px;
}

.accordion2 .panel-body .media-left img{
	max-width: 80px;
}

.accordion2 .panel-group .panel-heading + .panel-collapse .panel-body{
	padding: 0 3px 20px 3px;
	border-radius: 0;
	z-index: 2;
}

.accordion2 .collapse-unstyled .panel h4{
	margin: 0 0 0.5em;
}

.accordion2 .collapse-unstyled .panel h4 a:after{
	color: black;
	padding: 0 5px;
	display: inline-block;
	font-family: 'FontAwesome';
	content: "\f107";
}

.accordion2 .collapse-unstyled .panel h4 a.collapsed:after{
	content: "\f105";
}

.accordion2 .collapse-unstyled .panel-content{
	padding: 10px 20px;
}

/* datetimepicker */
.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover{
	background-color: black !important;
	color: #ffffff !important;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget .timepicker .btn-primary{
	color: #ffffff;
	background-color: black;
	border-color: black;
	border-radius: 5px;
}

.bootstrap-datetimepicker-widget a{
	color: black;
}

/*progress bars*/
p + .progress{
	margin-top: -10px;
}

.progress{
	overflow: visible;
	height: 4px;
	margin-bottom: 18px;
	position: relative;
	border-radius: 0;
	background-color: rgba(164, 169, 185, 0.3);
	box-shadow: none;
	border: none;
}

.with_background .progress{
	background-color: #ffffff;
}

.progress:last-child{
	margin-bottom: 10px;
}

.progress-bar{
	background-color: black;
	color: inherit;
	box-shadow: none;
	height: 4px;
}

.progress-bar span{
	position: absolute;
	right: 0;
	top: -30px;
	font-size: 14px;
	letter-spacing: 0.2em;
	font-weight: bold;
	text-transform: uppercase;
}

.ls .progress-bar span{
	color: #1f2732;
}

.progress-title{
	font-weight: bold;
	font-size: 12px;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	margin-bottom: 16px;
}

.ls .progress-title{
	color: #1f2732;
}

.progress-level{
	font-size: 12px;
	font-weight: 400;
	float: right;
	position: relative;
	top: 4px;
}

.progress-bar-success{
	background-color: #a0ce4e;
}

.progress-bar-info{
	background-color: #00bea3;
}

.progress-bar-warning{
	background-color: #f1894c;
}

.progress-bar-danger{
	background-color: black;
}

.alert{
	border-radius: 0;
	border-width: 0 0 0 4px;
	padding: 30px;
	margin-bottom: 40px;
}

.media{
	margin-top: 16px;
}

.media .media-object{
	max-width: 100px;
}

/*nav*/
.nav-tabs > li{
	margin-bottom: -2px;
}

.nav > li > a:hover,
.nav > li > a:focus{
	background-color: inherit;
}

/*tabs*/
.tab-content{
	border-radius: 0;
	padding: 35px 35px;
	border: 0;
	background-color: #f4f4f5;
	margin-bottom: 40px;
}

.tab-content h6{
	font-size: 16px;
	margin-bottom: 10px;
}

@media (min-width: 1200px) {
	.tab-content{
		padding: 40px;
	}
}

.tab-content.no-border{
	padding: 36px 0 0;
	border: none;
}

.tab-content.top-color-border{
	position: relative;
}

.tab-content.top-color-border > :before{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: -2px;
	height: 7px;
	background-color: black;
}

.tab-content .featured-tab-image{
	margin: -30px -31px 24px -31px;
}

.nav-tabs{
	border-bottom-color: transparent;
	margin-top: 0;
	margin-bottom: 0;
}

@media (max-width: 480px) {
	ul.nav-tabs.nav > li{
		float: none !important;
		margin-bottom: 10px;
	}
}

ul.nav-tabs.nav > li > a{
	font-size: 12px;
	padding: 15px 20px 15px;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	font-weight: bold;
	margin-right: 4px;
	margin-top: 0;
	margin-bottom: 0;
	border: 0;
	background-color: #1f2732;
	color: #ffffff;
	border-radius: 30px 30px 0 0;
	line-height: 1.42857143;
}

.ds ul.nav-tabs.nav > li > a{
	background-color: #ffffff;
	color: #1f2732;
}

@media (max-width: 480px) {
	ul.nav-tabs.nav > li > a{
		padding: 12px 10px 12px;
		border-radius: 15px;
	}
}

@media (min-width: 768px) {
	ul.nav-tabs.nav > li > a{
		padding: 12px 10px 12px;
	}
}

@media (min-width: 1400px) {
	ul.nav-tabs.nav > li > a{
		padding: 22px 35px 21px;
		min-width: 170px;
	}
}

ul.nav-tabs.nav > li.active > a{
	line-height: 1.5;
}

ul.nav-tabs.nav > li.active > a,
ul.nav-tabs.nav > li.active > a:hover,
ul.nav-tabs.nav > li.active > a:focus{
	border: 0;
	background-color: #f4f4f5;
	color: #1f2732;
}

ul.nav-tabs.nav > li > a:hover,
ul.nav-tabs.nav > li > a:focus,
ul.nav-tabs.nav.nav-justified > .active > a{
	border: 0;
	background-color: #f4f4f5;
	color: #1f2732;
}

.bootstrap-tabs.color_1 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a:focus,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a:focus,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a:focus,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a:focus{
	color: #ffffff;
}

.bootstrap-tabs.color_1 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_1 ul.nav-tabs.nav > li.active > a:focus{
	background-color: black;
}

.bootstrap-tabs.color_2 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_2 ul.nav-tabs.nav > li.active > a:focus{
	background-color: #a0ce4e;
}

.bootstrap-tabs.color_3 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_3 ul.nav-tabs.nav > li.active > a:focus{
	background-color: #00bea3;
}

.bootstrap-tabs.color_4 ul.nav-tabs.nav > li > a:hover,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li > a:focus,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a:hover,
.bootstrap-tabs.color_4 ul.nav-tabs.nav > li.active > a:focus{
	background-color: #f1894c;
}

/*vertical tabs*/
.vertical-tabs .tab-content.no-border{
	padding-top: 0;
}

.vertical-tabs .nav > li > a{
	background-color: #1f2732;
	color: #ffffff;
	padding: 18px 60px 18px 40px;
	margin-bottom: 10px;
	position: relative;
}

.vertical-tabs .nav > li > a i{
	position: relative;
	top: 1px;
	padding-right: 12px;
}

.vertical-tabs .nav > li > a:after{
	color: rgba(255, 255, 255, 0.8);
	font-family: 'rt-icons-2';
	content: "\e7c5";
	position: absolute;
	font-size: 12px;
	line-height: 58px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover{
	background-color: #f4f4f5;
}

@media (min-width: 768px) {
	.vertical-tabs .nav > li.active{
		position: relative;
		z-index: 2;
	}
}

/* bs tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th{
	padding: 13px 8px;
	border-color: #dedfe1;
}

.table{
	border-top: 1px solid #dedfe1;
	border-bottom: 1px solid #dedfe1;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td{
	border-left: 1px solid #dedfe1;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th{
	border-bottom-width: 1px;
}

.table-responsive{
	border: none;
}

/*bs buttons*/
.btn{
	padding: 18px 30px;
	text-transform: uppercase;
	margin: 0 2px 6px 0;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.btn:hover,
.btn:focus{
	outline: none;
}

li.media:before,
li.media:after{
	display: block;
	content: '';
	clear: both;
}

/* bs dropdown menu */
.dropdown-menu{
	box-shadow: none;
	border-radius: 0;
	border-color: rgba(164, 169, 185, 0.3);
	z-index: 1001;
	font-size: 16px;
	margin-top: 0;
}

[class*="right"] .dropdown-menu{
	right: 0;
	left: auto;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover{
	color: #1f2732;
	background-color: transparent;
}

.well{
	box-shadow: none;
	border: none;
	border-radius: 0;
}

/* our custom video placeholser*/
.embed-responsive-3by2{
	padding-bottom: 66.666%;
}

.embed-placeholder:after{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "";
	background: url("../img/video.png") 50% 50% no-repeat;
	position: absolute;
	color: #ffffff;
	font-size: 14px;
	line-height: 56px;
	width: 60px;
	height: 42px;
	text-align: center;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	margin-top: -30px;
}

/*
**general styles
*/
html{
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
}

::-moz-selection{
	color: #ffffff;
	background-color: black;
}

::selection{
	color: #ffffff;
	background-color: black;
}

::-moz-selection{
	color: #ffffff;
	background-color: black;
}

body{
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6{
	word-break: break-word;
	font-weight: 500;
	line-height: 1;
}

h1,
.post.vertical-item .entry-content h1{
	font-size: 56px;
	margin-top: 1.15em;
	margin-bottom: 0.37em;
}

@media (max-width: 1024px) {
	h1,
	.post.vertical-item .entry-content h1{
		font-size: 52px;
	}
}

@media (max-width: 479px) {
	h1,
	.post.vertical-item .entry-content h1{
		font-size: 48px;
	}
}

h2,
.post.vertical-item .entry-content h2{
	font-size: 48px;
	margin-top: 0.9em;
	margin-bottom: 0.48em;
}

h2.big_title,
.post.vertical-item .entry-content h2.big_title{
	font-size: 54px;
}

@media (max-width: 1024px) {
	h2,
	.post.vertical-item .entry-content h2{
		font-size: 44px;
	}
}

@media (max-width: 479px) {
	h2,
	.post.vertical-item .entry-content h2{
		font-size: 40px;
	}

	h2.xs_middle,
	.post.vertical-item .entry-content h2.xs_middle{
		font-size: 34px;
	}
}

h3,
.post.vertical-item .entry-content h3{
	font-size: 42px;
	margin-top: 1.1em;
	margin-bottom: 0.57em;
}

@media (max-width: 1024px) {
	h3,
	.post.vertical-item .entry-content h3{
		font-size: 38px;
	}
}

@media (max-width: 479px) {
	h3,
	.post.vertical-item .entry-content h3{
		font-size: 34px;
	}
}

h4,
.post.vertical-item .entry-content h4{
	font-size: 36px;
	margin-top: 1.27em;
	margin-bottom: 0.7em;
}

@media (max-width: 1024px) {
	h4,
	.post.vertical-item .entry-content h4{
		font-size: 32px;
	}
}

@media (max-width: 479px) {
	h4,
	.post.vertical-item .entry-content h4{
		font-size: 28px;
	}
}

h5,
.post.vertical-item .entry-content h5{
	font-size: 30px;
	margin-top: 1.57em;
	margin-bottom: 0.8em;
}

@media (max-width: 1024px) {
	h5,
	.post.vertical-item .entry-content h5{
		font-size: 26px;
	}
}

h6,
.post.vertical-item .entry-content h6{
	font-size: 24px;
	margin-top: 1.9em;
	margin-bottom: 1.1em;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child{
	margin-top: 0;
}

p{
	margin-bottom: 20px;
}

.big_text p{
	font-size: 20px;
}

p:last-child{
	margin-bottom: 0;
}

blockquote{
	position: relative;
	margin-top: 45px;
	margin-bottom: 50px;
	text-align: left;
	padding: 0 0 0 33px;
	border-left: 4px solid black;
	max-width: 100%;
	font-size: 20px;
	font-weight: 300;
	line-height: 30px;
	font-style: italic;
	color: #1f2732;
}

.ds blockquote,
.cs blockquote{
	color: #ffffff;
}

blockquote .avatar{
	position: relative;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	z-index: 1;
}

blockquote .avatar img{
	min-width: 150px;
	max-width: 150px;
	border-radius: 50%;
	margin: 0;
}

.ls.ms blockquote .avatar img{
	background-color: #ffffff;
}

.ds blockquote .avatar img{
	background-color: #191f28;
}

blockquote .item-meta,
blockquote .blockqoute-meta{
	margin-top: 10px;
	font-style: normal;
	font-size: 14px;
	text-transform: uppercase;
}

blockquote footer{
	font-weight: 400;
	text-transform: none;
	font-style: normal;
	font-size: 32px;
	color: #1f2732;
	line-height: 20px;
	margin-top: 35px;
}

.cs blockquote footer,
.ds blockquote footer{
	color: #ffffff;
}

blockquote footer:before{
	display: none;
}

blockquote .author-job{
	text-transform: none;
	font-style: normal;
	display: block;
	margin-top: 5px;
	line-height: 1;
}

.cs blockquote .author-job,
.ds blockquote .author-job{
	color: #ffffff;
}

blockquote .author-name,
blockquote cite,
blockquote cite a{
	font-style: normal;
	text-align: center;
	text-transform: uppercase;
	color: black;
	display: inline-block;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
}

img,
figure{
	max-width: 100%;
	height: auto;
}

figcaption,
.entry-caption{
	padding: 2px 0 12px;
}

figcaption p,
.entry-caption p{
	margin-bottom: 0;
}

label{
	color: #1f2732;
}

a{
	text-decoration: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

a:hover{
	color: black;
	text-decoration: none;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus{
	outline: medium none;
	text-decoration: none;
}

a > img{
	-webkit-transition: all 0.15s ease-in-out 0s;
	transition: all 0.15s ease-in-out 0s;
}

a:hover > img{
	opacity: 0.8;
}

hr{
	border: none;
	height: 1px;
	background-color: #ddd;
}

iframe{
	border: none;
	max-width: 100%;
}

input[type='number']{
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
	-webkit-appearance: none;
}

table{
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

table td,
table th{
	padding: 13px 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border: 1px solid #dedfe1;
}

table th{
	color: #1f2732;
	font-weight: normal;
}

.table_template thead{
	background-color: black;
	color: #1f2732;
}

.table_template thead.light{
	background-color: #ffffff;
}

.table_template th{
	font-weight: 700;
}

.table_template td,
.table_template th{
	padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd){
	background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even){
	background-color: rgba(17, 17, 17, 0.05);
}

/*
**styled lists
*/
ul,
ol{
	padding-left: 30px;
}

.list1 ol,
ol.list1{
	counter-reset: li;
	list-style: none outside none;
	padding: 0;
	margin-bottom: 0;
}

.list1 ol li,
ol.list1 li{
	position: relative;
	padding: 4px 0 4px 30px;
	font-size: 16px;
	line-height: 30px;
	font-weight: 300;
	color: #1f2732;
}

.list1 ol li:after,
ol.list1 li:after{
	content: counter(li, decimal-leading-zero) ".";
	counter-increment: li;
	position: absolute;
	left: 2px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-weight: 500;
	color: black;
}

.ds .list1 ol li,
.ds ol.list1 li{
	color: #7f7f7f;
}

.list1 ul,
ul.list1{
	list-style: none;
	padding-left: 10px;
	margin-bottom: 20px;
}

.list1 ul li,
ul.list1 li{
	position: relative;
	padding: 5px 0 5px 5px;
	font-size: 16px;
	line-height: 30px;
	font-weight: 300;
	color: #1f2732;
}

.ds .list1 ul li,
.ds ul.list1 li{
	color: #7f7f7f;
}

.list1 ul li:before,
ul.list1 li:before{
	font-family: 'FontAwesome';
	font-size: 5px;
	content: "\f111";
	position: absolute;
	left: -10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: black;
}

/*  Buttons */
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
a.wp-block-button__link,
button,
input[type="submit"]{
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 20px 30px 20px;
	margin-bottom: 4px;
	line-height: 1;
	display: inline-block;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	text-align: center;
	color: #ffffff;
	border: 4px solid black;
	background-color: black;
	border-radius: 50px;
	position: relative;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_buttons a:active,
.theme_button:hover,
.theme_button:focus,
.theme_button:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.buttons a:hover,
.buttons a:focus,
.buttons a:active,
a.wp-block-button__link:hover,
a.wp-block-button__link:focus,
a.wp-block-button__link:active,
button:hover,
button:focus,
button:active,
input[type="submit"]:hover,
input[type="submit"]:focus,
input[type="submit"]:active{
	color: black;
	background-color: transparent;
}

a.wp-block-button__link{
	color: #ffffff !important;
}

a.wp-block-button__link:hover,
a.wp-block-button__link:focus,
a.wp-block-button__link:active{
	color: black !important;
	background-color: transparent;
}

/* Sizes */
.theme_button.large_height{
	padding: 30px 35px 30px;
}

.theme_button.medium_height{
	padding: 20px 30px 20px;
}

.theme_button.small_height{
	padding: 10px 30px 10px;
}

.theme_button + .theme_button{
	margin-left: 20px;
}

.theme_button.small_button + .theme_button.small_button{
	margin-left: 8px;
}

.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.form-submit [type="submit"]{
	color: #ffffff;
	border-color: black;
	background-color: black;
}

.theme_buttons.color1 a:hover,
.theme_buttons.color1 a:focus,
.theme_buttons.color1 a:active,
.theme_button.color1:hover,
.theme_button.color1:focus,
.theme_button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.form-submit [type="submit"]:hover,
.form-submit [type="submit"]:focus,
.form-submit [type="submit"]:active{
	color: black;
	background-color: transparent;
}

.theme_buttons.color2 a,
.theme_button.color2{
	color: #ffffff;
	border-color: #a0ce4e;
	background-color: #a0ce4e;
}

.theme_buttons.color2 a:hover,
.theme_buttons.color2 a:focus,
.theme_buttons.color2 a:active,
.theme_button.color2:hover,
.theme_button.color2:focus,
.theme_button.color2:active{
	color: #a0ce4e;
	background-color: transparent;
}

.theme_buttons.color3 a,
.theme_button.color3{
	color: #ffffff;
	border-color: #00bea3;
	background-color: #00bea3;
}

.theme_buttons.color3 a:hover,
.theme_buttons.color3 a:focus,
.theme_buttons.color3 a:active,
.theme_button.color3:hover,
.theme_button.color3:focus,
.theme_button.color3:active{
	color: #00bea3;
	background-color: transparent;
}

.theme_buttons.color4 a,
.theme_button.color4{
	color: #ffffff;
	border-color: #f1894c;
	background-color: #f1894c;
}

.theme_buttons.color4 a:hover,
.theme_buttons.color4 a:focus,
.theme_buttons.color4 a:active,
.theme_button.color4:hover,
.theme_button.color4:focus,
.theme_button.color4:active{
	color: #f1894c;
	background-color: transparent;
}

.theme_buttons.color_white a,
.theme_button.color_white{
	color: #1f2732;
	border-color: #ffffff;
	background-color: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.theme_buttons.color_white a:hover,
.theme_buttons.color_white a:focus,
.theme_buttons.color_white a:active,
.theme_button.color_white:hover,
.theme_button.color_white:focus,
.theme_button.color_white:active{
	color: black;
	background-color: transparent;
	border-color: #ffffff;
	box-shadow: none;
}

.theme_buttons.color_grey a,
.theme_button.color_grey{
	color: #1f2732;
	border-color: #f4f4f5;
	background-color: #f4f4f5;
}

.theme_buttons.color_grey a:hover,
.theme_buttons.color_grey a:focus,
.theme_buttons.color_grey a:active,
.theme_button.color_grey:hover,
.theme_button.color_grey:focus,
.theme_button.color_grey:active{
	border-color: #ffffff;
	background-color: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus{
	color: #1f2732;
	opacity: 1;
	text-decoration: none;
	outline: none;
	-webkit-transition: all 0.05s linear 0s;
	transition: all 0.05s linear 0s;
}

.is-style-outline .wp-block-button__link{
	background-color: transparent;
	border: 4px solid black;
	color: black !important;
}

.is-style-outline .wp-block-button__link:hover,
.is-style-outline .wp-block-button__link:focus,
.is-style-outline .wp-block-button__link:active{
	color: #ffffff !important;
	background-color: black;
}

.ds .theme_button.inverse.color1{
	color: #ffffff;
}

.theme_button.inverse.color1{
	color: black;
	background-color: transparent;
	border-color: black;
}

.theme_button.inverse.color1:hover,
.theme_button.inverse.color1:focus,
.theme_button.inverse.color1:active{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.theme_button.inverse.color2{
	color: #a0ce4e;
	background-color: transparent;
	border-color: #a0ce4e;
}

.theme_button.inverse.color2:hover,
.theme_button.inverse.color2:focus,
.theme_button.inverse.color2:active{
	background-color: #a0ce4e;
	border-color: #a0ce4e;
	color: #ffffff;
}

.theme_button.inverse.color3{
	color: #00bea3;
	background-color: transparent;
	border-color: #00bea3;
}

.theme_button.inverse.color3:hover,
.theme_button.inverse.color3:focus,
.theme_button.inverse.color3:active{
	background-color: #00bea3;
	border-color: #00bea3;
	color: #ffffff;
}

.theme_button.inverse.color4{
	color: #f1894c;
	background-color: transparent;
	border-color: #f1894c;
}

.theme_button.inverse.color4:hover,
.theme_button.inverse.color4:focus,
.theme_button.inverse.color4:active{
	background-color: #f1894c;
	border-color: #f1894c;
	color: #ffffff;
}

.theme_button.inverse.color_white{
	color: #1f2732;
	background-color: #ffffff;
	border-color: #f4f4f5;
	box-shadow: none;
}

.theme_button.inverse.color_white:hover,
.theme_button.inverse.color_white:focus,
.theme_button.inverse.color_white:active{
	background-color: #f4f4f5;
	border-color: #f4f4f5;
}

.theme_button.inverse.color_grey{
	color: #1f2732;
	background-color: #ffffff;
	border-color: #f4f4f5;
	box-shadow: none;
}

.theme_button.inverse.color_grey:hover,
.theme_button.inverse.color_grey:focus,
.theme_button.inverse.color_grey:active{
	background-color: #f4f4f5;
	border-color: #f4f4f5;
}

.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active{
	color: black;
}

.muted_buttons a,
.muted_button{
	opacity: 0.2;
}

.small_buttons a,
.small_button{
	padding: 5px 7px 5px;
	text-transform: uppercase;
	min-width: auto;
	color: #ffffff !important;
}

.small_buttons a:hover,
.small_buttons a:focus,
.small_button:hover,
.small_button:focus{
	color: black !important;
}

.small_buttons a.disabled,
.small_button.disabled{
	color: black !important;
	background-color: transparent;
}

.square_buttons a,
.square_button{
	padding-left: 10px;
	padding-right: 10px;
	min-width: auto;
}

.wide_buttons a,
.wide_button{
	min-width: 230px;
	padding-right: 30px;
	padding-left: 30px;
}

@media (min-width: 1600px) {
	.wide_buttons a,
	.wide_button{
		min-width: 230px;
	}
}

.block_buttons a,
.block_button{
	display: block;
	width: 100%;
	text-align: center;
}

.no_bg_button{
	background-color: transparent !important;
}

.no_bg_button.color1{
	color: black;
}

.no_bg_button[class*="color"]:hover,
.no_bg_button[class*="color"]:focus,
.no_bg_button[class*="color"]:active{
	color: #1f2732;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"]{
	position: relative;
	top: .08em;
	padding-right: 0.1em;
}

p > [class*="rt-icon"]{
	font-size: 1.1em;
	position: relative;
	top: .1em;
	line-height: 0;
	padding: 0 0.2em;
}

p > [class*="rt-icon"] + [class*="rt-icon"]{
	margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_button > [class*="rt-icon"]{
	font-size: 1.8em;
	line-height: 0;
	position: relative;
	top: 5px;
	padding: 0 3px;
}

.theme_buttons a > [class*="glyphicon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="glyphicon"],
.theme_button > [class*="fa-"]{
	font-size: 1.5em;
	position: relative;
	top: 3px;
	line-height: 0;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme_button{
	margin-bottom: 0;
}

.btn-group-lg > .btn,
.btn-lg{
	padding: 16px 16px;
}

.btn-group-sm > .btn,
.btn-sm{
	padding: 5px 10px;
}

.btn-group-xs > .btn,
.btn-xs{
	padding: 1px 5px;
}

.simple_link{
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.read-more{
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.theme_button[type="reset"]{
	display: none;
}

/*
**vertical margins and paddings
*/
.bottommargin_0{
	margin-bottom: 0 !important;
}

/*same top and bottom paddings for sections*/
.section_padding_0 > [class*="container"]{
	padding-top: 0;
	padding-bottom: 0;
}

.section_padding_5 > [class*="container"]{
	padding-top: 5px;
	padding-bottom: 5px;
}

.section_padding_10 > [class*="container"]{
	padding-top: 10px;
	padding-bottom: 10px;
}

.section_padding_15 > [class*="container"]{
	padding-top: 15px;
	padding-bottom: 15px;
}

.section_padding_25 > [class*="container"]{
	padding-top: 25px;
	padding-bottom: 25px;
}

.section_padding_35 > [class*="container"]{
	padding-top: 35px;
	padding-bottom: 35px;
}

.section_padding_40 > [class*="container"]{
	padding-top: 40px;
	padding-bottom: 40px;
}

.section_padding_50 > [class*="container"]{
	padding-top: 50px;
	padding-bottom: 50px;
}

.section_padding_65 > [class*="container"]{
	padding-top: 65px;
	padding-bottom: 65px;
}

.section_padding_75 > [class*="container"]{
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_100 > [class*="container"]{
	padding-top: 100px;
	padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"]{
	padding-top: 0;
}

.section_padding_top_5 > [class*="container"]{
	padding-top: 5px;
}

.section_padding_top_15 > [class*="container"]{
	padding-top: 15px;
}

.section_padding_top_20 > [class*="container"]{
	padding-top: 20px;
}

.section_padding_top_25 > [class*="container"]{
	padding-top: 25px;
}

.section_padding_top_30 > [class*="container"]{
	padding-top: 30px;
}

.section_padding_top_40 > [class*="container"]{
	padding-top: 40px;
}

.section_padding_top_50 > [class*="container"]{
	padding-top: 50px;
}

.section_padding_top_65 > [class*="container"]{
	padding-top: 65px;
}

.section_padding_top_75 > [class*="container"]{
	padding-top: 75px;
}

.section_padding_top_85 > [class*="container"]{
	padding-top: 85px;
}

.section_padding_top_90 > [class*="container"]{
	padding-top: 90px;
}

.section_padding_top_100 > [class*="container"]{
	padding-top: 100px;
}

.section_padding_top_110 > [class*="container"]{
	padding-top: 110px;
}

.section_padding_top_120 > [class*="container"]{
	padding-top: 120px;
}

.section_padding_top_125 > [class*="container"]{
	padding-top: 125px;
}

.section_padding_top_130 > [class*="container"]{
	padding-top: 130px;
}

.section_padding_top_140 > [class*="container"]{
	padding-top: 140px;
}

.section_padding_top_150 > [class*="container"]{
	padding-top: 150px;
}

.section_padding_top_170 > [class*="container"]{
	padding-top: 170px;
}

.section_padding_top_200 > [class*="container"]{
	padding-top: 200px;
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"]{
	padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"]{
	padding-bottom: 5px;
}

.section_padding_bottom_15 > [class*="container"]{
	padding-bottom: 15px;
}

.section_padding_bottom_20 > [class*="container"]{
	padding-bottom: 20px;
}

.section_padding_bottom_25 > [class*="container"]{
	padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"]{
	padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"]{
	padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"]{
	padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"]{
	padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"]{
	padding-bottom: 75px;
}

.section_padding_bottom_85 > [class*="container"]{
	padding-bottom: 85px;
}

.section_padding_bottom_90 > [class*="container"]{
	padding-bottom: 90px;
}

.section_padding_bottom_100 > [class*="container"]{
	padding-bottom: 100px;
}

.section_padding_bottom_110 > [class*="container"]{
	padding-bottom: 110px;
}

.section_padding_bottom_120 > [class*="container"]{
	padding-bottom: 120px;
}

.section_padding_bottom_125 > [class*="container"]{
	padding-bottom: 125px;
}

.section_padding_bottom_130 > [class*="container"]{
	padding-bottom: 130px;
}

.section_padding_bottom_140 > [class*="container"]{
	padding-bottom: 140px;
}

.section_padding_bottom_150 > [class*="container"]{
	padding-bottom: 150px;
}

.section_padding_bottom_170 > [class*="container"]{
	padding-bottom: 170px;
}

.section_padding_bottom_200 > [class*="container"]{
	padding-bottom: 200px;
}

@media (max-width: 1199px) {
	.section_padding_100 > [class*="container"],
	.section_padding_top_90 > [class*="container"],
	.section_padding_top_100 > [class*="container"],
	.section_padding_top_110 > [class*="container"],
	.section_padding_top_120 > [class*="container"],
	.section_padding_top_125 > [class*="container"],
	.section_padding_top_130 > [class*="container"],
	.section_padding_top_140 > [class*="container"],
	.section_padding_top_150 > [class*="container"],
	.section_padding_top_170 > [class*="container"],
	.section_padding_top_200 > [class*="container"]{
		padding-top: 75px;
	}

	.section_padding_100 > [class*="container"],
	.section_padding_bottom_90 > [class*="container"],
	.section_padding_bottom_100 > [class*="container"],
	.section_padding_bottom_110 > [class*="container"],
	.section_padding_bottom_120 > [class*="container"],
	.section_padding_bottom_125 > [class*="container"],
	.section_padding_bottom_130 > [class*="container"],
	.section_padding_bottom_140 > [class*="container"],
	.section_padding_bottom_150 > [class*="container"],
	.section_padding_bottom_170 > [class*="container"],
	.section_padding_bottom_200 > [class*="container"]{
		padding-bottom: 75px;
	}
}

@media (max-width: 479px) {
	.section_padding_100 > [class*="container"],
	.section_padding_top_90 > [class*="container"],
	.section_padding_top_100 > [class*="container"],
	.section_padding_top_110 > [class*="container"],
	.section_padding_top_120 > [class*="container"],
	.section_padding_top_125 > [class*="container"],
	.section_padding_top_130 > [class*="container"],
	.section_padding_top_140 > [class*="container"],
	.section_padding_top_150 > [class*="container"],
	.section_padding_top_170 > [class*="container"],
	.section_padding_top_200 > [class*="container"]{
		padding-top: 50px;
	}

	.section_padding_100 > [class*="container"],
	.section_padding_bottom_90 > [class*="container"],
	.section_padding_bottom_100 > [class*="container"],
	.section_padding_bottom_110 > [class*="container"],
	.section_padding_bottom_120 > [class*="container"],
	.section_padding_bottom_125 > [class*="container"],
	.section_padding_bottom_130 > [class*="container"],
	.section_padding_bottom_140 > [class*="container"],
	.section_padding_bottom_150 > [class*="container"],
	.section_padding_bottom_170 > [class*="container"],
	.section_padding_bottom_200 > [class*="container"]{
		padding-bottom: 50px;
	}
}

[class*="col-"]{
	margin-top: 10px;
	margin-bottom: 10px;
}

[class*="col-"] > .checkbox,
[class*="col-"] > .radio{
	margin: 0;
}

[class*="_0"] [class*="col-"],
header [class*="col-"]{
	margin-top: 0;
	margin-bottom: 0;
	min-height: 0;
}

.page_topline [class*="col-"]{
	margin-top: 0;
	margin-bottom: 0;
	min-height: 0;
}

.columns_padding_1 [class*="col-"]{
	margin-top: 0;
	margin-bottom: 1px;
}

.columns_padding_2 [class*="col-"]{
	margin-top: 1px;
	margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"]{
	margin-top: 5px;
	margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"]{
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_padding_0 [class*="col-"]{
	padding: 0;
}

.columns_padding_1 [class*="col-"]{
	padding-left: 0;
	padding-right: 1px;
}

.columns_padding_2 [class*="col-"]{
	padding-left: 1px;
	padding-right: 1px;
}

.columns_padding_5 [class*="col-"]{
	padding-left: 5px;
	padding-right: 5px;
}

.columns_padding_10 [class*="col-"]{
	padding-left: 10px;
	padding-right: 10px;
}

/* for section class '.column_padding_*' */
.columns_padding_0 > .container,
.columns_padding_1 > .container,
.columns_padding_2 > .container{
	padding-left: 15px;
	padding-right: 15px;
}

.columns_padding_5 > .container{
	padding-left: 25px;
	padding-right: 25px;
}

.columns_padding_10 > .container{
	padding-left: 25px;
	padding-right: 25px;
}

/* for row class '.column_padding_*' */
.row.columns_padding_0,
.columns_padding_0 .row{
	margin: 0;
}

.row.columns_padding_1,
.columns_padding_1 .row{
	margin-left: 0;
	margin-right: -1px;
}

.row.columns_padding_2,
.columns_padding_2 .row{
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_5,
.columns_padding_5 .row{
	margin-left: -5px;
	margin-right: -5px;
}

.row.columns_padding_10,
.columns_padding_10 .row{
	margin-left: -10px;
	margin-right: -10px;
}

@media (min-width: 992px) {
	.columns_padding_25 > [class*="container"] > .row,
	.row.columns_padding_25{
		margin-right: -25px;
		margin-left: -25px;
	}

	.columns_padding_25 > [class*="container"] > .row > [class*="col-"],
	.row.columns_padding_25 > [class*="col-"]{
		padding-left: 25px;
		padding-right: 25px;
	}

	.columns_padding_30 > [class*="container"] > .row,
	.row.columns_padding_30{
		margin-right: -30px;
		margin-left: -30px;
	}

	.columns_padding_30 > [class*="container"] > .row > [class*="col-"],
	.row.columns_padding_30 > [class*="col-"]{
		padding-left: 30px;
		padding-right: 30px;
	}

	.columns_padding_50 > [class*="container"] > .row{
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_50 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 50px;
		padding-right: 50px;
	}

	.columns_padding_80 > [class*="container"] > .row{
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 50px;
		padding-right: 50px;
	}
}

/* extra large column paddings */
@media (max-width: 992px) {
	.columns_padding_60 > [class*="container"] > .row,
	.columns_padding_80 > [class*="container"] > .row{
		margin-right: 0;
		margin-left: 0;
	}

	.columns_padding_60 > [class*="container"] > .row > [class*="col-"],
	.columns_padding_80 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (min-width: 992px) {
	.columns_padding_60 > [class*="container"] > .row{
		margin-right: 0;
		margin-left: 0;
	}

	.columns_padding_60 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 30px;
		padding-right: 30px;
	}

	.columns_padding_80 > [class*="container"] > .row{
		margin-right: 0;
		margin-left: 0;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (min-width: 1200px) {
	.columns_padding_60 > [class*="container"] > .row{
		margin-right: 0;
		margin-left: 0;
	}

	.columns_padding_60 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 60px;
		padding-right: 60px;
	}

	.columns_padding_80 > [class*="container"] > .row{
		margin-right: 0;
		margin-left: 0;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"]{
		padding-left: 80px;
		padding-right: 80px;
	}
}

/*top and bottom margins for columns inside sections and rows */
.columns_margin_top_0 [class*="col-"],
.row.columns_margin_top_0 [class*="col-"]{
	margin-top: 0;
}

.columns_margin_top_5 [class*="col-"],
.row.columns_margin_top_5 [class*="col-"]{
	margin-top: 5px;
}

.columns_margin_top_10 [class*="col-"],
.row.columns_margin_top_10 [class*="col-"]{
	margin-top: 10px;
}

.columns_margin_top_15 [class*="col-"],
.row.columns_margin_top_15 [class*="col-"]{
	margin-top: 15px;
}

.columns_margin_top_20 [class*="col-"],
.row.columns_margin_top_20 [class*="col-"]{
	margin-top: 20px;
}

.columns_margin_top_30 [class*="col-"],
.row.columns_margin_top_30 [class*="col-"]{
	margin-top: 30px;
}

.columns_margin_bottom_0 [class*="col-"],
.row.columns_margin_bottom_0 [class*="col-"]{
	margin-bottom: 0;
}

.columns_margin_bottom_5 [class*="col-"],
.row.columns_margin_bottom_5 [class*="col-"]{
	margin-bottom: 5px;
}

.columns_margin_bottom_10 [class*="col-"],
.row.columns_margin_bottom_10 [class*="col-"]{
	margin-bottom: 10px;
}

.columns_margin_bottom_15 [class*="col-"],
.row.columns_margin_bottom_15 [class*="col-"]{
	margin-bottom: 15px;
}

.columns_margin_bottom_20 [class*="col-"],
.row.columns_margin_bottom_20 [class*="col-"]{
	margin-bottom: 20px;
}

.columns_margin_bottom_30 [class*="col-"],
.row.columns_margin_bottom_30 [class*="col-"]{
	margin-bottom: 30px;
}

.columns_margin_bottom_40 [class*="col-"],
.row.columns_margin_bottom_40 [class*="col-"]{
	margin-bottom: 40px;
}

.columns_margin_bottom_50 [class*="col-"],
.row.columns_margin_bottom_50 [class*="col-"]{
	margin-bottom: 50px;
}

/* vertical alignmetn in columns */
@media (min-width: 992px) {
	.table_section > [class*="container"]{
		height: 100%;
	}

	.table_section > [class*="container"] > .row{
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	.table_section > [class*="container"] > .row > [class*="col-"]{
		display: table-cell;
		vertical-align: middle;
		float: none;
	}

	.table_section > [class*="container"] > .row > [class*="col-"].background_cover{
		width: 50%;
		position: relative;
		background-position: 50% 50%;
		background-size: cover;
	}
}

@media screen and (max-width: 991px) {
	.table_section > [class*="container"] > .row > [class*="col-"].background_cover{
		padding-bottom: 66%;
		margin-bottom: -30px;
		width: 100%;
		position: relative;
		background-position: 50% 50%;
		background-size: cover;
	}
}

@media screen and (max-width: 767px) {
	.table_section > [class*="container"] > .row,
	.table_section > [class*="container"] > .row > [class*="col-"]{
		display: block; /*width: auto;*/
	}
}

@media (min-width: 768px) {
	.table_section:not(.with-padding) .container{
		padding-left: 0;
		padding-right: 0;
	}
}

@media screen and (max-width: 1199px) {
	.table_section.table_section_lg .row,
	.table_section.table_section_lg [class*="col-"]{
		display: block;
		width: auto;
	}
}

@media screen and (min-width: 1200px) { /* overlap featured person */
	.top-overlap{
		margin-top: -170px;
	}

	.top-overlap-small{
		margin-top: -100px;
	}
}

@media (max-width: 767px) {
	.container{
		max-width: 500px;
	}
}

@media (min-width: 768px) {
	.container-fluid > .row{
		margin-right: 0;
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.container-fluid{
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media (min-width: 1200px) {
	.container-fluid{
		padding-right: 45px;
		padding-left: 45px;
	}
}

@media (min-width: 1600px) {
	.container-fluid{
		padding-right: 65px;
		padding-left: 65px;
	}
}

.fluid_padding_0 .container-fluid{
	padding-right: 15px;
	padding-left: 15px;
}

@media (max-width: 767px) {
	.table_section .container-fluid{
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 992px) {
	.center-footer-column{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
	}
}

/*
**common sections styles
*/
/* full width section */
.fullwidth-section.horizontal-paddings-0 .container-fluid{
	padding-right: 0;
	padding-left: 0;
}

@media (min-width: 1600px) {
	.fullwidth-section.horizontal-paddings-150 .container-fluid{
		padding-right: 150px;
		padding-left: 150px;
	}
}

@media (min-width: 992px) {
	.container-fluid{
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media (min-width: 1200px) {
	.container-fluid{
		padding-right: 45px;
		padding-left: 45px;
	}
}

@media (min-width: 1600px) {
	.container-fluid{
		padding-right: 65px;
		padding-left: 65px;
	}

	.boxed .container-fluid{
		padding-right: 55px;
		padding-left: 55px;
	}

	.boxed .fullwidth-section .container-fluid{
		padding-right: 0;
		padding-left: 0;
	}
}

@media (max-width: 767px) {
	.table_section .container-fluid{
		padding-right: 0;
		padding-left: 0;
	}
}

/* flex section */
@media (min-width: 992px) {
	.section_flex > .container > .row,
	.section_flex > .container-fluid > .row{
		display: -webkit-box;
		display: flex;
	}

	.section_flex > .container > .row > [class*="col-"],
	.section_flex > .container-fluid > .row > [class*="col-"]{
		-webkit-box-flex: 1;
		flex: 1 1;
	}
}

/*parallax*/
.image_cover,
.background_cover{
	background-size: cover;
}

/* on big screens //section with one half image and one half text (right and left) */
.image_cover{
	display: block;
	position: absolute;
	width: 50%;
	z-index: 2;
	top: 0;
	bottom: 0;
	background-position: 50% 50%; /* hiding real image in onehalf image/text section */
}

.image_cover > img{
	visibility: hidden;
}

.image_cover > a{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.image_cover > a:after{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "\f04b";
	font-family: 'FontAwesome';
	position: absolute;
	color: black;
	font-size: 18px;
	line-height: 60px;
	width: 60px;
	height: 60px;
	text-align: center;
	background-color: transparent;
	border: 1px solid #ffffff;
	border-radius: 50%;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	margin-top: -30px;
}

.image_cover > a:hover:after{
	color: #ffffff;
}

.image_cover.image_cover_right{
	right: 0;
}

@media (max-width: 991px) {
	.image_cover{
		height: 0;
		padding-bottom: 66%;
		width: 100%;
		position: relative;
	}

	.image_cover_hidden_sm .image_cover{
		display: none;
	}
}

.image_cover + [class*='container'] [class*='col-'] > img,
.image_cover + [class*='container'] [class*='col-'] > a:after,
.image_cover + [class*='container'] [class*='col-'] > a > img{
	display: none;
}

.half_section{
	position: relative;
}

@media (min-width: 992px) {
	.half_section.columns_padding_60 > [class*="container"] > .row,
	.half_section.columns_padding_80 > [class*="container"] > .row{
		margin-left: 0;
		margin-right: 0;
	}
}

.half_section .image_cover_left + .container .row > .col-md-6{
	padding-right: 15px;
}

.half_section .image_cover_right + .container .row > .col-md-6{
	padding-left: 15px;
}

.parallax{
	background-attachment: fixed;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative !important;
}

.parallax [class*="col-"].cs{
	background-color: rgba(255, 73, 124, 0.9);
}

.parallax [class*="col-"].ds{
	background-color: rgba(31, 39, 50, 0.9);
}

@media (max-width: 767px) {
	.parallax{
		background-size: auto auto;
	}
}

.column-overlay:before{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #191f28;
	opacity: 0.6;
	z-index: 1;
}

.column-overlay > div{
	z-index: 2;
}

.section_overlay.ls:after{
	background-color: #ffffff;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=60);
	opacity: 0.90;
}

.section_overlay.ls.ms:after{
	background-color: #f4f4f5;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=60);
	opacity: 0.60;
}

.section_overlay.cs:after{
	background-color: black;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=60);
	opacity: 0.60;
}

.section_overlay.cs:before{
	display: none;
}

.header_overlay:after,
.section_overlay.ds:after{
	background-color: #191f28;
	opacity: 0.6;
}

.parallax.ds.ms:after{
	display: none;
}

.texture_bg{
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

.muted_section:before{
	background-color: rgba(31, 39, 50, 0.1);
	opacity: 1;
}

.muted_section > *,
.radial_gradient > *,
.gradient > *,
.header_overlay > *,
.section_overlay > *,
.vertical_gradient > *,
.color_gradient > *,
.diagonal_section > *{
	z-index: 4;
	position: relative;
}

.radial_gradient{
	position: relative;
	overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.section_overlay,
.header_overlay,
.color_gradient{
	position: relative;
}

.muted_section:before,
.muted_section:after,
.section_overlay:before,
.section_overlay:after,
.header_overlay:before,
.header_overlay:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.color_gradient:before,
.color_gradient:after{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.muted_section:before,
.muted_section:after,
.header_overlay:before,
.header_overlay:after,
.section_overlay:before,
.section_overlay:after{
	opacity: 0.9;
}

/* color gradient */
.color_gradient:before{
	background: #a0ce4e;
	background: -webkit-gradient(left top, right top, color-stop(0%, #a0ce4e), color-stop(100%, black));
	background: -webkit-linear-gradient(left, #a0ce4e 0%, black 100%);
	background: -webkit-gradient(linear, left top, right top, from(#a0ce4e), to(black));
	background: linear-gradient(to right, #a0ce4e 0%, black 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$mainColor2, endColorstr=$mainColor, GradientType=1);
}

.color_gradient a:hover{
	color: black !important;
}

.with_top_border,
.with_bottom_border{
	position: relative;
}

.with_top_border:before,
.with_bottom_border:after{
	content: '';
	display: block;
	position: absolute;
	height: 2px;
	left: 0;
	right: 0;
	top: 0;
	background-color: #dedfe1;
}

.with_bottom_border:after{
	top: auto;
	bottom: 0;
}

/* for border appear on container width - not on full section width */
.with_top_border_container .container-fluid,
.with_bottom_border_container .container-fluid{
	position: relative;
}

.with_top_border_container .container-fluid:before,
.with_bottom_border_container .container-fluid:after{
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	background-color: #dedfe1;
	z-index: 1;
}

.ds .with_top_border_container .container-fluid:before,
.ds .with_bottom_border_container .container-fluid:after{
	background-color: rgba(255, 255, 255, 0.1);
}

.ds.with_top_border_container .container-fluid:before,
.ds.with_bottom_border_container .container-fluid:after{
	background-color: rgba(255, 255, 255, 0.1);
}

.with_bottom_border_container .container-fluid:after{
	top: auto;
	bottom: 0;
}

/* background pattern */
.bg_image,
.bg_image:after{
	background-image: url("../img/background_section.png");
}

.text-center .text-block.shortcode{
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.ds .text-block.shortcode > p{
	color: #a4a9b9;
}

.text-block.shortcode.white-text p{
	font-weight: 400;
	color: #ffffff;
}

/*page preloader*/
.preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #ffffff;
	z-index: 13000;
	height: 100%;
}

.lt-ie9 .preloader{
	display: none;
}

.preloader_image{
	width: 80px;
	height: 80px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: url("../img/preloader.png") no-repeat 50% 50% transparent;
	margin: -40px 0 0 -40px;
}

/* animated elements */
.to_animate{
	visibility: hidden;
}

.lt-ie10 .to_animate,
.lt-ie9 .to_animate{
	visibility: visible;
}

.animated{
	visibility: visible !important;
}

/*
**boxed layout
*/
@media (min-width: 1600px) {
	.boxed #box_wrapper{
		margin-left: 180px;
		margin-right: 180px;
	}
}

#box_wrapper,
#canvas{
	overflow: hidden;
	position: relative;
}

#box_wrapper > section,
#box_wrapper > div > section{
	clear: both;
}

#canvas.boxed{
	padding: 1px 0;
}

#box_wrapper.container,
#box_wrapper > section,
#box_wrapper > div > section{
	overflow: hidden;
}

.fw-page-builder-content > section{
	overflow: visible !important;
}

body.boxed{
	position: static;
	width: auto;
	height: auto;
}

.pattern1{
	background-image: url("../img/pattern1.png");
}

.pattern2{
	background-image: url("../img/pattern2.png");
}

.pattern3{
	background-image: url("../img/pattern3.png");
}

.pattern4{
	background-image: url("../img/pattern4.png");
}

.pattern5{
	background-image: url("../img/pattern5.png");
}

.pattern6{
	background-image: url("../img/pattern6.png");
}

.pattern7{
	background-image: url("../img/pattern7.png");
}

.pattern8{
	background-image: url("../img/pattern8.png");
}

.pattern9{
	background-image: url("../img/pattern9.png");
}

.pattern10{
	background-image: url("../img/pattern10.png");
}

.pattern11{
	background-image: url("../img/pattern11.png");
}

@media (min-width: 1200px) {
	.section_bottom_overlap{
		position: absolute;
		z-index: 3;
		right: 0;
		left: 0;
	}

	.section_bottom_overlap.slider-topline{
		background-color: rgba(0, 0, 0, 0.3);
	}
}

@media (min-width: 1200px) {
	.section_bottom_overlap{
		position: relative;
		z-index: 3;
		margin-bottom: -118px;
	}
}

/* Section types */
@media (min-width: 480px) {
	.skew_right{ /* IE 9 */
		-webkit-transform: rotate(3deg); /* Safari */
		transform: rotate(3deg);
		margin-left: -100px;
		margin-right: -100px;
		z-index: 5;
	}

	.skew_right .container{ /* IE 9 */
		-webkit-transform: rotate(-3deg); /* Safari */
		transform: rotate(-3deg);
	}

	.skew_left{ /* IE 9 */
		-webkit-transform: rotate(-3deg); /* Safari */
		transform: rotate(-3deg);
		margin-left: -100px;
		margin-right: -100px;
		z-index: 5;
	}

	.skew_left .container,
	.skew_left .container-fluid{ /* IE 9 */
		-webkit-transform: rotate(3deg); /* Safari */
		transform: rotate(3deg);
	}

	.top_corner_inverse .top_corner_body{
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		position: relative;
		z-index: 2;
	}

	.top_corner_inverse .top_corner_body:before{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		left: 0;
		-webkit-transform: skewY(3deg);
		transform: skewY(3deg);
	}

	.top_corner_inverse .top_corner_body:after{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		right: 0;
		-webkit-transform: skewY(-3deg);
		transform: skewY(-3deg);
	}

	.bottom_corner_inverse .bottom_corner_body{
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		position: relative;
		z-index: 2;
	}

	.bottom_corner_inverse .bottom_corner_body:before{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		left: 0;
		-webkit-transform: skewY(-3deg);
		transform: skewY(-3deg);
	}

	.bottom_corner_inverse .bottom_corner_body:after{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		right: 0;
		-webkit-transform: skewY(3deg);
		transform: skewY(3deg);
	}

	.bottom_corner .bottom_corner_body{
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		position: relative;
		z-index: 2;
	}

	.bottom_corner .bottom_corner_body:before{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		left: 0;
		-webkit-transform: skewY(3deg);
		transform: skewY(3deg);
	}

	.bottom_corner .bottom_corner_body:after{
		content: '';
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		height: 60px;
		position: absolute;
		top: -30px;
		right: 0;
		-webkit-transform: skewY(-3deg);
		transform: skewY(-3deg);
	}
	/* Corner Colors */
	.top_corner_inverse.ls .top_corner_body:before,
	.top_corner_inverse.ls .top_corner_body:after,
	.top_corner_inverse.ls .bottom_corner_body:before,
	.top_corner_inverse.ls .bottom_corner_body:after{
		background-color: #ffffff;
	}

	.top_corner_inverse.ls.ms .top_corner_body:before,
	.top_corner_inverse.ls.ms .top_corner_body:after,
	.top_corner_inverse.ls.ms .bottom_corner_body:before,
	.top_corner_inverse.ls.ms .bottom_corner_body:after{
		background-color: #f4f4f5;
	}

	.top_corner_inverse.cs .top_corner_body:before,
	.top_corner_inverse.cs .top_corner_body:after,
	.top_corner_inverse.cs .bottom_corner_body:before,
	.top_corner_inverse.cs .bottom_corner_body:after{
		background-color: black;
	}

	.top_corner_inverse.ds .top_corner_body:before,
	.top_corner_inverse.ds .top_corner_body:after,
	.top_corner_inverse.ds .bottom_corner_body:before,
	.top_corner_inverse.ds .bottom_corner_body:after{
		background-color: #1f2732;
	}
}

/* Oval sections */
.fw-page-builder-content > section.oval-both{
	border-radius: 60vw / 5vw;
	-moz-border-radius: 60vw / 5vw;
	-webkit-border-radius: 60vw / 5vw;
	overflow: hidden !important;
}

@media (min-width: 767px) {
	.fw-page-builder-content > section.oval-both{
		margin: 0 -100px;
	}
}

.fw-page-builder-content > section.oval-bottom{
	border-radius: 60vw / 5vw;
	-moz-border-radius: 60vw / 5vw;
	-webkit-border-radius: 60vw / 5vw;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	overflow: hidden !important;
}

@media (min-width: 767px) {
	.fw-page-builder-content > section.oval-bottom{
		margin: 0 -100px;
	}
}

/*
**utilites
*/
/* css helpers */
.clear,
.clearfix{
	clear: both;
}

.alignleft{
	float: left;
	margin: 7px 30px 15px 0;
	max-width: 50%;
}

.alignright{
	float: right;
	margin: 7px 0 15px 30px;
	max-width: 50%;
}

.round{
	border-radius: 50%;
}

.inline-block{
	display: inline-block;
	max-width: 100%;
}

.divided-content > *{
	display: inline-block;
	padding-right: 11px;
}

.divided-content > * + *:before{
	content: "|";
	display: inline-block;
	padding-right: 12px;
	color: #1f2732;
}

.inline-media-wrap{
	display: inline-block;
	margin-left: -13px;
	margin-right: -13px;
}

.inline-media-wrap > .media{
	display: inline-block;
	margin-top: 0;
	vertical-align: middle;
	margin-left: 13px;
	margin-right: 13px;
}

.inline-media-wrap > .media .media-body{
	width: auto;
	line-height: 18px;
}

@media (min-width: 1200px) {
	.inline-media-wrap{
		margin-left: -28px;
		margin-right: -28px;
	}

	.inline-media-wrap > .media{
		margin-left: 28px;
		margin-right: 28px;
	}
}

.with_padding{
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.with_padding{
		padding: 35px;
	}
}

@media (min-width: 1200px) {
	.with_padding.big-padding{
		padding: 35px 43px;
	}
}

.with_background{
	background-color: #f4f4f5;
}

.transp_black_bg{
	background-color: rgba(31, 39, 50, 0.7);
}

.muted_background{
	background-color: #f9f9f9;
}

.with_border{
	border: 1px solid #dedfe1;
}

.ls.ms .with_border{
	border: 1px solid rgba(164, 169, 185, 0.3);
}

.rounded{
	border-radius: 5px;
}

.overflow-hidden{
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.overflow-visible{
	overflow: visible !important;
}

.with_border.sticky.post{
	border: 2px solid black;
}

.with_shadow{
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.content-justify{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	flex-wrap: wrap;
}

.content-justify-center{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: center;
	flex-wrap: wrap;
}

.inline-content > *{
	vertical-align: middle;
	display: inline-block;
	margin-right: 15px;
	margin-left: 15px;
}

.inline-content > *.media{
	margin-top: 10px;
	margin-bottom: 10px;
}

.inline-content ul{
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 0;
}

.inline-content ul:first-child{
	margin-right: 30px;
}

@media (min-width: 1200px) {
	.inline-content ul:first-child{
		margin-right: 120px;
	}
}

.flex-wrap{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
}

.flex-wrap:before,
.flex-wrap:after{
	display: none;
}

.v-center{
	-webkit-box-align: center;
	align-items: center;
}

.bottom-border{
	position: relative;
}

.bottom-border:after{
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.1);
}

.border-paragraphs{
	margin: 25px 0 25px;
}

.border-paragraphs p{
	padding: 9px 0 10px;
	margin: 0;
	border-bottom: 1px solid rgba(164, 169, 185, 0.3);
}

.border-paragraphs p:first-child{
	border-top: 1px solid rgba(164, 169, 185, 0.3);
}

.border-top{
	border-top: 1px solid #dedfe1;
}

.table_section_content{
	padding: 30px 50px;
}

.table_section_content h3{
	font-size: 42px;
	font-weight: 300;
	margin: 0 0 32px;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > *{
	position: relative;
	z-index: 2;
}

.before_cover:before,
.after_cover:after{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.top-corner{
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.big{
	font-size: 42px;
	line-height: 0.9;
}

.response{
	color: black;
	border-color: black;
}

.highlight{
	color: black !important;
	border-color: black !important;
}

.highlight a{
	color: black !important;
}

.highlight a:hover{
	color: #1f2732 !important;
}

.highlight2{
	color: #a0ce4e !important;
	border-color: #a0ce4e !important;
}

.highlight2 a{
	color: #a0ce4e !important;
}

.highlight2 a:hover{
	color: #1f2732 !important;
}

.highlight3{
	color: #00bea3 !important;
	border-color: #00bea3 !important;
}

.highlight3 a{
	color: #00bea3 !important;
}

.highlight3 a:hover{
	color: #1f2732 !important;
}

.highlight4{
	color: #f1894c !important;
	border-color: #f1894c !important;
}

.highlight4 a{
	color: #f1894c !important;
}

.highlight4 a:hover{
	color: #1f2732 !important;
}

.red,
.required{
	color: #fa5c5d;
}

.black{
	color: #191f28;
	border-color: #191f28;
}

.lightfont{
	color: #ffffff !important;
}

.greyfont{
	color: #7f7f7f;
}

.greyfont > *:not(.widget-title){
	color: #7f7f7f !important;
}

.lightgrey{
	color: #f4f4f5;
}

.darkgrey{
	color: #1f2732;
	border-color: #1f2732;
}

.greylinks a{
	color: #7f7f7f;
}

.greylinks a.social-icon{
	color: rgba(127, 127, 127, 0.6);
}

.greylinks a.social-icon:hover,
.greylinks a.social-icon:focus,
.greylinks a.social-icon:active{
	color: black;
}

.greylinks a.social-icon.border-icon{
	border-color: rgba(127, 127, 127, 0.6);
}

.greylinks a.social-icon.border-icon:hover,
.greylinks a.social-icon.border-icon:focus,
.greylinks a.social-icon.border-icon:active{
	color: black;
	border-color: rgba(127, 127, 127, 0.6);
}

.colorlinks a{
	color: black;
}

.colorlinks a:hover,
.colorlinks a:focus,
.colorlinks a:active{
	color: #1f2732;
}

.darklinks a,
.darklinks span{
	color: #1f2732;
}

.spacing-text-small{
	letter-spacing: 0.3em;
	text-indent: 0.3em;
}

.spacing-text-large{
	letter-spacing: 2em;
	text-indent: 2em;
}

@media (max-width: 767px) {
	.spacing-text-large{
		letter-spacing: 1em;
		text-indent: 1em;
	}
}

ul.no-bullets{
	padding-left: 0;
}

ul.no-bullets li{
	list-style: none;
}

.thin{
	font-weight: 200;
}

.light-weight{
	font-weight: 300;
}

.normal{
	font-weight: 400;
}

.medium{
	font-weight: 500;
}

.bold{
	font-weight: bold;
}

.black-weight{
	font-weight: 900;
}

.quotesign{
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 1;
}

.quotesign:before{
	font-size: 150px;
	font-family: 'PT Sans', sans-serif;
	content: '‘‘';
	position: absolute;
	left: 0;
	top: 0;
	line-height: 1;
}

.light_bg_color{
	background-color: #ffffff;
}

.main_bg_color{
	color: #ffffff;
	background-color: black;
}

.dark_bg_color{
	color: #ffffff;
	background-color: #191f28;
}

.dark_bg_color h1,
.dark_bg_color h2,
.dark_bg_color h3,
.dark_bg_color h4,
.dark_bg_color h5,
.dark_bg_color h6{
	color: #ffffff;
}

.darkgrey_bg_color{
	color: #ffffff;
	background-color: #1f2732;
}

.darkgrey_bg_color h1,
.darkgrey_bg_color h2,
.darkgrey_bg_color h3,
.darkgrey_bg_color h4,
.darkgrey_bg_color h5,
.darkgrey_bg_color h6{
	color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_15{
	margin: 15px 0;
	line-height: 1;
}

.divider_20{
	margin: 20px 0;
	line-height: 1;
}

.divider_30{
	margin: 30px 0;
}

.divider_40{
	margin: 40px 0;
}

.divider_95{
	margin-top: 95px;
}

.margin_0{
	margin: 0;
}

.topmargin_0{
	margin-top: 0;
}

.topmargin_1{
	margin-top: 1px;
}

.topmargin_5{
	margin-top: 5px;
}

.topmargin_10{
	margin-top: 10px;
}

.topmargin_15{
	margin-top: 15px;
}

.topmargin_20{
	margin-top: 20px;
}

.topmargin_25{
	margin-top: 25px;
}

.topmargin_30{
	margin-top: 30px;
}

.topmargin_40{
	margin-top: 40px;
}

.topmargin_50{
	margin-top: 50px;
}

.topmargin_60{
	margin-top: 60px;
}

.topmargin_80{
	margin-top: 80px;
}

.bottommargin_0{
	margin-bottom: 0;
}

.bottommargin_5{
	margin-bottom: 5px;
}

.bottommargin_10{
	margin-bottom: 10px;
}

.bottommargin_15{
	margin-bottom: 15px;
}

.bottommargin_20{
	margin-bottom: 20px;
}

.bottommargin_25{
	margin-bottom: 25px;
}

.bottommargin_30{
	margin-bottom: 30px;
}

.bottommargin_40{
	margin-bottom: 40px;
}

.bottommargin_60{
	margin-bottom: 60px;
}

.leftmargin_10{
	margin-left: 10px;
}

.leftmargin_20{
	margin-left: 20px;
}

.leftmargin_30{
	margin-left: 30px;
}

.leftmargin_40{
	margin-left: 40px;
}

.leftmargin_50{
	margin-left: 50px;
}

.leftmargin_60{
	margin-left: 60px;
}

.rightmargin_10{
	margin-right: 10px;
}

.rightmargin_20{
	margin-right: 20px;
}

.rightmargin_30{
	margin-right: 30px;
}

.rightmargin_40{
	margin-right: 40px;
}

.rightmargin_50{
	margin-right: 50px;
}

.rightmargin_60{
	margin-right: 60px;
}

.padding_0{
	padding: 0;
}

.padding_10{
	padding: 10px;
}

.padding_20{
	padding: 20px;
}

.padding_30{
	padding: 30px;
}

.padding_40{
	padding: 40px;
}

.padding_60{
	padding: 60px;
}

.padding_80{
	padding: 80px;
}

@media (max-width: 1200px) {
	.padding_0{
		padding: 0;
	}

	.padding_10{
		padding: 10px 15px;
	}

	.padding_20{
		padding: 20px 15px;
	}

	.padding_30{
		padding: 30px 15px;
	}

	.padding_40{
		padding: 40px 15px;
	}

	.padding_60{
		padding: 60px 30px;
	}

	.padding_80{
		padding: 80px 60px;
	}
}

@media (max-width: 480px) {
	.padding_80{
		padding: 80px 15px;
	}
}

.toppadding_10{
	padding-top: 10px;
}

.toppadding_20{
	padding-top: 20px;
}

.toppadding_30{
	padding-top: 30px;
}

.toppadding_40{
	padding-top: 40px;
}

.toppadding_50{
	padding-top: 50px;
}

.toppadding_60{
	padding-top: 60px;
}

.bottompadding_10{
	padding-bottom: 10px;
}

.bottompadding_20{
	padding-bottom: 20px;
}

.bottompadding_30{
	padding-bottom: 30px;
}

.bottompadding_40{
	padding-bottom: 40px;
}

.bottompadding_50{
	padding-bottom: 50px;
}

.bottompadding_60{
	padding-bottom: 60px;
}

.leftpadding_10{
	padding-left: 10px;
}

.leftpadding_20{
	padding-left: 20px;
}

.leftpadding_30{
	padding-left: 30px;
}

.leftpadding_40{
	padding-left: 40px;
}

.leftpadding_50{
	padding-left: 50px;
}

.leftpadding_60{
	padding-left: 60px;
}

.rightpadding_10{
	padding-right: 10px;
}

.rightpadding_20{
	padding-right: 20px;
}

.rightpadding_30{
	padding-right: 30px;
}

.rightpadding_40{
	padding-right: 40px;
}

.rightpadding_50{
	padding-right: 50px;
}

.rightpadding_60{
	padding-right: 60px;
}

.fontsize_12{
	font-size: 12px;
}

.fontsize_14{
	font-size: 14px;
}

.fontsize_16{
	font-size: 16px;
}

.fontsize_18{
	font-size: 18px;
}

.fontsize_20{
	font-size: 20px;
	line-height: 30px;
}

.fontsize_24{
	font-size: 24px;
}

.fontsize_32{
	font-size: 32px;
}

/*alignment*/
.display_table{
	display: table;
	min-width: 100%;
	height: 100%;
	margin: auto;
	border-collapse: collapse;
}

.display_table_cell{
	display: table-cell;
	vertical-align: middle;
	float: none;
	overflow: hidden;
	zoom: 1;
	box-sizing: border-box;
}

@media (min-width: 1200px) {
	.text-lg-right{
		text-align: right;
	}

	.text-lg-left{
		text-align: left;
	}

	.text-lg-center{
		text-align: center;
	}

	.display_table_lg{
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_lg{
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 992px) {
	.text-md-right{
		text-align: right;
	}

	.text-md-left{
		text-align: left;
	}

	.text-md-center{
		text-align: center;
	}

	.display_inline_md{
		display: inline;
	}

	.display_table_md{
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_md{
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 768px) {
	.text-sm-right{
		text-align: right;
	}

	.text-sm-left{
		text-align: left;
	}

	.text-sm-center{
		text-align: center;
	}

	.display_table_sm{
		display: table;
		min-width: 100%;
		height: 100%;
		margin: auto;
		border-collapse: collapse;
	}

	.display_table_cell_sm{
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (max-width: 767px) {
	.text-xs-right{
		text-align: right;
	}

	.text-xs-left{
		text-align: left;
	}

	.text-xs-center{
		text-align: center;
	}

	.not_found{
		font-size: 130px;
	}
}

@media (max-width: 500px) {
	.hidden-xxs{
		display: none;
	}
}

.inline-dropdown{
	list-style: none;
	margin: 0;
	padding: 0;
}

.inline-dropdown > li{
	display: inline-block;
	margin: 0 -2px;
}

.small-text{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.entry-date,
.entry-author,
.entry-tags{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.entry-date .post-date a{
	color: #ffffff;
}

.entry-date:before{
	font-style: normal;
}

.entry-author{
	margin-bottom: 0;
}

.entry-author:before{
	font-style: normal;
}

.entry-author a{
	color: black;
}

.entry-author a:hover{
	color: #ff497c;
}

.transform-none{
	-webkit-transform: none !important;
	transform: none !important;
}

.bg-position-bottom{
	background-position: bottom;
}

@media (min-width: 1600px) {
	.ml--150-xl{
		-webkit-transform: translateX(-150px);
		transform: translateX(-150px);
		max-width: inherit;
	}
}

@media (min-width: 1600px) {
	.mr--100-xl-with-scale{
		-webkit-transform: translateX(100px) scale(1.2);
		transform: translateX(100px) scale(1.2);
	}
}

.mt--50{
	margin-top: -50px;
}

.z-index-big{
	position: relative;
	z-index: 2;
}

/* common side and vertical items */
.with_border .item-media{
	margin: -2px !important;
	z-index: 1;
}

.with_border.full-padding .item-media{
	margin: 0;
}

/* side and vertical items paddings */
.full-padding{
	padding: 40px 40px 40px 40px;
}

.content-padding .item-content{
	padding: 30px 40px 30px;
}

@media (max-width: 992px) {
	.full-padding{
		padding: 30px 30px 30px 30px;
	}

	.content-padding .item-content{
		padding: 30px 30px 20px;
	}
}

.side-item .row{
	margin: 0;
}

.side-item .row [class*="col-"]{
	padding: 0;
	margin: 0;
}

.side-item .item-title + .item-content{
	padding-top: 20px;
}

.side-item [class*="col-"] > .item-title{
	padding: 15px 30px;
}

.side-item [class*="col-"] > .item-title h2,
.side-item [class*="col-"] > .item-title h3,
.side-item [class*="col-"] > .item-title h4{
	margin: 0;
}

.side-item.with_border [class*="col-"] > .item-title{
	margin: -1px;
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
	.no-content-padding [class*='col-xs-'] .item-content,
	.full-padding [class*='col-xs-'] .item-content{
		padding-left: 30px;
	}

	.no-content-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content,
	.full-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content{
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-xs-12 .item-content,
	.full-padding .col-xs-12 .item-content{
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
	.no-content-padding [class*='col-sm-'] .item-content,
	.full-padding [class*='col-sm-'] .item-content{
		padding-left: 30px;
	}

	.no-content-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content,
	.full-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content{
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-sm-12 .item-content,
	.full-padding .col-sm-12 .item-content{
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
	.no-content-padding [class*='col-md-'] .item-content,
	.full-padding [class*='col-md-'] .item-content{
		padding-left: 30px;
	}

	.no-content-padding [class*='col-md-'][class*='col-md-pull'] .item-content,
	.full-padding [class*='col-md-'][class*='col-md-pull'] .item-content{
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-md-12 .item-content,
	.full-padding .col-md-12 .item-content{
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
	.no-content-padding [class*='col-lg-'] .item-content,
	.full-padding [class*='col-lg-'] .item-content{
		padding-left: 30px;
	}

	.no-content-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content,
	.full-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content{
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-lg-12 .item-content,
	.full-padding .col-lg-12 .item-content{
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

.vertical-item h3{
	line-height: 1.1;
}

.vertical-item h4{
	line-height: 1.25;
}

.vertical-item .list1{
	text-align: left;
	margin: 0;
}

.vertical-item .list1 + .social-icons{
	margin-top: 30px;
}

.vertical-item.content-padding .item-content{
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.vertical-item.content-padding .item-content{
		padding: 35px 35px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.content-padding.big-padding .item-content{
		padding: 50px 55px;
	}
}

.vertical-item.content-absolute{
	position: relative;
}

.vertical-item.content-absolute .item-content{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 30px 20px;
}

.vertical-item.content-absolute.vertical-center .item-content{
	top: 0;
	line-height: 1.1;
}

.vertical-item + .item-title{
	padding: 10px 30px;
}

.vertical-item + .item-title h2,
.vertical-item + .item-title h3,
.vertical-item + .item-title h4{
	margin: 0;
}

/* item meta */
.item-meta{
	margin-bottom: 5px;
}

.item-meta i{
	position: relative;
	font-size: 20px;
	top: 3px;
	padding: 0;
	margin: 0;
}

/* item media links */
.item-media{
	position: relative;
	overflow: hidden;
}

.item-media img{
	max-width: 100%;
	width: auto;
}

.bottom-right-corner{
	position: absolute;
	right: 0;
	bottom: 0;
	width: 50px;
	height: 50px;
	border: 25px solid transparent;
	border-right-color: black;
	border-bottom-color: black;
	font-size: 13px;
	z-index: 0;
}

.bottom-right-corner i{
	color: #ffffff;
	position: absolute;
	right: -16px;
	bottom: -16px;
	z-index: 1;
	-webkit-transition: all 0.2s ease 0s;
	transition: all 0.2s ease 0s;
}

.format-standard .item-media-wrap,
.format-image .item-media-wrap{
	position: relative;
	-webkit-box-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: flex;
}

.format-standard .item-media-wrap > .item-media,
.format-image .item-media-wrap > .item-media{
	display: inline-block;
}

.media-links{
	text-align: center;
	z-index: 1;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.media-links div{
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -25px;
	z-index: 4;
}

.media-links:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.9);
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

[class*="-item"]:hover .media-links:before{
	opacity: 1;
}

.media-links a.abs-link{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link{
	opacity: 1;
}

.links-wrap a{
	position: relative;
	width: 40px;
	height: 40px;
	line-height: 36px;
	top: -100px;
	display: inline-block;
	z-index: 5;
	font-size: 24px;
	text-align: center;
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	border: 2px solid transparent;
	background-color: transparent;
}

.links-wrap a:hover{
	color: rgba(255, 255, 255, 0.6);
}

.links-wrap a.p-link{
	background-color: transparent;
	border-color: transparent;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

.links-wrap a:before{
	font-family: FontAwesome;
	content: "\f002";
}

.links-wrap a.p-link:before{
	content: "\f041";
}

[class*="item"]:hover .links-wrap a{
	top: 0;
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="item"]:hover .media-links div{
	filter: alpha(opacity=100);
	opacity: 1;
}

/* item icons */
.item-icons{
	display: table;
	width: 100%;
	border-top: 1px solid #dedfe1;
}

.item-icons i{
	display: block;
	font-size: 16px;
}

.item-icons > div{
	padding: 22px 10px 18px;
	display: table-cell;
	width: 400px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
}

.item-icons > div + div{
	border-left: 1px solid #dedfe1;
}

.item-icons [data-id]{
	display: none;
}

/**light section - .ls*/
.light-body{
	background-color: #ffffff;
}

.ls,
.light{
	background-color: #ffffff;
	color: #7f7f7f;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6,
.light h1,
.light h2,
.light h3,
.light h4,
.light h5,
.light h6{
	color: #1f2732;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a,
.light h1 a,
.light h2 a,
.light h3 a,
.light h4 a,
.light h5 a,
.light h6 a{
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover,
.light h1 a:hover,
.light h2 a:hover,
.light h3 a:hover,
.light h4 a:hover,
.light h5 a:hover,
.light h6 a:hover{
	color: black;
}

.ls h1.grey,
.ls h2.grey,
.ls h3.grey,
.ls h4.grey,
.ls h5.grey,
.ls h6.grey,
.light h1.grey,
.light h2.grey,
.light h3.grey,
.light h4.grey,
.light h5.grey,
.light h6.grey{
	color: #7f7f7f;
}

.ls a,
.light a{
	color: black;
}

.ls a:hover,
.light a:hover{
	color: #ff497c;
}

.ls a.logo,
.light a.logo{
	color: #191f28;
}

.ls .text-block.shortcode p strong,
.light .text-block.shortcode p strong{
	color: #191f28;
}

.ls .theme_buttons.color a:hover,
.ls .theme_buttons.color a:focus,
.ls .theme_buttons.color a:active,
.ls .theme_button.color:hover,
.ls .theme_button.color:focus,
.ls .theme_button.color:active,
.light .theme_buttons.color a:hover,
.light .theme_buttons.color a:focus,
.light .theme_buttons.color a:active,
.light .theme_button.color:hover,
.light .theme_button.color:focus,
.light .theme_button.color:active{
	color: #1f2732 !important;
}

.ls .theme_buttons.inverse a,
.ls .theme_button.inverse,
.light .theme_buttons.inverse a,
.light .theme_button.inverse{
	color: #1f2732;
}

.ls .with_background input[type="text"],
.ls .with_background input[type="email"],
.ls .with_background input[type="url"],
.ls .with_background input[type="password"],
.ls .with_background input[type="search"],
.ls .with_background input[type="tel"],
.ls .with_background input[type="number"],
.ls .with_background textarea,
.ls .with_background select,
.ls .with_background .form-control,
.ls .with_background .select-group,
.ls .tab-content input[type="text"],
.ls .tab-content input[type="email"],
.ls .tab-content input[type="url"],
.ls .tab-content input[type="password"],
.ls .tab-content input[type="search"],
.ls .tab-content input[type="tel"],
.ls .tab-content input[type="number"],
.ls .tab-content textarea,
.ls .tab-content select,
.ls .tab-content .form-control,
.ls .tab-content .select-group,
.light .with_background input[type="text"],
.light .with_background input[type="email"],
.light .with_background input[type="url"],
.light .with_background input[type="password"],
.light .with_background input[type="search"],
.light .with_background input[type="tel"],
.light .with_background input[type="number"],
.light .with_background textarea,
.light .with_background select,
.light .with_background .form-control,
.light .with_background .select-group,
.light .tab-content input[type="text"],
.light .tab-content input[type="email"],
.light .tab-content input[type="url"],
.light .tab-content input[type="password"],
.light .tab-content input[type="search"],
.light .tab-content input[type="tel"],
.light .tab-content input[type="number"],
.light .tab-content textarea,
.light .tab-content select,
.light .tab-content .form-control,
.light .tab-content .select-group{
	background-color: #ffffff;
}

.ls .toggle_menu span,
.ls .toggle_menu span:before,
.ls .toggle_menu span:after,
.light .toggle_menu span,
.light .toggle_menu span:before,
.light .toggle_menu span:after{
	background-color: #191f28;
}

.ls .color-icon.bg-icon,
.light .color-icon.bg-icon{
	background-color: #f8f8f8;
}

.ls .with_background .color-icon.bg-icon,
.light .with_background .color-icon.bg-icon{
	background-color: #ffffff;
}

.ls .grey,
.ls .grey li,
.light .grey,
.light .grey li{
	color: #1f2732;
}

.ls .highlight,
.light .highlight{
	color: black;
}

.ls .greylinks a,
.light .greylinks a{
	color: #7f7f7f;
}

.ls .greylinks a:hover,
.light .greylinks a:hover{
	color: ff497c;
}

.ls .greylinks a.social-icon,
.light .greylinks a.social-icon{
	color: #7f7f7f;
}

.ls .darklinks a,
.light .darklinks a{
	color: #1f2732;
}

.ls .darklinks a:hover,
.light .darklinks a:hover{
	color: #ff497c;
}

.ls .darklinks2 a,
.light .darklinks2 a{
	color: #1f2732;
}

.ls .darklinks2 a:hover,
.light .darklinks2 a:hover{
	color: #a0ce4e;
}

.ls .categories-links.color1 a:hover,
.ls .categories-links.color2 a:hover,
.ls .categories-links.color3 a:hover,
.light .categories-links.color1 a:hover,
.light .categories-links.color2 a:hover,
.light .categories-links.color3 a:hover{
	color: #ff497c;
}

.ls .pagination > li > a,
.ls .pagination > li > span,
.light .pagination > li > a,
.light .pagination > li > span{
	color: #1f2732;
}

.ls .with_background .with_background,
.light .with_background .with_background{
	background-color: #ffffff;
}

.ls .with_background blockquote,
.light .with_background blockquote{
	color: #1f2732;
}

.ls .widget_craftpro_twitter .tweet_time,
.light .widget_craftpro_twitter .tweet_time{
	color: black;
}

.ls .price-table,
.light .price-table{
	background-color: #f4f4f5;
}

/**grey section - light section, muted section - .ls.ms*/
.ls.ms{
	background-color: #f4f4f5; /* template styles */ /*accordion*/
}

.ls.ms .with_background{
	background-color: #ffffff;
}

.ls.ms .price-table{
	background-color: #ffffff;
}

.ls.ms .page-links .small_buttons a span,
.ls.ms .page-links .small_button span{
	color: #ffffff;
}

.ls.ms .page-links .small_buttons a:hover,
.ls.ms .page-links .small_button:hover{
	opacity: 0.7;
}

.ls.ms .panel-body{
	background-color: #ffffff;
}

.ls.ms hr{
	background-color: #ffffff;
}

.ls.ms input[type="text"],
.ls.ms input[type="email"],
.ls.ms input[type="url"],
.ls.ms input[type="password"],
.ls.ms input[type="search"],
.ls.ms input[type="tel"],
.ls.ms input[type="number"],
.ls.ms textarea,
.ls.ms select,
.ls.ms .form-control{
	background-color: #ffffff;
}

.ls.ms .ls:not(.ms) input[type="text"],
.ls.ms .ls:not(.ms) input[type="email"],
.ls.ms .ls:not(.ms) input[type="url"],
.ls.ms .ls:not(.ms) input[type="password"],
.ls.ms .ls:not(.ms) input[type="search"],
.ls.ms .ls:not(.ms) input[type="tel"],
.ls.ms .ls:not(.ms) input[type="number"],
.ls.ms .ls:not(.ms) textarea,
.ls.ms .ls:not(.ms) select,
.ls.ms .ls:not(.ms) .form-control,
.ls.ms .with_background input[type="text"],
.ls.ms .with_background input[type="email"],
.ls.ms .with_background input[type="url"],
.ls.ms .with_background input[type="password"],
.ls.ms .with_background input[type="search"],
.ls.ms .with_background input[type="tel"],
.ls.ms .with_background input[type="number"],
.ls.ms .with_background textarea,
.ls.ms .with_background select,
.ls.ms .with_background .form-control{
	border-color: #dedfe1;
}

.ls.ms .panel-title > a:hover{
	background-color: black;
}

.ls.ms .panel-title > a.collapsed:hover{
	background-color: black;
}

.ls.ms .pagination a,
.ls.ms .pagination span{
	background-color: #ffffff;
	border-color: #ffffff;
}

.ls.ms .price-table{
	background-color: #ffffff;
}

/**common dark and dark muted sections - .ds and .ds.ms*/
/**darkgrey section - .ds*/
.dark-body{
	background-color: #191f28;
}

.ds{
	background-color: #1f2732;
	color: #a4a9b9;
}

.ds.page_header{
	background-color: #191f28;
}

/**dark section - .ds.ms*/
.ds.ms{
	background-color: #1f2732;
}

.ds.dark{
	background-color: #191f28;
}

.ds{ /* light section in dark section - .ds .ls */ /* Dark headers */ /* tabs */ /* shortcode post carousel */ /* shop */ /* for wordpress - unyson calendar */
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6{
	color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a{
	color: #ffffff;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover{
	color: ff497c;
}

.ds .theme_button.color1:hover,
.ds .theme_button.color2:hover,
.ds .theme_button.color3:hover,
.ds .theme_button.color4:hover,
.ds .theme_button.color_white:hover{
	color: #ffffff !important;
}

.ds .ls{
	background-color: #ffffff;
	color: #7f7f7f;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6{
	color: #1f2732;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a{
	color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover{
	color: #ff497c;
}

.ds .ls a{
	color: black;
}

.ds .ls a:hover{
	color: #ff497c;
}

.ds .ls.ms{
	background-color: #f4f4f5;
	color: #7f7f7f;
}

.ds .ls.ms h1,
.ds .ls.ms h2,
.ds .ls.ms h3,
.ds .ls.ms h4,
.ds .ls.ms h5,
.ds .ls.ms h6{
	color: #1f2732;
}

.ds .ls.ms h1 a,
.ds .ls.ms h2 a,
.ds .ls.ms h3 a,
.ds .ls.ms h4 a,
.ds .ls.ms h5 a,
.ds .ls.ms h6 a{
	color: inherit;
}

.ds .ls.ms h1 a:hover,
.ds .ls.ms h2 a:hover,
.ds .ls.ms h3 a:hover,
.ds .ls.ms h4 a:hover,
.ds .ls.ms h5 a:hover,
.ds .ls.ms h6 a:hover{
	color: ff497c;
}

.ds .ls.ms a{
	color: black;
}

.ds .ls.ms a:hover{
	color: #ff497c;
}

.ds .ls.ms .theme_button.inverse.color1,
.ds .ls.ms .theme_button.inverse.color2,
.ds .ls.ms .theme_button.inverse.color3,
.ds .ls.ms .theme_button.inverse.color4{
	color: #191f28 !important;
}

.ds .ls.ms .theme_button.color1:hover,
.ds .ls.ms .theme_button.color2:hover,
.ds .ls.ms .theme_button.color3:hover,
.ds .ls.ms .theme_button.color4:hover,
.ds .ls.ms .theme_button.color_white:hover{
	color: #ffffff !important;
}

.ds label{
	color: #ffffff;
}

.ds a{
	color: #f4f4f5;
}

.ds a:hover{
	color: #ffffff;
}

.ds hr{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds blockquote,
.ds .blockquote{
	color: #a4a9b9;
}

.ds blockquote .author-job,
.ds .blockquote .author-job{
	color: #7f7f7f;
}

.ds .center-column a{
	color: #1f2732;
}

.ds .center-column a:hover{
	color: #ff497c;
}

.ds a.logo{
	color: #ffffff;
}

.ds .nav > li > a{
	color: #ffffff;
}

.ds .social-icon.color-icon:hover{
	color: #ffffff;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control{
	color: #a4a9b9;
}

.ds .transp_black_bg .form-control{
	border-color: #ffffff;
}

.ds .transp_black_bg .form-control:-moz-placeholder{
	color: #ffffff;
}

.ds .transp_black_bg .form-control::-moz-placeholder{
	color: #ffffff;
}

.ds .transp_black_bg .form-control:-ms-input-placeholder{
	color: #ffffff;
}

.ds .transp_black_bg .form-control::-webkit-input-placeholder{
	color: #ffffff;
}

.ds .transp_black_bg .form-control:focus:-moz-placeholder{
	color: transparent;
}

.ds .transp_black_bg .form-control:focus::-moz-placeholder{
	color: transparent;
}

.ds .transp_black_bg .form-control:focus:-ms-input-placeholder{
	color: transparent;
}

.ds .transp_black_bg .form-control:focus::-webkit-input-placeholder{
	color: transparent;
}

.ds .muted_background,
.ds .well{
	background-color: #ffffff;
}

.ds .with_background{
	background-color: #191f28;
}

.ds.muted_section:before{
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .with_border{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .highlight{
	color: black;
}

.ds .highlight > *{
	color: black !important;
}

.ds .highlight a{
	color: black;
}

.ds .highlight a:hover{
	color: #ffffff !important;
}

.ds .highlight2{
	color: #a0ce4e;
}

.ds .highlight2 > *{
	color: #a0ce4e !important;
}

.ds .highlight2 a{
	color: #a0ce4e;
}

.ds .highlight2 a:hover{
	color: #ffffff !important;
}

.ds .highlight3{
	color: #00bea3;
}

.ds .highlight3 > *{
	color: #00bea3 !important;
}

.ds .highlight3 a{
	color: #00bea3;
}

.ds .highlight3 a:hover{
	color: #ffffff !important;
}

.ds .black{
	color: #ffffff;
	border-color: #ffffff;
}

.ds .lightfont{
	color: #ffffff;
}

.ds .lightfont > *{
	color: #ffffff !important;
}

.ds .highlightlinks a{
	color: black;
}

.ds .highlightlinks a:hover{
	color: #ffffff;
}

.ds .greylinks a{
	color: #a4a9b9;
}

.ds .greylinks a:hover{
	color: #ff497c;
}

.ds .darklinks a{
	color: #ffffff;
}

.ds .darklinks a:hover{
	color: ff497c;
}

.ds .darklinks2 a{
	color: #ffffff;
}

.ds .darklinks2 a:hover{
	color: #a0ce4e;
}

.ds .categories-links.color1 a:hover,
.ds .categories-links.color2 a:hover,
.ds .categories-links.color3 a:hover{
	color: #ffffff;
}

.ds.with_top_border:before,
.ds.with_bottom_border:after{
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .section_header + p{
	color: inherit;
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control{
	background-color: #f4f4f5;
}

.ds .with_background input[type="text"],
.ds .with_background input[type="email"],
.ds .with_background input[type="url"],
.ds .with_background input[type="password"],
.ds .with_background input[type="search"],
.ds .with_background input[type="tel"],
.ds .with_background input[type="number"],
.ds .with_background textarea,
.ds .with_background select,
.ds .with_background .form-control{
	background-color: #1f2732;
	border-color: #1f2732;
}

.ds .form-control:focus{
	border-color: rgba(128, 128, 128, 0.2);
}

.ds .center-column input[type="text"],
.ds .center-column input[type="email"],
.ds .center-column input[type="url"],
.ds .center-column input[type="password"],
.ds .center-column input[type="search"],
.ds .center-column input[type="tel"],
.ds .center-column input[type="number"],
.ds .center-column textarea,
.ds .center-column select,
.ds .center-column .form-control{
	border-color: rgba(31, 39, 50, 0.5);
}

.ds form label + [class*="icon-"]{
	color: #ffffff;
}

.ds .form-group-select:before{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .form-control:-moz-placeholder{
	color: #a4a9b9;
	opacity: 1;
}

.ds .form-control::-moz-placeholder{
	color: #a4a9b9;
	opacity: 1;
}

.ds .form-control:-ms-input-placeholder{
	color: #a4a9b9;
}

.ds .form-control::-webkit-input-placeholder{
	color: #a4a9b9;
}

.ds .contact-form.transparent-background .form-control{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .border-paragraphs p{
	color: #ffffff;
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds table th{
	color: #ffffff;
}

.ds .table_template th,
.ds .table_template td{
	border-color: transparent;
}

.ds .table_template th{
	color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th{
	border-color: rgba(164, 169, 185, 0.3);
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon{
	background-color: #ffffff;
}

.ds .color-icon.bg-icon:hover{
	color: #ffffff;
}

.ds .color-bg-icon:hover{
	background-color: #f4f4f5;
}

.ds .tab-content,
.ds .nav-tabs > li > a{
	background-color: #1f2732;
}

.ds .nav-tabs > li.active > a,
.ds .nav-tabs > li.active > a:hover,
.ds .nav-tabs > li.active > a:focus{
	border-color: black;
	border-bottom-color: transparent;
	background-color: black;
	color: #ffffff;
}

.ds .fw-package.price-table{
	background-color: #1f2732;
}

.ds .fw-package.price-table .fw-default-row{
	color: #7f7f7f;
}

.ds .fw-package.price-table hr{
	background-color: rgba(255, 255, 255, 0.1);
}

.ds .price-table.style2{
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .plan-name{
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .features-list .disabled:before{
	color: #ffffff;
}

.ds ul.list1{
	color: #ffffff;
}

.ds ul.list1 li{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .owl-theme .owl-dots .owl-dot span{
	border-color: #ffffff;
}

.ds .testimonials-carousel .media + p{
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .shortcode-posts.carousel .text-owl-nav > div{
	color: #ffffff;
}

.ds .shortcode-posts.carousel .text-owl-nav > div:hover{
	color: rgba(255, 255, 255, 0.5);
}

.ds .shortcode-posts.carousel .text-owl-nav > div.owl-next:before{
	color: #ffffff;
}

.ds .side-item{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .entry-excerpt{
	color: #ffffff;
}

.ds .comment-meta{
	color: #ffffff;
}

.ds .comment-meta .author_url,
.ds .comment-meta .author_url span{
	color: #ffffff;
}

.ds .comment-list article:after{
	background-color: rgba(164, 169, 185, 0.3);
}

.ds .comment-respond{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .entry-tags{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li{
	border-color: rgba(164, 169, 185, 0.3) !important;
}

.ds .widget_recent_entries li .post-date{
	color: #ffffff;
}

.ds .widget_nav_menu ul ul{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_popular_entries .media-heading{
	color: #ffffff;
}

.ds .tweet_list li + li .tweet_right{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_shopping_cart li a + a{
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a:hover{
	color: black;
}

.ds .widget .woocommerce-Price-amount{
	color: #ffffff;
}

.ds .summary .price{
	color: #ffffff;
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .summary .product_meta{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_price_filter .price_label{
	color: #a4a9b9;
}

.ds #calendar_wrap{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_calendar table{
	background-color: rgba(255, 255, 255, 0.05);
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_calendar tfoot td a{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .widget_calendar caption,
.ds .widget_calendar thead,
.ds .widget_calendar th{
	color: #ffffff;
}

.ds .widget_calendar tbody td a:hover{
	color: #ffffff;
}

.ds .grid-view h3:before,
.ds .grid-view h3:after{
	background-color: rgba(164, 169, 185, 0.3);
}

.ds .owl-carousel.product-thumbnails .owl-nav > div:after{
	color: #ffffff;
}

.ds .ui-slider{
	background-color: #ffffff;
}

.ds .ui-slider .ui-slider-handle{
	background-color: black;
}

.ds #sort_view{
	border-color: rgba(164, 169, 185, 0.3);
	color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after,
.ds #toggle_shop_view:before{
	background-color: rgba(247, 247, 247, 0.05);
}

.ds .widget_layered_nav .color-filters a:before{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .pagination > li > a,
.ds .pagination > li > span{
	color: #a4a9b9;
	background-color: transparent;
	border: 1px solid rgba(164, 169, 185, 0.3);
}

.ds .pagination > li.active > a,
.ds .pagination > li.active > span,
.ds .pagination > li > a:hover,
.ds .pagination > li > span:hover,
.ds .pagination > li > a:focus,
.ds .pagination > li > span:focus{
	background-color: black;
	color: #ffffff;
}

.ds .thumbnail{
	background-color: transparent;
}

.ds .thumbnail h3 a{
	color: #ffffff;
}

.ds .thumbnail .caption{
	border-color: transparent;
	background-color: rgba(252, 252, 252, 0.05);
}

.ds .progress{
	background-color: rgba(164, 169, 185, 0.3);
}

.ds .panel-group .panel-heading + .panel-collapse .panel-body{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds .vertical-tabs .nav > li > a{
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .vertical-tabs .nav > li.active > a,
.ds .vertical-tabs .nav > li > a:hover{
	background-color: black;
}

.ds .entry-meta .comments-link a{
	color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td{
	border-color: rgba(164, 169, 185, 0.3);
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*='today']{
	color: #ffffff;
}

.ds.page_footer{
	color: #a4a9b9;
	background-color: #191f28;
}

.ds.page_footer.light{
	background-color: #1f2732;
}

/**color section*/
.cs{
	background-color: black;
	color: rgba(255, 255, 255, 0.6);
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6{
	color: #1f2732;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a{
	color: #ffffff;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover{
	color: rgba(255, 255, 255, 0.6);
}

.cs.cs2{
	background-color: #a0ce4e;
}

.cs.cs3{
	background-color: #00bea3;
}

.cs.cs4{
	background-color: #f1894c;
}

.cs a{
	color: #ffffff;
}

.cs a:hover{
	color: #ffe2ea;
}

.cs blockquote,
.cs .blockquote{
	border-color: #ffffff;
}

.cs .divided-content > * + *:before{
	color: #ffffff;
}

.cs .with_background{
	background-color: rgba(255, 255, 255, 0.2);
}

.cs .with_border{
	border-color: rgba(255, 255, 255, 0.2);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after{
	background-color: #ff7ca1;
}

.cs .section_header:before{
	background-color: #ff7ca1;
}

.cs .ls .greylinks a{
	color: #1f2732;
}

.cs .ls .greylinks a:hover,
.cs .ls .greylinks a:focus,
.cs .ls .greylinks a:active{
	color: #7f7f7f;
}

.cs .black{
	border-color: #ffffff;
	color: #ffffff;
}

.cs .grey{
	border-color: #1f2732;
	color: #1f2732;
}

.cs .response{
	color: #ffffff;
}

.cs.page_copyright i{
	color: #ffffff;
}

.cs ul.list1 li{
	border-color: rgba(255, 255, 255, 0.2);
}

.cs [class*='soc-']{
	color: #ffffff;
	border-color: #ffffff;
}

.cs .color-icon.bg-icon{
	background-color: #ffffff;
}

.cs a.social-icon.color-bg-icon:hover{
	background-color: #1f2732;
}

.cs input[type="text"],
.cs input[type="email"],
.cs input[type="url"],
.cs input[type="password"],
.cs input[type="search"],
.cs input[type="tel"],
.cs input[type="number"],
.cs textarea,
.cs select,
.cs .form-control{
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0.2);
}

.cs .form-control:focus{
	border-color: rgba(255, 255, 255, 0.5);
}

.cs .form-control:-moz-placeholder{
	color: rgba(255, 255, 255, 0.5);
	opacity: 1;
}

.cs .form-control::-moz-placeholder{
	color: rgba(255, 255, 255, 0.5);
	opacity: 1;
}

.cs .form-control:-ms-input-placeholder{
	color: rgba(255, 255, 255, 0.5);
}

.cs .form-control::-webkit-input-placeholder{
	color: rgba(255, 255, 255, 0.5);
}

.cs .pagination > li > a,
.cs .pagination > li > span{
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.2);
}

.cs .pagination > li.active > a,
.cs .pagination > li.active > span,
.cs .pagination > li > a:hover,
.cs .pagination > li > span:hover,
.cs .pagination > li > a:focus,
.cs .pagination > li > span:focus{
	background-color: #ffffff;
	color: #1f2732;
}

.cs .widget_recent_entries li + li,
.cs .widget_recent_comments li + li,
.cs .widget_archive li + li,
.cs .widget_categories li + li,
.cs .widget_meta li + li,
.cs .widget_popular_entries li + li,
.cs .widget_nav_menu li + li,
.cs .widget_pages li + li{
	border-top-color: rgba(255, 255, 255, 0.2);
}

/** custom classes for all elements */
.title-center .widget-title{
	text-align: center;
}

.sidebar-visible,
.shortcode-widget-area .sidebar-visible{
	display: none;
}

aside .sidebar-visible{
	display: block;
}

@media (min-width: 1200px) {
	.mobile-overlay.section_overlay:after{
		display: none;
	}
}

@media (min-width: 479px) {
	.space-between{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
	}
}

@media (max-width: 478px) {
	.space-between > *:not(:first-child){
		margin-top: 40px;
	}
}

/* Services custom classes */
.negative-img-margin-60{
	padding: 0 20px;
}

.negative-img-margin-60 .img-wrap{
	margin-left: -15px;
	margin-right: -15px;
}

@media (min-width: 400px) {
	.negative-img-margin-60 .img-wrap{
		margin-left: -35px;
		margin-right: -35px;
	}
}

@media (min-width: 1200px) {
	.negative-img-margin-60 .img-wrap{
		margin-left: -60px;
		margin-right: -60px;
	}
}

@media (min-width: 400px) {
	.negative-img-margin-60{
		padding: 0 35px;
	}
}

@media (min-width: 1200px) {
	.negative-img-margin-60{
		padding: 0 60px;
	}
}

@media (min-width: 1200px) {
	.right-padding-60{
		padding-right: 60px;
	}
}

@media (min-width: 992px) {
	.custom-left-part,
	.custom-right-part{
		width: 50%;
		display: block;
		float: left;
		margin-bottom: 25px;
	}

	.custom-left-part{
		padding-right: 40px;
	}
}

/* Footer widgets margin*/
@media (min-width: 992px) {
	.widget.margin-top-md .widget-title{
		margin-top: 35px;
		margin-bottom: 55px;
	}
}

.margin-negative{
	margin-top: -100px !important;
}

.negative_section .container,
.negative_section .container-fluid{
	z-index: 3;
	position: relative;
}

.negative-row-margin .row{
	position: relative;
	z-index: 2;
	margin-top: -100px !important;
}

.fw-column.special_button{
	z-index: 5;
	position: relative;
}

.fw-column.special_button .theme_button.color_white:hover{
	background-color: #ffffff;
	color: black !important;
	border-color: black !important;
}

.fw-column.special_button_2{
	z-index: 5;
	position: relative;
}

.fw-column.special_button_2 .theme_button.color1:hover{
	background-color: #ffffff;
	color: black !important;
}

/* Vertical Lines */
section.top_white_line_big{
	position: relative;
}

section.top_white_line_big .container:before{
	content: '';
	background-image: url("../img/white_line_big.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 134px;
	z-index: 4;
	top: 0;
	left: 50%;
	margin-left: -2px;
}

section.bottom_white_line_big{
	position: relative;
}

section.bottom_white_line_big .container:before{
	content: '';
	background-image: url("../img/white_line_big.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 134px;
	z-index: 4;
	bottom: 0;
	left: 50%;
	margin-left: -2px;
}

section.top_pink_line_big{
	position: relative;
}

section.top_pink_line_big .container:before{
	content: '';
	background-image: url("../img/pink_line_big.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 134px;
	z-index: 4;
	top: 0;
	left: 50%;
	margin-left: -2px;
}

section.bottom_pink_line_big{
	position: relative;
}

section.bottom_pink_line_big .container:before{
	content: '';
	background-image: url("../img/pink_line_big.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 134px;
	z-index: 4;
	bottom: 0;
	left: 50%;
	margin-left: -2px;
}

section.bottom_pink_line_short{
	position: relative;
}

section.bottom_pink_line_short .container:before{
	content: '';
	background-image: url("../img/pink_line_short.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 94px;
	z-index: 4;
	bottom: 0;
	left: 50%;
	margin-left: -2px;
}

section.top_dark_line_short{
	position: relative;
}

section.top_dark_line_short .container:before{
	content: '';
	background-image: url("../img/dark_line_short.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 4px;
	height: 94px;
	z-index: 4;
	top: 8px;
	left: 50%;
	margin-left: -2px;
}

@media (min-width: 992px) {
	section.columns-height .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
		height: 100%;
	}
}

@media (min-width: 992px) {
	section.colums-center .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
	}
}

.flex-center{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
}

/* Icon Boxes */
.icon-box.icon-boxes{
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}

.icon-box.icon-boxes .box-heading{
	font-size: 54px;
	font-weight: bold;
	margin: 0;
}

.icon-box.icon-boxes .box_icon{
	height: 60px;
}

.icon-box.max-340{
	max-width: 340px;
}

.icon-box.main-color .box-heading{
	color: black;
}

.icon-box.dark-color .box-heading{
	color: #1f2732;
}

.icon-box.light-color .box-heading{
	color: #ffffff;
}

.services-footer-menu > *{
	display: inline-block;
  /*
	width: 44%;
  */
  width:100%;
}

.services-footer-menu > *:first-child{
	margin-right: 9%;
}

.footer-alt-logo{
	margin-top: -90px;
}

.section-special > div{
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 992px) {
	.section-special > div{
		max-width: 580px;
	}
}

@media (min-width: 1200px) {
	.section-special > div.right{
		padding-left: 70px;
	}
}

@media (min-width: 1200px) {
	.section-special > div.left{
		padding-right: 70px;
	}
}

@media (min-width: 992px) {
	.section-special.left{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: end;
		justify-content: flex-end;
	}
}

@media (min-width: 992px) {
	.section-special.section-special-big > div{
		max-width: 730px;
		width: 100%;
	}
}

@media (min-width: 992px) {
	.section-special.section-special-small > div{
		max-width: 412px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.bg-transparent-lg{
		background-color: transparent !important;
	}
}

@media (min-width: 1200px) {
	.margin-top-minus{
		margin-top: -315px;
	}
}

@media (min-width: 1200px) {
	.margin-top-minus-2{
		margin-top: -290px;
	}
}

/***
header 2
***/
@media (min-width: 768px) {
	.home .main-header-wrap{
		position: absolute;
		width: 100%;
	}

	.home .main-header-wrap .page_header{
		-webkit-transition: background 0.2s ease-in-out 0s;
		transition: background 0.2s ease-in-out 0s;
		background-color: rgba(25, 31, 40, 0.5);
	}

	.home .main-header-wrap .page_header.affix{
		position: fixed;
		background-color: #1f2732;
	}

	.home .main-header-wrap.transparent_wrapper .page_header{
		-webkit-transition: background 0.2s ease-in-out 0s;
		transition: background 0.2s ease-in-out 0s;
		background-color: transparent;
	}

	.home .main-header-wrap.transparent_wrapper .page_header.affix{
		position: fixed;
		background-color: rgba(25, 31, 40, 0.8);
	}

	.home .intro_section.page_mainslider .slide_description_wrapper{
		margin-top: 30px;
	}
}

.home.with-slider .main-header-wrap + .page_breadcrumbs{
	padding-top: 120px;
}

@media (max-width: 992px) {
	.home.with-slider .main-header-wrap + .page_breadcrumbs{
		padding-top: 90px;
	}
}

/* Header position when slider is active  */
.header_left_logo{
	position: relative;
}

.header_left_logo{
	white-space: nowrap;
	padding: 0px;
	overflow: visible;
}

.header_left_logo .logo img{
	max-width: 200px;
}

.header_mainmenu{
	overflow: visible;
	padding: 0 15px;
}

@media (max-width: 991px) {
	.header_mainmenu{
		padding: 0;
		height: 60px;
	}
}

@media (max-width: 991px) {
	.page_social_icons .social-icons{
		margin-left: 20px;
	}
}

.page_header [class*="container"]{
	-webkit-transition: padding-top 0.2s linear 0s, padding-bottom 0.2s linear 0s;
	transition: padding-top 0.2s linear 0s, padding-bottom 0.2s linear 0s;
}

.page_header.affix [class*="container"]{
	padding-top: 0;
	padding-bottom: 0;
}

.row .header_right_buttons{
	padding: inherit;
}

.header_right_buttons .header_phone{
	font-size: 24px;
	color: #ffffff;
	font-weight: 200;
}

.ls .header_right_buttons .header_phone{
	color: #1f2732;
}

.header_right_buttons .header_phone span{
	color: black;
}

@media (min-width: 1600px) {
	.header_left_logo{
		padding: 0 15px;
	}

	.row .header_left_logo{
		padding: inherit;
	}

	.header_right_buttons{
		padding: 0;
	}

	.row .header_right_buttons{
		padding: inherit;
	}
}

@media (min-width: 768px) {
	.header_right_buttons{
		white-space: nowrap;
		text-align: right;
		overflow: visible;
	}
}

.ls.affix{
	box-shadow: 0 7px 30px 0px rgba(0, 0, 0, 0.3);
}

.ds.affix{
	box-shadow: 0 7px 30px 0px rgba(0, 0, 0, 0.3);
}

/* topline buttons */
.currency-dropdown .dropdown-menu{
	min-width: 0;
	padding: 20px 10px;
}

.currency-dropdown .dropdown-menu > li > a{
	color: inherit;
	padding: 5px 20px;
}

.currency-dropdown .dropdown-menu > li > a:hover,
.currency-dropdown .dropdown-menu > li.active-currency{
	color: #1f2732;
}

.login-dropdown .dropdown-menu{
	min-width: 400px;
	padding: 30px 30px;
	margin-top: 14px;
}

.login-dropdown .dropdown-menu input[type=checkbox]{
	margin-top: 8px;
}

.cart-dropdown .dropdown-menu{
	min-width: 400px;
	padding: 20px 30px;
}

.cart-dropdown .cart-products-number{
	position: absolute;
	font-size: 8px;
	color: #1f2732;
	background-color: black;
	border-radius: 50%;
	line-height: 1;
	padding: 1px 3px;
	top: 25px;
	left: 50%;
	margin-left: 2px;
	font-weight: bold;
}

@media (max-width: 767px) {
	.login-dropdown,
	.cart-dropdown{
		position: static;
	}

	.login-dropdown .dropdown-menu,
	.cart-dropdown .dropdown-menu{
		margin-left: 0;
		margin-right: 0;
		right: 0;
		left: 0;
		min-width: 0;
	}
}

/* logo */
a.logo{
	display: inline-block;
	white-space: nowrap;
	line-height: 1;
	position: relative;
	z-index: 2;
	padding: 5px 0;
}

a.logo img{
	display: inline-block;
	vertical-align: middle;
}

a.logo .logo_text{
	padding: 5px 8px 5px 8px;
	display: inline-block;
	vertical-align: middle;
	font-weight: 200;
	font-size: 30px;
	text-transform: none;
	line-height: 1.2;
}

@media (max-width: 479px) {
	a.logo .logo_text{
		padding: 5px;
		font-size: 28px;
	}
}

a.logo .logo_text span{
	font-weight: 500;
}

a.logo .logo_text .logo_subtext{
	font-weight: 300;
	font-size: 11px;
	text-transform: uppercase;
	display: block;
	width: 100%;
	color: #a5a5a5;
	margin-left: 2px;
}

a.logo.logo_text_only{
	margin-left: 0;
	line-height: 80px;
}

.header_gradient a.logo,
.header_darkgrey a.logo{
	color: #ffffff;
}

a.logo.bottom_logo{
	padding: 0;
	font-size: 28px;
}

a.logo.bottom_logo img{
	max-width: 65px;
}

.floating_logo a.logo{
	float: left;
	margin-right: 30px;
}

a.logo.vertical_logo{
	padding: 0;
}

a.logo.vertical_logo img{
	margin: 0 0 10px;
}

/*header_thin*/
@media (min-width: 992px) {
	.header_thin .sf-menu > li > a{
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

/*text near logo*/
.logo-meta{
	line-height: 1.2;
	display: inline-block;
	padding: 20px 0;
}

@media (max-width: 1400px) {
	.logo + .logo-meta{
		display: none;
	}
}

.logo-meta strong{
	font-size: 20px;
	line-height: 1.6;
	font-weight: 500;
	color: #ffffff;
}

.logo-meta a:hover{
	color: #ffffff;
}

.page_header_side .logo-meta{
	display: block !important;
}

.lt-ie9 .page_header_wrapper{
	height: auto !important;
}

.lt-ie9 .page_header_wrapper .md-hidden{
	display: inline-block;
}

.page_toplogo .shortcode-icon .icon-wrap.bg_color{
	background-color: transparent !important;
	border: 1px solid rgba(164, 169, 185, 0.3);
	border-radius: 3px;
}

.page_toplogo .shortcode-icon .icon-wrap.bg_color.color_1:hover{
	border: 1px solid black;
	background-color: black !important;
}

.page_toplogo .shortcode-icon .icon-wrap.bg_color.color_1:hover i{
	color: #ffffff;
}

.page_toplogo .shortcode-icon .icon-wrap.bg_color.color_2:hover{
	border: 1px solid #a0ce4e;
	background-color: #a0ce4e !important;
}

.page_toplogo .shortcode-icon .icon-wrap.bg_color.color_2:hover i{
	color: #ffffff;
}

.page_toplogo .header_logo_center .logo{
	width: 100%;
	max-width: 100%;
	display: block;
	text-align: center;
	padding: 0;
}

.page_toplogo .header_logo_center .logo img{
	max-width: 80px;
}

.page_toplogo .header_logo_center .logo_text{
	width: 100%;
	display: block;
}

.page_toplogo .header_logo_center .logo_image_and_text .logo_text{
	padding: 0 15px;
	line-height: 0.6;
}

.page_toplogo .teaser,
.page_toplogo .small-teaser{
	vertical-align: middle;
	display: inline-block;
}

.page_toplogo .teaser .media-left,
.page_toplogo .small-teaser .media-left{
	line-height: 1;
}

.page_toplogo .teaser .media-body,
.page_toplogo .small-teaser .media-body{
	text-align: left;
	width: auto;
}

.page_toplogo .teaser h4,
.page_toplogo .small-teaser h4{
	font-size: 18px;
	margin-bottom: 0;
}

@media (min-width: 992px) {
	.page_toplogo{
		white-space: nowrap;
	}
}

@media (max-width: 991px) {
	.page_toplogo .container{
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.page_header{
	top: 0;
	left: 0;
	right: 0;
	position: relative;
	z-index: 1000;
}

.page_header.mobile-active{
	z-index: 1002;
}

.page_header.affix{
	position: fixed;
}

.page_header.affix .search_modal{
	display: none;
}

.page_header.affix-bottom{
	top: 0 !important;
	position: fixed;
}

.page_header a.header-button{
	display: inline-block;
	padding: 0 10px;
	line-height: 60px;
	position: relative;
}

.page_header .social-icon:last-child{
	margin-right: 6px;
}

/* modal search */
.search_modal{
	display: none;
}

.home .search_modal{
	display: block;
}

.home .search_modal a.header_custom_button,
.home .search_modal a.search_modal_button{
	width: 60px;
	height: 60px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	text-align: center;
	top: 96px;
	background-color: #ffffff;
	border: 1px solid #dedfe1;
	border-radius: 50%;
	z-index: 100;
}

@media (max-width: 991px) {
	.home .search_modal a.header_custom_button,
	.home .search_modal a.search_modal_button{
		display: none;
	}
}

.home .search_modal a.header_custom_button i,
.home .search_modal a.search_modal_button i{
	color: #1f2732;
}

.home .search_modal a.header_custom_button:hover,
.home .search_modal a.search_modal_button:hover{
	background-color: black;
	border: 1px solid #1f2732;
}

.home .search_modal a.header_custom_button:hover i,
.home .search_modal a.search_modal_button:hover i{
	color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.home .modal-open .page_header_wrapper,
.home .modal-open .page_header.affix{
	right: 16px;
}

.home #search_modal{
	color: #ffffff;
}

.home #search_modal .widget_search{
	padding: 20px;
	background-image: none;
}

.home #search_modal .widget_search:before{
	display: none;
}

.home #search_modal .search-form{
	margin: 0 0 0 40px;
}

.home #search_modal .search-submit{
	color: #ffffff;
	height: 45px;
	width: 60px;
}

.home #search_modal .search-submit:hover{
	color: black;
}

.home #search_modal a{
	color: #ffffff;
	opacity: 0.6;
	font-size: 12px;
	text-transform: uppercase;
}

.home #search_modal a:hover{
	color: black;
	opacity: 1;
}

.home #search_modal .searchform-respond{
	width: 300px;
	margin: 20px auto;
}

.home #search_modal .form-control{
	border: none;
	background-color: transparent;
	height: 40px;
	color: #ffffff;
	width: 100%;
}

.home #search_modal .form-control:-moz-placeholder{
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

.home #search_modal .form-control::-moz-placeholder{
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

.home #search_modal .form-control:-ms-input-placeholder{
	color: rgba(255, 255, 255, 0.8);
}

.home #search_modal .form-control::-webkit-input-placeholder{
	color: rgba(255, 255, 255, 0.8);
}

.home #search_modal .close{
	padding: 28px 20px;
	position: absolute;
	min-width: 0;
	color: #ffffff;
	text-shadow: none;
	opacity: 1;
}

/*
header floats
*/
.page_header:before,
.page_header:after{
	content: '';
	display: block;
	clear: both;
}

@media (min-width: 992px) {
	.page_header{
		height: auto;
	}

	.header_left_logo{
		white-space: nowrap;
	}
}

/***
header 3
***/
.header-3 .page_topline{
	position: relative;
	z-index: 1001;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 500;
	color: #ffffff;
	background: rgba(0, 0, 0, 0.7);
}

.home .header-3 .page_topline{
	background: rgba(0, 0, 0, 0.2);
}

.header-3 .page_topline a{
	color: #ffffff;
}

.header-3 .page_topline a:hover{
	color: black;
}

.header-3 .page_topline span.social-icons{
	display: inline-block;
}

@media (max-width: 767px) {
	.header-3 .page_topline [class*="col-"] > span{
		display: block;
	}
}

.header-3 .page_topline .header_phone:before,
.header-3 .page_topline .header_email:before,
.header-3 .page_topline .header_open_hours:before{
	content: "";
	font-family: FontAwesome;
	font-size: 14px;
	margin-right: 5px;
	font-weight: 400;
}

@media (max-width: 992px) {
	.header-3 .page_topline .header_phone:before,
	.header-3 .page_topline .header_email:before,
	.header-3 .page_topline .header_open_hours:before{
		font-size: 12px;
	}
}

@media (max-width: 992px) {
	.header-3 .page_topline .header_phone,
	.header-3 .page_topline .header_email,
	.header-3 .page_topline .header_open_hours{
		display: inline-block;
		font-size: 10px;
	}
}

.header-3 .page_topline .header_phone{
	margin-right: 20px;
}

@media (max-width: 1600px) {
	.header-3 .page_topline .header_phone{
		display: block;
	}
}

.header-3 .page_topline .header_phone:before{
	content: "\f095";
}

.header-3 .page_topline .header_email:before{
	content: "\f041";
}

.header-3 .page_topline .header_open_hours:before{
	content: "\f017";
}

.header-3.main-header-wrap .page_header{
	background-color: #191f28 !important;
}

.header-3.main-header-wrap .page_header .login-dropdown .dropdown-menu{
	padding: 30px 30px;
	margin-top: 44px;
}

.header-3.main-header-wrap .page_header .login-dropdown .dropdown-menu label{
	color: black;
}

.header-3.main-header-wrap .page_header .header_right_buttons > *{
	display: inline-block;
}

.header-3.main-header-wrap .page_header .header_right_buttons .header-button{
	width: 40px;
	height: 40px;
	position: relative;
	line-height: 40px;
	left: auto;
	margin-left: 5px;
	text-align: center;
	top: auto;
	background-color: #ffffff;
	border: 0;
	border-radius: 50%;
	z-index: 100;
	font-size: 14px;
}

.header-3.main-header-wrap .page_header .header_right_buttons .header-button:hover i{
	color: #ffffff;
}

.header-3.main-header-wrap .page_header .header_right_buttons .header_custom_button i,
.header-3.main-header-wrap .page_header .header_right_buttons .search_modal_button i{
	color: #00bea3;
}

.header-3.main-header-wrap .page_header .header_right_buttons .header_custom_button:hover,
.header-3.main-header-wrap .page_header .header_right_buttons .search_modal_button:hover{
	background-color: #00bea3;
}

.header-3.main-header-wrap .page_header .header_right_buttons .registration__toggle i{
	color: #a0ce4e;
}

.header-3.main-header-wrap .page_header .header_right_buttons .registration__toggle:hover{
	background-color: #a0ce4e;
}

.header-3.main-header-wrap .page_header .header_right_buttons .login-button i{
	color: #f1894c;
}

.header-3.main-header-wrap .page_header .header_right_buttons .login-button:hover{
	background-color: #f1894c;
}

@media (min-width: 768px) {
	.home.with-slider .header-3.main-header-wrap + .intro_section.page_mainslider .slide_description_wrapper{
		margin-top: 75px;
	}
}

/***
header 4
***/
.header-4 .page-topline{
	position: relative;
	z-index: 1001;
	background: #191f28;
}

@media (min-width: 768px) {
	.header-4 .page-topline{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.header-4 .page-topline .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}
}

@media (min-width: 992px) {
	.header-4 .page-topline{
		height: 160px;
	}
}

.header-4 .page-topline .header_left_logo{
	padding: 0;
}

.header-4 .page-topline .login-dropdown .dropdown-menu{
	padding: 30px 30px;
	margin-top: 59px;
}

.header-4 .page-topline .login-dropdown .dropdown-menu label{
	color: black;
}

.header-4 .page-topline .page_social_icons a{
	color: #ffffff;
}

.header-4 .page-topline .header_right_buttons{
	padding-left: 0;
	padding-right: 0;
}

.header-4 .page-topline .header_right_buttons > *{
	display: inline-block;
}

.header-4 .page-topline .header_right_buttons .header-button{
	width: 40px;
	height: 40px;
	position: relative;
	line-height: 40px;
	left: auto;
	margin-left: 5px;
	text-align: center;
	top: auto;
	background-color: #ffffff;
	border: 0;
	border-radius: 50%;
	z-index: 100;
	font-size: 14px;
	display: inline-block;
}

.header-4 .page-topline .header_right_buttons .header-button:hover i{
	color: #ffffff;
}

.header-4 .page-topline .header_right_buttons .header_custom_button i,
.header-4 .page-topline .header_right_buttons .search_modal_button i{
	color: #00bea3;
}

.header-4 .page-topline .header_right_buttons .header_custom_button:hover,
.header-4 .page-topline .header_right_buttons .search_modal_button:hover{
	background-color: #00bea3;
}

.header-4 .page-topline .header_right_buttons .registration__toggle i{
	color: #a0ce4e;
}

.header-4 .page-topline .header_right_buttons .registration__toggle:hover{
	background-color: #a0ce4e;
}

.header-4 .page-topline .header_right_buttons .login-button i{
	color: #f1894c;
}

.header-4 .page-topline .header_right_buttons .login-button:hover{
	background-color: #f1894c;
}

.header-4 .page-topline span.social-icons{
	display: inline-block;
	margin-left: 0;
}

@media (max-width: 767px) {
	.header-4 .page-topline [class*="col-"] > span{
		display: block;
	}
}

.header-4.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.home .header-4.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 767px) {
	.header-4.main-header-wrap .page_header{
		background-color: #191f28 !important;
	}
}

.header-4.main-header-wrap .page_header.affix{
	background-color: #191f28 !important;
}

@media (min-width: 992px) {
	.header-4 + .page_mainslider{
		margin-top: 160px;
	}
}

/***
header 8
***/
.header-8 .affix{
	position: fixed;
	background-color: rgba(255, 255, 255, 0.8) !important;
}

.header-8 .toggle_menu_side{
	position: relative;
	display: inline-block;
	right: 0;
	left: auto;
	top: -5px;
	margin-left: 40px;
	box-shadow: none;
	background-color: transparent;
	border-top: 4px solid #1f2732;
	border-bottom: 4px solid #1f2732;
	width: 40px;
	height: 40px;
	line-height: 32px;
	color: #1f2732;
}

.header-8 .toggle_menu_side:before{
	font-family: "Poppins", sans-serif;
	content: "Menu";
	font-weight: 700;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 3px;
}

/***
header 10
***/
.header-10 .page-topline{
	position: relative;
	z-index: 1001;
	background-color: rgba(0, 0, 0, 0.2) !important;
}

@media (min-width: 768px) {
	.header-10 .page-topline .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}
}

@media (min-width: 992px) {
	.header-10 .page-topline{
		height: 120px;
	}
}

.header-10 .page-topline .header_left_logo{
	padding: 0;
}

.header-10 .page-topline .header_phone{
	font-size: 24px;
	color: #ffffff;
	font-weight: 200;
}

.ls .header-10 .page-topline .header_phone{
	color: #1f2732;
}

.header-10 .page-topline .header_phone span{
	color: black;
}

.header-10 .page-topline .header_right_buttons{
	padding-left: 0;
	padding-right: 0;
}

.header-10 .page-topline .header_right_buttons > *{
	display: inline-block;
}

.header-10 .page-topline span.social-icons{
	display: inline-block;
	margin-left: 0;
}

@media (max-width: 767px) {
	.header-10 .page-topline [class*="col-"] > span{
		display: block;
	}
}

.header-10.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.home .header-10.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 767px) {
	.header-10.main-header-wrap .page_header{
		background-color: #191f28 !important;
	}
}

.header-10.main-header-wrap .page_header.affix{
	background-color: #191f28 !important;
}

@media (min-width: 992px) {
	.header-10 + .page_mainslider{
		margin-top: 160px;
	}
}

.header-11 .page-topline{
	position: relative;
	z-index: 1001;
	background: #ffffff;
}

@media (min-width: 768px) {
	.header-11 .page-topline{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.header-11 .page-topline .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}
}

@media (min-width: 992px) {
	.header-11 .page-topline{
		height: 160px;
	}
}

.header-11 .page-topline .header_left_logo{
	padding: 0;
}

.header-11 .page-topline .login-dropdown .dropdown-menu{
	padding: 30px 30px;
	margin-top: 59px;
}

.header-11 .page-topline .login-dropdown .dropdown-menu label{
	color: black;
}

.header-11 .page-topline .page_social_icons a{
	color: #1f2732;
}

.header-11 .page-topline .header_right_buttons{
	padding-left: 0;
	padding-right: 0;
}

.header-11 .page-topline .header_right_buttons > *{
	display: inline-block;
}

.header-11 .page-topline .header_right_buttons .header-button{
	width: 40px;
	height: 40px;
	position: relative;
	line-height: 40px;
	left: auto;
	right: auto;
	margin-left: 5px;
	text-align: center;
	top: auto;
	background-color: #ffffff;
	box-shadow: 0 0 6px 1px rgba(31, 39, 50, 0.1);
	border: 0;
	border-radius: 50%;
	z-index: 100;
	font-size: 14px;
	display: inline-block;
}

.header-11 .page-topline .header_right_buttons .header-button:hover i{
	color: #ffffff;
}

.header-11 .page-topline .header_right_buttons .header_custom_button i,
.header-11 .page-topline .header_right_buttons .search_modal_button i{
	color: #00bea3;
}

.header-11 .page-topline .header_right_buttons .header_custom_button:hover,
.header-11 .page-topline .header_right_buttons .search_modal_button:hover{
	background-color: #00bea3;
}

.header-11 .page-topline .header_right_buttons .registration__toggle i{
	color: #a0ce4e;
}

.header-11 .page-topline .header_right_buttons .registration__toggle:hover{
	background-color: #a0ce4e;
}

.header-11 .page-topline .header_right_buttons .login-button i{
	color: #a0ce4e;
}

.header-11 .page-topline .header_right_buttons .login-button:hover{
	background-color: #a0ce4e;
}

.header-11 .page-topline .header_right_buttons .toggle_menu_side{
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header-11 .page-topline .header_right_buttons .toggle_menu_side::before{
	color: #f1894c;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header-11 .page-topline .header_right_buttons .toggle_menu_side:hover{
	background-color: #f1894c;
}

.header-11 .page-topline .header_right_buttons .toggle_menu_side:hover::before{
	color: #ffffff;
}

.header-11 .page-topline span.social-icons{
	display: inline-block;
	margin-left: 0;
}

@media (max-width: 767px) {
	.header-11 .page-topline [class*="col-"] > span{
		display: block;
	}
}

.header-11.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.home .header-11.main-header-wrap .page_header{
	background-color: rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 767px) {
	.header-11.main-header-wrap .page_header{
		background-color: #191f28 !important;
	}
}

.header-11.main-header-wrap .page_header.affix{
	background-color: #191f28 !important;
}

@media (min-width: 992px) {
	.header-11 + .page_mainslider{
		margin-top: 160px;
	}
}

@media (min-width: 992px) {
	.header-11 .sf-menu > li > a{
		padding-top: 24px;
		padding-bottom: 23px;
	}
}

.header-12 .toggle_menu_side,
.header-13 .toggle_menu_side{
	position: relative;
	display: inline-block;
	right: 0;
	left: auto;
	top: 5px;
	margin-left: 40px;
	box-shadow: none;
	background-color: transparent !important;
	width: 40px;
	height: 40px;
	line-height: 32px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header-12 .toggle_menu_side::before,
.header-13 .toggle_menu_side::before{
	font-size: 20px;
}

.header-12 .toggle_menu_side:hover,
.header-13 .toggle_menu_side:hover{
	color: black;
}

@media (max-width: 1100px) and (min-width: 992px) {
	.header-13 .col-xs-12 .header_left_logo{
		padding-left: 40px;
	}
}

.page_header_side_special.page_header_side_special-2{
	background: #1f2732 !important;
}

.page_header_side_special.page_header_side_special-2 .toggle-wrap{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	margin-bottom: 30px;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side{
	position: static;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	background-color: transparent !important;
	box-shadow: none !important;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side span{
	display: inline-block;
	height: 2px;
	width: 17px;
	background-color: #ffffff;
	margin: 2px 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side span:first-child{
	background-color: black;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side::before{
	display: none !important;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side:hover span:first-child{
	opacity: 0;
	margin: 0;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side:hover span:nth-child(2){
	-webkit-transform: rotate(-45deg) translateX(-4px);
	transform: rotate(-45deg) translateX(-4px);
	-webkit-transform-origin: center;
	transform-origin: center;
}

.page_header_side_special.page_header_side_special-2 .toggle_menu_side:hover span:last-child{
	-webkit-transform: rotate(45deg) translateX(-4px);
	transform: rotate(45deg) translateX(-4px);
	-webkit-transform-origin: center;
	transform-origin: center;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu{
	margin-bottom: 30px;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu .nav > li:nth-child(2n + 2){
	border-bottom: 1px solid #dedfe1;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu ul ul li{
	padding-left: 0;
	text-align: center;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu a{
	text-align: center;
	padding-left: 25px;
	padding-right: 25px;
	margin-right: 0;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu a::before{
	position: relative;
	top: auto;
	bottom: 3px;
	margin-right: 15px;
}

.page_header_side_special.page_header_side_special-2 .widget_nav_menu a:hover{
	color: black;
}

.page_header_side_special.page_header_side_special-3{
	background: #1f2732 !important;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side{
	position: static;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	background-color: transparent !important;
	box-shadow: none !important;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side span{
	display: inline-block;
	height: 2px;
	width: 17px;
	background-color: #ffffff;
	margin: 2px 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side span:first-child{
	background-color: black;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side::before{
	display: none !important;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side:hover span:first-child{
	opacity: 0;
	margin: 0;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side:hover span:nth-child(2){
	-webkit-transform: rotate(-45deg) translateX(-4px);
	transform: rotate(-45deg) translateX(-4px);
	-webkit-transform-origin: center;
	transform-origin: center;
}

.page_header_side_special.page_header_side_special-3 .toggle_menu_side:hover span:last-child{
	-webkit-transform: rotate(45deg) translateX(-4px);
	transform: rotate(45deg) translateX(-4px);
	-webkit-transform-origin: center;
	transform-origin: center;
}

.page_header_side_fixed{
	background: #1f2732 !important;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100px;
	z-index: 1001;
	padding-top: 30px;
	padding-bottom: 100px;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}

.page_header_side_fixed .toggle_menu_side{
	position: static;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	background-color: transparent !important;
	box-shadow: none !important;
}

.page_header_side_fixed .toggle_menu_side span{
	display: inline-block;
	height: 2px;
	width: 17px;
	background-color: #ffffff;
	margin: 2px 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.page_header_side_fixed .toggle_menu_side::before{
	display: none !important;
}

.page_header_side_fixed .toggle_menu_side:hover span:first-child{
	background-color: black;
}

.page_header_side_fixed .social-icons{
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.page_header_side_fixed .social-icons a + a{
	margin-left: 0 !important;
	margin-top: 20px;
}

@media (max-width: 1500px) {
	.page_header_side_fixed{
		width: 50px;
	}
}

/*
**side header
*/
body{
	position: relative;
	left: 0;
}

body:after{
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	visibility: hidden;
	opacity: 0;
}

body,
body:after,
.page_header_side{
	-webkit-transition: all 0.8s ease-in-out;
	transition: all 0.8s ease-in-out;
}

body{
	-webkit-transition-property: left, right;
	transition-property: left, right;
}

body.active-side-header{
	left: 270px;
}

body.active-side-header .page_header_side{
	left: 0;
}

body.active-side-header:after{
	visibility: visible;
	opacity: 1;
}

.page_header_side{
	z-index: 1002;
	position: fixed;
	width: 270px;
	left: -270px;
	top: 0;
	bottom: 0;
}

.page_header_side.active-slide-side-header{
	box-shadow: 0 0px 3px 3px rgba(50, 50, 50, 0.15);
	background-color: #1f2732;
}

.side_header_inner{
	padding: 60px 30px;
}

.toggle_menu_side{
	position: absolute;
	top: 15px;
	right: -65px;
	background-color: #fff;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	cursor: pointer;
	box-shadow: 0 0 4px 0 rgba(50, 50, 50, 0.5);
}

.toggle_menu_side:before{
	font-family: 'FontAwesome';
	content: "\f0c9";
}

.active-slide-side-header .toggle_menu_side:before,
.active-side-header .toggle_menu_side:before{
	content: "\f00d";
}

.ds .toggle_menu_side{
	background-color: #1f2732;
	color: #ffffff;
}

@media (max-width: 350px) {
	.toggle_menu_side{
		top: 5px;
		right: -55px;
	}
}

.page_header_side.active-slide-side-header{
	left: 0;
}

/*right layout*/
.page_header_side.header_side_right{
	left: auto;
	right: -270px;
}

.page_header_side.header_side_right.active-slide-side-header{
	right: 0;
}

.page_header_side.header_side_right .toggle_menu_side{
	right: auto;
	left: -65px;
}

@media (max-width: 350px) {
	.page_header_side.header_side_right .toggle_menu_side{
		left: -50px;
	}
}

body.active-side-header.slide-right{
	left: -270px;
}

body.active-side-header.slide-right .page_header_side{
	left: auto;
	right: 0;
}

/*side menu styles*/
.mainmenu_side_wrapper ul ul,
.mainmenu_side_wrapper .mega-menu{
	height: 0;
	overflow: hidden;
}

.mainmenu_side_wrapper ul li.sfHover > .mega-menu ul{
	display: block;
	height: 100%;
}

.mainmenu_side_wrapper ul li{
	border-color: rgba(164, 169, 185, 0.3) !important;
}

.mainmenu_side_wrapper .mega-menu > ul{
	height: auto;
}

.menu-side-click{
	padding: 0;
}

.menu-side-click li{
	position: relative;
	list-style: none;
}

.menu-side-click li a{
	padding-left: 25px;
	margin-right: 25px;
	font-size: 13px;
	font-weight: bold;
	color: #ffffff;
	text-transform: uppercase;
}

.menu-side-click li a:before{
	top: 10px;
}

.menu-side-click li a:hover{
	color: black;
}

.menu-side-click li .sub-menu a:before{
	top: 10px;
}

.menu-side-click li .activate_submenu{
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 10px;
	font-size: 16px;
	color: black;
	text-align: center;
	width: 25px;
}

.menu-side-click li .activate_submenu:before{
	content: '+';
}

.menu-side-click li.active-submenu > .activate_submenu:before{
	content: '-';
}

.menu-side-click ul{
	padding-left: 20px;
	opacity: 0;
	height: 0;
	overflow: hidden;
	-webkit-transition: opacity 0.05s, height 0.05s ease;
	transition: opacity 0.05s, height 0.05s ease;
}

.menu-side-click .active-submenu > div{
	height: auto;
}

.menu-side-click .active-submenu > ul,
.menu-side-click .active-submenu > div > ul{
	display: block;
	opacity: 1;
	height: auto;
	-webkit-transition: opacity 0.6s, height 0.6s ease;
	transition: opacity 0.6s, height 0.6s ease;
}

.sf-menu-side li{
	position: relative;
	list-style: none;
}

.sf-menu-side li a{
	padding-left: 25px;
	margin-right: 25px;
	font-size: 13px;
	font-weight: bold;
	color: #ffffff;
	text-transform: uppercase;
}

.sf-menu-side li a:before{
	top: 10px;
}

.sf-menu-side li a:hover{
	color: black;
}

.sf-menu-side li .sub-menu a:before{
	top: 10px;
}

/* sidebar scroll plugin */
.scroll-wrapper{
	overflow: hidden;
	padding: 0;
	position: relative;
	height: 100%;
}

.scroll-wrapper > .scroll-content{
	border: none;
	box-sizing: content-box;
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none;
	overflow: scroll;
	padding: 0;
	position: relative;
	top: 0;
	width: auto;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar{
	height: 0;
	width: 0;
}

.scroll-wrapper.scroll--rtl{
	direction: rtl;
}

.scroll-element{
	box-sizing: content-box;
	display: none;
}

.scroll-element div{
	box-sizing: content-box;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow{
	cursor: default;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible{
	display: block;
}

.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div{
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}

.scrollbar-macosx > .scroll-element div{
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track{
	display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar{
	background-color: rgba(127, 127, 127, 0.4);
	display: block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	border-radius: 7px;
	-webkit-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar{
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	opacity: 0.7;
}

.scrollbar-macosx > .scroll-element.scroll-x{
	bottom: 0;
	height: 0;
	left: 0;
	min-width: 100%;
	overflow: visible;
	width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y{
	height: 100%;
	min-height: 100%;
	right: 0;
	top: 0;
	width: 0;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar{
	height: 7px;
	min-width: 10px;
	top: -9px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar{
	left: -9px;
	min-height: 10px;
	width: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer{
	left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size{
	left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer{
	top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size{
	top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size{
	left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size{
	top: -11px;
}

/***
header 5,6
***/
.header-5 .page_topline,
.header-6 .page_topline,
.header-9 .page_topline{
	display: block;
	z-index: 4;
	position: relative;
	padding: 10px;
}

.header-5 .header-content .header_email,
.header-6 .header-content .header_email,
.header-9 .header-content .header_email{
	display: none;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.2em;
	font-size: 12px;
}

.header-5 .menu-side-click,
.header-6 .menu-side-click,
.header-9 .menu-side-click{
	padding: 0;
}

.header-5 .menu-side-click li,
.header-6 .menu-side-click li,
.header-9 .menu-side-click li{
	position: relative;
	list-style: none;
}

.header-5 .menu-side-click li a,
.header-6 .menu-side-click li a,
.header-9 .menu-side-click li a{
	padding-left: 25px;
	margin-right: 25px;
	font-size: 13px;
	font-weight: bold;
	color: #ffffff;
	text-transform: uppercase;
}

.header-5 .menu-side-click li a:before,
.header-6 .menu-side-click li a:before,
.header-9 .menu-side-click li a:before{
	top: 10px;
}

.header-5 .menu-side-click li a:hover,
.header-6 .menu-side-click li a:hover,
.header-9 .menu-side-click li a:hover{
	color: black;
}

.header-5 .menu-side-click li .sub-menu a:before,
.header-6 .menu-side-click li .sub-menu a:before,
.header-9 .menu-side-click li .sub-menu a:before{
	top: 10px;
}

.header-5 .menu-side-click li .activate_submenu,
.header-6 .menu-side-click li .activate_submenu,
.header-9 .menu-side-click li .activate_submenu{
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 15px;
	font-size: 16px;
	color: black;
	text-align: center;
	width: 25px;
}

@media (max-width: 768px) {
	.header-5 .menu-side-click li .activate_submenu,
	.header-6 .menu-side-click li .activate_submenu,
	.header-9 .menu-side-click li .activate_submenu{
		top: 10px;
	}
}

.header-5 .menu-side-click li .activate_submenu:before,
.header-6 .menu-side-click li .activate_submenu:before,
.header-9 .menu-side-click li .activate_submenu:before{
	content: '+';
}

.header-5 .menu-side-click .sub-menu .activate_submenu,
.header-6 .menu-side-click .sub-menu .activate_submenu,
.header-9 .menu-side-click .sub-menu .activate_submenu{
	top: 3px;
}

.header-5 .menu-side-click li.active-submenu > .activate_submenu:before,
.header-6 .menu-side-click li.active-submenu > .activate_submenu:before,
.header-9 .menu-side-click li.active-submenu > .activate_submenu:before{
	content: '-';
}

.header-5 .menu-side-click ul,
.header-6 .menu-side-click ul,
.header-9 .menu-side-click ul{
	padding-left: 20px;
	opacity: 0;
	height: 0;
	overflow: hidden;
	-webkit-transition: opacity 0.05s, height 0.05s ease;
	transition: opacity 0.05s, height 0.05s ease;
}

.header-5 .menu-side-click .active-submenu > div,
.header-6 .menu-side-click .active-submenu > div,
.header-9 .menu-side-click .active-submenu > div{
	height: auto;
}

.header-5 .menu-side-click .active-submenu > ul,
.header-5 .menu-side-click .active-submenu > div > ul,
.header-6 .menu-side-click .active-submenu > ul,
.header-6 .menu-side-click .active-submenu > div > ul,
.header-9 .menu-side-click .active-submenu > ul,
.header-9 .menu-side-click .active-submenu > div > ul{
	display: block;
	opacity: 1;
	height: auto;
	-webkit-transition: opacity 0.6s, height 0.6s ease;
	transition: opacity 0.6s, height 0.6s ease;
}

@media (min-width: 992px) {
	.header-5 .page_topline,
	.header-6 .page_topline,
	.header-9 .page_topline{
		display: none;
	}

	.header-5 .header-content,
	.header-6 .header-content,
	.header-9 .header-content{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
	}

	.header-5 .header-content .header_email,
	.header-6 .header-content .header_email,
	.header-9 .header-content .header_email{
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
}

.header-6 .page-topline{
	z-index: 12;
	position: absolute;
	top: 0;
	width: 100%;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header-6 .page-topline .social-icons{
	margin-left: 0;
}

.header-6 .page-topline .page-topline-tab{
	position: absolute;
	width: 80px;
	height: 40px;
	left: 50%;
	margin: 0 0 0 -40px;
	background-color: black;
	text-align: center;
}

.header-6 .page-topline .page-topline-tab a{
	color: #ffffff;
	width: 100%;
	line-height: 40px;
	display: inline-block;
}

.header-6 .page-topline .page-topline-tab a:after{
	font-family: FontAwesome;
	font-size: 16px;
	content: "\f107";
}

@media (max-width: 480px) {
	.header-6 .page-topline .page-topline-tab{
		height: 25px;
	}

	.header-6 .page-topline .page-topline-tab a{
		line-height: 25px;
	}
}

.header-6 .page-topline.transform-none .page-topline-tab a:after{
	content: "\f106";
}

.header-6 .page-topline .page_social_icons a{
	color: #ffffff;
}

.header-6 .page-topline .header_center_buttons{
	padding-left: 0;
	padding-right: 0;
}

.header-6 .page-topline .header_center_buttons > *{
	display: inline-block;
}

.header-6 .page-topline .header_center_buttons .header-button{
	width: 40px;
	height: 40px;
	position: relative;
	line-height: 40px;
	left: auto;
	margin-left: 5px;
	text-align: center;
	top: auto;
	background-color: #ffffff;
	border: 0;
	border-radius: 50%;
	z-index: 100;
	font-size: 14px;
	display: inline-block;
}

.header-6 .page-topline .header_center_buttons .header-button:hover i{
	color: #ffffff;
}

.header-6 .page-topline .header_center_buttons .header_custom_button i,
.header-6 .page-topline .header_center_buttons .search_modal_button i{
	color: #00bea3;
}

.header-6 .page-topline .header_center_buttons .header_custom_button:hover,
.header-6 .page-topline .header_center_buttons .search_modal_button:hover{
	background-color: #00bea3;
}

.header-6 .page-topline .header_center_buttons .registration__toggle i{
	color: #a0ce4e;
}

.header-6 .page-topline .header_center_buttons .registration__toggle:hover{
	background-color: #a0ce4e;
}

.header-6 .page-topline .header_center_buttons .login-button i{
	color: #f1894c;
}

.header-6 .page-topline .header_center_buttons .login-button:hover{
	background-color: #f1894c;
}

.header-6 .page-topline .header_email{
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.2em;
	font-size: 12px;
}

.page_header_side.vertical_menu_header{
	width: auto;
	left: 0;
	z-index: 11;
	bottom: auto;
	-webkit-transition: none !important;
	transition: none !important;
}

.page_header_side.vertical_menu_header.affix-top{
	position: relative;
}

.page_header_side.vertical_menu_header.affix{
	-webkit-transition: none;
	transition: none;
}

@media (min-width: 1200px) {
	.page_header_side.vertical_menu_header .container-fluid{
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (min-width: 1600px) {
	.page_header_side.vertical_menu_header:not(.affix) .container-fluid{
		-webkit-transition: all 0.2s linear 0s;
		transition: all 0.2s linear 0s;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.header-6 .page_header_side.vertical_menu_header:not(.affix) .container-fluid{
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.page_header_side.vertical_menu_header .toggle_menu_side{
	z-index: 3;
	top: 50%;
	right: 15px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	position: absolute;
	display: block;
	background-color: transparent;
	color: #ffffff;
	width: 30px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	cursor: pointer;
	box-shadow: none;
}

@media (min-width: 992px) {
	.page_header_side.vertical_menu_header .toggle_menu_side{
		position: relative;
		top: auto;
		right: auto;
		-webkit-transform: none;
		transform: none;
	}
}

.page_header_side.vertical_menu_header .toggle_menu_side:before,
.page_header_side.vertical_menu_header .toggle_menu_side:after{
	content: '';
	display: block;
	width: 30px;
	position: absolute;
	background-color: #ffffff;
	left: 50%;
	top: 15px;
	margin-left: -15px;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	height: 2px;
}

.page_header_side.vertical_menu_header .toggle_menu_side:after{
	top: auto;
	bottom: 17px;
}

.page_header_side.vertical_menu_header .toggle_menu_side > span:before{
	position: absolute;
	content: 'menu';
	font-size: 8px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.2em;
	line-height: 1.75em;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side > span:before,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side > span:before{
	display: none;
}

.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:before,
.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:after,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:before,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:after{
	left: 50%;
	top: 37px;
	margin-left: -15px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	height: 2px;
}

.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:after,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:after{
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: auto;
	bottom: 37px;
}

.page_header_side.vertical_menu_header .toggle_menu_side:hover{
	opacity: 0.7;
}

.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:before,
.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:after,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:before,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:after{
	top: 35px;
}

.active-slide-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:after,
.active-side-header .page_header_side.vertical_menu_header .toggle_menu_side:hover:after{
	top: auto;
	bottom: 35px;
}

.page_header_side.vertical_menu_header .scroll-wrapper{
	position: fixed !important;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 2;
	opacity: 0;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.admin-bar .page_header_side.vertical_menu_header .scroll-wrapper{
	top: 46px;
}

@media (min-width: 783px) {
	.admin-bar .page_header_side.vertical_menu_header .scroll-wrapper{
		top: 32px;
	}
}

.page_header_side.vertical_menu_header.active-slide-side-header .scroll-wrapper{
	opacity: 1;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

.page_header_side.vertical_menu_header .side_header_inner{
	padding: 0;
}

@media (min-width: 1600px) {
	.page_header_side.vertical_menu_header .side_header_inner .header-side-menu{
		margin-top: 30px;
	}
}

@media (min-width: 768px) {
	.page_header_side.vertical_menu_header .side_header_inner > .container-fluid{
		margin-right: 100px;
	}
}

.page_header_side.vertical_menu_header.ds .scroll-wrapper{
	background-color: #1f2732;
}

.page_header_side.vertical_menu_header .header-side-menu{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
}

.page_header_side.vertical_menu_header .menu-side-click{
	text-align: center;
	font-family: 'Fjalla One', sans-serif;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 22px;
}

.page_header_side.vertical_menu_header .menu-side-click a{
	padding: 5px 0;
}

.page_header_side.vertical_menu_header .menu-side-click > li{
	padding: 0;
	border-left: none;
}

.page_header_side.vertical_menu_header .menu-side-click > li li{
	padding: 0;
}

.page_header_side.vertical_menu_header .menu-side-click > li > a{
	padding: 8px 0;
}

.page_header_side.vertical_menu_header .menu-side-click > li ul{
	font-size: 0.75em;
}

.page_header_side.vertical_menu_header .menu-side-click > li ul > li:first-child{
	margin-top: 20px;
}

.page_header_side.vertical_menu_header .menu-side-click > li ul > li:last-child{
	margin-bottom: 35px;
}

@media (min-width: 1200px) {
	.page_header_side.vertical_menu_header .menu-side-click{
		font-size: 28px;
	}

	.page_header_side.vertical_menu_header .menu-side-click > li > a{
		padding: 15px 0;
	}

	.page_header_side.vertical_menu_header .menu-side-click > li ul > li:first-child{
		margin-top: 30px;
	}

	.page_header_side.vertical_menu_header .menu-side-click > li ul > li:last-child{
		margin-bottom: 30px;
	}
}

.page_header_side.vertical_menu_header .menu-side-click ul{
	padding: 0;
	margin: 0;
}

.page_header_side.vertical_menu_header .menu-side-click div.mega-menu{
	margin-right: 0;
}

.page_header_side.vertical_menu_header input{
	color: #ffffff;
	background-color: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding-left: 0;
}

.page_header_side.vertical_menu_header .widget_search .form-group + .theme_button{
	text-align: right;
}

.page_header_side.vertical_menu_header .widget_search form{
	display: inline-block;
}

/***
header 9
***/
.header-9 .social-icons a.social-icon{
	color: #ffffff;
}

.header-9 .menu-wrap .toggle_menu_side{
	float: right;
	position: relative;
	top: auto;
	right: auto;
	-webkit-transform: none;
	transform: none;
}

.page_header_side_special{
	background: #ffffff !important;
}

.page_header_side_special .side_header_inner{
	padding: 30px;
}

.page_header_side_special .close-wrapper{
	display: none !important;
}

.page_header_side_special .mainmenu_side_wrapper .widget{
	margin-top: 30px;
}

.page_header_side_special .mainmenu_side_wrapper .widget-first{
	margin-top: 0;
}

.page_header_side_special .mainmenu_side_wrapper .widget-title{
	margin-bottom: 14px;
}

.page_header_side_special .mainmenu_side_wrapper .widget_archive li a,
.page_header_side_special .mainmenu_side_wrapper .widget_categories li a,
.page_header_side_special .mainmenu_side_wrapper .widget_nav_menu li a,
.page_header_side_special .mainmenu_side_wrapper .widget_meta li a,
.page_header_side_special .mainmenu_side_wrapper .widget_pages li a{
	-webkit-box-flex: 10;
	flex-grow: 10;
	display: inline-block;
	padding-top: 9px;
	padding-bottom: 9px;
}

.page_header_side_special .mainmenu_side_wrapper .wordpress-helpdesk-faq-list li,
.page_header_side_special .mainmenu_side_wrapper .widget_faq_topics li{
	padding: 9px 0 9px 0;
	margin-bottom: 0;
	list-style: none;
	border-bottom: 1px solid rgba(76, 76, 76, 0.1);
}

/*
**template common sections styles
*/
.page_mainslider .flex-custom-nav{
	opacity: 0;
}

.ds.page_breadcrumbs::before{
	background: url("../img/parallax/breadcrumbs_ds.jpg") no-repeat center/cover;
}

.page_breadcrumbs{
	position: relative; /* styles for breadcrumbs style 3 */ /* styles for breadcrumbs style 4 */
}

.page_breadcrumbs::before{
	content: '';
	position: absolute;
	background: url("../img/parallax/breadcrumbs.jpg") no-repeat center/cover;
	-webkit-clip-path: polygon(0% 0%, 0% 75%, 50% 100%, 100% 75%, 100% 0%);
	clip-path: polygon(0% 0%, 0% 75%, 50% 100%, 100% 75%, 100% 0%);
	width: 100%;
	height: 100%;
	z-index: 5;
}

.page_breadcrumbs .breadcrumbs_inner{
	z-index: 5;
}

.page_breadcrumbs .breadcrumbs-title{
	display: inline;
	font-size: 54px;
	text-transform: none;
	word-wrap: break-word;
	line-height: 1;
}

@media (max-width: 991px) {
	.page_breadcrumbs .breadcrumbs-title{
		font-size: 44px;
	}
}

@media (max-width: 767px) {
	.page_breadcrumbs .breadcrumbs-title{
		font-size: 34px;
	}
}

@media (max-width: 479px) {
	.page_breadcrumbs .breadcrumbs-title{
		font-size: 24px;
	}
}

@media screen and (min-width: 992px) {
	.page_breadcrumbs .display_table_md{
		width: auto;
		min-width: 0;
	}

	.page_breadcrumbs h2.display_table_cell_md{
		padding-right: 20px;
	}

	.page_breadcrumbs .breadcrumb.display_table_cell_md{
		border-left: 1px solid rgba(255, 255, 255, 0.2);
		padding-left: 25px;
	}
}

.page_breadcrumbs .bottom_breadcrumbs{
	text-align: center;
	float: right;
	padding: 0 20px;
	margin: 0 0 -26px 0;
	background-color: rgba(25, 31, 40, 0.035);
}

.page_footer{
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	position: relative;
	z-index: 5;
}

.page_footer .container{
	position: relative;
	z-index: 4;
}

.page_footer .left_part{
	background-color: #191f28;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	width: 50%;
	height: 150px;
	position: absolute;
	top: -30px;
	left: 0;
	-webkit-transform: skewY(3deg);
	transform: skewY(3deg);
}

.page_footer .right_part{
	background-color: #191f28;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	width: 50%;
	height: 150px;
	position: absolute;
	top: -30px;
	right: 0;
	-webkit-transform: skewY(-3deg);
	transform: skewY(-3deg);
}

.page_footer.footer-2{
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 60vw / 5vw;
	-moz-border-radius: 60vw / 5vw;
	-webkit-border-radius: 60vw / 5vw;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 4;
	width: auto;
	background-position: 50% -50px;
}

@media (min-width: 767px) {
	.page_footer.footer-2{
		margin-left: -100px;
		margin-right: -100px;
	}
}

.page_portfolio{
	overflow: hidden;
}

.page_content + .page_portfolio{
	margin-top: -270px;
}

@media (max-width: 1200px) {
	.page_portfolio{
		overflow: hidden;
	}

	.page_content + .page_portfolio{
		margin-top: -150px;
	}
}

@media (max-width: 479px) {
	.page_portfolio{
		overflow: hidden;
	}

	.page_content + .page_portfolio{
		margin-top: -100px;
	}
}

.section_subscribe .form-control{
	width: 350px;
	max-width: 100%;
	display: inline-block;
}

/* Hiding category and archives titles */
.taxonomy-name-title{
	display: none;
}

.page_breadcrumbs.parallax{
	background-size: auto auto;
	background-position: 50% 50% !important;
}

.page_testimonials{
	overflow: hidden;
}

.page_copyright{
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	font-weight: 600;
	letter-spacing: 0.2em;
	font-size: 12px;
	text-transform: uppercase;
	color: #7f7f7f;
}

.page_copyright.cs{
	color: #ffffff;
}

.page_copyright.ls{
	color: #1f2732;
}

.page_copyright.ds{
	background-color: #1f2732;
	color: #a4a9b9;
}

.page_copyright.ds.ms{
	background-color: #191f28;
	color: #a4a9b9;
}

.page_copyright p{
	margin: 0;
	line-height: 30px;
}

.page_copyright i{
	padding: 0 10px;
}

.page_copyright.table_section .bottom_logo img{
	margin-bottom: 0;
}

.page_copyright.copyright_3 .row{
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
}

.page_copyright_4{
	position: relative;
}

.page_copyright_4 p{
	color: #ffffff;
}

.page_copyright_4:before{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.2);
}

.ds.ms + .page_footer.ds.ms{
	background-color: #151a22;
}

@media (min-width: 768px) and (max-width: 992px) {
	.page_footer .row{
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
	}

	.page_footer .row > [class*="col-"]{
		-webkit-box-flex: 1;
		flex: 1 1 auto;
	}
}

#map,
.page_map{
	height: 500px;
}

#map img,
.page_map img{
	max-width: inherit;
}

#map p,
.page_map p{
	line-height: 1.3;
	margin-bottom: 0;
}

#map .map_marker_description,
.page_map .map_marker_description{
	padding: 10px 5px 5px;
}

#map .map_marker_icon,
.page_map .map_marker_icon{
	display: none;
}

/* slider inside content */
section .intro_section .slide_description_wrapper{
	padding-left: 60px;
	padding-right: 60px;
	right: 30px;
}

@media (max-width: 600px) {
	section .intro_section .slide_description_wrapper{
		padding-left: 5px;
		padding-right: 5px;
	}
}

.muted_background .pagination{
	margin: 40px 40px 20px;
}

.ds .wordpress-helpdesk-faq-list li a{
	color: #ffffff;
}

.ds .wordpress-helpdesk-faq-list li a:hover{
	color: black;
}

/** intro_section styles */
/* flexslider */
.intro_section.page_mainslider{
	position: relative;
	overflow: visible !important;
	z-index: 1;
	clear: both; /* font weight */
}

.intro_section.page_mainslider > img{
	width: 100%;
}

.intro_section.page_mainslider.ds{
	color: #ffffff;
}

.intro_section.page_mainslider .slides > li{
	position: relative;
	overflow: hidden;
}

.intro_section.page_mainslider .slides > li:before{
	content: '';
	background-image: url("../img/pattern.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 432px;
	height: 432px;
	z-index: 4;
	top: 50%;
	left: 50%;
	margin-top: -216px;
	margin-left: -216px;
}

.intro_section.page_mainslider .slides > li .container{
	z-index: 5;
}

.intro_section.page_mainslider .flexslider-overlay{
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(31, 39, 50, 0.7);
	z-index: 3;
}

.intro_section.page_mainslider .flexslider-bottom{
	position: relative;
}

.intro_section.page_mainslider .flexslider-bottom .mouse-button{
	content: '';
	background-image: url("../img/mouse_icon.png");
	background-repeat: no-repeat;
	position: absolute;
	display: block;
	width: 40px;
	height: 60px;
	z-index: 4;
	bottom: 30px;
	left: 50%;
	margin-left: -20px;
}

@media (max-width: 992px) {
	.intro_section.page_mainslider .flexslider-bottom .mouse-button{
		display: none;
	}
}

@media (min-width: 1400px) {
	.intro_section.page_mainslider .flexslider-bottom .mouse-button{
		bottom: 15px;
	}
}

.intro_section.page_mainslider .flexslider-bottom:before{
	content: '';
	background-color: #ffffff;
	width: 50%;
	height: 60px;
	position: absolute;
	bottom: -30px;
	left: 0;
	-webkit-transform: skewY(3deg);
	transform: skewY(3deg);
	z-index: 4;
}

.intro_section.page_mainslider .flexslider-bottom:after{
	content: '';
	background-color: #ffffff;
	width: 50%;
	height: 60px;
	position: absolute;
	bottom: -30px;
	right: 0;
	-webkit-transform: skewY(-3deg);
	transform: skewY(-3deg);
	z-index: 4;
}

.intro_section.page_mainslider .flexslider .flex-control-nav{
	bottom: 40px;
	display: none;
}

.intro_section.page_mainslider .flexslider .flex-control-paging li a:after{
	bottom: -48px;
	height: 30px;
}

.intro_section.page_mainslider .flexslider .flex-control-paging li a{
	color: #ffffff;
}

.intro_section.page_mainslider .flexslider .flex-control-paging li a.flex-active,
.intro_section.page_mainslider .flexslider .flex-control-paging li a:hover{
	color: black;
}

.intro_section.page_mainslider .flexslider .flex-control-paging li a:after{
	background-color: #ffffff;
}

.intro_section.page_mainslider .flexslider:before,
.intro_section.page_mainslider .flexslider:after{
	content: '';
	display: block;
	clear: both;
}

.intro_section.page_mainslider .flexslider .intro-layer img{
	width: auto;
	display: inline-block;
}

.intro_section.page_mainslider .short_line{
	width: 50px;
	display: inline-block;
	height: 3px;
	margin-top: 25px;
	margin-bottom: 20px;
	background-color: black;
}

.intro_section.page_mainslider .container,
.intro_section.page_mainslider img + .container{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro_section.page_mainslider [class*="col-"]{
	position: static;
	min-height: 0;
}

.intro_section.page_mainslider .slide_description_wrapper{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	font-size: 0;
	z-index: 5;
	padding: 30px 0;
}

.intro_section.page_mainslider .slide_description_wrapper:before,
.intro_section.page_mainslider .slide_description{
	display: inline-block;
	vertical-align: middle;
}

.intro_section.page_mainslider .slide_description_wrapper:before{
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
}

.intro_section.page_mainslider .slide_description{
	padding: 15px;
}

@media (min-width: 992px) {
	.intro_section.page_mainslider .slide_description{
		padding: 30px 15px;
	}
}

@media (min-width: 1200px) {
	.intro_section.page_mainslider .slide_description{
		padding: 55px 15px;
	}
}

.intro_section.page_mainslider .slide_description.ls{
	background-color: rgba(255, 255, 255, 0.8);
}

.intro_section.page_mainslider .slide_description.ds{
	background-color: rgba(31, 39, 50, 0.8);
}

@media (min-width: 1400px) {
	.intro_section.page_mainslider .slide_description .short{
		max-width: 65%;
	}
}

.intro_section.page_mainslider .theme_button{
	margin-top: 40px;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.intro_section.page_mainslider .theme_button{
		margin-top: 70px;
	}
}

.intro_section.page_mainslider .theme_button.color1:hover,
.intro_section.page_mainslider .theme_button.color2:hover,
.intro_section.page_mainslider .theme_button.color3:hover,
.intro_section.page_mainslider .theme_button.color4:hover{
	color: #ffffff;
}

.intro_section.page_mainslider .theme_button.inverse.color1,
.intro_section.page_mainslider .theme_button.inverse.color2,
.intro_section.page_mainslider .theme_button.inverse.color3,
.intro_section.page_mainslider .theme_button.inverse.color4{
	color: #ffffff;
}

.intro_section.page_mainslider .intro-layer.button{
	display: inline-block;
}

@media (min-width: 768px) {
	.intro_section.page_mainslider .intro-layer.button + .intro-layer.button{
		margin-left: 30px;
	}
}

.intro_section.page_mainslider .spacing-text-large > *{
	letter-spacing: 2em;
	text-indent: 2em;
}

@media (max-width: 767px) {
	.intro_section.page_mainslider .spacing-text-large > *{
		letter-spacing: 1em;
		text-indent: 1em;
	}
}

@media (min-width: 1200px) {
	.intro_section.page_mainslider .extra-big h2{
		font-size: 100px;
		line-height: 1;
	}
}

@media (min-width: 1600px) {
	.intro_section.page_mainslider .extra-big h2{
		font-size: 200px;
		line-height: 1;
	}
}

@media (min-width: 1200px) {
	.intro_section.page_mainslider .extra-big h3{
		font-size: 60px;
		line-height: 1;
	}
}

@media (min-width: 1440px) {
	.intro_section.page_mainslider .big h2{
		font-size: 100px;
		line-height: 1;
	}
}

@media (min-width: 1440px) {
	.intro_section.page_mainslider .big h3{
		font-size: 54px;
		line-height: 1;
	}
}

.intro_section.page_mainslider .grey{
	color: #1f2732;
}

.intro_section.page_mainslider .light{
	color: #ffffff;
}

.intro_section.page_mainslider p.light{
	color: #ffffff;
}

.intro_section.page_mainslider .black{
	color: #1f2732;
}

.intro_section.page_mainslider .color-1{
	color: black;
}

.intro_section.page_mainslider .color-2{
	color: #a0ce4e;
}

.intro_section.page_mainslider .color-3{
	color: #00bea3;
}

.intro_section.page_mainslider .color-4{
	color: #f1894c;
}

.intro_section.page_mainslider .thin-weight{
	font-weight: 100;
}

.intro_section.page_mainslider .extra-light{
	font-weight: 200;
}

.intro_section.page_mainslider .light-weight{
	font-weight: 300;
}

.intro_section.page_mainslider .regular{
	font-weight: 400;
}

.intro_section.page_mainslider .medium{
	font-weight: 500;
}

.intro_section.page_mainslider .semibold{
	font-weight: 600;
}

.intro_section.page_mainslider .bold{
	font-weight: bold;
}

.intro_section.page_mainslider .black-weight{
	font-weight: 900;
}

.intro_section.page_mainslider h2,
.intro_section.page_mainslider h3,
.intro_section.page_mainslider h4,
.intro_section.page_mainslider h5,
.intro_section.page_mainslider h6{
	margin-bottom: 0;
}

.intro_section.page_mainslider p{
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 0.7em;
}

.intro_section.page_mainslider .slider-bottomline{
	border-top: 5px solid black;
	border-bottom: 5px solid #a0ce4e;
}

@media (max-width: 992px) {
	.intro_section.page_mainslider{
		text-align: center;
	}

	.intro_section.page_mainslider .nav-container{
		padding: 0;
	}

	.intro_section.page_mainslider .flex-control-nav,
	.intro_section.page_mainslider .flex-custom-nav{
		bottom: 50px;
		text-align: center;
	}

	.intro_section.page_mainslider .flexslider .container{
		position: static;
		width: auto;
		max-width: 100%;
	}

	.intro_section.page_mainslider .slide_description_wrapper:before{
		display: none;
	}

	.intro_section.page_mainslider .slide_description{
		padding: 30px 0 100px;
		display: block;
		margin: auto;
	}

	.intro_section.page_mainslider .slide_description_wrapper{
		padding: 0;
		position: relative;
	}

	.intro_section.page_mainslider .flex-direction-nav .flex-prev{
		top: 24%;
	}

	.intro_section.page_mainslider .flex-direction-nav .flex-next{
		top: 24%;
	}
}

.slider-bottomline{
	display: none;
	position: relative;
}

@media (min-width: 992px) {
	.slider-bottomline{
		display: block;
		z-index: 99;
		margin-top: -220px;
		min-height: 220px;
	}
}

.slider-bottomline .block{
	padding: 60px 0;
}

.slider-bottomline .block .icon i{
	font-size: 36px;
	color: black;
}

.slider-bottomline .block .title{
	font-weight: bold;
	font-size: 16px;
	color: #ffffff;
	text-transform: uppercase;
	display: block;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 0;
}

.slider-bottomline .block .text{
	font-weight: 300;
	color: #ffffff;
	font-size: 14px;
}

/* Blog slider */
.blog-slider{
	margin-bottom: 0;
}

.blog-slider .scroll_button_wrap,
.blog-slider .slider-bottomline{
	display: none;
}

.blog-slider .page_mainslider{
	margin-top: 10px;
	margin-bottom: 70px;
}

.blog-slider .page_mainslider:after{
	display: none;
}

.blog-slider .slide_description_wrapper .slide_description{
	max-width: none;
}

.blog-slider img{
	opacity: 0.4;
}

.blog-slider .intro_section.page_mainslider .scroll_button_wrap{
	display: none;
}

.blog-slider .intro_section.page_mainslider .flexslider .flex-control-nav{
	bottom: 50px;
}

.blog-slider .intro_section.page_mainslider .flexslider .flex-control-paging li a:after{
	bottom: -57px;
	height: 40px;
}

.blog-slider .intro_section.page_mainslider .flexslider .flex-direction-nav{
	display: none;
}

@media (min-width: 992px) {
	.blog-slider .intro_section.page_mainslider .slides > li{
		max-height: 600px;
	}
}

.blog-slider .intro_section.page_mainslider .container{
	width: 100% !important;
}

.blog-slider .intro_section.page_mainslider .container .slide_description_wrapper{
	padding: 0 15px;
}

@media (min-width: 1199px) {
	.blog-slider .intro_section.page_mainslider .container .slide_description_wrapper{
		padding-left: 25px;
		padding-right: 25px;
	}
}

/* Main Menu styles */
.sf-menu a{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.2em;
}

/* Desktop menu - (min 992px) styles */
@media (min-width: 992px) {
	.mainmenu_wrapper{
		position: relative;
	}
	/* menu layout */
	.sf-menu,
	.sf-menu ul{
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.sf-menu li{
		position: relative;
	}

	.sf-menu li > ul{
		position: absolute;
		display: none;
		top: 100%;
		z-index: 99;
	}

	.sf-menu > li > ul.sub-menu{
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.sf-menu > li > ul.sub-menu .menu-item-has-children > a:after{
		font-family: "FontAwesome";
		content: '\f105';
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		color: black;
		font-size: 12px;
		padding-left: 15px;
	}

	.sf-menu li:hover > ul,
	.sf-menu li.sfHover > ul{
		display: block;
	}

	.sf-menu a{
		display: block;
		position: relative;
	}

	.sf-menu li > ul > li > ul{
		top: -15px;
		left: 100%;
	}

	.header_1 .sf-menu > li:last-child > a{
		margin-right: 0;
	}
	/* menu skin */
	.sf-menu a{
		padding: 10px 0 10px;
		text-decoration: none;
		zoom: 1; /* IE7 */
		-webkit-transition-property: color, background-color, border-color, padding-top, padding-bottom;
		transition-property: color, background-color, border-color, padding-top, padding-bottom;
	}

	.sf-menu li{
		white-space: nowrap; /* no need for Supersubs plugin */
		*white-space: normal; /* ...unless you support IE7 (let it wrap) */
		-webkit-transition: background .2s;
		transition: background .2s;
	}

	.sf-menu > li{
		display: inline-block;
		position: relative;
		margin: 0 -2px;
	}

	.sf-menu > li:first-child{
		margin-left: 0;
	}

	.sf-menu > li:last-child{
		margin-right: 0;
	}

	.sf-menu > li > a{
		padding-top: 48px;
		padding-bottom: 48px;
		padding-right: 0;
		padding-left: 0;
	}

	.header-4 .sf-menu > li > a{
		padding-top: 13px;
		padding-bottom: 13px;
	}

	.header-10 .sf-menu > li > a{
		padding-top: 23px;
		padding-bottom: 23px;
	}

	.sf-menu > li > a:after{
		font-family: FontAwesome;
		color: #ffffff;
		content: "\f111";
		font-size: 5px;
		position: relative;
		margin-left: 0;
		top: -3px;
		right: -10px;
	}

	.ls .sf-menu > li > a:after{
		color: #1f2732;
	}

	.affix .sf-menu > li > a{
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.header-4 .affix .sf-menu > li > a{
		padding-top: 23px;
		padding-bottom: 23px;
	}

	.sf-menu > li:last-child > a:after{
		display: none;
	}

	.sf-menu > li > a:hover,
	.sf-menu > li.current-menu-item > a{
		color: #ff497c;
		border-color: black;
	}

	.item_with_border .sf-menu > li.active > a{
		border-top: 5px solid black;
	}

	.bordered_items .sf-menu > li{
		padding: 0;
	}

	.bordered_items .sf-menu > li + li:before{
		content: '';
		width: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.2);
	}

	.ls .sf-menu > li > a{
		color: #1f2732;
	}

	.ls .sf-menu > li > a:hover{
		color: black;
	}

	.ls .sf-menu > li + li:before{
		background-color: #dedfe1;
	}

	.ds .sf-menu > li > a{
		color: #ffffff;
	}

	.ds .sf-menu > li > a:hover{
		color: black;
	}

	.cs .sf-menu > li > a{
		color: #ffffff;
	}

	.cs .sf-menu > li > a:hover,
	.cs .sf-menu > li.current-menu-item > a{
		color: #ff497c;
	}

	.sf-menu li:hover,
	.sf-menu li.sfHover{ /* only transition out, not in */
		-webkit-transition: none;
		transition: none;
	}
	/* sub-menu skin */
	.sf-menu .sub-menu{
		margin: 0 2px;
	}

	.sf-menu .sub-menu li{
		padding-right: 15px;
		padding-left: 15px;
	}

	.sf-menu .sub-menu li a{
		padding-right: 0;
		padding-left: 0;
	}

	.sf-menu .sub-menu li:last-child a{
		border-bottom: 0;
	}
	/* second and next levels */
	.sf-menu > li .mega-menu,
	.sf-menu li > ul{
		margin-top: 10px;
		text-align: center;
		list-style: none;
		padding: 30px 0;
		background-color: #ffffff;
		min-width: 20em; /* allow long menu items to determine submenu width */
		*width: 20em; /* no auto sub width for IE7, see white-space comment below */
		border-radius: 0;
	}

	.sf-menu > li .mega-menu > li > ul,
	.sf-menu li > ul > li > ul{
		background-color: #ffffff;
		border-radius: 0;
		z-index: 1;
	}

	.sf-menu ul a{
		color: #1f2732;
		padding-left: 30px;
		padding-right: 30px;
	}

	.sf-menu .mega-menu ul{
		box-shadow: none;
		padding: 0;
	}

	.sf-menu .mega-menu ul a{
		padding-left: 0;
		padding-right: 0;
	}

	.sf-menu ul a:hover,
	.sf-menu ul li.active > a{
		color: black;
	}
	/* third level */
	.sf-menu ul ul{
		margin-top: 0;
	}
}

/* common paddings */
@media (min-width: 992px) {
	.sf-menu > li > a{
		margin-left: 2px;
		margin-right: 2px;
	}

	.bordered_items .sf-menu > li > a{
		margin: 0;
		padding: 25px 40px;
	}
}

/* -md- paddings */
@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li > a{
		margin-left: 15px;
		margin-right: 15px;
	}

	.sf-menu > li > a:after{
		margin-left: 26px;
	}

	.bordered_items .sf-menu > li > a{
		margin-right: 24px;
		margin-left: 24px;
	}
}

/* -lg- paddings */
@media (min-width: 1200px) {
	.sf-menu > li > a{
		margin-left: 10px;
		margin-right: 10px;
	}

	.boxed .col-md-6 .sf-menu > li > a{
		margin-left: 14px;
		margin-right: 14px;
	}
}

@media (min-width: 1400px) {
	.sf-menu > li > a{
		margin-left: 15px;
		margin-right: 15px;
	}

	.sf-menu > li > a:after{
		right: -15px;
	}

	.header-4 .sf-menu > li > a{
		margin-left: 35px;
		margin-right: 35px;
	}

	.header-4 .sf-menu > li > a:after{
		right: -35px;
	}
}

@media (min-width: 1600px) {
	.sf-menu > li > a{
		margin-left: 35px;
		margin-right: 35px;
	}

	.sf-menu > li > a:after{
		right: -35px;
	}

	.header-4 .sf-menu > li > a{
		margin-left: 57px;
		margin-right: 57px;
	}

	.header-4 .sf-menu > li > a:after{
		right: -57px;
	}

	.header-4 .sf-menu > li:first-child > a{
		margin-left: 0;
	}

	.header-4 .sf-menu > li:last-child > a{
		margin-right: 0;
	}
}

.sf-menu i[class]{
	margin-right: 7px;
	font-size: 14px;
}

/* hidden menu items */
@media (min-width: 992px) {
	.sf-menu .sf-md-hidden{
		display: none !important;
	}
}

@media (max-width: 991px) {
	.sf-more-li{
		display: none;
	}
}

/* logo in center of menu */
.sf-menu > .sf-logo-li{
	visibility: hidden;
}

/* Mobile menu toggler */
@media (max-width: 991px) {
	.toggle_menu{
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
		height: 60px;
		cursor: pointer;
		z-index: 1001;
		visibility: visible;
	}

	.affix.affix_toggler_right .toggle_menu{
		right: 0;
		left: auto;
	}

	.toggler_right .toggle_menu{
		right: 0;
		left: auto;
	}

	.toggler_left .toggle_menu{
		right: auto;
		left: 0;
		margin-left: 0;
	}
}

@media (max-width: 991px) and (max-width: 767px) {
	.toggler_xs_right .toggle_menu{
		right: 0;
		left: auto;
	}
}

@media (max-width: 991px) and (max-width: 499px) {
	.toggler_xxs_right .toggle_menu{
		right: 0;
		left: auto;
	}
}

@media (max-width: 991px) {
	.ls .toggle_menu span,
	.ls .toggle_menu span:before,
	.ls .toggle_menu span:after,
	.header_white .toggle_menu span,
	.header_white .toggle_menu span:before,
	.header_white .toggle_menu span:after{
		background-color: #1f2732;
	}

	.intro_section + .page_header_wrapper .toggle_menu span,
	.intro_section + .page_header_wrapper .toggle_menu span:before,
	.intro_section + .page_header_wrapper .toggle_menu span:after{
		background-color: #ffffff;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu span,
	.intro_section + .page_header_wrapper .affix .toggle_menu span:before,
	.intro_section + .page_header_wrapper .affix .toggle_menu span:after{
		background-color: #1f2732;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span,
	.intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:before,
	.intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:after{
		background-color: #ffffff;
	}

	.page_toplogo .toggle_menu{
		position: fixed;
		top: 0;
		left: auto;
		right: 0;
		margin: 0;
	}

	.toggle_menu:hover span:before{
		top: -9px;
	}

	.toggle_menu:hover span:after{
		top: 9px;
	}

	.toggle_menu span,
	.toggle_menu span:before,
	.toggle_menu span:after{
		display: block;
		width: 30px;
		position: absolute;
		background-color: #ffffff;
		content: '';
		left: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		height: 2px;
	}

	.toggle_menu:before,
	.toggle_menu span{
		left: 15px;
		top: 50%;
	}

	.toggle_menu span:before{
		opacity: 1;
		top: -8px;
	}

	.toggle_menu span:after{
		opacity: 1;
		top: 8px;
	}

	.toggle_menu.mobile-active{
		background-color: #1f2732;
		border: none;
		left: auto;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		z-index: 3000;
	}

	.toggle_menu.mobile-active:before{
		background-color: #ffffff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.toggle_menu.mobile-active span{
		background-color: #ffffff;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.toggle_menu.mobile-active span:before,
	.toggle_menu.mobile-active span:after{
		opacity: 0;
	}
}

/*
**main menu mobile styles
*/
@media (max-width: 991px) {
	.mainmenu_wrapper{ /*position: relative;*/
		position: fixed;
		visibility: hidden;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparent;
		opacity: 0;
		-webkit-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}

	.mobile-active .mainmenu_wrapper{
		visibility: visible;
		opacity: 1;
		background-color: #111;
	}

	.toggle_menu{
		display: block;
		cursor: pointer;
	}

	.toggle_menu.mobile-active{
		background-color: #111;
		border: none;
		left: 0;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		width: auto;
		height: 50px;
		z-index: 3000;
	}

	.toggle_menu.mobile-active:after{
		position: absolute;
		top: 14px;
		right: 5px;
		font-family: 'rt-icons-2';
		content: "\e117";
		font-size: 40px;
		color: #ffffff;
	}

	.toggle_menu.mobile-active span{
		display: none;
	}

	.nav-justified > li > a{
		text-align: left;
	}

	.sf-menu{
		position: fixed;
		overflow: auto;
		background-color: #1f2732;
		padding: 60px 0 20px 10px;
		top: 0;
		left: 0;
		bottom: 0;
		right: 100%;
		font-size: 1.1em;
		text-transform: uppercase;
		font-weight: 400;
		word-wrap: break-word;
		text-align: left;
		-webkit-transition: all 0.25s ease-out 0s;
		transition: all 0.25s ease-out 0s;
		opacity: 0;
	}

	.mobile-active .sf-menu{
		opacity: 1;
		right: 15px;
	}

	.sf-menu > li > a,
	.sf-menu a{
		color: #ffffff;
		z-index: 2;
	}

	.sf-menu ul{
		list-style: none;
		padding-left: 10px;
		opacity: 1 !important;
		margin-top: 0 !important;
	}

	.sf-menu ul li a{
		display: block;
		padding: 10px;
		font-weight: 300;
		font-size: 16px;
		text-transform: none;
	}

	.sf-menu ul li a:before{
		content: '-';
		padding-right: 5px;
	}

	.sf-menu ul ul li a:before{
		content: '--';
		padding-right: 5px;
	}
	/*** arrows (for all except IE7) **/
	.sf-arrows .sf-with-ul{
		padding-right: 3em;
		*padding-right: 1em; /* no CSS arrows for IE7 (lack pseudo-elements) */
	}

	.sf-arrows .sfHover > .sf-with-ul{
		margin-right: 3em;
	}
	/* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul + .sf-menu-item-mobile-toggler{
		position: absolute;
		right: 5px;
		top: 7px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		background-color: #111;
		text-align: center;
		font-size: 20px;
		z-index: 1;
	}

	.sf-arrows .sf-with-ul + .sf-menu-item-mobile-toggler:after{
		font-family: 'FontAwesome';
		content: '\f105';
		position: relative;
		top: -1px;
	}
	/*top menu level*/
	.sf-arrows .sfHover > a,
	.sf-arrows .sfHover > a:hover{
		color: #ffffff;
	}

	.sfHover > .sf-with-ul + .sf-menu-item-mobile-toggler:after{
		content: '\f107';
	}
}

/* Mega Menu */
@media screen and (min-width: 992px) {
	.mainmenu_wrapper ul .mega-menu ul{
		min-width: auto;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu{
		position: relative;
	}

	.mainmenu_wrapper .mega-menu{
		display: none;
		position: absolute;
		width: 940px;
		top: 100%;
		z-index: 10;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu{
		display: block;
	}
	/* row */
	.mainmenu_wrapper .mega-menu-row{
		position: static;
		display: table;
		table-layout: fixed;
	}

	.mainmenu_wrapper .mega-menu-row ul{
		position: static;
		display: block;
	}
	/* column */
	.mainmenu_wrapper .mega-menu-col{
		display: table-cell;
		width: 2000px;
		padding: 0 20px;
		border-left: 1px solid #dedfe1; /* overrides from settings / styling / mega-menu */
	}

	.mainmenu_wrapper .mega-menu-col > a{
		text-transform: uppercase;
		font-weight: 700;
	}

	.mainmenu_wrapper .mega-menu-col:first-child{
		border-left: none;
	}

	.mainmenu_wrapper ul ul .mega-menu{
		top: -20px;
		margin-left: 1px;
		display: block;
		width: auto;
	}

	.mainmenu_wrapper ul ul .mega-menu li{
		display: block;
		width: auto;
		border: none;
	}
}

@media screen and (min-width: 1200px) {
	.mainmenu_wrapper .mega-menu{
		width: 1140px;
	}

	.mainmenu_wrapper ul .mega-menu ul{
		min-width: 12em;
	}
}

@media screen and (max-width: 991px) {
	.mainmenu_wrapper .mega-menu{
		position: static;
	}
}

/*
**subpages
*/
.edit-link{
	text-align: right;
	display: block;
}

.entry-content:before,
.entry-content:after{
	clear: both;
	content: "";
	display: block;
	height: 0;
	width: 0;
}

/*about*/
.about-content{
	padding: 10px 10px 20px;
}

/* page meta blog */
.page-meta{
	padding: 9px 40px 30px;
}

.page-meta h5{
	font-size: 14px;
	font-weight: 300;
}

.page-meta i{
	line-height: 0.6;
	padding-right: 8px;
}

.page-meta .media{
	position: relative;
	padding: 28px 0 0;
	margin-bottom: 20px;
}

.page-meta .media + .media:before{
	content: '';
	position: absolute;
	height: 1px;
	width: 100%;
	top: 0;
	background-color: rgba(150, 150, 150, 0.1);
}

.page-meta .media-left{
	padding-right: 8px;
}

/*blog*/
article iframe{
	margin-bottom: -8px;
}

h1.entry-title{
	font-size: 42px;
	margin: 0 0 5px;
}

@media (max-width: 991px) {
	h1.entry-title{
		font-size: 32px;
	}
}

article.post + article.post,
.search article + article,
.archive article + article{
	margin-top: 60px;
}

/* mosaic */
.mosaic-post p{
	margin-bottom: 0;
}

.mosaic-post .post-social-links{
	margin: -1px;
	padding: 18px 40px 17px;
}

h3.entry-title{
	font-size: 28px;
	margin: 0 0 14px;
}

h3.entry-title + .date{
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	padding: 4px 14px;
	margin-bottom: 22px;
}

h4.entry-title{
	margin: 0 0 20px;
}

h2.search-title{
	margin: 0 0 30px;
	font-size: 40px;
}

.item-meta + .entry-title{
	margin-bottom: 20px;
}

.single .item-meta + .entry-title{
	font-size: 20px;
	margin-bottom: 25px;
}

.entry-tags{
	margin-top: 0;
}

.entry-tags .theme_button{
	margin-right: 6px;
}

.entry-tags span + span{
	margin-left: 14px;
}

.entry-tags i{
	font-size: 14px;
	margin-right: 5px;
}

.entry-meta:before,
.entry-meta:after,
.post-adds:before,
.post-adds:after{
	display: block;
	clear: both;
	content: '';
	width: 0;
	height: 0;
}

.entry-thumbnail{
	text-align: center;
	position: relative;
}

.entry-thumbnail[class*='col-']{
	margin-bottom: 0;
}

.entry-thumbnail .entry-thumbnail-carousel .owl-dots{
	position: absolute;
	bottom: 45px;
	text-align: center;
	left: 0;
	right: 0;
}

@media (max-width: 479px) {
	.entry-thumbnail .entry-thumbnail-carousel .owl-dots{
		bottom: 20px;
	}
}

/** Theme share buttons */
.entry-blog-share{
	margin-right: 0 !important;
}

@media (min-width: 1200px) {
	.entry-blog-share{
		position: absolute;
		right: 60px;
		top: 50%;
		margin-top: -7px !important;
		line-height: 1 !important;
	}
}

@media (min-width: 768px) {
	.entry-blog-share{
		position: absolute;
		right: 30px;
		top: 33px;
		margin-top: 0 !important;
		line-height: 1 !important;
	}
}

.post-adds{
	border: 0;
	border-radius: 0;
	margin: 0;
	height: auto;
	z-index: 1;
}

.post-adds .dropdown-menu{
	margin: 0;
	padding: 0;
	border: none;
	background-color: transparent;
}

.post-adds .dropdown-menu .share_buttons{
	width: 40px;
	min-width: 40px;
	overflow: visible;
	position: fixed;
	height: 280px;
	margin-top: -140px;
	top: 50%;
	right: 0;
	left: auto;
}

.post-adds .dropdown-menu .share_buttons a{
	margin: 0 !important;
	display: block;
	width: 40px;
	height: 40px;
}

.post-adds .dropdown.open .dropdown-menu{
	background-color: transparent;
	z-index: 2;
}

.post-adds .dropdown.open .dropdown-menu .share_buttons{
	animation-name: slideInRight;
	-webkit-animation-name: slideInRight;
	animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;
	animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
}

/* additional meta in corner of thumbnail */
.entry-meta-corner{
	display: none;
	position: absolute;
	z-index: 2;
	text-align: center;
	color: #ffffff;
	background-color: black;
}

.entry-meta-corner a{
	color: #ffffff;
}

.entry-meta-corner a:hover{
	color: black;
}

.entry-meta-corner .date{
	color: #1f2732;
	display: block;
	min-width: 60px;
	padding: 0 3px;
	text-transform: uppercase;
}

.entry-meta-corner .date strong{
	color: #ffffff;
	display: block;
	font-size: 42px;
	font-weight: 200;
	padding: 12px 0 0;
}

.entry-meta-corner .comments-link{
	line-height: 1;
	display: block;
	padding: 2px 5px;
	background-color: #1f2732;
}

.entry-meta-corner .comments-link i{
	position: relative;
	top: 1px;
}

.categories-links{
	font-weight: 500;
	letter-spacing: 0.2em;
	font-size: 12px;
	text-transform: uppercase;
	color: #ffffff;
}

.categories-links a{
	margin-right: 6px;
	margin-bottom: 6px;
	color: #ffffff;
}

.categories-links a:hover,
.categories-links a:focus{
	color: #1f2732;
}

.categories-links a:last-child{
	margin-right: 0;
}

.categories-links.small_height a{
	padding: 5px 20px 5px;
}

/* blog post entry meta */
.entry-meta{
	position: relative;
	text-align: left;
	line-height: 1;
}

.entry-meta > *{
	display: inline-block;
	margin-right: 25px;
	margin-top: 0;
	line-height: 1.6;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 600;
}

@media (max-width: 479px) {
	.entry-meta > *{
		margin-right: 15px;
	}
}

.entry-meta.with_padding{
	padding: 10px 20px;
}

@media (min-width: 768px) {
	.entry-meta.with_padding{
		padding: 15px 60px;
	}
}

.entry-meta .entry-avatar{
	margin-right: 10px;
}

.entry-meta .entry-avatar img{
	width: 50px;
	height: 50px;
	border: 3px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
}

.entry-meta .tag-links > *{
	margin-right: 10px;
}

.entry-meta .share_button a,
.entry-meta .entry-author a,
.entry-meta .entry-date a{
	color: #ffffff;
}

.entry-meta .share_button a:hover,
.entry-meta .entry-author a:hover,
.entry-meta .entry-date a:hover{
	color: black;
}

.entry-meta .entry-date:before,
.entry-meta .tag-links:first-child:before{
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "";
	color: black;
	margin-right: 5px;
	font-weight: 400;
}

.entry-meta .entry-date:before{
	content: "\f073";
}

.entry-meta .tag-links:first-child:before{
	content: "\f02b";
}

.entry-meta .blog-adds{
	text-align: right;
}

.entry-meta .blog-adds > *{
	margin-right: 25px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	position: relative;
	color: #ffffff;
}

@media (max-width: 479px) {
	.entry-meta .blog-adds > *{
		margin-right: 15px;
	}
}

.entry-meta .blog-adds > *:last-child{
	margin-right: 0;
}

.entry-meta .blog-adds > *:before{
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "";
	margin-right: 5px;
	font-weight: 400;
	color: black;
}

.entry-meta .blog-adds .like-count:before{
	color: black;
	content: "\f004";
}

.entry-meta .blog-adds .views-count:before{
	content: "\f06e";
}

.entry-meta .blog-adds .comment-count:before{
	content: "\f086";
}

.entry-meta .blog-adds .item-views-word,
.entry-meta .blog-adds .item-likes-word{
	display: none;
}

/* highlight first letter in blog post */
.entry-content p.big-first-letter:first-letter{
	font-size: 60px;
	line-height: 60px;
	font-weight: 700;
	color: black;
	float: left;
	margin-right: 10px;
}

.entry-excerpt{
	font-size: 20px;
	line-height: 30px;
	margin: 8px 0 30px;
}

.entry-excerpt p{
	margin-bottom: 16px;
}

.blog .sticky,
.archive .sticky,
.search .sticky,
.single-post .sticky{
	border: 1px solid black;
}

.blog .sticky .featured-post,
.archive .sticky .featured-post,
.search .sticky .featured-post,
.single-post .sticky .featured-post{
	position: absolute;
	top: 25px;
	left: 50px;
}

.blog .readmore-hidden .more-link,
.archive .readmore-hidden .more-link,
.search .readmore-hidden .more-link,
.single-post .readmore-hidden .more-link{
	display: none;
}

.blog .owl-carousel.owl-loaded,
.archive .owl-carousel.owl-loaded,
.search .owl-carousel.owl-loaded,
.single-post .owl-carousel.owl-loaded{
	margin-bottom: 0;
}

.blog .entry-content ul,
.blog .entry-content ol,
.archive .entry-content ul,
.archive .entry-content ol,
.search .entry-content ul,
.search .entry-content ol,
.single-post .entry-content ul,
.single-post .entry-content ol{
	margin-bottom: 20px;
}

.blog .entry-content ul ul,
.blog .entry-content ul ol,
.blog .entry-content ol ul,
.blog .entry-content ol ol,
.archive .entry-content ul ul,
.archive .entry-content ul ol,
.archive .entry-content ol ul,
.archive .entry-content ol ol,
.search .entry-content ul ul,
.search .entry-content ul ol,
.search .entry-content ol ul,
.search .entry-content ol ol,
.single-post .entry-content ul ul,
.single-post .entry-content ul ol,
.single-post .entry-content ol ul,
.single-post .entry-content ol ol{
	margin-bottom: 0;
}

a.more-link{
	font-weight: 400;
	text-transform: uppercase;
	color: black;
	font-size: 14px;
	margin-left: 0;
	display: inline;
}

a.more-link:after{
	font-family: FontAwesome;
	content: '\f149';
	font-size: 12px;
	margin-left: 5px;
}

/* Blog list */
.blog,
.archive,
.search{ /* format small image */ /* format quote */ /* format status */ /* blog masonry layout */
}

.blog .sticky,
.archive .sticky,
.search .sticky{
	border: 1px solid black;
}

.blog .sticky .item-content,
.archive .sticky .item-content,
.search .sticky .item-content{
	position: relative;
}

.blog .sticky .item-content::before,
.archive .sticky .item-content::before,
.search .sticky .item-content::before{
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	border-left: 3.5em solid black;
	border-bottom: 3.5em solid transparent;
}

.blog .sticky .item-content::after,
.archive .sticky .item-content::after,
.search .sticky .item-content::after{
	position: absolute;
	top: 4px;
	left: 8px;
	color: #ffffff;
	font-family: FontAwesome;
	content: "\f08d";
	font-size: 1.5em;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.blog .media-links:before,
.archive .media-links:before,
.search .media-links:before{
	background-color: rgba(255, 255, 255, 0.5);
}

.blog .gallery-item .media-links:before,
.archive .gallery-item .media-links:before,
.search .gallery-item .media-links:before{
	background-color: rgba(255, 255, 255, 0.8);
}

.blog .entry-title,
.archive .entry-title,
.search .entry-title{
	font-size: 36px;
	text-transform: none;
	margin-top: 10px;
	margin-bottom: 23px;
	line-height: 1.1;
	width: 100%;
}

.blog .entry-content p:last-child,
.archive .entry-content p:last-child,
.search .entry-content p:last-child{
	margin-bottom: 0;
}

.blog .entry-author .featured-post,
.archive .entry-author .featured-post,
.search .entry-author .featured-post{
	display: none;
}

.blog blockquote,
.archive blockquote,
.search blockquote{
	width: 100%;
	max-width: 600px;
}

.blog blockquote:after,
.blog blockquote:before,
.archive blockquote:after,
.archive blockquote:before,
.search blockquote:after,
.search blockquote:before{
	display: none;
}

.blog .format-small-image .item-media-wrap > .item-media,
.archive .format-small-image .item-media-wrap > .item-media,
.search .format-small-image .item-media-wrap > .item-media{
	display: block;
}

.blog .format-quote,
.blog .format-status,
.archive .format-quote,
.archive .format-status,
.search .format-quote,
.search .format-status{ /* background teaser */
	background-color: #191f28;
	background-position: 50% 50%;
}

.blog .format-quote.darkgrey_bg .bg_overlay,
.blog .format-status.darkgrey_bg .bg_overlay,
.archive .format-quote.darkgrey_bg .bg_overlay,
.archive .format-status.darkgrey_bg .bg_overlay,
.search .format-quote.darkgrey_bg .bg_overlay,
.search .format-status.darkgrey_bg .bg_overlay{
	background-color: rgba(25, 31, 40, 0.9);
}

.blog .format-quote.bg_teaser,
.blog .format-status.bg_teaser,
.archive .format-quote.bg_teaser,
.archive .format-status.bg_teaser,
.search .format-quote.bg_teaser,
.search .format-status.bg_teaser{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	color: #ffffff;
	overflow: hidden;
}

.blog .format-quote.bg_teaser .bg_overlay,
.blog .format-status.bg_teaser .bg_overlay,
.archive .format-quote.bg_teaser .bg_overlay,
.archive .format-status.bg_teaser .bg_overlay,
.search .format-quote.bg_teaser .bg_overlay,
.search .format-status.bg_teaser .bg_overlay{
	position: absolute;
	width: 100%;
	height: 1000px;
}

.blog .format-quote.bg_teaser > img,
.blog .format-status.bg_teaser > img,
.archive .format-quote.bg_teaser > img,
.archive .format-status.bg_teaser > img,
.search .format-quote.bg_teaser > img,
.search .format-status.bg_teaser > img{
	display: none;
	visibility: hidden;
}

.blog .format-quote .entry-header p,
.blog .format-status .entry-header p,
.archive .format-quote .entry-header p,
.archive .format-status .entry-header p,
.search .format-quote .entry-header p,
.search .format-status .entry-header p{
	line-height: 1;
	margin-bottom: 0;
}

.blog .format-quote .entry-title,
.blog .format-status .entry-title,
.archive .format-quote .entry-title,
.archive .format-status .entry-title,
.search .format-quote .entry-title,
.search .format-status .entry-title{
	color: #ffffff;
	margin-top: 25px !important;
	margin-bottom: 23px !important;
	text-transform: none;
}

.blog .format-quote .entry-avatar,
.blog .format-status .entry-avatar,
.archive .format-quote .entry-avatar,
.archive .format-status .entry-avatar,
.search .format-quote .entry-avatar,
.search .format-status .entry-avatar{
	margin-top: 65px;
	margin-bottom: 30px;
}

.blog .format-quote .entry-avatar img,
.blog .format-status .entry-avatar img,
.archive .format-quote .entry-avatar img,
.archive .format-status .entry-avatar img,
.search .format-quote .entry-avatar img,
.search .format-status .entry-avatar img{
	margin: 0;
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	background-color: transparent;
}

.blog .format-quote .entry-content,
.blog .format-status .entry-content,
.archive .format-quote .entry-content,
.archive .format-status .entry-content,
.search .format-quote .entry-content,
.search .format-status .entry-content{
	margin-bottom: 22px;
	color: #7f7f7f;
}

.blog .format-quote .entry-meta,
.blog .format-status .entry-meta,
.archive .format-quote .entry-meta,
.archive .format-status .entry-meta,
.search .format-quote .entry-meta,
.search .format-status .entry-meta{
	margin: 7px 0 60px;
	line-height: 1;
	text-align: center;
}

.blog .format-quote .entry-meta .entry-date:before,
.blog .format-quote .entry-meta .format-name:before,
.blog .format-status .entry-meta .entry-date:before,
.blog .format-status .entry-meta .format-name:before,
.archive .format-quote .entry-meta .entry-date:before,
.archive .format-quote .entry-meta .format-name:before,
.archive .format-status .entry-meta .entry-date:before,
.archive .format-status .entry-meta .format-name:before,
.search .format-quote .entry-meta .entry-date:before,
.search .format-quote .entry-meta .format-name:before,
.search .format-status .entry-meta .entry-date:before,
.search .format-status .entry-meta .format-name:before{
	font-family: FontAwesome;
	font-size: 14px;
	content: "\f073";
	color: black;
	margin-right: 5px;
	font-weight: 400;
}

.blog .format-quote .entry-meta .format-name:before,
.blog .format-status .entry-meta .format-name:before,
.archive .format-quote .entry-meta .format-name:before,
.archive .format-status .entry-meta .format-name:before,
.search .format-quote .entry-meta .format-name:before,
.search .format-status .entry-meta .format-name:before{
	content: "\f02c";
}

.blog .format-quote .entry-meta > *,
.blog .format-status .entry-meta > *,
.archive .format-quote .entry-meta > *,
.archive .format-status .entry-meta > *,
.search .format-quote .entry-meta > *,
.search .format-status .entry-meta > *{
	margin-top: 0;
}

.blog .format-quote .entry-meta div:last-child,
.blog .format-status .entry-meta div:last-child,
.archive .format-quote .entry-meta div:last-child,
.archive .format-status .entry-meta div:last-child,
.search .format-quote .entry-meta div:last-child,
.search .format-status .entry-meta div:last-child{
	margin-right: 0;
}

.blog .format-quote .blog-adds,
.blog .format-status .blog-adds,
.archive .format-quote .blog-adds,
.archive .format-status .blog-adds,
.search .format-quote .blog-adds,
.search .format-status .blog-adds{
	text-align: center;
	margin-bottom: 12px;
}

.blog .format-quote .blog-adds > *,
.blog .format-status .blog-adds > *,
.archive .format-quote .blog-adds > *,
.archive .format-status .blog-adds > *,
.search .format-quote .blog-adds > *,
.search .format-status .blog-adds > *{
	color: #a4a9b9;
}

.blog .format-quote .entry-title,
.blog .format-quote .bg_overlay,
.archive .format-quote .entry-title,
.archive .format-quote .bg_overlay,
.search .format-quote .entry-title,
.search .format-quote .bg_overlay{
	display: none;
}

.blog .format-quote .entry-avatar,
.archive .format-quote .entry-avatar,
.search .format-quote .entry-avatar{
	margin-top: 20px;
	margin-bottom: 0;
}

.blog .format-quote .entry-avatar img,
.archive .format-quote .entry-avatar img,
.search .format-quote .entry-avatar img{
	margin: 0;
	border: 10px solid rgba(255, 255, 255, 0.1);
	border-radius: 50%;
	background-color: transparent;
}

.blog .format-quote .entry-content blockquote,
.archive .format-quote .entry-content blockquote,
.search .format-quote .entry-content blockquote{
	text-align: center;
	border: 0;
	font-size: 16px;
	font-weight: 300;
	color: #7f7f7f;
	width: 100%;
	padding-left: 0;
	margin-left: auto;
	margin-right: auto;
}

.blog .format-quote .entry-content blockquote cite,
.archive .format-quote .entry-content blockquote cite,
.search .format-quote .entry-content blockquote cite{
	display: block;
	width: 100%;
	color: black;
	font-size: 12px;
	letter-spacing: 0.2em;
	font-weight: 500;
}

.blog .format-quote .entry-content blockquote cite strong,
.archive .format-quote .entry-content blockquote cite strong,
.search .format-quote .entry-content blockquote cite strong{
	display: block;
	width: 100%;
	color: #ffffff;
	font-size: 30px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 25px;
	text-transform: none;
}

.blog .format-quote .entry-meta,
.archive .format-quote .entry-meta,
.search .format-quote .entry-meta{
	margin: 7px 0 15px;
}

.blog .masonry-layout,
.archive .masonry-layout,
.search .masonry-layout{ /* format chat */ /* format quote */ /* format status */
}

.blog .masonry-layout .entry-title,
.archive .masonry-layout .entry-title,
.search .masonry-layout .entry-title{
	font-size: 24px;
	text-transform: none;
	margin-top: 12px;
	margin-bottom: 15px;
	line-height: 30px;
	width: 100%;
}

.blog .masonry-layout .entry-date,
.archive .masonry-layout .entry-date,
.search .masonry-layout .entry-date{
	line-height: 1;
}

.blog .masonry-layout .format-chat strong,
.archive .masonry-layout .format-chat strong,
.search .masonry-layout .format-chat strong{
	width: 100%;
	display: block;
}

.blog .masonry-layout .format-chat p,
.archive .masonry-layout .format-chat p,
.search .masonry-layout .format-chat p{
	display: none;
}

.blog .masonry-layout .format-chat p:nth-child(1),
.blog .masonry-layout .format-chat p:nth-child(2),
.archive .masonry-layout .format-chat p:nth-child(1),
.archive .masonry-layout .format-chat p:nth-child(2),
.search .masonry-layout .format-chat p:nth-child(1),
.search .masonry-layout .format-chat p:nth-child(2){
	display: block;
}

.blog .masonry-layout .format-quote .item-content:after,
.archive .masonry-layout .format-quote .item-content:after,
.search .masonry-layout .format-quote .item-content:after{
	position: absolute;
	font-family: "ShonarBangla", sans-serif;
	font-weight: bold;
	content: '”';
	font-size: 100px;
	color: black;
	bottom: 0;
	right: 10px;
}

.blog .masonry-layout .format-quote .item-content .entry-avatar,
.archive .masonry-layout .format-quote .item-content .entry-avatar,
.search .masonry-layout .format-quote .item-content .entry-avatar{
	margin-top: 5px;
}

.blog .masonry-layout .format-quote .item-content .entry-avatar img,
.archive .masonry-layout .format-quote .item-content .entry-avatar img,
.search .masonry-layout .format-quote .item-content .entry-avatar img{
	width: 90px;
	border: 5px solid rgba(255, 255, 255, 0.1);
}

.blog .masonry-layout .format-quote .item-content .entry-content,
.archive .masonry-layout .format-quote .item-content .entry-content,
.search .masonry-layout .format-quote .item-content .entry-content{
	margin-bottom: 15px;
}

.blog .masonry-layout .format-quote blockquote,
.archive .masonry-layout .format-quote blockquote,
.search .masonry-layout .format-quote blockquote{
	padding: 0;
	margin-top: 15px;
	margin-bottom: 15px;
}

.blog .masonry-layout .format-quote blockquote cite,
.archive .masonry-layout .format-quote blockquote cite,
.search .masonry-layout .format-quote blockquote cite{
	display: none;
}

.blog .masonry-layout .format-quote .entry-meta,
.archive .masonry-layout .format-quote .entry-meta,
.search .masonry-layout .format-quote .entry-meta{
	margin: 0;
}

.blog .masonry-layout .format-quote .entry-meta .entry-date,
.archive .masonry-layout .format-quote .entry-meta .entry-date,
.search .masonry-layout .format-quote .entry-meta .entry-date{
	display: none;
}

.blog .masonry-layout .format-quote .entry-meta .format-name,
.archive .masonry-layout .format-quote .entry-meta .format-name,
.search .masonry-layout .format-quote .entry-meta .format-name{
	margin-top: 0;
}

.blog .masonry-layout .format-quote .entry-meta .format-name:before,
.archive .masonry-layout .format-quote .entry-meta .format-name:before,
.search .masonry-layout .format-quote .entry-meta .format-name:before{
	display: none;
}

.blog .masonry-layout .format-status .entry-title,
.archive .masonry-layout .format-status .entry-title,
.search .masonry-layout .format-status .entry-title{
	font-size: 24px;
	text-transform: none;
	margin-top: 12px;
	margin-bottom: 15px;
	line-height: 30px;
	width: 100%;
}

.blog .masonry-layout .format-status .entry-content .entry-date,
.archive .masonry-layout .format-status .entry-content .entry-date,
.search .masonry-layout .format-status .entry-content .entry-date{
	margin: 7px 0 0;
}

.blog .masonry-layout .format-status .entry-content .entry-title,
.archive .masonry-layout .format-status .entry-content .entry-title,
.search .masonry-layout .format-status .entry-content .entry-title{
	margin-top: 12px !important;
	margin-bottom: 15px !important;
}

.blog .masonry-layout .format-status .entry-meta,
.archive .masonry-layout .format-status .entry-meta,
.search .masonry-layout .format-status .entry-meta{
	margin: 7px 0;
}

.blog .masonry-layout .format-status .entry-meta .entry-date,
.archive .masonry-layout .format-status .entry-meta .entry-date,
.search .masonry-layout .format-status .entry-meta .entry-date{
	display: none;
}

.blog .masonry-layout .format-status .entry-meta .format-name,
.archive .masonry-layout .format-status .entry-meta .format-name,
.search .masonry-layout .format-status .entry-meta .format-name{
	margin-top: 3px;
}

.blog .masonry-layout .format-status .entry-meta .format-name:before,
.archive .masonry-layout .format-status .entry-meta .format-name:before,
.search .masonry-layout .format-status .entry-meta .format-name:before{
	display: none;
}

/* single format status */
.single-post .isotope_container{
	margin: 50px -5px !important;
}

.single-post .isotope_container .gallery-item{
	padding: 5px;
}

.single-post .entry-tags{
	color: #ffffff;
	margin-top: 15px;
}

.single-post .entry-tags:before{
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "\f02b";
	color: black;
	margin-right: 5px;
	font-weight: 400;
}

.single-post .entry-tags .tag-links:before{
	display: none;
}

.single-post .entry-tags .tag-links a{
	color: black;
}

.single-post .entry-tags .tag-links a:hover{
	color: #ffffff;
}

.single-post .entry-meta{
	clear: both;
	margin-top: 15px;
}

.single-post .format-status .entry-avatar{
	margin-top: 25px;
	margin-bottom: 30px;
}

.single-post .format-status .entry-avatar img{
	margin: 0;
	border: 0;
	border-radius: 50%;
	background-color: #ffffff;
}

.single-post .format-status .item-content .entry-meta,
.single-post .format-status .entry-content .entry-meta{
	text-align: center;
	margin-top: 0;
}

.single-post .format-status .item-content .entry-meta div:last-child,
.single-post .format-status .entry-content .entry-meta div:last-child{
	margin-right: 0;
}

.blog .format-quote .entry-content blockquote{
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
}

/* single format quote */
.single-post .format-quote .item-content .entry-meta,
.single-post .format-quote .entry-content .entry-meta{
	text-align: center;
	margin-top: 0;
}

.single-post .format-quote .item-content .entry-meta:before,
.single-post .format-quote .item-content .entry-meta:after,
.single-post .format-quote .entry-content .entry-meta:before,
.single-post .format-quote .entry-content .entry-meta:after{
	display: none;
}

.single-post .format-quote .item-content .entry-meta div:last-child,
.single-post .format-quote .entry-content .entry-meta div:last-child{
	margin-right: 0;
}

.ds .format-chat p:nth-child(odd) strong{
	color: #ffffff;
	font-weight: 600;
}

/* format chat */
.format-chat p{
	margin: 0;
	padding: 10px 0;
}

.format-chat p:first-of-type{
	padding: 0 0 6px;
}

.format-chat p:nth-child(odd) strong{
	color: #1f2732;
	font-weight: 600;
}

.format-chat p:nth-child(even) strong{
	color: black;
	font-weight: 600;
}

/* format gallery */
.wp-block-gallery{
	margin-bottom: 10px !important;
}

.event-single .owl-carousel,
.format-gallery .owl-carousel{ /* dots */
}

.blog-more-toggle{
	display: block;
	text-transform: uppercase;
	padding: 9px 0;
	margin: 32px 0 80px;
	background-color: rgba(128, 128, 128, 0.05);
}

.blog-more-toggle .blog-more-visible{
	display: inline;
}

.blog-more-toggle .blog-more-hidden{
	display: none;
}

.blog-more-toggle.collapsed .blog-more-visible{
	display: none;
}

.blog-more-toggle.collapsed .blog-more-hidden{
	display: inline;
}

.item-cats{
	margin: 0 0 20px 0;
}

.st_facebook_hcount,
.st_twitter_hcount,
.st_googleplus_hcount{
	position: relative;
	text-align: right;
}

.st_facebook_hcount span,
.st_twitter_hcount span,
.st_googleplus_hcount span{
	-ms-box-sizing: content-box;
	box-sizing: content-box;
}

.author-meta{
	margin-top: 60px;
}

.author-meta .author-name{
	font-size: 32px;
	font-weight: 400;
	color: #7f7f7f;
	margin: 0 0 5px;
	text-transform: none;
	position: relative;
}

.ds .author-meta .author-name{
	color: #ffffff;
}

.author-meta .author-bio{
	margin-bottom: 18px;
}

.author-meta .author-position{
	display: block;
	line-height: 1.2;
	margin-bottom: 10px;
}

.author-meta .item-media img{
	background-color: #f4f4f5;
	margin: 0;
}

.author-meta .item-content{
	padding: 35px;
}

.author-meta .author-social{
	position: relative;
	left: 0;
	bottom: -10px;
}

.author-meta .author-social a{
	display: inline-block;
	margin: 0;
}

.author-meta .author-social a:before{
	top: 1px;
	position: relative;
}

.author-meta .author-social a.social-icon + a.social-icon.color-bg-icon{
	margin-left: 5px;
}

/*single post*/
.single-post article > .entry-thumbnail{
	margin-bottom: 0;
}

.single-post .item-content ul{
	margin-bottom: 35px !important;
}

.single-post .item-content ul.wp-block-gallery{
	margin-bottom: 10px !important;
}

.single-post .item-content ul:last-child{
	margin-bottom: 0 !important;
}

.single-post .item-content ul ul{
	margin-bottom: 0 !important;
}

.single-post blockquote{
	margin-top: 45px;
	margin-bottom: 45px;
}

.single-post blockquote:before,
.single-post blockquote:after{
	display: none;
}

.single-post blockquote footer{
	margin-top: 25px;
}

.single-post blockquote footer cite{
	margin-top: 5px;
}

.single-post blockquote footer cite:before{
	display: none;
}

.single-post .video-entry-thumbnail [class*="col-"]{
	margin-top: 0;
	margin-bottom: 0;
}

.single-post .video-entry-thumbnail .entry-thumbnail{
	margin-bottom: 0;
}

.single-post footer.entry-meta{
	margin: 30px 0 0;
}

/*comments*/
.comments-area{
	margin-top: 60px;
}

@media (min-width: 1200px) {
	.comments-area.with_padding.big-padding{
		padding: 50px 60px;
	}
}

.comments-area .comments-title{
	font-size: 36px;
}

.comments-area h1{
	font-size: 54px;
}

@media (max-width: 991px) {
	.comments-area h1{
		font-size: 28px;
	}
}

@media (max-width: 479px) {
	.comments-area h1{
		font-size: 26px;
	}
}

.comments-area h2{
	font-size: 44px;
}

@media (max-width: 991px) {
	.comments-area h2{
		font-size: 26px;
	}
}

@media (max-width: 479px) {
	.comments-area h2{
		font-size: 24px;
	}
}

.comments-area h3{
	font-size: 34px;
}

@media (max-width: 991px) {
	.comments-area h3{
		font-size: 22px;
	}
}

@media (max-width: 479px) {
	.comments-area h3{
		font-size: 22px;
	}
}

.comments-area h4{
	font-size: 28px;
}

@media (max-width: 991px) {
	.comments-area h4{
		font-size: 20px;
	}
}

@media (max-width: 479px) {
	.comments-area h4{
		font-size: 20px;
	}
}

.comments-area h5{
	font-size: 20px;
}

@media (max-width: 991px) {
	.comments-area h5{
		font-size: 18px;
	}
}

.comments-area h6{
	font-size: 16px;
}

.comments-area table{
	overflow-x: auto;
	overflow-x: scroll;
}

@media (max-width: 600px) {
	.comments-area table{
		display: block;
	}
}

@media (max-width: 991px) {
	.comments-area ol.children{
		padding-left: 0;
	}
}

.comments-area form [class*="col-sm-4"]{
	margin-bottom: 10px;
}

.comments-area li.comment{
	list-style: none;
	padding-top: 5px;
}

.comments-area .comment-body{
	display: -webkit-box;
	display: flex;
}

.comments-area .comment-body .comment-text{
	margin-left: 10px;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.comments-area .comment-body .comment-author img{
	max-width: 90px;
	border: 5px solid rgba(25, 31, 40, 0.1);
	border-radius: 50%;
	background-color: #f4f4f5;
}

.comments-area ol.children .comment-body img{
	max-width: 70px;
}

.comments-area .trackback .comment-body,
.comments-area .pingback .comment-body{
	margin-bottom: 10px;
}

@media (min-width: 480px) {
	.comments-area .trackback .comment-body a,
	.comments-area .pingback .comment-body a{
		margin-left: 10px;
	}
}

.comments-area .media-object{
	max-width: 70px;
}

.comments-area .comment-text{
	padding: 20px 20px 10px 0;
	width: auto;
}

.comments-area .comment-text .comment-content p{
	margin-bottom: 5px;
}

.comments-area .comment-text .comment-content blockquote{
	margin-left: 0;
	margin-right: 0;
	text-align: left;
	max-width: 500px !important;
}

.comments-area .comment-text .comment-content table{
	overflow-x: auto; /* Scrollbar is displayed as it's needed */
}

@media (max-width: 600px) {
	.comments-area .comment-text .comment-content table{
		display: block;
	}
}

.comments-area .comment-text .comment-metadata{
	display: block;
	margin: 0 0 10px;
	line-height: 1.2;
}

.comments-area .comment-text .comment-metadata a{
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.2em;
}

.comment-list{
	margin: 0 0 20px;
	padding: 0;
	position: relative;
	list-style: none;
}

.comment-list ol.comment-list{
	padding-left: 15px;
}

@media (min-width: 992px) {
	.comment-list ol.comment-list{
		padding-left: 70px;
	}
}

.comment-meta{
	line-height: 1;
	margin-bottom: 0;
	padding-bottom: 0;
}

.comment-meta .comment-date{
	display: block;
	margin: 7px 0 10px;
}

.comment-meta .author_url{
	font-size: 16px;
	font-weight: 500;
	text-transform: none;
}

.comment-meta .author_url a{
	line-height: 1;
}

.reply a{
	position: relative;
	padding: 0 0 13px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 500;
	color: black;
}

.reply a:hover{
	color: #1f2732;
}

.comment-respond{
	margin-top: 3em;
	margin-bottom: 3em;
}

.comment-respond h3{
	font-size: 36px;
	font-weight: 500;
	text-transform: none;
	margin: 0 0 20px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	flex-wrap: wrap;
}

.comment-respond p{
	margin-bottom: 10px;
	position: relative;
}

.comment-form.muted_background{
	padding: 30px 30px 20px;
}

.comment-form p{
	margin-bottom: 0;
	line-height: 1.2;
}

.comment-form .pre-text{
	margin-left: 10px;
	margin-bottom: 12px;
}

.comment-form .form-submit{
	margin-top: 20px;
	margin-bottom: 0;
}

.comment-form label[for]{
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.2em;
}

.comment-form label[for] .required{
	display: none;
}

.comment-form .theme_button{
	margin: 0 20px 10px 0;
}

.comment-form .theme_button[type="reset"]{
	display: inline-block;
}

@media (max-width: 500px) {
	.comment-respond i.pull-left{
		display: none;
	}

	.comment-respond .comment-form{
		margin-left: 0;
	}
}

.comment-navigation{
	margin-top: 40px;
}

.comment-navigation .pagination > li > a,
.comment-navigation .pagination > li > span{
	padding: 5px 10px;
}

/* wordpress lost password */
.post-password-form input[type="password"].form-control{
	border-color: rgba(0, 0, 0, 0.1);
}

.post-password-form .theme_button{
	top: -1px;
	padding: 20px 30px 20px;
}

@media (min-width: 768px) {
	.post-password-form .theme_button{
		min-width: 150px;
		margin-left: 20px;
	}
}

@media (min-width: 1400px) {
	.post-password-form .theme_button{
		min-width: 170px;
	}
}

/* gallery */
#isotope_filters,
.filters{
	text-align: center;
	font-size: 12px;
	margin: 0 0 30px;
	text-transform: uppercase;
	padding: 0 15px;
	list-style: none;
	position: relative;
}

@media (min-width: 1200px) {
	#isotope_filters,
	.filters{
		margin: 0 0 42px;
	}
}

#isotope_filters li,
.filters li{
	display: inline-block;
	margin: 0;
	padding: 0;
}

#isotope_filters a,
.filters a{
	padding: 5px 10px;
	margin: 0 0 10px;
	display: inline-block;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: bold;
	color: #191f28;
	background-color: transparent;
	position: relative;
	z-index: 2;
}

#isotope_filters a.selected,
.filters a.selected{
	color: black !important;
}

#isotope_filters a:hover,
#isotope_filters a.active,
.filters a:hover,
.filters a.active{
	background-color: transparent;
	border-color: transparent;
	color: black;
}

@media (min-width: 480px) {
	#isotope_filters a,
	.filters a{
		padding: 5px 10px;
	}
}

@media (min-width: 1400px) {
	#isotope_filters a,
	.filters a{
		padding: 5px 15px;
	}
}

.ds #isotope_filters a,
.ds .filters a{
	color: #ffffff;
}

.ds #isotope_filters a:hover,
.ds #isotope_filters a.active,
.ds .filters a:hover,
.ds .filters a.active{
	background-color: transparent;
	border-color: transparent;
	color: black;
}

.filter_text{
	position: absolute;
	font-size: 104px;
	width: 100%;
	text-align: center;
	font-weight: 700;
	top: 6px;
	text-transform: uppercase;
	color: rgba(25, 31, 40, 0.05);
	z-index: 1;
}

@media (max-width: 768px) {
	.filter_text{
		display: none;
	}
}

.gallery-item{
	overflow: hidden;
	position: relative; /* hover styles */
}

.gallery-item .item-title{
	font-size: 20px;
	font-weight: 800;
	text-transform: none;
}

.gallery-item .item-content{
	padding: 0 40px 5px;
}

.gallery-item .item-meta{
	font-weight: 300;
	z-index: 5;
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.gallery-item:hover .item-meta{
	-webkit-transform: scale(1);
	transform: scale(1);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

.gallery-item .post-adds{
	margin-bottom: 0;
}

/* Gallery regular layput*/
.item-layout-item-regular .gallery-item .media-wrap{
	display: none;
	top: 50%;
	margin-top: auto;
	height: auto;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.item-layout-item-regular .gallery-item .media-wrap .categories-links{
	margin-bottom: 0;
	display: block;
	position: relative;
}

.item-layout-item-regular .gallery-item .media-wrap .categories-links a{
	display: none;
}

.item-layout-item-regular .gallery-item .media-wrap .categories-links a:first-child{
	display: block;
}

.item-layout-item-regular .gallery-item .media-wrap .item-title{
	display: block;
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
}

.ds .item-layout-item-regular .gallery-item .media-wrap .item-title a{
	color: #1f2732;
}

.item-layout-item-regular:hover .gallery-item .media-wrap{
	display: block;
}

.owl-item:not(.center) .item-layout-item-regular-2:hover .gallery-item .media-wrap{
	display: none;
}

.item-layout-item-regular-2 .gallery-item .media-wrap{
	padding: 30px !important;
	display: none;
	margin-top: auto;
	text-align: left;
	top: auto;
	height: 100%;
	width: 100%;
}

@media (max-width: 767px) {
	.item-layout-item-regular-2 .gallery-item .media-wrap{
		padding: 10px !important;
	}
}

.item-layout-item-regular-2 .gallery-item .media-wrap .categories-links{
	margin-bottom: 0;
	margin-top: 0;
	display: inline-block;
	width: auto;
	top: 0;
	left: 0;
	position: relative;
}

.item-layout-item-regular-2 .gallery-item .media-wrap .categories-links a{
	display: none;
	margin-bottom: 0;
	font-size: 12px;
	letter-spacing: 0.2em;
	line-height: 24px;
	font-weight: 500;
	padding: 8px 20px;
	border: 0;
	border-radius: 30px;
	background: rgba(31, 39, 50, 0.5);
}

.item-layout-item-regular-2 .gallery-item .media-wrap .categories-links a:first-child{
	display: inline-block;
}

.item-layout-item-regular-2 .gallery-item .media-wrap .categories-links a:hover{
	color: black;
}

@media (max-width: 767px) {
	.item-layout-item-regular-2 .gallery-item .media-wrap .categories-links{
		display: none;
	}
}

.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap{
	position: absolute;
	bottom: 30px;
	left: 30px;
	right: 30px;
	margin-top: 0;
	display: inline-block;
	width: auto;
	top: auto;
}

.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap .item-title{
	display: block;
	position: relative;
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
	color: #ffffff;
}

.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap .post-date{
	margin-top: 0;
	display: block;
	position: relative;
	line-height: 16px;
}

.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap .post-date a{
	color: #ffffff;
}

@media (max-width: 767px) {
	.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap{
		bottom: 10px;
		left: 10px;
		right: 10px;
	}

	.item-layout-item-regular-2 .gallery-item .media-wrap .content-wrap .item-title{
		font-size: 18px;
		margin-bottom: 5px;
	}
}

.item-layout-item-regular-2:hover .gallery-item .media-wrap{
	display: block;
}

.item-layout-item-regular-2 .media-links:before{
	background-color: rgba(31, 39, 50, 0.7) !important;
}

.item-layout-item-regular-3 .gallery-item{
	padding: 0;
}

.item-layout-item-regular-3 .gallery-item .media-links::before{
	opacity: 1;
}

.item-layout-item-regular-3 .gallery-item .item-media a{
	display: inline-block;
	height: 100%;
	position: relative;
}

.item-layout-item-regular-3 .gallery-item .media-wrap{
	padding: 30px !important;
	display: block;
	margin-top: auto;
	text-align: left;
	top: auto;
	height: 100%;
	width: 100%;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .categories-links{
	margin-bottom: 0;
	margin-top: 0;
	display: inline-block;
	width: auto;
	top: 0;
	left: 0;
	position: relative;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .categories-links a{
	display: none;
	margin-bottom: 0;
	font-size: 12px;
	letter-spacing: 0.2em;
	line-height: 24px;
	font-weight: 500;
	padding: 8px 20px;
	border: 0;
	border-radius: 30px;
	background: rgba(31, 39, 50, 0.5);
}

.item-layout-item-regular-3 .gallery-item .media-wrap .categories-links a:first-child{
	display: inline-block;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .categories-links a:hover{
	color: black;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .content-wrap{
	position: absolute;
	bottom: 30px;
	left: 30px;
	right: 30px;
	margin-top: 0;
	display: inline-block;
	width: auto;
	top: auto;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .content-wrap .item-title{
	display: block;
	position: relative;
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
	color: #ffffff;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .content-wrap .post-date{
	margin-top: 0;
	display: block;
	position: relative;
	line-height: 16px;
}

.item-layout-item-regular-3 .gallery-item .media-wrap .content-wrap .post-date a{
	color: black;
}

.item-layout-item-regular-3:hover .gallery-item .item-media a{
	z-index: 2;
}

.item-layout-item-regular-3:hover .gallery-item .media-links::before{
	opacity: 0;
}

.item-layout-item-regular-3:hover .gallery-item .media-wrap{
	display: none;
}

.item-layout-item-regular-3 .media-links:before{
	background-color: rgba(31, 39, 50, 0.7) !important;
}

.item-layout-item-extended-2{
	position: relative;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.item-layout-item-extended-2 .item-media::before{
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(31, 39, 50, 0.4);
	z-index: 1;
}

.item-layout-item-extended-2 .item-content{
	padding: 40px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	text-align: center;
	z-index: 2;
}

.item-layout-item-extended-2 .item-content > *:not(.item-button-hover):not(.item-number){
	opacity: 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	z-index: 1;
}

.item-layout-item-extended-2 .item-content::before{
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0;
	background-color: black;
	-webkit-transition: all .8s ease-in-out;
	transition: all .8s ease-in-out;
	-webkit-transition-delay: .2s;
	transition-delay: .2s;
	width: 100%;
	height: 100%;
	display: inline-block;
	z-index: 0;
	-webkit-clip-path: circle(20px at 87% 90%);
	clip-path: circle(20px at 87% 90%);
}

.item-layout-item-extended-2 .item-content .item-number{
	position: absolute;
	right: 30px;
	top: 30px;
	font-size: 12px;
	color: #ffffff;
	font-weight: 500;
}

.item-layout-item-extended-2 .item-content .item-number::before{
	content: '0';
}

.item-layout-item-extended-2 .item-content .item-button-hover{
	position: absolute;
	bottom: 30px;
	right: 30px;
	display: inline-block;
	-webkit-mask: url("../img/icon_plus.png");
	width: 40px;
	height: 40px;
	background-color: black;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.item-layout-item-extended-2 .item-content .item-title a:hover{
	color: #ff497c;
}

.item-layout-item-extended-2 .item-content .categories-links a{
	color: #1f2732 !important;
}

.item-layout-item-extended-2 .item-content .categories-links a:hover{
	color: #ffffff !important;
}

.item-layout-item-extended-2 .item-content p{
	color: rgba(255, 255, 255, 0.8);
}

.item-layout-item-extended-2 .item-content:hover > *:not(.item-button-hover){
	opacity: 1;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	-webkit-transition-delay: .2s;
	transition-delay: .2s;
}

.item-layout-item-extended-2 .item-content:hover::before{
	opacity: 1;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	-webkit-clip-path: circle(900px at 88% 90%);
	clip-path: circle(900px at 88% 90%);
}

.item-layout-item-extended-2 .item-content:hover .item-button-hover{
	opacity: 0;
}

.item-layout-item-extended-3{
	padding-left: 0 !important;
	padding-right: 0 !important;
	position: relative;
}

.item-layout-item-extended-3 .media-links::before{
	background-color: rgba(31, 39, 50, 0.5) !important;
	opacity: 1;
}

.item-layout-item-extended-3 .item-title{
	font-size: 30px;
	font-weight: 500;
	word-break: break-word;
}

@media (max-width: 1199px) {
	.item-layout-item-extended-3 .item-title{
		font-size: 25px;
	}
}

.item-layout-item-extended-3 p{
	font-size: 20px;
}

.item-layout-item-extended-3 .item-content{
	position: absolute;
	bottom: 0;
	z-index: 3;
	padding-bottom: 30px;
}

.item-layout-item-extended-3 .item-content p{
	max-height: 0;
	opacity: 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	color: #ffffff;
}

.item-layout-item-extended-3:hover .item-content p{
	max-height: 150px;
	opacity: 1;
}

.gallery-title-item + .item-title{
	padding: 30px 0;
}

.gallery-title-item + .item-title h3{
	font-size: 28px;
	margin-bottom: 5px;
}

i.loadmore_spinner{
	font-size: 30px;
	opacity: 0.3;
	margin: 10px 0 20px;
}

/* gallery single */
.gallery-single-title{
	margin: 12px 0 0;
}

@media (min-width: 768px) {
	.gallery-single-title{
		font-size: 60px;
	}
}

.gallery-single-title + .block-header{
	margin-bottom: 40px;
}

.share-gallery-single{
	margin: 40px 0 16px;
}

.items-nav{
	padding: 30px 23px;
}

.items-nav[class*='display_table_']{
	margin-top: 40px;
}

.items-nav h4{
	margin: -5px 0 0;
}

.items-nav .media{
	position: relative;
	padding: 10px 0;
}

.items-nav .next-item img{
	max-width: none;
	padding: 0 30px 0 0;
}

.items-nav .next-item i{
	right: 0;
}

.items-nav .prev-item img{
	max-width: none;
	padding: 0 0 0 30px;
}

.items-nav a{
	position: relative;
}

.items-nav a i{
	top: 50%;
	margin-top: -14px;
	font-size: 20px;
}

.items-nav a i.position-absolute{
	position: absolute;
}

.page-links{
	clear: both;
}

.page-links .page-links-title{
	margin-right: 10px;
}

.page-links > span.theme_button,
.page-links > a.theme_button{
	border-width: 2px;
	min-width: 30px;
	min-height: 30px;
	line-height: 15px;
}

.page-links > span.theme_button span,
.page-links > a.theme_button span{
	border: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	text-indent: -0.05em;
}

/* wp pagination */
.loop-pagination{
	margin-top: 60px;
}

.muted_background .loop-pagination{
	margin-top: 0;
}

.owl-carousel.hover-scale{
	margin-bottom: 50px;
}

@media (min-width: 768px) {
	.owl-carousel.hover-scale .owl-stage-outer{
		overflow: visible;
	}

	.owl-carousel.hover-scale .vertical-item{
		-webkit-transition: all 0.2s ease-in;
		transition: all 0.2s ease-in;
	}

	.owl-carousel.hover-scale .owl-item:hover .vertical-item,
	.owl-carousel.hover-scale .owl-item.center .vertical-item{
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		z-index: 4;
		overflow: hidden;
	}
}

.owl-carousel.related-photos-carousel{
	margin-bottom: 0;
}

/*404*/
.error404 #box_wrapper,
.error404 #canvas{
	min-height: 100vh;
}

.error404 #box_wrapper{
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.error404 .page_content{
	-webkit-box-flex: 1;
	flex: 1 0 auto;
}

.error404 .page_content{
	margin-top: -60px;
	z-index: 3;
	background-image: url("../img/404.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.error404 .page_content .content-404{
	min-height: 650px;
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	align-items: flex-end;
	-webkit-box-pack: center;
	justify-content: center;
	margin-bottom: 150px;
}

.error404 .page_content .content-404 .error-title{
	color: #191f28;
	letter-spacing: 0;
	margin-bottom: 35px;
}

.error404 .page_content .content-404 .widget_search{
	margin-bottom: 30px;
}

.error404 .page_content .content-404 .widget_search .search-form{
	max-width: 370px;
	margin: 0 auto;
}

.error404 .page_content .content-404 .widget_search .search-form .search-field.form-control{
	text-align: center;
}

.error404 .page_content .content-404 .widget_search .search-form .search-submit{
	display: none;
}

@media (max-width: 480px) {
	.error404 .page_content{
		margin-top: -35px;
	}

	.error404 .page_content .content-404{
		min-height: 570px;
		margin-bottom: 160px;
	}

	.error404 .page_content .content-404 .error-title{
		margin-bottom: 15px;
	}

	.error404 .page_content .content-404 .widget_search{
		margin-bottom: 10px;
	}
}

@media (max-width: 1200px) {
	.error404 .page_content{
		margin-top: -50px;
	}

	.error404 .page_content .content-404{
		min-height: 570px;
		margin-bottom: 160px;
	}

	.error404 .page_content .content-404 .error-title{
		margin-bottom: 15px;
	}

	.error404 .page_content .content-404 .widget_search{
		margin-bottom: 20px;
	}
}

/* Events */
.events-loop .side-item .row{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
}

.events-loop .side-item .row [class*="col-"]{
	margin: 0;
	padding: 0;
}

.events-loop .cover-image{
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.events-loop .cover-image{
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		bottom: 0;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	.events-loop .cover-image img{
		display: none;
	}
}

.ds .fw-event .event-info{
	color: #ffffff;
}

.fw-event .entry-title{
	margin-top: 12px;
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 500;
}

.fw-event .event-info{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: start;
	justify-content: flex-start;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 600;
	color: #191f28;
}

@media (max-width: 992px) {
	.fw-event .event-info{
		display: block;
		float: left;
	}
}

.fw-event .event-info .event-start-date,
.fw-event .event-info .event-start-time{
	margin-right: 20px;
}

.fw-event .event-info .event-start-date:before,
.fw-event .event-info .event-start-time:before,
.fw-event .event-info .event-place:before{
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "";
	margin-right: 2px;
	font-weight: 500;
	color: black;
}

.fw-event .event-info .event-start-date:before{
	content: "\f073";
}

.fw-event .event-info .event-start-time:before{
	content: "\f017";
}

.fw-event .event-info .event-place:before{
	content: "\f041";
}

.fw-event .blog-excerpt{
	max-height: 90px;
	overflow: hidden;
}

.fw-event .more-link{
	display: none;
}

.event-single-page p{
	color: #7f7f7f;
}

.event-single-page .entry-content h1:first-of-type,
.event-single-page .entry-content h2:first-of-type,
.event-single-page .entry-content h3:first-of-type,
.event-single-page .entry-content h4:first-of-type,
.event-single-page .entry-content h5:first-of-type,
.event-single-page .entry-content h6:first-of-type{
	margin-top: 0;
}

.event-single-page .single-event-info{
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 0.2em;
	font-weight: 500;
	color: #7f7f7f;
}

.event-single-page .single-event-info strong{
	font-weight: 500;
	color: #191f28;
	min-width: 70px;
	display: inline-block;
}

.event-single-page .single-event-info .info-label{
	min-width: 60px;
	display: inline-block;
}

.event-single-page .single-event-info .event-place:before,
.event-single-page .single-event-info .event-start-date:before,
.event-single-page .single-event-info .event-end-date:before{
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "";
	margin-right: 4px;
	font-weight: 500;
	color: black;
	width: 14px;
	display: inline-block;
}

.event-single-page .single-event-info .event-start-date:before,
.event-single-page .single-event-info .event-end-date:before{
	content: "\f073";
}

.event-single-page .single-event-info .event-place:before{
	content: "\f041";
}

/* wordpress post navigation */
.navigation.post-navigation{
	padding: 0;
}

.navigation.post-navigation a{
	display: inline-block;
	width: 49%;
	position: relative;
	text-align: center;
	overflow: hidden;
	padding: 0;
	font-weight: normal;
	font-size: 14px;
}

.navigation.post-navigation a[rel="next"]{
	float: right;
}

.navigation.post-navigation a .media{
	background-position: center;
	background-size: cover;
	padding: 0;
	height: 240px;
	display: table;
	width: 100%;
}

.navigation.post-navigation a .media .nav-overlay{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: block;
	background: rgba(31, 39, 50, 0.8);
	z-index: 1;
}

.navigation.post-navigation a .media .nav-middle{
	display: table-cell;
	vertical-align: middle;
	position: relative;
	z-index: 2;
	padding: 0 15px;
}

.navigation.post-navigation a .media .nav-middle .nav{
	font-weight: 500;
	text-transform: uppercase;
	font-size: 12px;
	display: block;
	letter-spacing: 0.2em;
	color: black;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.navigation.post-navigation a .media .nav-middle .nav:hover{
	color: #ffffff;
}

@media (max-width: 479px) {
	.navigation.post-navigation a .media .nav-middle .nav{
		font-size: 18px;
	}
}

.navigation.post-navigation a .media .nav-middle .title{
	font-size: 16px;
	font-weight: 500;
	display: block;
	line-height: 30px;
	margin-top: 5px;
	color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.navigation.post-navigation a .media .nav-middle .title:hover{
	color: black;
}

@media (max-width: 479px) {
	.navigation.post-navigation a .media .nav-middle .title{
		display: none;
	}
}

.navigation.post-navigation.nav_2 a .prev-item{
	text-align: left;
}

.navigation.post-navigation.nav_2 a .next-item{
	text-align: right;
}

.navigation_2.post-navigation{
	padding: 30px 3px 60px;
}

.navigation_2.post-navigation a > span.prev-item,
.navigation_2.post-navigation a > span.next-item{
	display: block;
	color: #1f2732;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 0.2em;
	font-weight: 600;
}

.navigation_2.post-navigation a > span.prev-item:hover,
.navigation_2.post-navigation a > span.next-item:hover{
	color: black;
}

.navigation_2.post-navigation a > span.prev-item .nav,
.navigation_2.post-navigation a > span.next-item .nav{
	line-height: 1.1;
}

.navigation_2.post-navigation a > span.prev-item .nav:before,
.navigation_2.post-navigation a > span.prev-item .nav:after,
.navigation_2.post-navigation a > span.next-item .nav:before,
.navigation_2.post-navigation a > span.next-item .nav:after{
	font-family: 'FontAwesome';
	font-size: 16px;
	content: "";
	display: inline-block;
	font-weight: 500;
}

.navigation_2.post-navigation a > span.prev-item .nav:before{
	content: "\f104";
	margin-right: 5px;
}

.navigation_2.post-navigation a > span.next-item .nav:after{
	content: "\f105";
	margin-left: 5px;
}

/* wordpress related posts */
.related-posts{
	overflow: hidden;
	display: block;
}

.related-posts .title{
	display: block;
}

@media (min-width: 767px) {
	.related-posts .row{
		display: -webkit-box;
		display: flex;
	}

	.related-posts .row > *{
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.related-posts .item{
		position: relative;
		-webkit-box-flex: 1;
		flex: 1 1;
	}
}

.related-posts .item{
	position: relative;
}

.related-posts .item .caption{
	bottom: 0;
	width: 100%;
	padding: 35px 40px;
	text-align: left;
	color: #ffffff;
	background-color: #191f28;
}

.related-posts .item .caption a{
	color: #ffffff;
}

.related-posts .item .caption a:hover{
	color: black;
}

.related-posts .item .caption .item-title{
	font-size: 16px;
	font-weight: 500 !important;
	margin-bottom: 5px;
	line-height: 1.2;
	overflow: hidden;
}

.related-posts .item .caption .post-date{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600 !important;
	letter-spacing: 0.2em;
	color: black;
}

/* Unyson portfolio extension */
.fw-portfolio .categories-links a,
.tax-fw-portfolio-category .categories-links a{
	display: none;
}

.fw-portfolio .categories-links a:first-child,
.tax-fw-portfolio-category .categories-links a:first-child{
	display: block;
}

/* Portfolio single page */
.single-fw-portfolio article.fw-portfolio{
	margin-top: 15px;
}

.single-fw-portfolio .owl-theme .owl-nav > div{
	background-color: #f4f4f5;
}

.single-fw-portfolio .owl-theme .owl-nav > div:after{
	color: #1f2732;
}

.single-fw-portfolio .owl-theme .owl-nav > div:hover{
	background-color: black;
}

.single-fw-portfolio .owl-theme .owl-nav > div:hover:after{
	color: #ffffff;
}

.single-fw-portfolio .gallery-single-title{
	margin-bottom: 20px;
}

.single-fw-portfolio .owl-carousel.owl-loaded{
	margin-bottom: 0;
}

.single-fw-portfolio .share_buttons{
	margin-bottom: 5px;
}

.single-fw-portfolio .navigation.post-navigation{
	padding: 0;
}

/* Portfolio single page 2 */
.single-fw-portfolio.single_portfolio_layout_2 .page_content{
	display: none;
}

.single-fw-portfolio.single_portfolio_layout_2 .portfolio-carousel{
	margin-top: -70px;
}

.single-fw-portfolio.single_portfolio_layout_2 .portfolio-carousel .container-fluid{
	padding-left: 0;
	padding-right: 0;
}

.single-fw-portfolio.single_portfolio_layout_2 .portfolio-carousel .container-fluid .layout-2{
	padding-left: 0;
	padding-right: 0;
}

.single-fw-portfolio.single_portfolio_layout_2 .gallery-item .item-content{
	padding: 0 0 5px;
}

/* Taxonomies */
.post-type-archive-fw-portfolio,
.tax-fw-portfolio-category{ /* Gallery item extended */ /* Gallery item with title */ /* Full width gallery */
}

.post-type-archive-fw-portfolio .item-button,
.tax-fw-portfolio-category .item-button{
	display: none;
}

.post-type-archive-fw-portfolio .item-layout-item-extended .item-content p,
.tax-fw-portfolio-category .item-layout-item-extended .item-content p{
	margin-bottom: 0;
	height: 87px;
	overflow: hidden;
}

.post-type-archive-fw-portfolio .item-layout-item-extended .item-title,
.tax-fw-portfolio-category .item-layout-item-extended .item-title{
	font-size: 25px;
	line-height: 24px;
	overflow: hidden;
	font-weight: 500;
	text-transform: none;
	margin: 5px 0 5px 0;
	height: 28px;
}

.post-type-archive-fw-portfolio .item-layout-item-title .item-content,
.tax-fw-portfolio-category .item-layout-item-title .item-content{
	overflow: hidden;
	margin-bottom: 0;
	padding: 23px 20px 50px;
}

.post-type-archive-fw-portfolio .item-layout-item-title .item-content .item-title,
.tax-fw-portfolio-category .item-layout-item-title .item-content .item-title{
	font-size: 24px;
	line-height: 30px;
	overflow: hidden;
	font-weight: 500;
	text-transform: none;
	margin: 5px 0 0 0;
	height: 30px;
}

@media (min-width: 1200px) {
	.post-type-archive-fw-portfolio .gallery_fluid_section .container-fluid,
	.tax-fw-portfolio-category .gallery_fluid_section .container-fluid{
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 1200px) {
	.post-type-archive-fw-portfolio .gallery_fluid_section .container-fluid .content-wrap,
	.tax-fw-portfolio-category .gallery_fluid_section .container-fluid .content-wrap{
		padding-left: 0;
		padding-right: 0;
	}
}

.owl-carousel.big-center-slide .owl-stage{
	padding: 50px 0;
}

@media (max-width: 575px) {
	.owl-carousel.big-center-slide .owl-stage{
		padding: 15px 0;
	}
}

.owl-carousel.big-center-slide .owl-item:not(.center) .media-links:before{
	opacity: 1;
}

.owl-carousel.big-center-slide .owl-item.center .gallery-item{
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	position: relative;
	z-index: 2;
	padding: 0;
}

/* Services plugin */
/* category and archive */
.service_item{
	z-index: 1;
	position: relative;
}

.service_item .service_icon{
	margin-top: -30px;
	font-size: 14px;
	width: 60px;
	height: 60px;
	display: block;
	margin-left: -30px;
	left: 50%;
	color: #ffffff;
	background-color: black;
	position: absolute;
	z-index: 2;
}

.service_item .service_icon i{
	line-height: 60px;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.service_item .entry-title{
	font-size: 24px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 15px;
}

.service_item .info{
	text-transform: uppercase;
	font-size: 12px;
	margin-bottom: 12px;
}

.service_item .excerpt{
	height: 90px;
	overflow: hidden;
}

.service_item .excerpt > *{
	color: #7f7f7f;
}

.ds .service_item .excerpt > *{
	color: #a4a9b9;
}

.service_item .excerpt p{
	margin-bottom: 0;
}

.service_item .read-more{
	margin-top: 5px;
}

/* Service item loop 3 */
.service_item.loop-3{
	box-shadow: 0 0 6px 3px rgba(31, 39, 50, 0.1);
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

@media (min-width: 1200px) {
	.service_item.loop-3.vertical-item.content-padding.big-padding .item-content{
		padding: 70px 40px 60px;
	}
}

.service_item.loop-3 .item-content .icon_wrap a{
	height: 65px;
	display: inline-block;
}

.service_item.loop-3 .item-content .icon_wrap a img{
	max-height: 100%;
}

.service_item.loop-3 .entry-title{
	margin-top: 25px;
	margin-bottom: 15px;
}

@media (min-width: 1200px) {
	.service_item.loop-3 .entry-title{
		margin-top: 55px;
	}
}

.service_item.loop-3 .excerpt{
	margin-bottom: 5px;
}

.service_item.loop-3:hover{
	box-shadow: 0 0 0 3px rgba(31, 39, 50, 0.1);
}

/* Service item loop 4 */
@media (min-width: 1200px) {
	.layout-4.services-grid .isotope-item:nth-child(even) .service_item{
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		flex-direction: column-reverse;
	}
}

.layout-4 .service_item{
	z-index: 1;
	position: relative;
}

.layout-4 .service_item.vertical-item.content-padding.big-padding .item-content{
	padding: 50px;
}

@media (min-width: 1200px) {
	.layout-4 .service_item.vertical-item.content-padding.big-padding .item-content{
		padding: 50px 60px;
	}
}

.layout-4 .service_item .service_icon{
	margin-top: -30px;
	font-size: 14px;
	width: 60px;
	height: 60px;
	display: block;
	margin-left: -30px;
	left: 50%;
	color: #ffffff;
	background-color: black;
	position: absolute;
	z-index: 2;
}

.layout-4 .service_item .service_icon i{
	line-height: 60px;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.layout-4 .service_item .entry-title{
	font-size: 24px;
	font-weight: 500;
	margin-top: 6px;
	margin-bottom: 23px;
}

.layout-4 .service_item .info{
	text-transform: uppercase;
	font-size: 12px;
	margin-bottom: 12px;
}

.layout-4 .service_item .excerpt{
	overflow: hidden;
}

@media (min-width: 768px) {
	.layout-4 .service_item .excerpt{
		height: 150px;
	}
}

.layout-4 .service_item .excerpt > *{
	color: #7f7f7f;
}

.ds .layout-4 .service_item .excerpt > *{
	color: #a4a9b9;
}

.layout-4 .service_item .excerpt p{
	margin-bottom: 0;
}

.layout-4 .service_item .read-more{
	margin-top: 20px;
}

/* single */
.single-service .entry-title{
	margin-top: 25px;
	margin-bottom: 25px;
	font-size: 40px;
}

.single-service ul,
.single-service ul.list1{
	margin-bottom: 20px;
}

.height-none-excerpt .shortcode-service .service_item .excerpt{
	height: auto;
}

/* Shortcode service */
.shortcode-service{ /* Isotope */ /* Carousel */
}

.shortcode-service.small-img .service_item img{
	max-width: 120px;
}

.shortcode-service .service_item img{
	width: auto;
	margin: 0 auto;
}

.shortcode-service .service_item .excerpt{
	height: 100px;
	overflow: hidden;
}

.shortcode-service .service_item.vertical-item.content-padding.loop-2 .item-content{
	padding: 20px 20px;
}

.shortcode-service .service_item.loop-4 .item-content{
	padding-top: 0 !important;
}

.shortcode-service .service_item.loop-4 .excerpt{
	height: auto;
}

.shortcode-service .service_item.loop-4 img{
	height: 60px;
}

.shortcode-service.isotope_container .service_item{
	margin: 6px 0;
}

.shortcode-service.isotope_container .service_item .entry-title{
	text-transform: none;
}

.shortcode-service.owl-carousel .owl-stage-outer{
	overflow: visible !important;
}

.shortcode-service.owl-carousel .owl-stage-outer .service_item .entry-title{
	font-size: 24px;
	text-transform: none;
}

.shortcode-service.owl-carousel .owl-stage-outer .service_item .entry-title a{
	color: #ffffff;
}

/* MWT Unyson Team plugin */
@media (min-width: 1200px) {
	.team-list{
		margin-top: 0;
		margin-bottom: 0;
	}
}

.team-list .item-title{
	margin-top: 5px;
	margin-bottom: 15px;
	text-transform: none;
}

.team-list .position{
	line-height: 1;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	margin-bottom: 18px;
}

.team-list.team-layout-1 .desc{
	display: none;
}

.team-list .pagination-nav{
	margin-top: 20px;
	text-align: center;
}

.team-list .pagination-nav li .page-numbers.current{
	background-color: black;
	color: #ffffff;
}

.single-fw-team .vertical-item{
	margin-bottom: 10px;
}

.single-fw-team .vertical-item img{
	width: 100%;
	background-color: #f4f4f5;
}

.single-fw-team .vertical-item .item-title{
	margin-top: 5px;
	margin-bottom: 15px;
}

.single-fw-team .vertical-item .position{
	line-height: 1;
	font-size: 12px;
	color: black;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.single-fw-team .item-content .soc-youtube:before{
	font-size: 16px;
}

.single-fw-team .item-content ul li{
	color: #1f2732;
}

.single-fw-team .item-content .bootstrap-tabs{
	margin-top: 52px;
}

@media (min-width: 1400px) {
	.single-fw-team .item-content .bootstrap-tabs ul.nav-tabs.nav > li > a{
		min-width: 170px;
		text-align: center;
	}
}

.single-fw-team .item-content .bootstrap-tabs .tab-content{
	margin-bottom: 55px;
}

.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="text"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="email"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="url"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="password"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="search"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="tel"],
.single-fw-team .item-content .bootstrap-tabs .tab-content input[type="number"],
.single-fw-team .item-content .bootstrap-tabs .tab-content textarea,
.single-fw-team .item-content .bootstrap-tabs .tab-content select,
.single-fw-team .item-content .bootstrap-tabs .tab-content .form-control{
	background-color: #ffffff;
	border-color: #ffffff;
}

.single-fw-team .item-content blockquote{
	margin-top: 40px;
	margin-bottom: 40px;
}

.single-fw-team .item-content blockquote footer{
	margin-bottom: 5px;
}

.single-fw-team .item-content blockquote footer cite{
	text-align: left;
	margin-top: 15px;
}

.single-fw-team .item-content blockquote footer cite:before,
.single-fw-team .item-content blockquote footer cite:after{
	display: none;
}

.single-fw-team .ds .item-content ul li{
	color: #ffffff;
}

.single-fw-team .ds .item-content blockquote{
	color: #a4a9b9;
	background-color: #191f28;
	border: 0;
}

.team-social-icons{
	margin-top: 25px;
	margin-bottom: 5px;
	line-height: 1;
}

.team-social-icons .soc-youtube:before{
	font-size: 16px;
}

.shortcode-team .vertical-item .item-content .team-social-icons{
	margin-top: 25px;
	margin-bottom: 5px;
}

.shortcode-team .item-content .item-title{
	margin-top: 5px;
	margin-bottom: 15px;
	text-transform: none;
}

.shortcode-team .item-content .position{
	line-height: 1;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0.2em;
	margin-bottom: 0;
}

.shortcode-team.hide-excerpt .item-content .desc{
	display: none;
}

.shortcode-team.hide-socials .item-content .team-social-icons{
	display: none;
}

/* Team Slider Shortcode */
.shortcode-team-slider{
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
}

.shortcode-team-slider .slider-title{
	display: none;
	word-break: unset;
	text-transform: lowercase;
	position: absolute;
	color: black;
	opacity: 0.1;
	font-size: 240px;
	font-weight: 600;
	line-height: 1;
	left: -210px;
	top: 50%;
	-webkit-transform: translateY(-58%) rotate(-90deg);
	transform: translateY(-58%) rotate(-90deg);
	margin-top: 0;
	margin-bottom: 0;
	z-index: 4;
}

.shortcode-team-slider .menu__item{
	text-decoration: none;
	color: #1f2732;
	outline: none;
	opacity: 0.3;
	margin-bottom: 15px;
	cursor: pointer;
}

.shortcode-team-slider .menu__item:hover,
.shortcode-team-slider .menu__item:focus{
	outline: none;
	opacity: 1;
}

.shortcode-team-slider .menu__item{
	font-size: 1.5em;
	line-height: 1;
	font-weight: 500;
	display: block;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.shortcode-team-slider .menu__item span.position:after{
	content: ' / ';
	color: #1f2732;
	opacity: 0.1 !important;
}

.shortcode-team-slider .menu__item span{
	color: black;
}

.shortcode-team-slider .menu__item.flex-active,
.shortcode-team-slider .menu__item.flex-active:hover,
.shortcode-team-slider .menu__item.flex-active:focus{
	opacity: 1;
}

.shortcode-team-slider .flexslider-controls{
	position: absolute;
	width: auto;
	right: 0;
	left: 6%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 4;
}

.shortcode-team-slider .flexslider-controls > ul{
	padding-left: 0;
}

.shortcode-team-slider .team-social-icons{
	margin-top: 0;
	margin-bottom: 20px;
	z-index: 4;
}

.shortcode-team-slider .team-social-icons a.social-icon.color-icon.border-icon.rounded-icon{
	border-width: 3px;
	border-style: solid;
	padding: 0;
	margin-bottom: 4px;
	line-height: 34px;
	width: 40px;
	height: 40px;
}

.shortcode-team-slider .team-social-icons{
	display: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.shortcode-team-slider .menu__item.flex-active + .team-social-icons{
	display: block;
}

@media (min-width: 768px) {
	.shortcode-team-slider .menu__item{
		font-size: 30px;
	}

	.shortcode-team-slider .team-social-icons{
		margin-bottom: 0;
		position: absolute;
		right: 10px;
		width: 60px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.shortcode-team-slider .team-social-icons a.social-icon.color-icon.border-icon.rounded-icon{
		display: inline-block;
		margin-left: 0;
		margin-bottom: 10px !important;
	}

	.shortcode-team-slider .team-social-icons a.social-icon.color-icon.border-icon.rounded-icon:last-child{
		margin-bottom: 0 !important;
	}
}

@media (min-width: 1200px) {
	.shortcode-team-slider .team-social-icons{
		right: 40px;
	}

	.shortcode-team-slider .team-social-icons a.social-icon.color-icon.border-icon.rounded-icon{
		width: 60px;
		height: 60px;
		line-height: 54px;
	}

	.shortcode-team-slider .menu__item{
		font-size: 40px;
	}
}

@media (min-width: 1600px) {
	.shortcode-team-slider .flexslider-controls{
		left: 17%;
	}

	.shortcode-team-slider .menu__item{
		font-size: 54px;
	}

	.shortcode-team-slider .slider-title{
		display: block;
	}

	.shortcode-team-slider .team-social-icons{
		right: 80px;
	}
}

@media (max-width: 767px) {
	.shortcode-team-slider .slides img{
		position: relative;
		right: 87px;
	}

	.shortcode-team-slider .menu__item span.position:after{
		display: none;
	}

	.shortcode-team-slider .flexslider-controls{
		padding: 30px 15px 80px;
		text-align: center;
		position: relative;
		width: 100%;
		right: 0;
		left: 0;
		top: auto;
		-webkit-transform: none;
		transform: none;
	}

	.shortcode-team-slider .team-social-icons{
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.col-md-7 .single-member-1.item-content{
	margin-bottom: 0;
}

.single-member-1.item-content{
	margin-bottom: 90px;
}

/* Team taxonomy layout 2 */
.team-layout-2{ /* Cover Image Style*/
}

.team-layout-2.team-list{
	margin-bottom: 60px;
}

@media (min-width: 1200px) {
	.team-layout-2 .item-content{
		padding: 50px 55px;
	}
}

@media (min-width: 992px) {
	.team-layout-2 .side-item .row{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
	}

	.team-layout-2 .side-item .row .cover-image{
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
	}

	.team-layout-2 .side-item .row .cover-image img{
		display: none;
	}
}

@media (min-width: 992px) and (min-width: 768px) {
	.team-layout-2 .side-item .row .cover-image{
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		bottom: 0;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}
}

.team-layout-2 .loop-item-2 .item-media{
	padding: 0;
	margin: 0;
}

/* Team content absolute */
.team-layout-absolute .item-content{
	position: absolute;
	opacity: 0;
	left: 0;
	top: 100%;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	z-index: 2;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.team-layout-absolute .item-content .item-title{
	margin-top: 0;
	margin-bottom: 5px;
}

.team-layout-absolute .item-content .item-title a:hover{
	color: #191f28;
}

.team-layout-absolute .item-content a.social-icon:not(.color-icon){
	color: #ffffff;
}

.team-layout-absolute .item-content a.social-icon:not(.color-icon):hover{
	color: #191f28;
}

.team-layout-absolute:hover .media-links:before{
	background-color: rgba(255, 73, 124, 0.7);
}

.team-layout-absolute:hover .item-content{
	opacity: 1;
	top: 0;
}

@media (min-width: 992px) {
	.team-variant-3 > div:nth-child(3n+3) .team-layout-absolute:hover .media-links:before{
		background-color: rgba(255, 73, 124, 0.7);
	}

	.team-variant-3 > div:nth-child(3n+3) .team-layout-absolute .media-links:before{
		opacity: 1;
		background-color: rgba(31, 39, 50, 0.7);
	}
}

/* Team member layout 2 */
.single-member-2.vertical-item{
	display: block;
	margin-bottom: 50px;
}

.single-member-2.vertical-item img{
	margin-bottom: 50px;
}

.single-member-2.vertical-item .progress-bar span{
	top: -30px;
}

.single-member-2.item-content .content-header .team-social-icons{
	margin-top: 10px;
	margin-bottom: 30px;
}

@media (min-width: 1440px) {
	.single-member-2.item-content .content-header{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.single-member-2.item-content .content-header .team-social-icons{
		margin-top: 10px;
		margin-bottom: 15px;
	}
}

.single-member-2.item-content .position{
	line-height: 1;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: black;
}

@media (min-width: 1440px) {
	.single-member-2.item-content .position{
		margin-bottom: 0;
	}
}

.single-member-2.item-content .item-title{
	margin-top: 0;
	margin-bottom: 15px;
	text-transform: none;
}

.single-member-2.item-content .team-social-icons{
	margin-top: 5px;
	margin-bottom: 5px;
}

.single-member-2.item-content .member-contacts{
	margin-bottom: 15px;
}

.single-member-2.item-content .member-contacts .icons-list ul > li{
	display: inline-block;
	margin-right: 15px;
}

.single-member-2.item-content .member-contacts .icons-list ul > li:last-child{
	margin-right: 0;
}

.single-member-2.item-content .member-contacts .icons-list ul > li .media-left{
	padding-right: 10px;
	vertical-align: top;
}

.single-member-2.item-content .member-contacts .icons-list ul > li .media-left .icon-wrap{
	line-height: 1;
}

.single-member-2.item-content .member-contacts .icons-list ul > li .media-body a{
	color: #1f2732;
}

.single-member-2.item-content .member-contacts .icons-list ul > li .media-body a:hover{
	color: black;
}

.single-member-carousel{
	position: relative;
	margin-bottom: 35px;
}

.single-member-carousel .carousel-title{
	margin-top: 0;
	margin-bottom: 33px;
}

.single-member-carousel .member-nav{
	text-align: center;
}

@media (min-width: 480px) {
	.single-member-carousel .member-nav{
		position: absolute;
		right: 0;
		top: 9px;
		text-align: right;
	}
}

.single-member-carousel .member-nav > div{
	line-height: 1;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	margin-bottom: 0;
	text-transform: uppercase;
	cursor: pointer;
	color: black;
	display: inline-block;
}

.single-member-carousel .member-nav > div:hover{
	opacity: 0.7;
}

.single-member-carousel .member-nav > div.owl-prev{
	margin-right: 30px;
}

.single-member-carousel .member-nav > div.owl-prev:before{
	font-family: FontAwesome;
	font-size: 12px;
	line-height: 1;
	content: "\f104";
	margin-right: 8px;
	font-weight: bold;
}

.single-member-carousel .member-nav > div.owl-next:after{
	font-family: FontAwesome;
	font-size: 12px;
	line-height: 1;
	content: "\f105";
	margin-left: 8px;
	font-weight: bold;
}

/* Team member layout 3 */
@media (min-width: 768px) {
	.single-member-3 .single-team-header{
		display: -webkit-box;
		display: flex;
	}

	.single-member-3 .single-team-header .team-image{
		margin-right: 30px;
		width: 50%;
	}
}

@media (min-width: 1200px) {
	.single-member-3 .single-team-header{
		-webkit-box-align: center;
		align-items: center;
	}

	.single-member-3 .single-team-header .team-image{
		margin-right: 60px;
		width: 65%;
	}
}

.single-member-3 .item-content .team-social-icons{
	margin-top: 30px;
	margin-bottom: 40px;
}

.single-member-3 .item-content .member-contacts .icons-list ul li{
	border-bottom: 1px solid #dedfe1;
	padding: 12px 0 12px 0;
}

.single-member-3 .item-content .member-contacts .icons-list ul li:first-child{
	border-top: 1px solid #dedfe1;
	padding: 12px 0 12px 0;
}

.single-member-3 .item-content .member-contacts .icons-list ul li strong{
	font-weight: 500;
	min-width: 110px;
	display: inline-block;
}

.single-member-3 .item-content .member-contacts .icons-list ul li a{
	color: black;
}

.single-member-3 .item-content .member-contacts .icons-list ul li a:hover{
	color: #1f2732;
}

.ds .single-member-3 .item-content .member-contacts .icons-list ul li a:hover{
	color: #ffffff;
}

.single-member-3 .member-bio h6{
	margin-bottom: 0.8em;
}

.single-member-3 .progress{
	margin-bottom: 27px;
}

.single-member-3 p + .progress{
	margin-top: 0;
}

.single-member-3 .progress-title{
	margin-bottom: 12px;
}

.single-member-3 .progress-bar span{
	top: -35px;
}

.single-member-3.form-wrapper{
	margin-bottom: 90px;
}

.single-member-3.form-wrapper .form-title{
	margin-bottom: 15px;
}

/*
**widgets
*/
aside .widget-title,
.before-loop-area .widget-title{
	text-align: left;
	position: relative;
	display: block;
	font-weight: bold;
}

aside > div + div,
.before-loop-area > div + div{
	margin-top: 10px;
}

aside > div + div > .widget-title,
aside > div + div.widget_no_background .widget-title,
.before-loop-area > div + div > .widget-title,
.before-loop-area > div + div.widget_no_background .widget-title{
	margin-top: 15px;
}

aside .widget + .widget,
.before-loop-area .widget + .widget{
	margin-top: 52px;
}

aside input[type="text"]:focus,
aside input[type="email"]:focus,
aside input[type="url"]:focus,
aside input[type="password"]:focus,
aside input[type="search"]:focus,
aside input[type="tel"]:focus,
aside input[type="number"]:focus,
aside textarea:focus,
aside select:focus,
aside .form-control:focus,
.before-loop-area input[type="text"]:focus,
.before-loop-area input[type="email"]:focus,
.before-loop-area input[type="url"]:focus,
.before-loop-area input[type="password"]:focus,
.before-loop-area input[type="search"]:focus,
.before-loop-area input[type="tel"]:focus,
.before-loop-area input[type="number"]:focus,
.before-loop-area textarea:focus,
.before-loop-area select:focus,
.before-loop-area .form-control:focus{
	border-color: rgba(164, 169, 185, 0.3);
}

.before-loop-area .widget{
	margin-top: 30px;
	margin-bottom: 40px;
	display: block;
	overflow: hidden;
}

.before-loop-area .widget .widget-title{
	margin-top: 40px;
	margin-bottom: 55px;
}

.before-loop-area .widget.widget-first{
	margin-top: 0;
}

.widget-theme-wrapper.with_background,
.widget-theme-wrapper.muted_background,
.widget-theme-wrapper.cs,
.widget-theme-wrapper.ds,
.widget-theme-wrapper.ls{
	padding: 35px;
}

/* affix-aside */
.affix-aside{
	position: relative;
}

.affix-aside.affix{
	position: fixed;
	top: 50px;
}

@media (max-width: 767px) {
	.affix-aside,
	.affix-aside.affix,
	.affix-aside.affix-bottom{
		position: static;
		width: inherit !important;
	}
}

.module-header{
	margin: 0 0 38px;
	text-transform: capitalize;
	font-size: 40px;
}

.page_footer .module-header{
	font-size: 28px;
}

.widget > h3,
.widget-title{
	font-size: 24px;
	margin: 0 0 32px;
	text-transform: none;
	font-weight: 500;
	position: relative;
	line-height: 32px;
}

.big_title .widget > h3,
.big_title .widget-title{
	font-size: 30px;
}

.widget:before,
.widget:after{
	display: block;
	content: '';
	clear: both;
}

.widget ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget ul ul li{
	padding-left: 20px;
}

.widget li{
	position: relative;
}

.widget .vertical-item h4{
	font-size: 20px;
	margin: 17px 0 0;
}

.widget .vertical-item + .widget .vertical-item{
	margin-top: 16px;
}

.widget select{
	width: 100%;
	max-width: 100%;
	height: 50px;
	padding-left: 20px;
	padding-right: 40px;
	padding-top: 9px;
	padding-bottom: 9px;
	font-size: 16px;
	border: 1px solid #dedfe1;
	text-transform: uppercase;
	font-weight: 500;
}

.ds .widget_text.special{
	background-color: #191f28;
}

.widget_text ul{
	padding-left: 10px;
}

.widget_text.special{
	background-color: #f4f4f5;
	padding: 30px;
}

@media (min-width: 768px) {
	.widget_text.special{
		padding: 35px 40px 35px 40px;
	}
}

.widget_text.special .widget-title{
	margin-top: 0;
	margin-bottom: 12px;
}

.page_footer.ds{
	color: #7f7f7f;
}

.page_footer p{
	line-height: 25px;
}

@media (min-width: 992px) {
	.page_footer .special-title .widget-title{
		margin-top: 95px !important;
		margin-bottom: 32px !important;
	}
}

.page_footer.before-footer{
	border-bottom: 1px solid #5b5e65;
}

.page_footer.before-footer .col-xs-12{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

@media (max-width: 991px) {
	.page_footer.before-footer .col-xs-12{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	.page_footer.before-footer .col-xs-12 > div:not(:first-child){
		margin-top: 50px;
	}
}

.page_footer.before-footer .col-xs-12 > div{
	flex-basis: 33%;
	padding: 0 15px;
}

.page_footer.before-footer .col-xs-12 > div:first-child{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.page_footer.before-footer .col-xs-12 > div:nth-child(2){
	text-align: center;
}

.page_footer.before-footer .col-xs-12 > div:last-child{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.page_footer.before-footer .col-xs-12 > div input{
	text-align: left;
}

.page_footer.before-footer .col-xs-12 > div .mc4wp-form-fields{
	max-width: 250px;
}

.page_footer.before-footer .col-xs-12 > div .mc4wp-form-fields .text_for_footer{
	display: none;
}

.page_footer .widget + .widget .widget-title{
	margin-top: 50px;
}

.page_footer .widget_banner{
	margin-top: 10px;
	margin-bottom: 20px;
}

.page_footer .widget_text{
	margin-bottom: 20px;
}

.page_footer .widget_craftpro_twitter ul.tweet_list{
	padding-top: 5px;
	overflow: hidden;
}

.page_footer .widget_recent_entries ul li:first-of-type{
	padding: 0 0 11px 0;
}

.page_footer .widget_recent_entries ul li:before{
	display: none;
}

.page_footer .widget_recent_entries ul li .post-date{
	display: block;
}

.page_footer #sb_instagram{
	margin-top: -10px !important;
}

.page_footer .item-meta{
	color: #7f7f7f;
}

.page_footer .widget_popular_entries .widget-title + ul{
	margin-top: -2px;
}

.page_footer .widget_recent_entries .widget-title + ul,
.page_footer .widget_recent_comments .widget-title + ul,
.page_footer .widget_archive .widget-title + ul,
.page_footer .widget_categories .widget-title + ul,
.page_footer .widget_meta .widget-title + ul,
.page_footer .widget_nav_menu ul.menu,
.page_footer .widget_pages .widget-title + ul{
	margin-top: -22px;
}

.page_footer .widget,
.page_footer .widget .widget-title{
	text-align: left;
}

.page_footer .widget-title{
	font-size: 30px;
}

.page_footer .widget.text-center,
.page_footer .widget.text-center .widget-title{
	text-align: center;
}

@media (max-width: 991px) {
	.page_footer .horizontal-menu.widget{
		text-align: center;
	}
}

/* Widget Instagram */
#sb_instagram{
	padding-bottom: 0 !important;
}

#sb_instagram #sbi_images{
	margin: -10px;
	width: calc(100% + 20px);
}

@media screen and (max-width: 767px) {
	aside{
		margin-top: 60px !important;
	}

	aside.shortcode-widget-area{
		margin-top: 0 !important;
	}
}

/*
widget_search,
widget_mailchimp
*/
.widget_mailchimp{
	overflow: hidden;
}

.widget_mailchimp p{
	padding-top: 10px;
}

.widget_search .form-inline,
.widget_product_search form,
.widget_mailchimp .form-inline{
	position: relative;
}

.widget_search .form-inline .form-control,
.widget_product_search form .form-control,
.widget_mailchimp .form-inline .form-control{
	width: 100%;
	margin: 0;
}

.widget_search .form-inline label,
.widget_product_search form label,
.widget_mailchimp .form-inline label{
	display: block;
}

.widget_search .form-inline label[for],
.widget_product_search form label[for],
.widget_mailchimp .form-inline label[for]{
	display: none;
}

.page_topline .widget_search .form-inline,
.page_topline .widget_product_search form,
.page_topline .widget_mailchimp .form-inline{
	display: inline-block;
}

.page_topline .widget_search .form-inline label,
.page_topline .widget_product_search form label,
.page_topline .widget_mailchimp .form-inline label{
	margin: 0;
}

.widget_search .form-inline .form-group,
.widget_mailchimp .form-inline .form-group{
	display: block;
	vertical-align: top;
	position: relative;
}

.text_for_footer{
	margin-bottom: 30px;
}

.cs.page_topline .form-control{
	color: #ffffff;
}

.cs.page_topline .form-control:-moz-placeholder{
	color: #ffffff;
}

.cs.page_topline .form-control::-moz-placeholder{
	color: #ffffff;
}

.cs.page_topline .form-control:-ms-input-placeholder{
	color: #ffffff;
}

.cs.page_topline .form-control::-webkit-input-placeholder{
	color: #ffffff;
}

.cs.page_topline .form-control:focus:-moz-placeholder{
	color: transparent;
}

.cs.page_topline .form-control:focus::-moz-placeholder{
	color: transparent;
}

.cs.page_topline .form-control:focus:-ms-input-placeholder{
	color: transparent;
}

.cs.page_topline .form-control:focus::-webkit-input-placeholder{
	color: transparent;
}

.widget_search .form-inline [type="text"],
.widget_product_search [type="search"],
.widget_mailchimp .form-inline [type="email"]{
	padding-right: 50px;
	display: block;
	width: 100%;
}

@media (min-width: 768px) {
	.widget_search .form-group.inline-block,
	.widget_mailchimp .form-group.inline-block{
		min-width: 370px;
		margin-right: 5px;
	}
}

.page_topline .widget_search .form-group .form-control{
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
	background-color: transparent;
	box-shadow: none;
	font-style: italic;
	min-width: 230px;
	border: none;
	color: #7f7f7f;
}

.page_topline .widget_search .form-group .form-control:-moz-placeholder{
	color: #7f7f7f;
}

.page_topline .widget_search .form-group .form-control::-moz-placeholder{
	color: #7f7f7f;
}

.page_topline .widget_search .form-group .form-control:-ms-input-placeholder{
	color: #7f7f7f;
}

.page_topline .widget_search .form-group .form-control::-webkit-input-placeholder{
	color: #7f7f7f;
}

.page_topline .widget_search .form-group .form-control:focus:-moz-placeholder{
	color: transparent;
}

.page_topline .widget_search .form-group .form-control:focus::-moz-placeholder{
	color: transparent;
}

.page_topline .widget_search .form-group .form-control:focus:-ms-input-placeholder{
	color: transparent;
}

.page_topline .widget_search .form-group .form-control:focus::-webkit-input-placeholder{
	color: transparent;
}

.widget_search .form-inline .theme_button,
.widget_product_search [type="submit"],
.widget_mailchimp .form-inline .theme_button{
	font-size: 0;
	padding: 12px;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	height: 60px;
	width: 60px;
	text-align: center;
	border: none;
	border-radius: 0;
	box-shadow: none;
	font-weight: bold;
	background-color: transparent;
	color: black;
}

.error404 .widget_search .form-inline .theme_button,
.error404 .widget_product_search [type="submit"],
.error404 .widget_mailchimp .form-inline .theme_button{
	background-color: transparent;
	color: #a0ce4e;
}

.widget_search .form-inline .theme_button:before,
.widget_product_search [type="submit"]:before,
.widget_mailchimp .form-inline .theme_button:before{
	font-size: 14px;
	content: "\f002";
	font-family: 'FontAwesome';
}

.widget_search .form-inline .theme_button:hover,
.widget_search .form-inline .theme_button:focus,
.widget_search .form-inline .theme_button:active,
.widget_product_search [type="submit"]:hover,
.widget_product_search [type="submit"]:focus,
.widget_product_search [type="submit"]:active,
.widget_mailchimp .form-inline .theme_button:hover,
.widget_mailchimp .form-inline .theme_button:focus,
.widget_mailchimp .form-inline .theme_button:active{
	color: #1f2732;
}

.ds .widget_search .form-inline .theme_button:hover,
.ds .widget_search .form-inline .theme_button:focus,
.ds .widget_search .form-inline .theme_button:active,
.ds .widget_product_search [type="submit"]:hover,
.ds .widget_product_search [type="submit"]:focus,
.ds .widget_product_search [type="submit"]:active,
.ds .widget_mailchimp .form-inline .theme_button:hover,
.ds .widget_mailchimp .form-inline .theme_button:focus,
.ds .widget_mailchimp .form-inline .theme_button:active{
	color: #a4a9b9;
}

.page_topline .widget_search .form-inline .theme_button,
.page_topline .widget_product_search [type="submit"],
.page_topline .widget_mailchimp .form-inline .theme_button{
	height: 40px;
	width: 40px;
	padding: 0;
	top: 0;
	background: transparent;
	color: #a0ce4e;
}

.page_topline .widget_search .form-inline .theme_button:hover,
.page_topline .widget_search .form-inline .theme_button:focus,
.page_topline .widget_search .form-inline .theme_button:active,
.page_topline .widget_product_search [type="submit"]:hover,
.page_topline .widget_product_search [type="submit"]:focus,
.page_topline .widget_product_search [type="submit"]:active,
.page_topline .widget_mailchimp .form-inline .theme_button:hover,
.page_topline .widget_mailchimp .form-inline .theme_button:focus,
.page_topline .widget_mailchimp .form-inline .theme_button:active{
	color: black;
}

.page_topline .widget_search .form-inline .theme_button:before,
.page_topline .widget_product_search [type="submit"]:before,
.page_topline .widget_mailchimp .form-inline .theme_button:before{
	font-size: 14px;
}

.widget_mailchimp .form-inline .theme_button:before{
	content: "\e65c";
}

/*wordpress - widget_mc4wp_form_widget*/
.widget_mc4wp_form_widget label{
	display: none;
}

.widget_mc4wp_form_widget .mc4wp-form-inner{
	position: relative;
}

.widget_mc4wp_form_widget .mc4wp-form-fields{
	position: relative;
	margin-top: -5px;
}

.widget_mc4wp_form_widget [type*="email"]{
	width: 100%;
	padding-right: 40px;
	border-style: solid;
}

.ds .widget_mc4wp_form_widget [type*="email"]{
	background-color: #191f28;
}

.ds.page_footer .widget_mc4wp_form_widget [type*="email"]{
	background-color: #ffffff;
	border-color: #1f2732;
}

.widget_mc4wp_form_widget [type*="submit"]{
	position: absolute;
	right: 0;
	bottom: 0;
	height: 60px;
	width: 60px;
	font-size: 0;
	color: black;
	background-color: transparent;
	padding: 0;
	margin-bottom: 0;
	box-shadow: none;
	border: none;
	border-radius: 0;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.cs .widget_mc4wp_form_widget [type*="submit"]{
	color: #ffffff;
}

.widget_mc4wp_form_widget [type*="submit"]:before{
	font-size: 14px;
	content: "\f0e0";
	font-family: 'FontAwesome';
}

.widget_mc4wp_form_widget [type*="submit"]:hover,
.widget_mc4wp_form_widget [type*="submit"]:focus{
	border: none;
	color: #1f2732;
	background-color: transparent;
}

.cs .widget_mc4wp_form_widget [type*="submit"]:hover,
.cs .widget_mc4wp_form_widget [type*="submit"]:focus{
	color: black;
}

.shortcode-widget-area .widget_mc4wp_form_widget .mc4wp-form-inner{
	margin-bottom: 0;
}

.text-block.mailchimp .text_for_footer{
	display: none;
}

.text-block.mailchimp .mc4wp-form-inner{
	position: relative;
}

.text-block.mailchimp .mc4wp-form-inner .form-control{
	background: #ffffff;
	text-align: left !important;
}

.text-block.mailchimp .mc4wp-form-inner label{
	display: none;
}

.text-block.mailchimp .mc4wp-form-inner [type*="submit"]{
	position: absolute;
	right: 0;
	bottom: 0;
	height: 60px;
	width: 60px;
	font-size: 0;
	color: black;
	background-color: transparent;
	padding: 0;
	margin-bottom: 0;
	box-shadow: none;
	border: none;
	border-radius: 0;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.text-block.mailchimp .mc4wp-form-inner [type*="submit"]:before{
	font-size: 14px;
	content: "\f0e0";
	font-family: 'FontAwesome';
}

.text-block.mailchimp .mc4wp-form-inner [type*="submit"]:hover,
.text-block.mailchimp .mc4wp-form-inner [type*="submit"]:focus{
	border: none;
	color: #1f2732;
	background-color: transparent;
}

.cs .text-block.mailchimp .mc4wp-form-inner [type*="submit"]:hover,
.cs .text-block.mailchimp .mc4wp-form-inner [type*="submit"]:focus{
	color: black;
}

.text-block.mailchimp.max-270 .mc4wp-form-inner{
	max-width: 270px;
}

.text-center .text-block.mailchimp .mc4wp-form-inner{
	margin: 0 auto;
}

@media (min-width: 992px) {
	.text-md-right .text-block.mailchimp .mc4wp-form-inner{
		float: right;
	}
}

.widget_search.special{
	background-color: #191f28;
	background-image: url("../img/texture.png");
	background-size: cover;
	padding: 20px;
	position: relative;
}

@media (min-width: 768px) {
	.widget_search.special{
		padding: 35px 40px 35px 40px;
	}
}

.widget_search.special:before{
	display: block;
	content: url("../img/search_icon_bg.png");
	position: absolute;
	right: -20px;
	top: -20px;
}

.widget_search.special .widget-title{
	margin-top: 0;
	margin-bottom: 22px;
	color: #ffffff;
}

.widget_search.special input[type="search"],
.widget_search.special .form-control{
	background-color: #ffffff;
}

/*
widget_recent_entries
widget_recent_comments
widget_archive
widget_categories
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments li{
	padding: 15px 0 13px;
	border-bottom: 1px solid #dedfe1;
}

.widget_recent_comments li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_recent_comments li:before{
	font-family: FontAwesome;
	content: "\f040";
	font-size: 16px;
	line-height: 1;
	position: relative;
	top: 1px;
	padding-right: 10px;
	color: black;
}

.widget_recent_comments li:last-child{
	border-bottom: 1px solid #dedfe1;
}

.widget_recent_entries li{
	padding: 15px 0 13px 36px;
	border-bottom: 1px solid #dedfe1;
}

.widget_recent_entries p{
	margin-bottom: 0;
}

.widget_recent_entries li:first-child{
	padding-top: 0;
}

.widget_recent_entries li:before{
	font-family: FontAwesome;
	content: "\f040";
	font-size: 16px;
	line-height: 1;
	position: absolute;
	top: 18px;
	left: 0;
	padding-right: 10px;
	color: black;
}

.widget_recent_entries li:first-child:before{
	top: 5px;
}

.widget_recent_entries li:last-child{
	border-bottom: 0;
}

.widget_recent_entries.type-2 ul li{
	padding: 0 0 15px 0;
	border: 0;
}

.ds .widget_recent_entries.type-2 ul li a{
	color: #7f7f7f;
}

.widget_recent_entries.type-2 ul li:before{
	display: none;
}

.widget_recent_entries.type-2 ul li .post-date{
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.2em;
}

.widget_recent_entries > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_popular_entries > ul > li:first-child,
.widget_nav_menu > ul > li:first-child,
.widget_pages > ul > li:first-child{
	border-top: none;
	margin-top: 0;
}

.widget_popular_entries > ul > li:first-child{
	margin-top: 0;
}

.widget_archive a,
.widget_categories a,
.widget_meta a,
.widget_nav_menu a,
.widget_pages a{
	display: inline-block;
	padding: 9px 0 10px 15px;
	position: relative;
	color: #1f2732;
}

.ds .widget_archive a,
.ds .widget_categories a,
.ds .widget_meta a,
.ds .widget_nav_menu a,
.ds .widget_pages a{
	color: #a4a9b9;
}

.ds .widget_archive a:hover,
.ds .widget_categories a:hover,
.ds .widget_meta a:hover,
.ds .widget_nav_menu a:hover,
.ds .widget_pages a:hover{
	color: #ffffff;
}

.widget_meta > ul,
.widget_pages > ul,
.widget_archive > ul,
.widget_categories > ul{
	margin-top: -10px;
}

.widget_meta a + span,
.widget_pages a + span,
.widget_archive a + span,
.widget_categories a + span{
	float: right;
	font-size: 16px;
	margin-top: 10px;
}

.widget_nav_menu .menu-main-menu-container > ul{
	margin-top: -10px;
}

.widget_archive a:before,
.widget_categories a:before,
.widget_meta a:before,
.widget_nav_menu a:before,
.widget_pages a:before{
	font-family: FontAwesome;
	content: "\f111";
	font-size: 5px;
	color: black;
	position: absolute;
	left: 0;
	top: 10px;
}

.widget_archive .children a:before,
.widget_categories .children a:before,
.widget_meta .children a:before,
.widget_nav_menu .children a:before,
.widget_pages .children a:before{
	color: #1f2732;
}

.ds .widget_archive .children a:before,
.ds .widget_categories .children a:before,
.ds .widget_meta .children a:before,
.ds .widget_nav_menu .children a:before,
.ds .widget_pages .children a:before{
	color: #ffffff;
}

.widget_nav_menu a i{
	padding-right: 7px;
}

.widget_nav_menu ul.menu > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_nav_menu ul.menu > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_nav_menu ul.sub-menu > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_nav_menu ul.sub-menu > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_nav_menu ul.sub-menu > li:last-child{
	border-bottom: 0;
}

/* widgets */
.widget_meta > ul > li,
.widget_pages > ul > li,
.widget_archive > ul > li,
.widget_categories > ul > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_meta > ul > li:first-child,
.widget_pages > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_categories > ul > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_meta ul.children > li,
.widget_pages ul.children > li,
.widget_archive ul.children > li,
.widget_categories ul.children > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_meta ul.children > li:first-child,
.widget_pages ul.children > li:first-child,
.widget_archive ul.children > li:first-child,
.widget_categories ul.children > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_meta ul.children > li:last-child,
.widget_pages ul.children > li:last-child,
.widget_archive ul.children > li:last-child,
.widget_categories ul.children > li:last-child{
	border-bottom: 0;
}

.widget_meta .count.dark,
.widget_pages .count.dark,
.widget_archive .count.dark,
.widget_categories .count.dark{
	color: #1f2732;
}

.ds .widget_categories.special{
	background-color: #191f28;
}

.widget_categories.special{
	background-color: #f4f4f5;
	padding: 20px;
}

@media (min-width: 768px) {
	.widget_categories.special{
		padding: 35px 40px 40px 40px;
	}
}

.widget_categories.special .widget-title{
	margin-top: 0;
}

@media (min-width: 768px) {
	.page_copyright .widget_nav_menu li,
	.page_topline .widget_nav_menu li{
		display: inline-block;
		position: relative;
		border: none;
		padding-right: 5px;
	}

	.page_copyright .widget_nav_menu li:before,
	.page_topline .widget_nav_menu li:before{
		content: '';
		position: absolute;
		padding: 0;
		left: -5px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: rgba(164, 169, 185, 0.3);
		opacity: 0.3;
		display: block;
	}

	.page_copyright .widget_nav_menu li:first-child:before,
	.page_topline .widget_nav_menu li:first-child:before{
		display: none;
	}

	.page_copyright .widget_nav_menu a,
	.page_topline .widget_nav_menu a{
		padding: 0 10px;
	}

	.page_copyright .widget_nav_menu a:before,
	.page_topline .widget_nav_menu a:before{
		display: none;
	}
}

/* Footer Horizontal Menu */
.widget_nav_menu.horizontal-menu .menu{
	margin-top: 0 !important;
}

.widget_nav_menu.horizontal-menu li{
	border: 0 !important;
	display: inline-block;
}

.widget_nav_menu.horizontal-menu li a{
	color: #ffffff;
	padding: 9px 8px 10px 15px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.2em;
}

@media (min-width: 1200px) {
	.widget_nav_menu.horizontal-menu li a{
		padding: 9px 30px 10px 35px;
	}
}

.widget_nav_menu.horizontal-menu li a:hover{
	color: black;
}

.widget_nav_menu.horizontal-menu li a:before{
	color: #ffffff;
}

@media (max-width: 991px) {
	.widget_nav_menu.horizontal-menu li a:before{
		display: none;
	}
}

.widget_nav_menu.horizontal-menu li:first-child a:before{
	display: none;
}

@media (min-width: 1200px) {
	.widget_nav_menu.horizontal-menu li:first-child a{
		padding: 9px 30px 10px 0;
	}
}

/*widget_tag_cloud*/
.ds .widget_tag_cloud.special{
	background-color: #191f28;
}

.widget_tag_cloud.special{
	background-color: #f4f4f5;
	padding: 20px;
}

@media (min-width: 768px) {
	.widget_tag_cloud.special{
		padding: 35px 40px 40px 40px;
	}
}

.widget_tag_cloud.special .widget-title{
	margin-top: 0;
}

.widget_tag_cloud.special a{
	background-color: #ffffff;
	font-weight: bold;
}

.widget_tag_cloud .tagcloud{
	margin-top: -12px;
	margin-bottom: -12px;
}

.widget_tag_cloud a{
	background-color: #f4f4f5;
	border: 0;
	border-radius: 40px;
	color: #191f28;
	padding: 6px 20px 6px;
	margin: 0 4px 10px 0;
	font-size: 12px !important;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	text-align: left;
	font-weight: bold;
	display: inline-block;
}

.widget_tag_cloud a:hover{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

.ds .widget_tag_cloud a:hover{
	background-color: black;
	border-color: black;
	color: #ffffff;
}

/*widget_calendar*/
#calendar_wrap{
	width: 100%;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
	#calendar_wrap{
		overflow-x: auto;
		overflow-y: hidden;
	}
}

#today{
	color: black;
	font-weight: 500;
}

.widget_calendar table{
	width: 100%;
	margin: 0;
	position: relative;
	text-align: center;
	background-color: #ffffff;
}

.widget_calendar caption{
	line-height: 1;
	font-size: 24px;
	padding: 18px 0 18px;
	text-align: center;
	color: #ffffff;
	background-color: black;
}

.widget_calendar thead{
	background-color: #191f28;
	border: 1px solid #191f28;
	border-width: 7px 1px 7px 1px;
	color: #ffffff;
}

.widget_calendar thead th{
	font-weight: 300;
}

.widget_calendar thead th:first-child{
	padding-left: 30px;
}

.widget_calendar thead th:last-child{
	padding-right: 30px;
}

.widget_calendar tbody{
	background-color: #f4f4f5;
	font-weight: 300;
}

.widget_calendar tbody tr:first-child td{
	padding-top: 29px;
}

.widget_calendar tbody tr:last-child td{
	padding-bottom: 30px;
}

.widget_calendar tbody td{
	border: none;
	padding: 3px 4px;
	position: relative;
}

.widget_calendar tbody td:first-child{
	padding-left: 30px;
}

.widget_calendar tbody td:last-child{
	padding-right: 30px;
}

.widget_calendar tbody td a{
	font-weight: 700;
	color: black;
}

.widget_calendar tbody td a:hover{
	color: black;
}

.widget_calendar th{
	text-align: center;
	border: 0;
	color: inherit;
}

.widget_calendar td{
	border: 0;
}

.widget_calendar tfoot{
	position: absolute;
	top: -3px;
	right: 0;
	left: 0;
}

.widget_calendar tfoot td{
	position: absolute;
	top: 0;
}

.widget_calendar tfoot td a{
	color: #ffffff;
	text-align: center;
	font-size: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	display: inline-block;
}

.widget_calendar tfoot td a:hover{
	color: #ffffff;
	background-color: black;
	border-color: black;
}

.widget_calendar tfoot #prev{
	left: 0;
	padding: 0;
}

.widget_calendar tfoot #prev a{
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #prev a:before{
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c4';
}

.widget_calendar tfoot #next{
	right: 0;
	padding: 0;
}

.widget_calendar tfoot #next a{
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #next a:after{
	font-size: 14px;
	font-family: 'rt-icons-2';
	content: '\e7c5';
}

/*widget_banner*/
.widget_banner{
	overflow: hidden;
	line-height: 1;
}

.widget_banner .item-content{
	padding: 20px;
}

.widget_banner .item-content h3{
	margin: 0;
	padding: 8px 0;
	font-size: 28px;
}

.widget_banner .item-content span{
	display: inline-block;
	text-transform: uppercase;
	padding: 4px 10px 1px;
	min-width: 120px;
}

.widget_banner .small-text{
	margin-bottom: 0;
}

/* widget socials */
.widget_socials a.social-icon{
	margin-left: 10px !important;
}

.widget_socials a.social-icon:first-of-type{
	margin-left: 0 !important;
}

.widget_socials a.social-icon:before{
	top: 1px;
	position: relative;
}

/* widget socials 2 */
.widget_socials_2 ul.social-list li.list-item{
	padding-bottom: 5px;
	margin-bottom: 2px;
	position: relative;
	overflow: hidden;
}

.widget_socials_2 ul.social-list li.list-item:after{
	content: "";
	position: absolute;
	height: 1px;
	bottom: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.1);
	display: block;
	left: 57px;
}

.widget_socials_2 ul.social-list li.list-item:last-child:after{
	display: none;
}

.widget_socials_2 ul.social-list li.list-item a.icon-title{
	color: #7f7f7f;
	padding-left: 15px;
}

.widget_socials_2 ul.social-list li.list-item a.icon-title:hover{
	color: black;
}

/* widget icons list */
.page_footer .widget_icons_list{
	margin-bottom: 25px;
	line-height: 1;
}

.widget_icons_list .media-body,
.widget_icons_list .media-left{
	display: table-cell;
	vertical-align: top;
}

.widget_icons_list .media-left{
	padding-right: 10px;
}

.widget_icons_list .media-left i{
	display: inline-block;
	width: 14px;
	text-align: center;
	color: black;
}

.widget_icons_list .media.inline-block{
	width: 100%;
	margin-top: 0;
}

.widget_icons_list .media.inline-block:first-child{
	margin-top: 0;
}

.widget_icons_list .media.inline-block .media-body,
.widget_icons_list .media.inline-block .media-left{
	display: inline-block;
}

.widget_icons_list .media.inline-block .media-body i,
.widget_icons_list .media.inline-block .media-left i{
	line-height: 18px;
}

.widget_icons_list .media.inline-block .media-body{
	width: auto;
}

.widget_icons_list .media.inline-block .media-body a:hover{
	text-decoration: underline;
}

.ds .widget_icons_list .media.inline-block .media-body a{
	color: #ffffff;
}

.ds .widget_icons_list .media.inline-block .media-body{
	color: #ffffff;
}

.ds.light .widget_icons_list .media.inline-block .media-body{
	color: inherit;
}

.ds.light .widget_icons_list .media.inline-block .media-body a{
	color: black !important;
}

.ls .widget_icons_list .media.inline-block .media-body{
	color: #1f2732;
}

.widget_icons_list.type-2 .media.inline-block{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: start;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.ds .widget_icons_list.type-2 .media.inline-block .media-body{
	color: #7f7f7f;
}

.widget_icons_list.type-2 .media.inline-block .media-body a{
	color: black;
}

.page_footer .widget_icons_list i{
	color: black !important;
}

.page_footer .widget_icons_list a{
	color: #ffffff !important;
}

@media (max-width: 991px) {
	.page_footer .widget_icons_list .small-teaser{
		display: block;
		width: 100%;
		margin-bottom: 15px;
	}
}

/*widget_slider*/
.widget_slider .owl-carousel{
	margin-bottom: 0;
}

.widget_slider .owl-carousel:hover .owl-nav{
	top: -43px;
}

.widget_slider .owl-carousel .owl-nav{
	opacity: 1;
	top: -43px;
}

.widget_slider .owl-carousel .owl-nav > div{
	width: auto;
	height: auto;
	right: 0;
	background-color: transparent;
}

.widget_slider .owl-carousel .owl-nav > div:first-child{
	left: auto;
	right: 22px;
}

.widget_slider .owl-carousel .owl-nav > div:after{
	font-size: 12px;
	color: inherit;
	color: #191f28;
}

/*widget_flickr*/
.widget_flickr li{
	display: inline-block;
	margin: 0 10px 10px 0;
}

.widget_flickr img{
	max-width: 70px;
	border-radius: 1px;
}

/*widget_twitter*/
.widget_dotdigital_twitter{
	background-color: #f4f4f5;
	padding: 20px;
}

@media (min-width: 768px) {
	.widget_dotdigital_twitter{
		padding: 35px 40px 35px 40px;
	}
}

.widget_dotdigital_twitter .widget-title{
	margin-top: 0;
	margin-bottom: 12px;
}

.widget_dotdigital_twitter .tweet_avatar{
	display: none;
}

.widget_dotdigital_twitter .tweet_text{
	font-size: 16px;
	color: #7f7f7f;
}

.widget_dotdigital_twitter .tweet_time{
	display: block;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #1f2732;
}

.widget_dotdigital_twitter .tweet_right{
	overflow: hidden;
	position: relative;
	zoom: 1;
	padding-left: 25px;
}

.widget_dotdigital_twitter .tweet_right:before{
	font-family: FontAwesome;
	content: "\f099";
	font-size: 18px;
	color: #5daae4;
	position: absolute;
	top: 0;
	left: 0;
}

.widget_dotdigital_twitter .tweet_list li + li{
	margin-top: 10px;
	clear: both;
}

/* posts widget */
.widget-posts .item-media{
	max-height: 240px;
	overflow: hidden;
	display: block;
}

.widget-posts .item-content .categories-links a{
	margin-right: 0;
}

.widget-posts .item-content .item-title{
	display: block;
	height: 42px;
	font-weight: 700;
	overflow: hidden;
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 20px;
	text-transform: uppercase;
}

.widget-posts .item-content p{
	padding-top: 0;
	margin-bottom: 0;
	height: 120px;
	overflow: hidden;
}

/*widget_popular_entries*/
.widget_popular_entries.theme_posts{
	background-color: #f4f4f5;
	padding: 30px;
}

@media (min-width: 768px) {
	.widget_popular_entries.theme_posts{
		padding: 35px 40px 35px 40px;
	}
}

.page_footer .widget_popular_entries.theme_posts{
	padding: 0;
	background-color: transparent;
}

.widget_popular_entries.theme_posts .widget-title{
	margin-top: 0;
	margin-bottom: 22px;
}

.page_footer .widget_popular_entries.theme_posts .widget-title{
	margin-bottom: 30px;
}

.widget_popular_entries.theme_posts h4{
	font-size: 20px;
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 700;
	line-height: 1;
}

.widget_popular_entries.theme_posts .media-left,
.widget_popular_entries.theme_posts .media-right{
	vertical-align: middle;
}

.widget_popular_entries.theme_posts .media-body{
	overflow: visible;
	display: table-cell;
	vertical-align: middle;
}

.widget_popular_entries.theme_posts .media-body .title{
	font-size: 16px;
	text-transform: none;
	font-weight: 600;
	line-height: 19px;
	margin: 0 0 5px;
}

.widget_popular_entries.theme_posts .media-body .title a{
	color: #1f2732;
}

.ds .widget_popular_entries.theme_posts .media-body .title a{
	color: #7f7f7f;
}

.widget_popular_entries.theme_posts .media-body .title a:hover{
	color: black;
}

.page_footer .widget_popular_entries.theme_posts .media-body .title{
	font-weight: 300;
	line-height: 24px;
}

.widget_popular_entries.theme_posts .media-body p{
	line-height: 20px;
	margin: 0 0 5px;
	height: 60px;
	overflow: hidden;
}

.widget_popular_entries.theme_posts .media-body .item-meta{
	margin-bottom: 0;
	line-height: 1;
}

.widget_popular_entries.theme_posts .media-body .item-meta > div{
	display: inline-block;
	margin-right: 5px;
}

.widget_popular_entries.theme_posts .media-body .item-meta > div:last-child{
	margin-right: 0;
}

.widget_popular_entries.theme_posts .media-body .item-meta > div a{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	color: black;
}

.widget_popular_entries.theme_posts .media-left{
	padding-right: 20px;
}

.widget_popular_entries.theme_posts a img,
.widget_popular_entries.theme_posts .media-object{
	max-width: 100px;
	border: 0;
	border-radius: 0;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.widget_popular_entries.theme_posts .media-object{
		max-width: 40px;
	}
}

.widget_popular_entries.theme_posts li{
	margin-top: 0;
	padding: 0 0 20px 0;
}

.page_footer .widget_popular_entries.theme_posts li{
	padding: 0 0 30px 0;
}

.widget_popular_entries.theme_posts li:last-child{
	padding-bottom: 0;
}

aside .widget_popular_entries .widget-title{
	margin-bottom: 25px;
}

/* widget_theme_post_tabs */
.widget_theme_post_tabs .nav-tabs > li > a{
	padding: 10px 15px;
}

.widget_theme_post_tabs .item-likes-word{
	display: none;
}

.widget_theme_post_tabs [class*='rt-icon']{
	line-height: 0;
}

.widget_theme_post_tabs .vertical-item{
	margin-bottom: 15px;
}

.widget_theme_post_tabs .pull-right{
	margin-left: 10px;
}

.widget_rss li{
	margin-bottom: 15px;
}

.widget_rss li .rsswidget{
	font-size: 1.1em;
}

.widget_rss .rss-date{
	display: block;
	font-weight: 700;
}

.widget_rss cite{
	display: block;
	text-align: right;
	font-weight: 700;
}

/*
**shortcodes
*/
/* Author Bio Shortcode */
.author-bio-shortcode{
	text-align: center;
}

.author-bio-shortcode .author_bio_shortcode_wrap{
	position: relative;
	max-width: 700px;
	margin: 0 auto;
}

.author-bio-shortcode .author_bio_shortcode_wrap .owl-carousel.owl-loaded{
	margin-bottom: 0;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav{
	width: 100%;
	position: absolute;
	top: 50%;
	margin-top: -20px;
	z-index: 4;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div{
	font-size: 0;
	color: black;
	position: absolute;
	width: 40px;
	opacity: 0.3;
	cursor: pointer;
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.ds .author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div{
	color: #ffffff;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div:hover{
	opacity: 1;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div:before{
	content: "";
	font-size: 30px;
	font-weight: 300;
	font-family: FontAwesome;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div.owl-prev{
	left: -50px;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div.owl-prev:before{
	content: "\f104";
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div.owl-next{
	left: auto;
	right: -50px;
}

.author-bio-shortcode .author_bio_shortcode_wrap .custom-nav > div.owl-next:before{
	content: "\f105";
}

.author-bio-shortcode .bio-text{
	color: #7f7f7f;
	font-size: 20px;
	line-height: 30px;
	font-weight: 200;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
}

.author-bio-shortcode .author-bio-word{
	position: absolute;
	top: -50%;
	font-size: 240px;
	text-transform: uppercase;
	font-weight: 900;
	color: black;
	text-align: left;
	opacity: 0.05;
}

@media (max-width: 1500px) {
	.author-bio-shortcode .author-bio-word{
		font-size: 180px;
	}
}

@media (max-width: 1200px) {
	.author-bio-shortcode .author-bio-word{
		font-size: 100px;
	}
}

@media (max-width: 767px) {
	.author-bio-shortcode .author-bio-word{
		font-size: 60px;
	}
}

@media (max-width: 575px) {
	.author-bio-shortcode .author-bio-word{
		display: none;
	}
}

.author-bio-shortcode .owl-carousel-slider{
	-webkit-transition: left 0.5s ease;
	transition: left 0.5s ease;
	position: relative;
	margin-top: 60px;
	-webkit-transform: translateX(50%);
	transform: translateX(50%);
	height: 4px;
	background-color: rgba(244, 244, 245, 0.1);
}

.author-bio-shortcode .ui-slider-handle{
	outline: none;
	background: transparent !important;
}

.author-bio-shortcode .ui-slider-range{
	height: 4px;
	-webkit-transition: width 0.5s ease;
	transition: width 0.5s ease;
	background-color: #f4f4f5;
	position: absolute;
	top: 0;
}

.author-bio-shortcode .author-bio-flex{
	position: relative;
	z-index: 2;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.author-bio-shortcode .author-bio-flex:first-child{
	margin-left: -15px;
	margin-right: -15px;
}

.author-bio-shortcode .author-bio-flex:first-child span{
	-webkit-transition: background-color 0.5s ease;
	transition: background-color 0.5s ease;
	margin-top: -8px;
	position: relative;
	cursor: pointer;
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: rgba(244, 244, 245, 0.1);
	z-index: 2;
}

.author-bio-shortcode .author-bio-flex:first-child span:after{
	content: "";
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	z-index: 3;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	border: 0;
	border-radius: 50%;
	background: black;
}

.author-bio-shortcode .author-bio-flex:first-child + div{
	margin-left: -1em;
	margin-right: -1em;
	position: relative;
	top: -60px;
}

.author-bio-shortcode .author-bio-flex .year-label{
	-webkit-transition: color 0.5s ease;
	transition: color 0.5s ease;
	color: #7f7f7f;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2em;
}

.ds .author-bio-shortcode .author-bio-flex .year-label{
	color: #ffffff;
}

/* media component with icon */
.small-teaser{
	line-height: 22px;
}

.small-teaser i{
	line-height: inherit;
}

/*teasers*/
.teaser{
	position: relative;
	overflow: visible;
}

.teaser.color_1 a.title-link:hover{
	color: black;
}

.teaser.color_2 a.title-link:hover{
	color: #a0ce4e;
}

.teaser.color_3 a.title-link:hover{
	color: #00bea3;
}

.teaser.color_4 a.title-link:hover{
	color: #f1894c;
}

.teaser.with_border h4{
	font-weight: 500;
}

.teaser[class*="bg_color"],
.teaser[class*="with_"]{
	padding: 30px 20px;
}

@media (min-width: 399px) {
	.teaser[class*="bg_color"],
	.teaser[class*="with_"]{
		padding: 50px 30px 50px;
	}
}

.teaser [class*="bg_color"]{
	width: 2.5em;
	height: 2.5em;
	text-align: center;
	vertical-align: bottom;
	padding: 4px;
}

.teaser [class*="bg_color"] .teaser_icon_wrap{
	width: 100%;
	height: 100%;
	border: 4px solid #ffffff;
	border-radius: 50%;
}

.teaser [class*="bg_color"] + h4{
	margin-top: 37px;
}

.teaser [class*="bg_color"] i{
	line-height: 2.2em;
}

.teaser [class*="bg_color"].size_normal{
	padding: 3px;
}

.teaser [class*="bg_color"].size_normal .teaser_icon_wrap{
	border: 3px solid #ffffff;
}

.teaser [class*="bg_color"].size_normal i{
	line-height: 1.95em;
}

.teaser [class*="bg_color"].size_small{
	padding: 1px;
}

.teaser [class*="bg_color"].size_small .teaser_icon_wrap{
	border: 1px solid #ffffff;
}

.teaser [class*="bg_color"].size_small i{
	line-height: 2.1em;
}

.teaser .media-top{
	line-height: 1;
}

.teaser .border_icon{
	width: 2.5em;
	height: 2.5em;
	text-align: center;
	vertical-align: bottom;
	padding: 4px;
	border-width: 4px;
	border-style: solid;
}

.teaser .border_icon .teaser_icon_wrap{
	background-color: #ffffff;
	border: 0;
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.teaser .border_icon i{
	line-height: 2.2em;
}

.teaser .border_icon.big{
	width: 4.29em;
	height: 4.29em;
}

.teaser .border_icon.big i{
	line-height: 4.29em;
}

.teaser i{
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.teaser h2{
	margin-bottom: 33px;
	position: relative;
	padding-bottom: 10px;
}

.teaser h2:after{
	content: '';
	position: absolute;
	width: 50px;
	margin-left: -25px;
	height: 1px;
	background-color: rgba(204, 204, 204, 0.2);
	bottom: -12px;
	left: 50%;
}

.teaser h3{
	display: block;
}

.teaser h4{
	line-height: 1.2;
}

.teaser h5{
	line-height: 1.2;
	margin-top: 23px;
	margin-bottom: 20px;
}

.teaser p{
	margin-bottom: 0;
}

.teaser p strong{
	font-weight: 500;
	color: #191f28;
}

.ls .teaser p a{
	color: #7f7f7f;
}

.ls .teaser p a:hover{
	color: black;
}

.teaser.media{
	display: -webkit-box;
	display: flex;
}

.teaser .media-left{
	padding-right: 20px;
	flex-shrink: 0;
}

.teaser .media-right{
	padding-left: 20px;
	flex-shrink: 0;
}

.teaser .media-body .media-heading{
	margin-bottom: 15px;
}

.teaser .media-body h3{
	margin-bottom: 20px;
}

.teaser .media-body h4{
	margin-top: 0.05em;
	margin-bottom: 0.6em;
}

.teaser .media-body h5{
	margin-top: 0.02em;
	margin-bottom: 0.8em;
}

@media (max-width: 500px) {
	.teaser h2,
	.teaser h3,
	.teaser h4{
		font-size: 1.4em;
	}

	.teaser h2 span,
	.teaser h3 span,
	.teaser h4 span{
		font-size: 1.2em;
	}
}

.teaser [class*="count"]{
	font-size: 40px;
	font-weight: 700;
	margin: 17px 0 6px;
}

.teaser [class*="count"] + p{
	margin: 0;
}

.teaser .theme_button{
	margin: 22px 0 0;
}

.section_padding_bottom_0 .teaser{
	margin-bottom: 0;
}

.teaser.color_1 h4 a:hover{
	color: black;
}

.teaser.color_2 h4 a:hover{
	color: #a0ce4e;
}

.teaser.color_3 h4 a:hover{
	color: #00bea3;
}

.teaser.color_4 h4 a:hover{
	color: #f1894c;
}

.teaser.color_dark h4 a:hover{
	color: #191f28;
}

.teaser.color_light h4 a:hover{
	color: #ffffff;
}

.teaser .default_icon.color_1{
	color: black;
}

.teaser .default_icon.color_2{
	color: #a0ce4e;
}

.teaser .default_icon.color_3{
	color: #00bea3;
}

.teaser .default_icon.color_4{
	color: #f1894c;
}

.teaser .default_icon.color_dark{
	color: #191f28;
}

.teaser .default_icon.color_light{
	color: #ffffff;
}

.teaser .border_icon.color_1{
	color: black;
	border-color: #ffffff;
}

.teaser .border_icon.color_2{
	color: #a0ce4e;
	border-color: #ffffff;
}

.teaser .border_icon.color_3{
	color: #00bea3;
	border-color: #ffffff;
}

.teaser .border_icon.color_4{
	color: #f1894c;
	border-color: #ffffff;
}

.teaser .border_icon.color_dark{
	color: #191f28;
	border-color: #191f28;
}

.teaser .border_icon.color_light{
	color: #ffffff;
	border-color: #ffffff;
}

.teaser .bg_color.color_1{
	color: #ffffff;
	background-color: black;
}

.teaser .bg_color.color_2{
	color: #ffffff;
	background-color: #a0ce4e;
}

.teaser .bg_color.color_3{
	color: #ffffff;
	background-color: #00bea3;
}

.teaser .bg_color.color_4{
	color: #ffffff;
	background-color: #f1894c;
}

.teaser .bg_color.color_dark{
	color: #ffffff;
	background-color: #191f28;
}

.teaser .bg_color.color_light{
	color: #191f28;
	background-color: #ffffff;
}

.teaser.icon_top.with_background .bg_color,
.teaser.icon_top.with_border .bg_color{
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.teaser.icon_top.with_background .bg_color.size_small,
.teaser.icon_top.with_border .bg_color.size_small{
	margin-top: -52px;
	font-size: 14px;
	width: 40px;
	height: 40px;
}

.teaser.icon_top.with_background .bg_color.size_small i,
.teaser.icon_top.with_border .bg_color.size_small i{
	line-height: 40px;
}

.teaser.icon_top.with_background .bg_color.size_normal,
.teaser.icon_top.with_border .bg_color.size_normal{
	margin-top: -62px;
	font-size: 28px;
	width: 60px;
	height: 60px;
}

.teaser.icon_top.with_background .bg_color.size_normal i,
.teaser.icon_top.with_border .bg_color.size_normal i{
	line-height: 60px;
}

.teaser.icon_top.with_background .bg_color.size_big,
.teaser.icon_top.with_border .bg_color.size_big{
	margin-top: -72px;
	font-size: 35px;
	width: 80px;
	height: 80px;
}

.teaser.icon_top.with_background .bg_color.size_big i,
.teaser.icon_top.with_border .bg_color.size_big i{
	line-height: 80px;
}

.teaser.icon_top .media-heading{
	margin-top: 1.2em;
	margin-bottom: 0.7em;
}

.teaser.icon_top h4{
	margin-top: 0.7em;
	margin-bottom: 0.7em;
}

.teaser_icon{
	display: inline-block;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.teaser_icon + p{
	margin-top: 27px;
}

.teaser:hover .teaser_icon{
	-webkit-animation: scaleDisappear 0.5s;
	animation: scaleDisappear 0.5s;
}

@media (min-width: 1200px) {
	.borders-angle{
		position: relative;
	}

	.borders-angle:before,
	.borders-angle:after{
		content: '';
		position: absolute;
		width: 1px;
		top: -30px;
		bottom: 28px;
		left: -1px;
		background-color: rgba(25, 31, 40, 0.1);
		-webkit-transform: rotate(15deg);
		transform: rotate(15deg);
	}

	.borders-angle:after{
		left: auto;
		right: -5px;
	}
}

.size_big{
	font-size: 65px;
	line-height: 50px;
}

.size_normal{
	font-size: 32px;
}

.size_small{
	font-size: 16px;
}

/*horizontal teaser*/
.teaser.table_section{
	margin-top: 26px;
}

.teaser.table_section + .teaser.table_section{
	margin-top: 70px;
}

@media (min-width: 768px) {
	.teaser.table_section [class*="col-"] > *{
		margin: 0;
		top: 0;
	}
}

@media (max-width: 767px) {
	.teaser.table_section [class*="col-"] *{
		text-align: center;
	}
}

.square_teaser .item-media:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(31, 39, 50, 0.8);
}

.square_teaser .item-content{
	top: 0;
	margin: 30px 0 80px;
	overflow: hidden;
}

.square_teaser .teaser_icon{
	color: black;
	position: absolute;
	bottom: 30px;
	left: 30px;
}

.square_teaser .item-media:after,
.square_teaser .teaser_icon,
.square_teaser h4,
.square_teaser p{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.square_teaser h4{
	color: #ffffff;
	font-size: 28px;
	font-weight: 700;
}

.square_teaser p{
	color: #ffffff;
	opacity: 0;
}

.square_teaser:hover h4,
.square_teaser:hover .teaser_icon{
	color: #1f2732;
}

.square_teaser:hover p{
	opacity: 1;
}

.square_teaser:hover .item-media:after{
	background-color: black;
}

.square_teaser.no-image .item-content{
	margin: 0;
}

.square_teaser.no-image .teaser_icon{
	position: static;
}

.square_teaser.no-image p{
	opacity: 1;
}

.inline-teasers-wrap{
	display: inline-block;
	margin-left: -15px;
	margin-right: -15px;
}

.inline-teasers-wrap > *{
	vertical-align: middle;
	margin: 10px 15px;
}

@media (min-width: 1200px) {
	.inline-teasers-wrap{
		margin-left: -25px;
		margin-right: -25px;
	}

	.inline-teasers-wrap > *{
		margin: 10px 25px;
	}
}

/* Teasers Carousel */
.teaser-carousel.owl-carousel .owl-stage-outer{
	overflow: visible;
}

.teaser-carousel .teaser.text-center .teaser_image{
	max-width: 180px;
	margin-right: auto;
	margin-left: auto;
	overflow: visible;
}

.teaser-carousel .teaser.text-center .teaser_image img{
	width: 270px;
	max-width: 270px;
}

.fw-column{
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

/* Signature */
.fw-theme-signature{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.fw-theme-signature .fw-signature-left-part .section_header{
	text-transform: none;
	margin-bottom: 0;
}

.fw-theme-signature .fw-signature-right-part{
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.fw-theme-signature.style2{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	align-items: center;
}

.fw-theme-signature.style2 .fw-signature-left-part{
	margin-right: 30px;
}

.fw-theme-signature.style2 .fw-signature-left-part .section_header{
	font-size: 16px;
	font-weight: 500;
}

.fw-theme-signature.style2 .fw-signature-left-part .subtitle{
	text-transform: none;
	font-weight: 300;
	font-size: 16px;
	color: #7f7f7f !important;
	letter-spacing: 0;
	margin-top: 10px;
}

.ds .fw-theme-signature.style2 .subtitle{
	color: #ffffff !important;
}

/* Simple Counter Shortcode */
.shortcode-simple-counter{
	position: relative;
}

.shortcode-simple-counter.text-center .counter_wrap{
	text-align: center;
}

.shortcode-simple-counter .counter_wrap{
	/*text-align: left;
	display: inline-block;
	width: 33.33%;
	margin-bottom: 40px;*/
  text-align: center;
  display: inline-block;
  width: 50%;
  margin-bottom: 40px;
  height: 200px;
}

@media (max-width: 480px) {
	.shortcode-simple-counter .counter_wrap{
		width: 100%;
		text-align: center;
	}
}

.shortcode-simple-counter .counter_wrap .counter_icon{
	display: block;
	width: auto;
	height: 64px;
	position: relative;
}

.shortcode-simple-counter .counter_wrap .counter_icon img{
	height: 64px;
}

.shortcode-simple-counter .counter_wrap .counter-size{
	color: #1f2732;
	font-size: 48px;
	font-weight: bold;
	display: inline-block;
	margin-bottom: 0;
	line-height: 1;
	margin-top: 16px;
}

.shortcode-simple-counter .counter_wrap .counter-text{
	color: #7f7f7f;
	margin-top: 8px;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	line-height: 1;
	margin-bottom: 0;
}

.shortcode-simple-counter.columns-md-4 .counter_wrap{
	text-align: center;
	padding: 0 15px;
	width: 49%;
}

@media (max-width: 480px) {
	.shortcode-simple-counter.columns-md-4 .counter_wrap{
		width: 100%;
	}
}

@media (min-width: 992px) {
	.shortcode-simple-counter.columns-md-4 .counter_wrap{
		width: 24.5%;
	}
}

/* portfolio-shortcode */
.portfolio-shortcode.owl-carousel .gallery-item{
	margin-bottom: 0 !important;
}

.portfolio-shortcode .gallery-item .media-wrap{
	padding: 35px 40px;
}

.portfolio-shortcode .gallery-item .media-links:before{
	background-color: rgba(255, 255, 255, 0.9);
}

.portfolio-shortcode .gallery-item.item-with-title{
	margin-bottom: 10px !important;
}

.portfolio-shortcode .gallery-item.item-with-title .media-links .links-wrap{
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin-top: 0;
}

.portfolio-shortcode .gallery-item.item-with-title .media-links .links-wrap a{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
}

.portfolio-shortcode .gallery-item.item-with-title .media-links .links-wrap a:before,
.portfolio-shortcode .gallery-item.item-with-title .media-links .links-wrap a.p-link{
	display: none;
}

.portfolio-shortcode .gallery-item.item-with-title .item-content{
	position: absolute;
	bottom: 0;
	padding: 10px 20px 20px;
	width: 100%;
	z-index: 2;
}

@media (min-width: 400px) {
	.portfolio-shortcode .gallery-item.item-with-title .item-content{
		padding: 10px 30px 30px;
	}
}

.portfolio-shortcode .gallery-item.item-with-title .item-content:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.6)));
	background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
	background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
}

.portfolio-shortcode .gallery-item.item-with-title .item-content .categories-links{
	display: none;
}

.portfolio-shortcode .gallery-item.item-with-title .item-content .item-title{
	z-index: 4;
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
}

.portfolio-shortcode .gallery-item.item-with-title .item-content .item-title a{
	color: #ffffff;
}

.portfolio-shortcode .gallery-item.item-with-title .item-content .item-title a:hover{
	color: black;
}

.testimonials.owl-theme .owl-stage-outer{
	overflow: visible;
}

.testimonials.owl-theme .owl-nav,
.testimonials.owl-theme:hover .owl-nav{
	top: 60%;
	opacity: 1;
}

@media (max-width: 479px) {
	.testimonials.owl-theme .owl-nav,
	.testimonials.owl-theme:hover .owl-nav{
		top: 15%;
	}
}

.testimonials blockquote{
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	border: 0;
	text-align: center;
	font-size: 16px;
	color: #7f7f7f;
}

.testimonials blockquote .avatar{
	margin-bottom: 20px;
}

.testimonials blockquote .avatar img{
	min-width: 90px;
	max-width: 90px;
	border-radius: 50%;
	border: 5px solid #dedfe1;
}

.testimonials blockquote .avatar:after{
	content: '”';
	position: absolute;
	display: block;
	margin-top: 0;
	top: 88px;
	right: 30px;
	font-family: "Vidaloka", sans-serif;
	font-style: normal;
	color: black;
	font-size: 100px;
}

.testimonials blockquote .author-name{
	font-size: 24px;
	font-weight: 500;
	text-transform: none;
	margin: 0 0 12px;
}

.testimonials blockquote .author-job{
	color: black;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.2em;
}

.testimonials blockquote .blockqoute-meta{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
}

.testimonials blockquote.blockquote-big{
	padding: 0 15px;
}

.cs .testimonials .blockqoute-content{
	color: #ffffff;
}

@media (min-width: 992px) {
	.testimonials .blockqoute-content{
		max-width: 720px;
		margin: 0 auto;
	}
}

.testimonials.owl-theme .owl-dots{
	line-height: 1;
	margin-top: 5px;
}

/* testimonials carousel 2 */
.testimonials-carousel-2.owl-theme .owl-dots{
	margin-top: 30px;
}

.testimonials-carousel-2.testimonials blockquote{
	position: relative;
}

.testimonials-carousel-2.testimonials blockquote .author-job{
	margin-top: 0;
	margin-bottom: 15px;
}

.testimonials-carousel-2.testimonials blockquote .author-name{
	width: 100%;
	position: relative;
	padding: 0 40px;
	margin-bottom: 30px;
}

.testimonials-carousel-2.testimonials blockquote .author-name span{
	font-weight: 100;
}

.testimonials-carousel-2.testimonials blockquote .author-name:before,
.testimonials-carousel-2.testimonials blockquote .author-name:after{
	content: '”';
	position: absolute;
	display: block;
	margin-top: 0;
	top: 0;
	right: 0;
	font-family: "Vidaloka", sans-serif;
	font-style: normal;
	color: #1f2732;
	font-size: 100px;
	opacity: 0.1;
	height: 28px;
	line-height: 0.8;
}

.cs .testimonials-carousel-2.testimonials blockquote .author-name:before,
.ds .testimonials-carousel-2.testimonials blockquote .author-name:before,
.cs .testimonials-carousel-2.testimonials blockquote .author-name:after,
.ds .testimonials-carousel-2.testimonials blockquote .author-name:after{
	color: #ffffff;
}

.testimonials-carousel-2.testimonials blockquote .author-name:before{
	right: auto;
	left: 0; /* IE 9 */
	-webkit-transform: rotate(180deg); /* Safari */
	transform: rotate(180deg);
}

.testimonials-carousel-2.testimonials .blockqoute-content{
	font-size: 20px;
}

/* testimonials single carousel*/
.testimonials-single-carousel.testimonials.owl-theme .owl-stage-outer{
	overflow: visible;
}

.testimonials-single-carousel.testimonials.owl-theme .owl-nav,
.testimonials-single-carousel.testimonials.owl-theme:hover .owl-nav{
	top: 60%;
	opacity: 1;
}

@media (max-width: 479px) {
	.testimonials-single-carousel.testimonials.owl-theme .owl-nav,
	.testimonials-single-carousel.testimonials.owl-theme:hover .owl-nav{
		top: 15%;
	}
}

.testimonials-single-carousel.testimonials blockquote{
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	border: 0;
	text-align: center;
	font-size: 20px;
	color: #7f7f7f;
}

.testimonials-single-carousel.testimonials blockquote .avatar{
	margin-bottom: 20px;
}

.testimonials-single-carousel.testimonials blockquote .avatar img{
	min-width: 90px;
	max-width: 90px;
	border-radius: 50%;
	border: 5px solid #dedfe1;
}

.testimonials-single-carousel.testimonials blockquote .avatar:after{
	content: '”';
	position: absolute;
	display: block;
	margin-top: 0;
	top: 88px;
	right: 30px;
	font-family: "Vidaloka", sans-serif;
	font-style: normal;
	color: black;
	font-size: 100px;
}

.testimonials-single-carousel.testimonials blockquote .author-name{
	font-size: 30px;
	font-weight: 500;
	text-transform: none;
	margin: 0 0 25px;
}

.testimonials-single-carousel.testimonials blockquote .author-job{
	color: black;
	margin-top: 0;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.testimonials-single-carousel.testimonials blockquote .blockqoute-meta{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
}

.testimonials-single-carousel.testimonials blockquote.blockquote-big{
	padding: 0 15px;
}

.testimonials-single-carousel.testimonials.owl-theme blockquote .avatar{
	margin-bottom: 40px;
	width: 270px;
	height: 270px;
	border: 10px solid rgba(255, 255, 255, 0.1);
	border-radius: 50%;
}

.testimonials-single-carousel.testimonials.owl-theme blockquote .avatar img{
	width: 100%;
	max-width: 250px;
	overflow: hidden;
	border-radius: 50%;
	border: 0;
}

.testimonials-single-carousel.testimonials.owl-theme blockquote .avatar:after{
	display: none;
}

@media (max-width: 767px) {
	.testimonials-single-carousel.testimonials.owl-theme blockquote .avatar{
		width: 220px;
		height: 220px;
	}
}

.testimonials-single-carousel.testimonials.owl-theme .blockqoute-content{
	position: relative;
}

@media (min-width: 787px) {
	.testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:before,
	.testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:after{
		content: '”';
		position: absolute;
		display: block;
		margin-top: 0;
		top: 5px;
		right: -70px;
		font-family: "Vidaloka", sans-serif;
		font-style: normal;
		color: #1f2732;
		font-size: 100px;
		opacity: 0.1;
		height: 28px;
		line-height: 0.8;
	}

	.cs .testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:before,
	.ds .testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:before,
	.cs .testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:after,
	.ds .testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:after{
		color: #ffffff;
	}

	.testimonials-single-carousel.testimonials.owl-theme .blockqoute-content:before{
		right: auto;
		left: -70px; /* IE 9 */
		-webkit-transform: rotate(180deg); /* Safari */
		transform: rotate(180deg);
	}
}

.cs .testimonials-single-carousel.testimonials .blockqoute-content{
	color: #ffffff;
}

@media (min-width: 992px) {
	.testimonials-single-carousel.testimonials .blockqoute-content{
		max-width: 770px;
		margin: 0 auto;
	}
}

.testimonials-single-carousel.testimonials.owl-theme .owl-dots{
	line-height: 1;
	margin-top: 15px;
}

.testimonials-single-carousel_2.testimonials.owl-theme .owl-stage-outer{
	overflow: visible;
}

.testimonials-single-carousel_2.testimonials.owl-theme .owl-nav,
.testimonials-single-carousel_2.testimonials.owl-theme:hover .owl-nav{
	top: 60%;
	opacity: 1;
}

@media (max-width: 479px) {
	.testimonials-single-carousel_2.testimonials.owl-theme .owl-nav,
	.testimonials-single-carousel_2.testimonials.owl-theme:hover .owl-nav{
		top: 15%;
	}
}

.testimonials-single-carousel_2.testimonials blockquote{
	margin-top: 150px;
	margin-bottom: 0;
	padding: 0;
	border: 0;
	text-align: center;
	font-size: 20px;
	color: #7f7f7f;
	background-color: #363d47;
}

.testimonials-single-carousel_2.testimonials blockquote .avatar{
	position: relative;
	-webkit-transform: translateY(-150px);
	transform: translateY(-150px);
	margin-bottom: -110px;
}

.testimonials-single-carousel_2.testimonials blockquote .avatar img{
	min-width: 90px;
	max-width: 90px;
	border-radius: 50%;
	border: 5px solid #dedfe1;
}

.testimonials-single-carousel_2.testimonials blockquote .avatar:after{
	content: '”';
	position: absolute;
	display: block;
	margin-top: 0;
	top: 88px;
	right: 30px;
	font-family: "Vidaloka", sans-serif;
	font-style: normal;
	color: black;
	font-size: 100px;
}

.testimonials-single-carousel_2.testimonials blockquote .author-name{
	font-size: 30px;
	font-weight: 500;
	text-transform: none;
	margin: 0 0 25px;
}

.testimonials-single-carousel_2.testimonials blockquote .author-job{
	color: black;
	margin-top: 0;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.testimonials-single-carousel_2.testimonials blockquote .blockqoute-meta{
	display: block;
	margin-top: 0;
	margin-bottom: 0;
}

.testimonials-single-carousel_2.testimonials blockquote.blockquote-big{
	padding: 0 15px 100px 15px;
}

.testimonials-single-carousel_2.testimonials.owl-theme blockquote .avatar{
	width: 270px;
	border: 10px solid #5a5f66;
	border-radius: 50%;
}

.testimonials-single-carousel_2.testimonials.owl-theme blockquote .avatar img{
	min-width: 250px;
	max-width: 250px;
	border-radius: 50%;
	border: 0;
}

.testimonials-single-carousel_2.testimonials.owl-theme blockquote .avatar:after{
	display: none;
}

.cs .testimonials-single-carousel_2.testimonials .blockqoute-content{
	color: #ffffff;
}

@media (min-width: 992px) {
	.testimonials-single-carousel_2.testimonials .blockqoute-content{
		max-width: 770px;
		margin: 0 auto;
	}
}

.testimonials-single-carousel_2.testimonials.owl-theme .owl-dots{
	line-height: 1;
	position: absolute;
	bottom: 50px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

/* Testimonials grid 2 */
.testimonials-grid-2 .fw-testimonials-item blockquote{
	display: -webkit-box;
	display: flex;
	margin-bottom: 60px;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .left-part{
	margin-right: 15px;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .left-part .avatar{
	position: relative;
	width: 100px;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .left-part .avatar:after{
	display: none;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .right-part{
	text-align: left;
	margin-right: 15px;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .right-part .author-name{
	text-align: left;
	font-size: 16px;
	margin: 20px 0 0;
}

.testimonials-grid-2 .fw-testimonials-item blockquote .right-part .author-job{
	font-style: normal;
	display: block;
	margin-top: 0;
	line-height: 1;
}

/* Shortcode Tabs with Icons list */
.bootstrap-tabs.tabs-icons ul.nav-tabs.nav > li > a{
	padding: 11px 20px 11px;
	min-width: auto;
	border-radius: 30px;
	margin-bottom: 10px;
	display: inline-block;
	margin-right: 10px;
	background-color: transparent;
	color: #ffffff;
	border: 4px solid transparent;
}

.ls .bootstrap-tabs.tabs-icons ul.nav-tabs.nav > li > a{
	color: #1f2732;
}

.bootstrap-tabs.tabs-icons ul.nav-tabs.nav > li:last-child > a{
	margin-right: 0;
}

.bootstrap-tabs.tabs-icons.color_1 ul.nav-tabs.nav > li > a{
	border-color: black;
}

.bootstrap-tabs.tabs-icons.color_1 ul.nav-tabs.nav > li.active > a{
	background-color: black;
}

.bootstrap-tabs.tabs-icons.color_2 ul.nav-tabs.nav > li > a{
	border-color: #a0ce4e;
}

.bootstrap-tabs.tabs-icons.color_2 ul.nav-tabs.nav > li.active > a{
	background-color: #a0ce4e;
}

.bootstrap-tabs.tabs-icons.color_3 ul.nav-tabs.nav > li > a{
	border-color: #00bea3;
}

.bootstrap-tabs.tabs-icons.color_3 ul.nav-tabs.nav > li.active > a{
	background-color: #00bea3;
}

.bootstrap-tabs.tabs-icons.color_4 ul.nav-tabs.nav > li > a{
	border-color: #f1894c;
}

.bootstrap-tabs.tabs-icons.color_4 ul.nav-tabs.nav > li.active > a{
	background-color: #f1894c;
}

.bootstrap-tabs.tabs-icons .tab-content{
	margin: 0;
	padding: 20px 0 0 0;
}

.bootstrap-tabs.tabs-icons .tab-content .icons-list ul li .media-left i{
	color: #ffffff;
}

.ds .bootstrap-tabs.tabs-icons .tab-content .icons-list .media-body{
	color: #7f7f7f;
}

.ds .bootstrap-tabs.tabs-icons .tab-content .icons-list .media-body a{
	color: #7f7f7f;
}

.ds .bootstrap-tabs.tabs-icons .tab-content .icons-list .media-body a:hover{
	color: black;
}

/*thumbnail*/
.thumbnail{
	position: relative;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	background-color: #ffffff;
}

.isotope-item .thumbnail{
	margin-bottom: 30px;
}

.thumbnail h3{
	font-size: 20px;
	margin-bottom: 2px;
}

.thumbnail h3 a{
	color: inherit;
}

.thumbnail h3 + p{
	color: black;
	margin-bottom: 10px;
}

.thumbnail .caption{
	color: #7f7f7f;
	background-color: #ffffff;
	padding: 16px 20px 26px;
}

/*price tables*/
.fw-pricing .default-col,
.fw-pricing .highlight-col{
	margin-bottom: 50px;
}

.fw-pricing .default-col .fw-package,
.fw-pricing .highlight-col .fw-package{
	position: relative;
}

.fw-pricing .highlight-col .fw-package{
	background-color: #191f28;
}

.fw-pricing .fw-default-row{
	line-height: 29px;
}

.price-table,
.price-table ul{
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

.price-table{
	margin: 0;
}

/* price table with background */
.price-table{
	background-color: #f4f4f5;
}

.price-table .features-list li + li{
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.fw-pricing .plan-name{
	padding: 28px 0 28px;
	margin-bottom: 40px;
	display: block;
}

@media (min-width: 992px) {
	.fw-pricing .plan-name{
		margin-bottom: 60px;
	}
}

.fw-pricing .plan-name h3{
	text-transform: none;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin: 0;
	color: #ffffff;
}

.fw-pricing .color1 .plan-name{
	background-color: black;
}

.fw-pricing .color2 .plan-name{
	background-color: #a0ce4e;
}

.fw-pricing .color3 .plan-name{
	background-color: #00bea3;
}

.fw-pricing .color4 .plan-name{
	background-color: #f1894c;
}

.fw-pricing .plan-price{
	padding: 10px 0 42px;
	position: relative;
	color: #191f28; /* price */ /* per month */
}

.fw-pricing .plan-price span + span{
	font-size: 24px;
	font-weight: 500;
}

.fw-pricing .plan-price span + span strong{
	font-size: 54px;
	font-weight: 500;
	margin-left: 5px;
}

.fw-pricing .plan-price span + span span.color1{
	color: black;
}

.fw-pricing .plan-price span + span span.color2{
	color: #a0ce4e;
}

.fw-pricing .plan-price p{
	display: block;
	width: 100%;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-align: center;
	margin-top: 10px;
	line-height: 1;
}

.fw-pricing .highlight-col .fw-package .plan-price{
	color: #ffffff;
}

.fw-pricing .features-list{
	margin-top: 20px;
}

.fw-pricing .features-list li{
	margin: 10px 40px;
	padding-top: 10px;
}

.fw-pricing .features-list .enabled:before,
.fw-pricing .features-list .disabled:before{
	font-family: 'rt-icons-2';
	font-size: 24px;
	line-height: 1;
	position: relative;
	top: 5px;
	right: 4px;
	content: "\e116";
	padding-right: 8px;
	color: #1f2732;
}

.fw-pricing .features-list .disabled:before{
	content: "\e117";
}

.call-to-action{
	padding: 5px 40px 0;
}

@media (max-width: 340px) {
	.call-to-action{
		padding: 5px 30px 0;
	}
}

.call-to-action .theme_button{
	position: relative;
	top: 40px;
}

.call-to-action .theme_button.inverse{
	background-color: #f4f4f5;
}

.call-to-action .theme_button.color:hover,
.call-to-action .theme_button.color:focus{
	border-color: #a0ce4e;
	background-color: #a0ce4e;
}

.highlight-col .theme_button.inverse{
	background-color: #ffffff;
}

.price-table.style1{
	border: 1px solid rgba(164, 169, 185, 0.3);
}

.price-table.style1 .features-list li + li{
	border-top: 1px solid rgba(164, 169, 185, 0.3);
}

.price-table.style1 .plan-price{
	background-color: black;
	color: #ffffff;
	margin: 1px -1px;
}

.price-table.style2{
	background-color: #f4f4f5;
}

.price-table.style2 .plan-name{
	background-color: black;
	padding: 30px 0;
}

.price-table.style2 .plan-price{
	padding-bottom: 0;
	color: #1f2732;
}

.price-table.style2 .enabled:before,
.price-table.style2 .disabled:before{
	font-family: 'rt-icons-2';
	content: "\e621";
	padding-right: 13px;
	color: black;
}

.price-table.style2 .disabled:before{
	color: #1f2732;
	content: "\e622";
}

.fw-package.price-table hr{
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: rgba(164, 169, 185, 0.3);
}

@media (min-width: 1200px) {
	.fw-package.price-table hr{
		margin-left: 60px;
		margin-right: 60px;
	}
}

.fw-switch-row{
	display: inline-block;
	font-size: 24px;
	position: relative;
	top: 4px;
	margin-right: 4px;
}

.fw-switch-row + .fw-default-row{
	display: inline-block;
}

.desc-col .fw-default-row{
	margin: 20px 0;
}

@media (min-width: 992px) {
	.highlight-col .price-table .plan-name{
		padding: 28px 0 28px;
		margin-top: -30px;
	}

	.highlight-col .price-table .plan-price span:first-child:before{
		margin-top: -44px;
	}
}

.owl-carousel .pricing-plan{
	margin-top: 0;
	margin-bottom: 0;
}

.pricing-plan{
	text-align: center;
	padding: 50px 25px;
	position: relative;
	z-index: 1;
}

@media (max-width: 991px) and (min-width: 576px) {
	.pricing-plan{
		padding: 60px 50px;
	}
}

@media (min-width: 1200px) {
	.pricing-plan{
		padding: 160px 20px 60px;
	}

	.pricing-plan [class*='bg-']{
		margin-left: -61px;
		margin-right: -61px;
	}

	.pricing-plan .plan-name[class*='bg-']{
		margin-top: -57px;
	}
}

.pricing-plan:hover::after{
	opacity: 1;
}

.pricing-plan.pattern_1,
.pricing-plan.pattern_2,
.pricing-plan.pattern_3{
	position: relative;
}

.pricing-plan.pattern_1::after,
.pricing-plan.pattern_2::after,
.pricing-plan.pattern_3::after{
	position: absolute;
	content: '';
	display: inline-block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 1;
}

.pricing-plan.pattern_1::after{
	background-image: url("../img/patterns/price_pattern_1.png");
	background-position: top right;
}

.pricing-plan.pattern_2::after{
	background-image: url("../img/patterns/price_pattern_2.png");
	background-position: bottom left;
}

.pricing-plan.pattern_3::after{
	background-image: url("../img/patterns/price_pattern_3.png");
	background-position: top right;
}

.pricing-plan .price-wrap{
	font-family: 'Poppins', sans-serif;
	color: #1f2732;
}

.pricing-plan .price-wrap.underline{
	padding-bottom: 0;
}

.pricing-plan .price-wrap.underline::after{
	bottom: 5px;
	background-color: #ffffff;
}

.pricing-plan .price-wrap[class*='bg-']{
	padding: 30px 30px 10px;
	margin-top: 50px;
	margin-bottom: 0;
}

.pricing-plan [class*='bg-']{
	padding: 10px 30px 30px;
	margin-left: -41px;
	margin-right: -41px;
}

.pricing-plan .plan-name{
	margin-bottom: 0;
}

.pricing-plan .plan-name[class*='bg-']{
	padding-top: 30px;
	margin-top: -40px;
}

.pricing-plan .plan-name h3{
	font-size: 30px;
	font-weight: 500;
	letter-spacing: 0;
}

@media (max-width: 1199px) {
	.pricing-plan .plan-name h3{
		font-size: 20px;
	}
}

.pricing-plan .custom-wrap{
	font-weight: bold;
	line-height: 1;
	letter-spacing: -1px;
	margin-top: 20px;
	color: #1f2732;
}

.pricing-plan .custom-wrap .plan-value{
	font-size: 60px;
}

.pricing-plan .custom-wrap .plan-value-units{
	font-size: 37px;
}

.pricing-plan .plan-description{
	text-transform: uppercase;
	color: #1f2732;
	letter-spacing: 2px;
	font-weight: 700;
	margin-top: 9px;
	font-size: 12px;
	line-height: 1;
}

.pricing-plan hr{
	margin-top: 45px;
	margin-bottom: 45px;
}

.pricing-plan .plan-sign{
	font-weight: 500;
	font-size: 24px;
	position: relative;
	color: black;
}

.pricing-plan .plan-price{
	font-size: 54px;
	line-height: 1;
	position: relative;
	font-weight: 600;
	color: black;
}

.pricing-plan .plan-decimals{
	font-size: 24px;
	position: relative;
	font-weight: 400;
	color: #7f7f7f;
}

.pricing-plan .plan-features{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	text-align: left;
	margin-top: 42px;
	max-width: 200px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 1024px) {
	.pricing-plan .plan-features{
		margin-top: 25px;
	}
}

@media (max-width: 991px) and (min-width: 576px) {
	.pricing-plan .plan-features{
		margin-top: 42px;
	}
}

.pricing-plan .plan-features .list-unstyled{
	padding-left: 0;
	list-style: none;
	color: #7f7f7f;
	margin-bottom: 0;
}

.pricing-plan .plan-features .list-unstyled li{
	line-height: 23px;
}

.pricing-plan .plan-button{
	margin-top: 55px;
}

@media (max-width: 1199px) {
	.pricing-plan .plan-button{
		margin-top: 45px;
	}
}

.pricing-plan .plan-button .theme_button{
	min-width: auto;
}

@media (max-width: 1199px) {
	.pricing-plan .plan-button .theme_button{
		padding-left: 15px;
		padding-right: 15px;
	}
}

.pricing-plan.plan-featured{
	overflow: hidden;
	position: relative;
}

@media (min-width: 992px) {
	.pricing-plan.plan-featured{
		top: -30px;
	}
}

.pricing-plan.plan-featured.main::before{
	background-color: black;
}

.pricing-plan.plan-featured.main2::before{
	background-color: #a0ce4e;
}

.pricing-plan.plan-featured.main3::before{
	background-color: #00bea3;
}

.pricing-plan.plan-featured.main4::before{
	background-color: #f1894c;
}

.pricing-plan.cs .plan-description{
	color: #ffffff;
}

.pricing-plan.cs .list-styled,
.pricing-plan.cs .list-unstyled{
	color: #ffffff;
}

.pricing-plan.cs .list-styled li::before,
.pricing-plan.cs .list-unstyled li::before{
	color: #ffffff;
}

.pricing-plan.cs .special-heading .underline:after{
	background-color: #ffffff;
}

.pricing-plan.cs .plan-sign,
.pricing-plan.cs .plan-price{
	color: #ffffff;
}

.pricing-plan.cs .plan-decimals{
	color: #ffffff;
}

.pricing-plan.ds .plan-description{
	color: #ffffff;
}

.pricing-plan.ds .list-styled,
.pricing-plan.ds .list-unstyled{
	color: #ffffff;
}

.pricing-plan.ds .plan-decimals{
	color: #ffffff;
}

.ds .fw-pricing .plan-price{
	color: #ffffff;
}

/* dividers */
.fw-divider-short-line .divider_left{
	margin-left: 0;
	text-align: left;
}

.fw-divider-short-line .divider_right{
	margin-right: 0;
	text-align: right;
}

.fw-divider-short-line .short_line{
	width: 50px;
	height: 3px;
	margin-top: 30px;
	margin-bottom: 40px;
	background-color: black;
}

/* notification */
.shortcode-notification.alert{
	color: #ffffff;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 26px;
	font-weight: bold;
	letter-spacing: 0.2em;
	border: 0;
	border-radius: 0;
	padding: 12px 15px 12px 40px;
	margin-bottom: 20px;
	position: relative;
}

.shortcode-notification.alert:before{
	content: "";
	font-family: FontAwesome;
	position: relative;
	top: 0;
	left: -20px;
	font-size: 14px;
}

@media (min-width: 1200px) {
	.shortcode-notification.alert{
		padding: 16px 15px 16px 40px;
	}

	.shortcode-notification.alert:before{
		left: -10px;
	}
}

.shortcode-notification.alert-success{
	background-color: #a0ce4e;
}

.shortcode-notification.alert-success:before{
	content: "\f058";
}

.shortcode-notification.alert-info{
	background-color: #009cbe;
}

.shortcode-notification.alert-info:before{
	content: "\f06a";
}

.shortcode-notification.alert-warning{
	background-color: #f1b94c;
}

.shortcode-notification.alert-warning:before{
	content: "\f071";
}

.shortcode-notification.alert-danger{
	background-color: #f14c4c;
}

.shortcode-notification.alert-danger:before{
	content: "\f057";
}

/* Call To Action */
.fw-theme-call-to-action{
	border: 0;
	padding: 0;
	display: block;
	margin-bottom: 0;
}

.fw-theme-call-to-action .section_header{
	margin-bottom: 20px;
}

.fw-theme-call-to-action .fw-action-wrap{
	display: inline-block;
	color: #7f7f7f;
}

.fw-theme-call-to-action .fw-action-wrap .fw-action-desc{
	margin-bottom: 20px;
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;
}

.fw-theme-call-to-action .fw-action-wrap .fw-action-desc strong{
	color: #1f2732;
	font-weight: 500;
}

@media (min-width: 768px) {
	.fw-theme-call-to-action .fw-action-wrap{
		display: -webkit-box;
		display: flex;
		justify-content: space-around;
	}

	.fw-theme-call-to-action .fw-action-wrap .fw-action-desc{
		margin-right: 35px;
	}

	.fw-theme-call-to-action .fw-action-wrap .fw-action-btn{
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.fw-theme-call-to-action .fw-action-wrap .fw-action-desc{
		margin-right: 90px;
	}
}

.ds .fw-action-wrap{
	color: #a4a9b9;
}

.ds .fw-action-wrap .fw-action-desc span{
	color: #ffffff;
}

/* icon */
.shortcode-icon{
	overflow: visible;
	margin-top: 0;
}

.shortcode-icon.text-right .media-body{
	text-align: right;
}

.shortcode-icon .media-body,
.shortcode-icon .media-left,
.shortcode-icon .media-right{
	display: table-cell;
	vertical-align: middle;
}

.shortcode-icon .media-left{
	padding-right: 20px;
}

.page_topline .shortcode-icon .media-left{
	padding-right: 10px;
}

.shortcode-icon .media-right{
	padding-left: 20px;
}

.page_topline .shortcode-icon .media-right{
	padding-left: 10px;
}

.shortcode-icon .media-body{
	width: auto;
}

.shortcode-icon .media-body .title{
	font-size: 16px;
	text-transform: none;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0;
}

.cs .shortcode-icon .media-body .title,
.ds .shortcode-icon .media-body .title,
.page_topline .shortcode-icon .media-body .title{
	color: #7f7f7f;
	font-weight: 300;
	font-size: 14px;
}

.shortcode-icon .media-body .text{
	font-size: 14px;
	color: #1f2732;
	font-weight: 300;
	line-height: 1;
}

.cs .shortcode-icon .media-body .text{
	color: #ffffff;
}

.ds .shortcode-icon .media-body .text,
.page_toplogo .shortcode-icon .media-body .text{
	color: #7f7f7f;
}

.shortcode-icon .icon-wrap.size_big{
	width: 80px;
	height: 80px;
}

.shortcode-icon .icon-wrap.size_big i{
	line-height: 80px;
	display: block;
	margin: 0;
	font-size: 48px;
}

.shortcode-icon .icon-wrap.size_normal{
	width: 50px;
	height: 50px;
}

.shortcode-icon .icon-wrap.size_normal i{
	line-height: 47px;
	display: block;
	margin: 0;
	font-size: 20px;
}

.shortcode-icon .icon-wrap.size_small{
	width: 60px;
	height: 60px;
}

.shortcode-icon .icon-wrap.size_small i{
	line-height: 60px;
	display: block;
	margin: 0;
	font-size: 1em;
}

.shortcode-icon .icon-wrap.default_icon.color_1{
	color: black;
}

.shortcode-icon .icon-wrap.default_icon.color_2{
	color: #a0ce4e;
}

.shortcode-icon .icon-wrap.default_icon.color_dark{
	color: #191f28;
}

.shortcode-icon .icon-wrap.default_icon.color_light{
	color: #ffffff;
}

.shortcode-icon .icon-wrap.border_icon{
	border-width: 1px;
	border-style: solid;
	margin-right: 0;
}

.shortcode-icon .icon-wrap.border_icon i{
	display: inline-block;
	width: 19px;
}

.page_toplogo .shortcode-icon .icon-wrap.border_icon{
	border-width: 2px;
	border-color: #ffffff !important;
}

.shortcode-icon .icon-wrap.border_icon.color_1{
	color: black;
	border-color: rgba(31, 39, 50, 0.1);
}

.shortcode-icon .icon-wrap.border_icon.color_2{
	color: #a0ce4e;
	border-color: rgba(31, 39, 50, 0.1);
}

.shortcode-icon .icon-wrap.border_icon.color_dark{
	color: #191f28;
	border-color: rgba(31, 39, 50, 0.1);
}

.shortcode-icon .icon-wrap.border_icon.color_light{
	color: #ffffff;
	border-color: #ffffff;
	border-width: 2px;
}

.shortcode-icon .icon-wrap.bg_color{
	margin-right: 0;
	border: 0;
	border-radius: 3px;
}

.shortcode-icon .icon-wrap.bg_color i{
	margin: 0 auto;
	min-width: 21px;
}

.shortcode-icon .icon-wrap.bg_color.color_1{
	color: black;
	background-color: #f4f4f5;
}

.shortcode-icon .icon-wrap.bg_color.color_2{
	color: #a0ce4e;
	background-color: #f4f4f5;
}

.shortcode-icon .icon-wrap.bg_color.color_light{
	color: #ffffff;
	background-color: #f4f4f5;
}

.shortcode-icon .icon-wrap.bg_color.color_dark{
	color: #191f28;
	background-color: #f4f4f5;
}

.shortcode-icon .icon-wrap.default_icon{
	width: auto;
	height: auto;
}

.shortcode-icon .icon-wrap.default_icon.size_big i,
.shortcode-icon .icon-wrap.default_icon.size_normal i,
.shortcode-icon .icon-wrap.default_icon.size_small i{
	line-height: 1.2;
}

.shortcode-icon.small-teaser .icon-wrap{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.shortcode-icon.small-teaser .icon-wrap:hover{
	-webkit-animation: scaleDisappear 0.5s;
	animation: scaleDisappear 0.5s;
}

.shortcode-icon.small-teaser .icon-wrap.size_big,
.shortcode-icon.small-teaser .icon-wrap.size_normal,
.shortcode-icon.small-teaser .icon-wrap.size_small{
	text-align: center;
}

/* Shortcode Video */
.video-shortcode{
	display: block; /* Responsive */
}

.video-shortcode.video-layout-2 .video-shortcode-wrapper .video-block .video_image_cover > a::after{
	background: transparent;
	color: #ffffff;
	border: 2px solid #ffffff;
}

.video-shortcode.video-layout-2 .video-shortcode-wrapper .video-block .video_image_cover > a:hover:after{
	background-color: #ffffff;
	color: black;
}

.video-shortcode .video-shortcode-wrapper .video-block .video_image_cover{
	background-size: cover;
	display: block;
	position: relative;
	z-index: 2;
	top: 0;
	bottom: 0;
	background-position: 50% 50%;
}

.video-shortcode .video-shortcode-wrapper .video-block .video_image_cover > img{
	visibility: hidden;
}

.video-shortcode .video-shortcode-wrapper .video-block .video_image_cover > a{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.video-shortcode .video-shortcode-wrapper .video-block .video_image_cover > a:after{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	content: "\f04b";
	font-family: 'FontAwesome';
	position: absolute;
	color: black;
	font-size: 18px;
	line-height: 80px;
	width: 80px;
	height: 80px;
	text-align: center;
	background-color: #ffffff;
	border-radius: 50%;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
}

.video-shortcode .video-shortcode-wrapper .video-block .video_image_cover > a:hover:after{
	background-color: black;
	color: #ffffff;
}

.video-shortcode .video-shortcode-wrapper .info-block{
	text-align: center;
}

@media (min-width: 768px) {
	.video-shortcode .video-shortcode-wrapper .info-block{
		text-align: left;
	}
}

@media (min-width: 768px) {
	.video-shortcode .video-shortcode-wrapper{
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.video-shortcode .video-shortcode-wrapper.right_position{
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}
}

@media (min-width: 1200px) {
	.video-shortcode .video-shortcode-wrapper{
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.video-shortcode .video-shortcode-wrapper .info-block{
		z-index: 3;
	}

	.video-shortcode .video-shortcode-wrapper .info-block .title{
		font-size: 54px;
		line-height: 1;
		margin-bottom: 40px;
	}

	.video-shortcode .video-shortcode-wrapper .info-block .desc{
		margin-bottom: 50px;
	}

	.video-shortcode .video-shortcode-wrapper.left_position .video-block .add-border{
		position: relative;
	}

	.video-shortcode .video-shortcode-wrapper.left_position .video-block .add-border:before{
		content: "";
		border: 10px solid rgba(31, 39, 50, 0.05);
		position: absolute;
		left: -30px;
		top: 30px;
		width: 100%;
		height: 100%;
		display: block;
	}

	.ds .video-shortcode .video-shortcode-wrapper.left_position .video-block .add-border:before{
		border: 10px solid rgba(255, 255, 255, 0.05);
	}

	.video-shortcode .video-shortcode-wrapper.left_position .info-block .title,
	.video-shortcode .video-shortcode-wrapper.left_position .info-block .readmore-btn{
		margin-left: -120px;
	}

	.video-shortcode .video-shortcode-wrapper.right_position .video-block .add-border{
		position: relative;
	}

	.video-shortcode .video-shortcode-wrapper.right_position .video-block .add-border:before{
		content: "";
		border: 10px solid rgba(31, 39, 50, 0.05);
		position: absolute;
		right: -30px;
		top: 30px;
		width: 100%;
		height: 100%;
		display: block;
	}

	.ds .video-shortcode .video-shortcode-wrapper.right_position .video-block .add-border:before{
		border: 10px solid rgba(255, 255, 255, 0.05);
	}

	.video-shortcode .video-shortcode-wrapper.right_position .info-block .title,
	.video-shortcode .video-shortcode-wrapper.right_position .info-block .readmore-btn{
		margin-right: -120px;
	}
}

/* Icon box shortcode */
.icon-box{
	display: -webkit-box;
	display: flex; /* Block Items styling */
}

@media (min-width: 1200px) {
	.icon-box.special-box.icon_top{
		text-align: left;
		-webkit-box-align: start;
		align-items: flex-start;
	}
}

.icon-box.special-box.icon_top img{
	height: 50px;
}

.icon-box.special-box.icon_top .box-heading{
	font-size: 20px;
}

.icon-box.special-box.icon_top .box_icon + .box-wrap{
	margin-top: 20px;
}

.icon-box.special-box.icon_top .box-content{
	color: #ffffff;
}

@media (max-width: 992px) {
	.icon-box.mobile-center{
		-webkit-box-align: center !important;
		align-items: center !important;
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		flex-direction: column !important;
		text-align: center !important;
	}

	.icon-box.mobile-center .box_icon + .box-wrap{
		margin-top: 15px !important;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.icon-box.mobile-center .box-content{
		max-width: 300px;
	}
}

.icon-box .box_icon{
	font-size: 25px;
}

.icon-box .box_icon img{
	max-width: 50px;
}

.icon-box.icon_top img{
	height: 60px;
	max-width: 100%;
}

.icon-box.type_2 .box_icon{
	border: 10px solid rgba(255, 255, 255, 0.1);
	background-clip: content-box;
	width: 120px;
	height: 120px;
	display: inline-block;
	max-width: 100%;
	border-radius: 50%;
	background-color: #fff;
	line-height: 90px;
}

.icon-box.type_2 .box_icon img{
	margin-top: 6px;
}

.icon-box.type_2 .box-heading{
	margin-top: 20px;
	margin-bottom: 17px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.2em;
}

.icon-box.type_3{
	padding: 25px 50px;
	box-shadow: 0 0 6px 1px rgba(31, 39, 50, 0.15);
}

@media (max-width: 575px) {
	.icon-box.type_3{
		padding: 30px;
	}
}

.icon-box.icon_top{
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	text-align: center;
}

.icon-box.icon_top .box_icon + .box-wrap{
	margin-top: 15px;
}

.icon-box.icon_left .box_icon + .box-wrap{
	margin-left: 20px;
}

.icon-box.icon_right{
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	flex-direction: row-reverse;
	text-align: right;
}

.icon-box.icon_right .box_icon + .box-wrap{
	margin-right: 20px;
}

.icon-box .box-heading{
	font-size: 23px;
	line-height: 30px;
	margin-bottom: 14px;
}

.cs .icon-box .box-heading{
	color: #191f28;
}

.ds .icon-box .box-content{
	color: #7f7f7f;
}

.cs .icon-box .box-content{
	color: #ffffff;
}

.icon-box.color-1 i{
	color: black;
}

.icon-box.color-2 i{
	color: #a0ce4e;
}

.icon-box.color-3 i{
	color: #00bea3;
}

.icon-box.block-item{
	padding: 60px 20px;
	background-color: transparent;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 0;
	position: relative;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.icon-box.block-item .box_icon{
	height: 60px;
	line-height: 1;
}

.icon-box.block-item i,
.icon-box.block-item i:before{
	font-size: 60px;
}

.icon-box.block-item .box_icon + .box-wrap{
	margin-top: 25px;
}

.icon-box.block-item .box_icon img{
	max-width: 60px;
}

.icon-box.block-item:hover{
	background: #ffffff !important;
}

.icon-box.block-item:hover .box_icon i,
.icon-box.block-item:hover .box-content{
	color: #7f7f7f !important;
}

.icon-box.block-item:hover .box-heading{
	color: #1f2732 !important;
}

.icon-box.block-item:hover .box_icon img{
	-webkit-filter: brightness(0.5) invert(1);
	filter: brightness(0.5) invert(1);
}

.icon-box.block-item .box_icon,
.icon-box.block-item .box-wrap{
	z-index: 2;
}

.icon-box.icon_top.big_icon img{
	height: 120px;
	max-width: 100%;
}

.icon-box.icon_top.big_icon .box-wrap{
	margin-top: 30px;
}

.icon-box.icon_top.big_icon .box-content{
	line-height: 24px;
}

.icon-box.icon_top.bigger_icon img{
	height: 190px;
	max-width: 100%;
}

.icon-box.icon_top.bigger_icon .box-wrap{
	margin-top: 15px;
}

.icon-box.icon_top.bigger_icon .box-content{
	line-height: 24px;
	max-width: 300px;
}

@media (min-width: 1200px) {
	.icon-box.icon_top.first_item .box_icon{
		position: relative;
	}

	.icon-box.icon_top.first_item .box_icon:before{
		content: '';
		background-image: url("../img/icon_first_line.png");
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		width: 110px;
		height: 25px;
		z-index: 4;
		top: 137px;
		left: -100px;
	}

	.icon-box.icon_top.center_item .box_icon{
		position: relative;
	}

	.icon-box.icon_top.center_item .box_icon:before{
		content: '';
		background-image: url("../img/icon_center_line_a.png");
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		width: 229px;
		height: 43px;
		z-index: 4;
		top: 30px;
		left: -220px;
	}

	.icon-box.icon_top.center_item .box_icon:after{
		content: '';
		background-image: url("../img/icon_center_line_b.png");
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		width: 228px;
		height: 43px;
		z-index: 4;
		top: 120px;
		right: -220px;
	}

	.icon-box.icon_top.last_item .box_icon{
		position: relative;
	}

	.icon-box.icon_top.last_item .box_icon:after{
		content: '';
		background-image: url("../img/icon_last_line.png");
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		width: 111px;
		height: 25px;
		z-index: 4;
		top: 30px;
		right: -97px;
	}
}

.ds .icon-box.block-item:hover{
	background-color: transparent !important;
}

/* Icon box 2 shortcode */
.icon-box-2{
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	text-align: center;
}

@media (min-width: 400px) {
	.icon-box-2.with_padding{
		padding: 60px;
	}
}

.icon-box-2 .box_icon{
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 25px;
	color: black;
}

.icon-box-2 .box_icon img{
	max-height: 50px;
}

.icon-box-2 .box_icon + .box-wrap{
	margin-top: 15px;
}

.icon-box-2 .box-heading{
	font-size: 23px;
	line-height: 30px;
	margin-bottom: 22px;
}

.cs .icon-box-2 .box-heading{
	color: #ffffff;
}

.icon-box-2 .box-content{
	margin-bottom: 30px;
}

.ds .icon-box-2 .box-content{
	color: #7f7f7f;
}

.cs .icon-box-2 .box-content{
	color: #ffffff;
}

.icon-box-2 .box-btn{
	margin-bottom: 20px;
}

/* icons list */
.icons-list .small-teaser{
	line-height: 21px;
}

.icons-list a{
	cursor: pointer;
}

.ls .icons-list a{
	color: #1f2732;
}

.ls .icons-list a:hover{
	color: black;
}

.icons-list ul{
	margin: 5px 0;
}

.icons-list ul li{
	border: 0;
	position: relative;
	padding: 7px 0 7px 0;
	line-height: 1;
}

.icons-list ul li:first-child{
	padding: 0 0 7px 0;
}

.icons-list ul li .media-left{
	padding-right: 10px;
	vertical-align: middle;
}

.icons-list ul li .media-left i{
	display: inline-block;
	width: 16px;
	font-size: 14px;
	text-align: center;
	vertical-align: middle;
	color: black;
}

.icons-list ul li .media-body{
	vertical-align: middle;
	width: auto;
}

.ds .icons-list .media-body{
	color: #a4a9b9;
}

/* info box */
.info-box .box-icon{
	margin-top: 25px;
	color: black;
	font-size: 60px;
}

.info-box .box-title{
	margin-top: 28px;
	margin-bottom: 35px;
}

.info-box .box-features{
	list-style: none;
	text-align: center;
	padding: 0 20px;
}

.info-box .box-features .feature{
	padding: 19px 0;
	border-top: 1px solid #dedfe1;
	line-height: 1.4;
}

/* shortcode-image-carousel */
.shortcode-image-carousel.owl-theme .owl-nav,
.shortcode-image-carousel.owl-theme:hover .owl-nav{
	top: 50%;
	opacity: 1;
}

.shortcode-image-carousel.owl-carousel .owl-nav > div{
	margin-top: -25px;
	background-color: transparent;
}

.shortcode-image-carousel.owl-carousel .owl-nav > div:after{
	color: #7f7f7f;
	font-size: 28px;
}

.shortcode-image-carousel.owl-carousel .owl-nav > div:hover:after{
	color: #ffffff;
}

.shortcode-image-carousel.owl-carousel .owl-nav > div.owl-prev{
	left: -50px;
}

.shortcode-image-carousel.owl-carousel .owl-nav > div.owl-next{
	right: -50px;
}

.shortcode-image-carousel.owl-carousel .owl-item{
	cursor: pointer;
}

.shortcode-image-carousel.owl-carousel .owl-item > *{
	text-align: center;
}

.shortcode-image-carousel.owl-carousel .owl-item img{
	width: auto;
	margin: 0 auto;
	-webkit-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	opacity: 0.2;
}

.shortcode-image-carousel.owl-carousel .owl-item img:hover{
	opacity: 1;
}

.shortcode-image-carousel.owl-carousel .owl-item.center img{
	opacity: 1;
}

.shortcode-image-carousel.opacity-none .owl-item img{
	opacity: 1;
}

.shortcode-image-carousel.opacity-none .owl-item img:hover{
	opacity: 0.5;
}

/* map shortcode */
.wrap-map{
	position: relative;
}

.wrap-map .fw-map-overlay{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background-color: rgba(31, 39, 50, 0.8);
}

.wrap-map .fw-map-overlay .fw-map-info-box{
	background-color: #ffffff;
	width: 430px;
	min-height: 250px;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	padding: 50px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

@media (max-width: 480px) {
	.wrap-map .fw-map-overlay .fw-map-info-box{
		width: 280px;
		padding: 50px 15px;
	}
}

.wrap-map .fw-map-overlay .fw-map-info-box .close-box{
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	display: block;
	width: 50px;
	height: 50px;
	line-height: 50px;
}

.wrap-map .fw-map-overlay .fw-map-info-box .close-box i{
	color: #1f2732;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.wrap-map .fw-map-overlay .fw-map-info-box .close-box:hover i{
	color: black;
}

.wrap-map .fw-map-overlay .fw-map-info-box .content{
	line-height: 2.2;
}

.wrap-map .fw-map-overlay .fw-map-info-box .content i{
	color: black;
	margin-right: 5px;
}

.wrap-map .fw-map-overlay .fw-map-info-box .content a{
	color: #1f2732;
}

.wrap-map .fw-map-overlay .fw-map-info-box .content a:hover{
	color: black;
}

.gm-style .gm-style-iw{
	overflow: visible !important;
	text-align: center;
	line-height: 1.6em;
}

.gm-style .gm-style-iw > *:first-child{
	position: relative;
}

.gm-style .gm-style-iw i{
	color: black;
	margin-right: 0;
}

.gm-style .gm-style-iw a{
	color: #1f2732 !important;
}

.gm-style .gm-style-iw a:hover{
	color: black !important;
}

.gm-style .gm-style-iw:before{
	content: '';
	display: block;
	position: absolute;
	border-top: 2px solid black;
	top: -25px;
	left: -16px;
	right: -16px;
	bottom: -25px;
	background: #ffffff;
	box-shadow: none;
}

/* Open Hours */
.open-hours .small-teaser{
	line-height: 21px;
}

.open-hours ul{
	margin: 5px 0;
}

.open-hours ul li{
	border: 0;
	position: relative;
	padding: 7px 0 7px 0;
	line-height: 1;
}

.open-hours ul li:first-child{
	padding: 0 0 7px 0;
}

.open-hours ul li .media-left{
	padding-right: 10px;
	vertical-align: middle;
}

.open-hours ul li .media-left i{
	display: inline-block;
	width: 16px;
	font-size: 14px;
	text-align: center;
	vertical-align: middle;
	color: black;
}

.open-hours ul li .media-body{
	vertical-align: middle;
	width: 100%;
}

.open-hours ul li .media-body .day,
.open-hours ul li .media-body .hours{
	width: 45%;
	display: inline-block;
}

.ls .open-hours ul li .media-body .hours{
	color: #1f2732;
}

.ds .icons-list .media-body{
	color: #a4a9b9;
}

/* posts shortcode */
.ds .shortcode-posts.tiled-layout .media-links:before{
	background-color: rgba(31, 39, 50, 0.8);
}

.ds .shortcode-posts.tiled-layout .item-content{
	background-color: #363d47;
}

.ds .shortcode-posts.tiled-layout .item-content .item-title{
	margin-top: 0;
}

.ds .shortcode-posts.tiled-layout .post-date a{
	color: #ffffff;
}

.ds .shortcode-posts.tiled-layout .post-date a:hover{
	color: #ffffff;
}

@media (min-width: 400px) {
	.ds .shortcode-posts.tiled-layout .smallitem .side-item .item-content{
		padding: 30px;
	}
}

.shortcode-posts{ /* Tiled special layout */ /* Side item*/
}

@media (min-width: 1200px) {
	.shortcode-posts .vertical-item.content-padding.big-padding .item-content{
		padding: 52px;
	}
}

@media (min-width: 1200px) {
	.shortcode-posts .vertical-item.content-padding.large-padding .item-content{
		padding: 60px 70px;
	}
}

.shortcode-posts .owl-carousel .owl-stage-outer{
	overflow: visible;
}

.shortcode-posts .owl-carousel .owl-nav .owl-prev,
.shortcode-posts .owl-carousel .owl-nav .owl-next{
	font-size: 40px;
	display: block;
	width: 110px;
	font-weight: 900;
	line-height: 32px;
	opacity: 0.1;
	color: #1f2732;
	text-transform: uppercase;
	text-align: center;
}

.ds .shortcode-posts .owl-carousel .owl-nav .owl-prev,
.ds .shortcode-posts .owl-carousel .owl-nav .owl-next{
	color: #ffffff;
}

.shortcode-posts .owl-carousel .owl-nav .owl-prev:hover,
.shortcode-posts .owl-carousel .owl-nav .owl-next:hover{
	color: black;
}

.shortcode-posts .owl-carousel .owl-nav .owl-prev:after,
.shortcode-posts .owl-carousel .owl-nav .owl-next:after{
	display: none;
}

@media (min-width: 1440px) {
	.shortcode-posts .owl-carousel .owl-nav{
		left: -130px !important;
		right: -130px !important;
	}
}

@media (min-width: 1600px) {
	.shortcode-posts .owl-carousel .owl-nav{
		left: -240px !important;
		right: -240px !important;
	}
}

@media (max-width: 1439px) {
	.shortcode-posts .owl-carousel .owl-nav{
		display: none;
	}
}

.shortcode-posts.carousel .text-owl-nav{
	text-align: left;
}

.shortcode-posts.carousel .text-owl-nav > div{
	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 13px !important;
	color: #191f28;
	font-weight: 700;
	text-transform: uppercase;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.shortcode-posts.carousel .text-owl-nav > div:hover{
	color: rgba(25, 31, 40, 0.5);
}

.shortcode-posts.carousel .text-owl-nav > div.owl-next{
	margin-left: 20px;
}

.shortcode-posts.carousel .text-owl-nav > div.owl-next:before{
	content: "|";
	position: absolute;
	top: -1px;
	left: -15px;
	opacity: 1;
	color: #191f28;
}

.shortcode-posts .item-content .entry-meta{
	text-align: center;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.2em;
	font-size: 12px;
}

.shortcode-posts .item-content .entry-meta:before,
.shortcode-posts .item-content .entry-meta:after{
	display: none;
}

.shortcode-posts .item-content .entry-meta > *{
	margin-top: 5px;
	margin-right: 0;
}

.shortcode-posts .item-content .categories-links a{
	display: none;
}

.shortcode-posts .item-content .categories-links a:first-child{
	display: block;
}

.shortcode-posts .item-content .item-title{
	margin-top: 7px;
	margin-bottom: 15px;
	height: 60px;
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
	overflow: hidden;
	text-transform: none;
}

.shortcode-posts .item-content p{
	height: 90px;
	margin-bottom: 20px;
	overflow: hidden;
}

.shortcode-posts .item-content .blog-adds{
	text-align: left;
	margin-bottom: -20px;
}

.shortcode-posts .item-layout-item-text .item-text.with_background{
	background-color: #ffffff;
}

.shortcode-posts .item-layout-item-text .item-text .entry-meta{
	margin-bottom: 10px;
}

.shortcode-posts .item-layout-item-text .item-text .post-author{
	position: relative;
	margin-top: 55px;
}

.shortcode-posts .item-layout-item-text .item-text .post-author a{
	color: #1f2732;
}

.shortcode-posts .item-layout-item-text .item-text .post-author a:hover{
	color: black;
}

.shortcode-posts .item-layout-item-text .item-text .post-author a:before{
	content: '';
	background-image: url("../img/small_author.png");
	background-repeat: no-repeat;
	width: 20px;
	height: 17px;
	left: 50%;
	margin-left: -10px;
	margin-top: -20px;
	position: absolute;
}

.shortcode-posts .item-layout-item-extended .entry-meta{
	margin-bottom: 10px;
}

.shortcode-posts .item-layout-item-extended .post-author{
	position: relative;
	margin-top: 45px;
}

.shortcode-posts .item-layout-item-extended .post-author a{
	color: #1f2732;
}

.shortcode-posts .item-layout-item-extended .post-author a:hover{
	color: black;
}

.shortcode-posts .item-layout-item-extended .post-author a:before{
	content: '';
	background-image: url("../img/small_author.png");
	background-repeat: no-repeat;
	width: 20px;
	height: 17px;
	left: 50%;
	margin-left: -10px;
	margin-top: -20px;
	position: absolute;
}

.shortcode-posts .item-layout-item-text-2 .item-title{
	font-size: 16px;
	height: auto;
	margin-top: 0;
	margin-bottom: 5px;
}

.shortcode-posts .item-layout-item-text-2 .item-content p{
	height: auto;
}

.shortcode-posts .item-layout-item-text-2 .btn-arrow{
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 3px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

.shortcode-posts .item-layout-item-text-2 .btn-arrow::after{
	content: '';
	-webkit-mask: url("../img/long-arrow-pointing-to-the-right.png") no-repeat center/contain;
	background-color: #ffffff;
	width: 15px;
	height: 14px;
	display: inline-block;
	margin-left: 5px;
}

.shortcode-posts .item-title-2-layout .item-media-wrap{
	display: -webkit-box;
	display: flex;
}

.shortcode-posts .item-title-2-layout .item-media-wrap .theme_buttons{
	position: absolute;
	left: 30px;
	top: 30px;
	z-index: 2;
}

.shortcode-posts .item-title-2-layout .item-title{
	margin-bottom: 0;
}

.shortcode-posts .item-title-2-layout .item-content{
	margin-top: -40px;
	margin-left: 40px;
	margin-right: 40px;
	position: relative;
	z-index: 2;
	background-color: #ffffff;
}

.shortcode-posts.tiled-layout{
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
}

.shortcode-posts.tiled-layout .post + .post{
	margin-top: 30px;
}

.shortcode-posts.tiled-layout .bigitem .vertical-item p{
	height: 56px;
	overflow: hidden;
	margin-bottom: 18px;
}

.shortcode-posts.tiled-layout .item-content .item-title{
	font-size: 30px;
	height: 70px;
	line-height: 1;
	margin-top: -10px;
}

.shortcode-posts.tiled-layout .item-content .post-meta{
	text-transform: uppercase;
	color: black;
	line-height: 1.2;
}

.shortcode-posts.tiled-layout .item-content .post-meta a{
	color: black;
}

.shortcode-posts.tiled-layout .item-content .post-meta a:hover{
	opacity: 0.8;
}

.shortcode-posts.tiled-layout .item-content .comments-link{
	margin-right: 8px;
}

.shortcode-posts.tiled-layout .item-content .comments-link:after{
	color: #1f2732;
	top: -1px;
}

.shortcode-posts.tiled-layout .smallitem .item-title{
	font-size: 24px;
	height: auto;
	line-height: 1.2;
	margin-top: 0;
}

@media (max-width: 767px) {
	.shortcode-posts.tiled-layout .smallitem .side-item{
		-webkit-box-orient: vertical !important;
		-webkit-box-direction: normal !important;
		flex-flow: column !important;
	}
}

@media (max-width: 399px) {
	.shortcode-posts.tiled-layout .smallitem .side-item .item-content{
		padding: 30px;
		text-align: left;
	}

	.shortcode-posts.tiled-layout .smallitem .side-item .item-content p{
		height: 85px;
		overflow: hidden;
	}
}

@media (min-width: 400px) {
	.shortcode-posts.tiled-layout .smallitem .side-item{
		display: -webkit-box;
		display: flex;
		flex-flow: wrap;
	}

	.shortcode-posts.tiled-layout .smallitem .side-item .item-media-wrap{
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-pack: start;
		justify-content: flex-start;
	}

	.shortcode-posts.tiled-layout .smallitem .side-item .item-content{
		padding: 30px 30px 0 30px;
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		text-align: left;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-pack: center;
		justify-content: center;
	}

	.shortcode-posts.tiled-layout .smallitem .side-item .item-content p{
		height: 56px;
		overflow: hidden;
	}

	.shortcode-posts.tiled-layout .smallitem .side-item .item-content > span{
		display: inline-block;
		width: auto;
	}
}

@media (min-width: 767px) {
	.shortcode-posts.tiled-layout .smallitem .side-item .item-content{
		padding-top: 0;
		padding-right: 0;
	}
}

.shortcode-posts .item-layout-item-extended2 .item-title{
	font-size: 24px;
	height: auto;
	line-height: 1.2;
	margin-top: 0;
}

@media (max-width: 768px) {
	.shortcode-posts .item-layout-item-extended2 .side-item .item-content{
		padding: 30px;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-content p{
		height: 85px;
		overflow: hidden;
		margin-bottom: 10px;
	}
}

@media (min-width: 769px) {
	.shortcode-posts .item-layout-item-extended2 .side-item{
		display: -webkit-box;
		display: flex;
		flex-flow: wrap;
		margin-bottom: 10px;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-media-wrap{
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-pack: start;
		justify-content: flex-start;
		display: -webkit-box;
		display: flex;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-media-wrap img{
		display: inline;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-content{
		padding: 0 0 0 30px;
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		text-align: left;
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		-webkit-box-pack: center;
		justify-content: center;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-content p{
		height: 56px;
		overflow: hidden;
		margin-bottom: 10px;
	}

	.shortcode-posts .item-layout-item-extended2 .side-item .item-content > span{
		display: inline-block;
		width: auto;
	}
}

.shortcode-posts .item-layout-item-extended3 .item-media{
	display: inline-block;
}

.shortcode-posts .item-layout-item-extended3 .item-content .item-title{
	margin-top: 0;
	margin-bottom: 15px;
	height: auto;
	font-weight: 500;
	overflow: hidden;
	text-transform: none;
}

.shortcode-posts .item-layout-item-extended3 .item-content p{
	height: 56px;
	margin-bottom: 10px;
	overflow: hidden;
}

/* event shortcode */
.item-event-layout h1,
.item-event-layout h2,
.item-event-layout h3,
.item-event-layout h4,
.item-event-layout h5,
.item-event-layout h6{
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 5px;
	max-height: 23px;
	overflow-y: hidden;
}

.item-event-layout .item-content{
	max-height: 67px;
	overflow-y: hidden;
}

/*special-post*/
.special-post .media-links:before{
	background-color: rgba(31, 39, 50, 0.5);
}

@media (min-width: 768px) {
	.special-post > div{
		padding: 0 !important;
	}
}

.special-post > div .shortcode-posts .item-layout-item-extended2 .side-item{
	background: transparent;
	margin-bottom: 0;
}

.special-post > div .shortcode-posts .item-layout-item-extended2 .side-item .post-date a{
	color: #ffffff;
}

.special-post > div .shortcode-posts .item-layout-item-extended2 .side-item .post-date a:hover{
	color: #ffffff;
}

/* Steps */
.fw-theme-steps .fw-theme-step-wrap{
	display: block;
	width: auto;
	text-align: center;
	margin: 0 auto;
}

.fw-theme-steps .fw-theme-step-wrap > *{
	position: relative;
	display: block;
	width: auto;
	margin: 0 auto;
}

.fw-theme-steps .fw-theme-step-wrap .fw-step-left-part{
	text-align: center;
	max-width: 400px;
}

.fw-theme-steps .fw-theme-step-wrap .fw-step-left-part .step-title{
	width: 100%;
}

.fw-theme-steps .fw-theme-step-wrap .fw-step-right-part{
	margin-top: 20px;
	max-width: 400px;
}

.ds .fw-theme-steps .fw-theme-step-wrap .fw-step-right-part{
	color: rgba(255, 255, 255, 0.7);
}

.fw-theme-steps .fw-theme-step-wrap .fw-step-center-part img{
	max-width: 270px;
	border: 10px solid rgba(255, 255, 255, 0.1);
	border-radius: 50%;
}

.ls .fw-theme-steps .fw-theme-step-wrap .fw-step-center-part img{
	border: 10px solid rgba(31, 39, 50, 0.1);
}

.fw-theme-steps .fw-theme-step-wrap:nth-child(odd) .step-title{
	text-align: right;
}

.fw-theme-steps .fw-theme-step-wrap:nth-child(odd) .step-title:before{
	text-align: left;
	left: 0;
}

.fw-theme-steps .fw-theme-step-wrap:nth-child(even) .step-title{
	text-align: left;
}

.fw-theme-steps .fw-theme-step-wrap:nth-child(even) .step-title:before{
	text-align: right;
	right: 0;
}

/* Steps counter */
#box_wrapper{
	counter-reset: number;
}

#box_wrapper .step-title{
	position: relative;
	z-index: 1;
}

#box_wrapper .step-title:before{
	content: counter(number, decimal-leading-zero);
	counter-increment: number;
	position: absolute;
	top: -25px;
	width: 100%;
	font-size: 90px;
	font-weight: 600;
	z-index: -1;
}

#box_wrapper .step-title.color1:before{
	color: black;
}

#box_wrapper .step-title.color2:before{
	color: #a0ce4e;
}

#box_wrapper .step-title.color3:before{
	color: #00bea3;
}

#box_wrapper .step-title.color4:before{
	color: #f1894c;
}

.fw-theme-step-wrap + .fw-theme-step-wrap{
	margin-top: 60px;
}

/* Responsive */
@media (min-width: 992px) {
	#box_wrapper .step-title{
		margin-bottom: 0;
	}

	#box_wrapper .step-title:before{
		top: -73px;
		width: 100%;
		font-size: 200px;
	}

	.fw-theme-steps .fw-theme-step-wrap{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		align-items: center;
	}

	.fw-theme-steps .fw-theme-step-wrap .fw-step-right-part{
		margin-top: 0;
		text-align: left;
		max-width: 100%;
	}

	.fw-theme-steps .fw-theme-step-wrap .fw-step-center-part{
		position: relative;
	}

	.fw-theme-steps .fw-theme-step-wrap .fw-step-center-part:after{
		content: '';
		background-image: url("../img/right_arrow.png");
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		width: 34px;
		height: 136px;
		z-index: 4;
		bottom: -100px;
		right: 20px;
	}

	.ls .fw-theme-steps .fw-theme-step-wrap .fw-step-center-part:after{
		background-image: url("../img/right_arrow_dark.png");
	}

	.fw-theme-steps .fw-theme-step-wrap:nth-child(odd){
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}

	.fw-theme-steps .fw-theme-step-wrap:nth-child(even){
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.fw-theme-steps .fw-theme-step-wrap:nth-child(even) .fw-step-center-part:after{
		background-image: url("../img/left_arrow.png");
		right: auto;
		left: 20px;
	}

	.ls .fw-theme-steps .fw-theme-step-wrap:nth-child(even) .fw-step-center-part:after{
		background-image: url("../img/left_arrow_dark.png");
	}

	.fw-theme-steps .fw-theme-step-wrap:nth-child(even) .fw-step-right-part{
		text-align: right;
	}

	.fw-theme-steps .fw-theme-step-wrap:last-child .fw-step-center-part:after{
		display: none;
	}

	.fw-theme-steps .fw-theme-step-wrap > *{
		flex-basis: 33.3%;
	}
}

@media (min-width: 1200px) {
	.fw-theme-steps .fw-theme-step-wrap .fw-step-center-part:after{
		right: 40px;
	}

	.fw-theme-steps .fw-theme-step-wrap:nth-child(even) .fw-step-center-part:after{
		left: 40px;
	}
}

/* Steps 2 */
.fw-theme-steps.steps-2 .fw-theme-step-wrap{
	-webkit-box-pack: center;
	justify-content: center;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .step-title{
	margin-top: 0;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .step-title:before,
.fw-theme-steps.steps-2 .fw-theme-step-wrap .step-title:after{
	display: none;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-center-part:before,
.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-center-part:after{
	display: none;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-center-part img{
	max-width: 190px;
	border-radius: 50%;
	border: 10px solid rgba(255, 255, 255, 0.05);
}

.ls .fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-center-part img{
	border: 10px solid rgba(31, 39, 50, 0.05);
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .year{
	display: inline-block;
	font-size: 90px;
	line-height: 0.8;
	font-weight: 600;
	letter-spacing: -6px;
}

@media (min-width: 992px) {
	.fw-theme-steps.steps-2 .fw-theme-step-wrap .year{
		font-size: 120px;
	}
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .year.color1{
	color: black;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .year.color2{
	color: #a0ce4e;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .year.color3{
	color: #00bea3;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap .year.color4{
	color: #f1894c;
}

.fw-theme-steps.steps-2 .fw-theme-step-wrap + .fw-theme-step-wrap{
	margin-top: 60px;
}

/* Responsive */
@media (min-width: 992px) {
	.fw-theme-steps.steps-2 .fw-theme-step-wrap + .fw-theme-step-wrap{
		margin-top: 40px;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-left-part{
		margin-top: 0;
		max-width: none;
		flex-basis: 40%;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-right-part{
		margin-top: 0;
		flex-basis: 40%;
		max-width: none;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-right-part .step-text{
		max-width: 370px;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(odd) .fw-step-left-part{
		text-align: right;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(odd) .fw-step-left-part .step-title{
		text-align: right;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(odd) .fw-step-right-part{
		text-align: left;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(odd) .fw-step-right-part .step-text{
		float: left;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(even) .fw-step-left-part{
		text-align: left;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(even) .fw-step-left-part .step-title{
		text-align: left;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(even) .fw-step-right-part{
		text-align: right;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap:nth-child(even) .fw-step-right-part .step-text{
		float: right;
	}

	.fw-theme-steps.steps-2 .fw-theme-step-wrap .fw-step-center-part{
		max-width: 190px;
		margin: 0 55px;
		flex-basis: 20%;
	}
}

/* Steps 3 */
@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		overflow: hidden;
		position: relative;
		z-index: 2;
	}
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item{
	position: relative;
	margin-bottom: 60px;
	padding: 30px 20px;
}

@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item{
		margin-bottom: 0;
	}
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:after{
	content: "";
	height: 54px;
	width: 4px;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -2px;
	bottom: -54px;
	background-image: url("../img/stripe-v.png");
	background-repeat: no-repeat;
}

@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:after{
		display: none;
	}
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:last-child{
	margin-bottom: 0;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:last-child:after{
	display: none;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-img{
	height: 60px;
	margin-bottom: 12px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-img img{
	max-height: 60px;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-title{
	margin-top: 12px;
	margin-bottom: 17px;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-dot{
	padding: 13px;
	background: #ffffff;
	display: inline-block;
	position: relative;
	z-index: 3;
}

@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-dot:after{
		content: "";
		height: 4px;
		width: 214px;
		display: block;
		position: absolute;
		left: 60px;
		margin-left: 0;
		top: 26px;
		background-image: url("../img/stripe-h-2.png");
		background-repeat: no-repeat;
	}
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-dot span{
	background: black;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 5px solid #ffffff;
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-text{
	max-width: 240px;
	display: inline-block;
}

@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item .item-text{
		max-width: 100%;
	}
}

@media (min-width: 1200px) {
	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:last-child .item-dot:after{
		background-image: url("../img/stripe-h-1.png");
	}

	.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item:first-child .item-dot:before{
		content: "";
		height: 4px;
		width: 94px;
		display: block;
		position: absolute;
		left: auto;
		right: 60px;
		margin-left: 0;
		top: 26px;
		background-image: url("../img/stripe-h-1.png");
		background-repeat: no-repeat;
	}
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item.color2 .item-dot span{
	background: #a0ce4e;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item.color3 .item-dot span{
	background: #00bea3;
}

.fw-theme-steps.steps-3 .fw-theme-steps-wrap .vertical-item.color4 .item-dot span{
	background: #f1894c;
}

/* Steps 4 */
.fw-theme-steps-4.steps-4{
	text-align: center;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item{
	margin-bottom: 90px;
	position: relative;
	padding: 0 15px;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:after{
	content: "";
	height: 54px;
	width: 4px;
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -2px;
	bottom: -70px;
	background-image: url("../img/stripe-v.png");
	background-repeat: no-repeat;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:last-child{
	margin-bottom: 0;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:last-child:after{
	display: none;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-img{
	display: inline-block;
	margin: 20px 0;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-img img{
	max-width: 190px;
	border-radius: 50%;
	border: 6px solid rgba(255, 255, 255, 0.05);
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content .step-title{
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 16px !important;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content .step-title:before,
.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content .step-title:after{
	display: none;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content .step-text{
	max-width: 240px;
	display: inline-block;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content:last-child{
	margin-bottom: 0;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content:last-child .step-text:after{
	display: none;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .year{
	display: block;
	font-size: 48px;
	line-height: 48px;
	height: 48px;
	overflow: hidden;
	font-weight: 600;
	margin-bottom: 10px;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .year.color1{
	color: black;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .year.color2{
	color: #a0ce4e;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .year.color3{
	color: #00bea3;
}

.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .year.color4{
	color: #f1894c;
}

/* Responsive */
@media (min-width: 992px) {
	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item{
		margin-bottom: 0;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:after{
		display: none;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-img{
		position: relative;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-img:after{
		content: "";
		height: 4px;
		width: 94px;
		display: block;
		position: absolute;
		right: -96px;
		margin-left: 0;
		margin-top: -2px;
		top: 50%;
		background-image: url("../img/stripe-h-3.png");
		background-repeat: no-repeat;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:first-child .item-img:before{
		content: "";
		height: 4px;
		width: 34px;
		display: block;
		position: absolute;
		left: -36px;
		margin-left: 0;
		top: 50%;
		background-image: url("../img/stripe-h-4.png");
		background-repeat: no-repeat;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item:last-child .item-img:after{
		content: "";
		height: 4px;
		width: 34px;
		display: block;
		position: absolute;
		right: -36px;
		margin-left: 0;
		top: 50%;
		background-image: url("../img/stripe-h-4.png");
		background-repeat: no-repeat;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content{
		margin-bottom: 0;
	}

	.fw-theme-steps-4.steps-4 .fw-theme-step-wrap .vertical-item .item-content .step-text:after{
		display: none;
	}
}

/* Steps 5 */
.fw-theme-steps.steps-5 .fw-theme-step-wrap{
	-webkit-box-pack: center;
	justify-content: center;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .step-title{
	margin-top: 0;
	margin-bottom: 24px;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .step-title:before,
.fw-theme-steps.steps-5 .fw-theme-step-wrap .step-title:after{
	display: none;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-left-part:before{
	content: "";
	height: 54px;
	width: 4px;
	display: block;
	position: absolute;
	left: 50%;
	right: auto;
	margin-left: -2px;
	bottom: auto;
	top: -70px;
	background-image: url("../img/stripe-v.png") !important;
	background-repeat: no-repeat;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap:last-child .fw-step-right-part:after{
	content: "";
	height: 54px;
	width: 4px;
	display: block;
	position: absolute;
	left: 50%;
	right: auto;
	margin-left: -2px;
	bottom: -70px;
	background-image: url("../img/stripe-v.png") !important;
	background-repeat: no-repeat;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part img{
	max-width: 170px;
	border-radius: 50%;
	border: 6px solid #ffffff;
	box-shadow: 0 0px 10px 0px rgba(255, 255, 255, 0.1);
}

.ls .fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part img{
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part:before,
.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part:after{
	display: none;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .year{
	display: inline-block;
	font-size: 48px;
	line-height: 0.8;
	font-weight: 700;
	letter-spacing: -1px;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .year.color1{
	color: black;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .year.color2{
	color: #a0ce4e;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .year.color3{
	color: #00bea3;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap .year.color4{
	color: #f1894c;
}

.fw-theme-steps.steps-5 .fw-theme-step-wrap{
	margin-top: 85px !important;
}

.fw-theme-steps.steps-5:last-child .fw-theme-step-wrap{
	margin-bottom: 85px !important;
}

/* Responsive */
@media (min-width: 992px) {
	.fw-theme-steps.steps-5 .fw-theme-step-wrap{
		margin-top: 85px !important;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-left-part{
		margin-top: 0;
		max-width: none;
		flex-basis: 40%;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-left-part:before{
		display: none;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-right-part{
		margin-top: 0;
		flex-basis: 40%;
		max-width: none;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-right-part .step-title{
		margin-top: 0;
		margin-bottom: 18px !important;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-right-part .step-text{
		max-width: 240px;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:last-child .fw-step-right-part:after{
		display: none;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(odd) .fw-step-left-part{
		text-align: right;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(odd) .fw-step-right-part{
		text-align: left;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(odd) .fw-step-right-part .step-title{
		text-align: left;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(odd) .fw-step-right-part .step-text{
		float: left;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(even) .fw-step-left-part{
		text-align: left;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(even) .fw-step-left-part .step-title{
		text-align: left;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(even) .fw-step-right-part{
		text-align: right;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(even) .fw-step-right-part .step-title{
		text-align: right;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:nth-child(even) .fw-step-right-part .step-text{
		float: right;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part{
		max-width: 190px;
		margin: 0 40px;
		flex-basis: 20%;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap .fw-step-center-part:after{
		content: "";
		height: 54px;
		width: 4px;
		display: block;
		position: absolute;
		left: 50%;
		right: auto;
		margin-left: -2px;
		bottom: -70px;
		background-image: url("../img/stripe-v.png") !important;
		background-repeat: no-repeat;
	}

	.fw-theme-steps.steps-5 .fw-theme-step-wrap:first-child .fw-step-center-part:before{
		content: "";
		height: 54px;
		width: 4px;
		display: block;
		position: absolute;
		left: 50%;
		right: auto;
		margin-left: -2px;
		bottom: auto;
		top: -70px;
		background-image: url("../img/stripe-v.png") !important;
		background-repeat: no-repeat;
	}
}

/* Steps 6 */
.fw-theme-steps.steps-6 .fw-theme-steps-wrap + .fw-theme-steps-wrap{
	margin-top: 80px;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap > *{
	position: relative;
	display: block;
	width: auto;
	margin: 0 auto;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-left-part{
	text-align: center;
	max-width: 400px;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part{
	margin-top: 0;
	text-align: center;
	max-width: 300px;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part .item-title{
	margin-bottom: 15px;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part:after{
	content: "";
	height: 54px;
	width: 4px;
	display: block;
	position: absolute;
	left: 50%;
	right: auto;
	margin-left: -2px;
	bottom: -64px;
	background-image: url("../img/stripe-v.png") !important;
	background-repeat: no-repeat;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap:last-child .fw-step-right-part:after{
	display: none;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part{
	text-align: center;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part .item-dot{
	padding: 13px;
	background: transparent;
	display: inline-block;
	position: relative;
	z-index: 3;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part .item-dot span{
	background: black;
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 5px solid #ffffff;
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part.color2 .item-dot span{
	background: #a0ce4e;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part.color3 .item-dot span{
	background: #00bea3;
}

.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part.color4 .item-dot span{
	background: #f1894c;
}

/* Responsive */
@media (min-width: 992px) {
	.fw-theme-steps.steps-6 .fw-theme-steps-wrap{
		margin-top: 60px !important;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:last-child{
		margin-bottom: 100px !important;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:first-child{
		margin-top: 100px !important;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap{
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		align-items: flex-start;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even){
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		flex-direction: row-reverse;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-left-part{
		margin-top: 0;
		max-width: none;
		flex-basis: 45%;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part{
		margin-top: 15px;
		flex-basis: 45%;
		max-width: none;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part:after{
		display: none;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part .item-title{
		margin-top: 0;
		margin-bottom: 18px !important;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-right-part .item-text{
		max-width: 240px;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(odd) .fw-step-left-part{
		text-align: right;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(odd) .fw-step-right-part{
		text-align: left;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(odd) .fw-step-right-part .item-title{
		text-align: left;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(odd) .fw-step-right-part .item-text{
		float: left;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even) .fw-step-left-part{
		text-align: left;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even) .fw-step-left-part .item-title{
		text-align: left;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even) .fw-step-right-part{
		text-align: right;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even) .fw-step-right-part .item-title{
		text-align: right;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:nth-child(even) .fw-step-right-part .item-text{
		float: right;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part{
		max-width: 50px;
		margin: 0;
		flex-basis: 10%;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap .fw-step-center-part:after{
		content: "";
		height: 94px;
		width: 4px;
		display: block;
		position: absolute;
		left: 50%;
		right: auto;
		margin-left: -2px;
		bottom: -110px;
		background-image: url("../img/stripe-v-1.png") !important;
		background-repeat: no-repeat;
	}

	.fw-theme-steps.steps-6 .fw-theme-steps-wrap:first-child .fw-step-center-part:before{
		content: "";
		height: 94px;
		width: 4px;
		display: block;
		position: absolute;
		left: 50%;
		right: auto;
		margin-left: -2px;
		top: -110px;
		bottom: auto;
		background-image: url("../img/stripe-v-1.png") !important;
		background-repeat: no-repeat;
	}
}

/* Simple list */
.simple-list .no-bullets{
	padding-left: 0;
}

.simple-list .list-title{
	padding-bottom: 13px;
	margin-bottom: 10px;
}

.simple-list .list-item{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: left;
	justify-content: left;
	margin-bottom: 0;
}

.simple-list .list-item:last-child{
	margin-bottom: 0;
}

.simple-list.text-center li.list-item{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center !important;
	justify-content: center !important;
}

.simple-list.list-1 li.list-item{
	position: relative;
	width: auto;
	display: block;
	padding: 9px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.simple-list.list-1 li.list-item:first-child{
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.simple-list.list-1 li.list-item a{
	position: relative;
	width: auto;
	color: #7f7f7f;
}

.simple-list.list-1 li.list-item a:hover{
	color: black;
}

.simple-list.list-1 li.list-item a:before{
	font-family: 'FontAwesome';
	font-size: 5px;
	content: "\f111";
	position: absolute;
	left: -15px;
	top: 50%;
	display: block;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: black;
}

.simple-list.list-2 li.list-item{
	position: relative;
	width: auto;
	display: block;
	padding: 5px 0 5px 20px;
	border: 0 !important;
	color: #ffffff;
}

.with_background .simple-list.list-2 li.list-item{
	color: #1f2732;
}

.simple-list.list-2 li.list-item:before{
	font-family: 'FontAwesome';
	font-size: 5px;
	content: "\f111";
	position: absolute;
	left: 0;
	top: 50%;
	display: block;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: black;
}

.cs .simple-list.list-2 li.list-item:before{
	color: #191f28;
}

/* Special heading shortcodes */
.special-heading{
	position: relative;
}

.special-heading p{
	font-size: 14px;
	margin-bottom: 0;
}

.special-heading p.big{
	font-size: 20px;
	line-height: 30px;
}

.special-heading .transparent{
	opacity: 0.8;
}

h2.section_header{
	margin: 0;
	line-height: 0.9;
	position: relative;
	word-wrap: break-word;
}

h2.section_header.big{
	font-size: 40px;
	line-height: 0.8;
}

@media (min-width: 480px) {
	h2.section_header.big{
		font-size: 70px;
	}
}

@media (min-width: 768px) {
	h2.section_header.big{
		font-size: 80px;
	}
}

@media (min-width: 1440px) {
	h2.section_header.big{
		font-size: 100px;
	}
}

h2.section_header.bigger{
	font-size: 45px;
	line-height: 0.8;
}

@media (min-width: 480px) {
	h2.section_header.bigger{
		font-size: 80px;
	}
}

@media (min-width: 768px) {
	h2.section_header.bigger{
		font-size: 100px;
	}
}

@media (min-width: 1440px) {
	h2.section_header.bigger{
		font-size: 160px;
	}
}

h2.section_header.extra-big{
	font-size: 60px;
	line-height: 0.8;
}

@media (min-width: 480px) {
	h2.section_header.extra-big{
		font-size: 90px;
	}
}

@media (min-width: 768px) {
	h2.section_header.extra-big{
		font-size: 120px;
	}
}

@media (min-width: 1440px) {
	h2.section_header.extra-big{
		font-size: 200px;
	}
}

h3.section_header.big{
	font-size: 30px;
}

@media (min-width: 480px) {
	h3.section_header.big{
		font-size: 34px;
	}
}

@media (min-width: 768px) {
	h3.section_header.big{
		font-size: 44px;
	}
}

@media (min-width: 1440px) {
	h3.section_header.big{
		font-size: 54px;
		line-height: 1;
	}
}

@media (min-width: 1200px) {
	h3.section_header.extra-big{
		font-size: 60px;
		line-height: 1;
	}
}

h2.section_header + p{
	margin-top: 5px;
	margin-bottom: 0;
}

h3.section_header{
	margin: 0;
	line-height: 1;
	position: relative;
	word-wrap: break-word;
}

h4.section_header{
	line-height: 1;
}

h5.section_header{
	line-height: 1.2;
}

.section_header.inline{
	display: inline;
	width: auto;
}

.text-transform-none{
	text-transform: none !important;
}

/* Special heading weights */
.special-heading .extra-light{
	font-weight: 200;
}

.special-heading .light-weight{
	font-weight: 300;
}

.special-heading .regular{
	font-weight: 400;
}

.special-heading .medium{
	font-weight: 500;
}

.special-heading .bold{
	font-weight: 600;
}

.special-heading .black-weight{
	font-weight: 700;
}

/*contacts*/
.contact-form{
	position: relative;
	z-index: 3;
}

.contact-form.ls{
	background-color: #fafafa;
}

.contact-form.ls.ms{
	background-color: #f1f1f3;
}

.contact-form label[for]{
	display: none;
}

.contact-form textarea{
	max-height: 140px;
}

.contact-form.parallax{
	padding: 50px 50px 35px;
	background-image: url("../img/parallax/progress.html");
}

.contact-form-respond{
	display: block;
	font-weight: 800;
}

.contact-form-respond:before{
	font-family: 'rt-icons-2';
	content: "\e71a";
	padding-right: 10px;
}

.contact-form p{
	margin-bottom: 10px;
	position: relative;
}

.contact-form-submit .theme_button{
	margin-right: 6px;
}

.contact-form.transparent-background .form-control{
	background-color: transparent;
}

.contact-email{
	font-size: 40px;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.contact-email{
		font-size: 20px;
		margin-bottom: 6px;
	}

	.contact-form.parallax{
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 40px;
	}
}

.wrap-forms .form-group p{
	margin: 10px 0 0;
}

.wrap-forms .form-group.has-placeholder label{
	display: none;
}

.wrap-forms .field-select label{
	display: inline;
}

#messages_modal .fw-messages-wrap{
	position: absolute;
	left: 50%;
	top: 10%;
	margin-left: -150px;
	width: 300px;
}

#messages_modal ul{
	margin-bottom: 0;
}

.form-wrapper.text-center .wrap-forms input,
.form-wrapper.text-center .wrap-forms textarea{
	text-align: center;
}

.form-wrapper.text-left .wrap-forms input,
.form-wrapper.text-left .wrap-forms textarea{
	text-align: left;
}

.form-wrapper.text-left .wrap-forms .theme_button{
	text-align: center;
}

.form-wrapper.text-right .wrap-forms input,
.form-wrapper.text-right .wrap-forms textarea{
	text-align: right;
}

.form-wrapper.text-right .wrap-forms .theme_button{
	text-align: center;
}

/* partners carousel */
.owl-carousel.partners-carousel{
	margin-bottom: 0;
}

.light-partner-image a{
	display: block;
	position: relative;
}

.light-partner-image a:before{
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;
	right: 1px;
	bottom: 1px;
	background-color: #7f7f7f;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.light-partner-image a:hover:before{
	background-color: black;
}

.highlight-partner-image a{
	display: block;
	background-color: black;
}

.highlight-partner-image a:hover{
	background-color: #1f2732;
}

/* events calendar */
div .fw-shortcode-calendar-wrapper .page-header h3,
div .btn-group button[data-calendar-nav],
div .btn-group button[data-calendar-nav*='today'],
div #cal-slide-content a.event-item{
	color: #1f2732;
}

div #cal-slide-content a.event-item{
	text-decoration: none !important;
	color: #191f28;
	font-size: 16px;
	font-weight: 500;
}

div #cal-day-box .time-col,
div #cal-day-box .cal-row-head,
div #cal-day-box .cal-day-hour,
div #cal-day-box .cal-row-head,
div #cal-day-box .cal-day-hour,
div #cal-day-box .cal-day-hour .cal-day-hour-part,
div .cal-week-box .cal-column,
div .cal-row-fluid.cal-row-head [class*="cal-cell"],
div .cal-week-box .cal-row-head [class*="cal-cell"] small,
div .cal-year-box [class*="span"],
div .cal-month-box [class*="cal-cell"],
div .cal-year-box .row-fluid,
div .cal-month-box .cal-row-fluid{
	border-color: rgba(164, 169, 185, 0.3);
}

div .fw-shortcode-calendar .cal-week-box hr{
	background-color: #dedfe1;
}

div .cal-month-day.cal-day-today.event-day{
	background-color: black;
}

div .cal-year-box [class*="span"]:hover,
div .cal-month-box [class*="cal-cell"]:hover{
	background-color: #a0ce4e;
}

div #cal-slide-tick{
	border-top-color: transparent;
}

div .cal-week-box .cal-day-today span[data-cal-date],
div .cal-week-box .cal-day-today.event-day span[data-cal-date],
div .cal-month-day.event-day span[data-cal-date]{
	color: #ffffff;
}

div .cal-month-box [class*="cal-cell"]:hover span[data-cal-date]{
	color: #ffffff;
}

/* Shortcode Overrides */
.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .cal-row-head{
	border-right: 1px solid #191f28;
	background: #191f28;
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header{
	margin-bottom: 0;
	background-color: black;
	position: relative;
	overflow: hidden;
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
	text-align: center;
	position: relative;
	-webkit-box-align: center;
	align-items: center;
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group{
	margin-right: 0;
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group h3{
	font-size: 30px;
	font-weight: 500;
	color: #ffffff;
}

@media (max-width: 480px) {
	.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group h3{
		font-size: 20px;
	}
}

@media (max-width: 768px) {
	.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group h3{
		font-size: 26px;
	}
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group button[data-calendar-nav]{
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 0;
	color: #ffffff;
	height: 80px;
	width: 80px;
	vertical-align: middle;
}

@media (max-width: 480px) {
	.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group button[data-calendar-nav]{
		height: 50px;
		width: 50px;
	}
}

@media (max-width: 768px) {
	.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group button[data-calendar-nav]{
		height: 60px;
		width: 60px;
	}
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group button[data-calendar-nav]:hover{
	color: rgba(255, 255, 255, 0.6);
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group.pull-left{
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .page-header .form-inline .btn-group.pull-right{
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.theme-calendar-wrappper.fw-shortcode-calendar-wrapper .table-wrap{
	margin-top: 0;
}

.theme-calendar .cal-month-day{
	height: 120px;
}

.theme-calendar .text-today{
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	top: 3px;
	right: 10px;
	left: auto;
	color: black;
	text-transform: uppercase;
}

@media (max-width: 768px) {
	.theme-calendar .text-today{
		font-size: 10px;
		line-height: 1.2;
		letter-spacing: 0;
	}
}

.theme-calendar span[data-cal-date]{
	position: relative;
	margin: 40px 0 0 0;
	width: 100%;
	height: 26px;
	text-align: center;
	font-size: 24px;
	font-weight: 500;
	cursor: pointer;
	color: #191f28;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
}

@media (max-width: 768px) {
	.theme-calendar span[data-cal-date]{
		font-size: 16px;
		margin: 20px 0 0 0;
	}
}

.ds .theme-calendar span[data-cal-date]{
	color: #ffffff;
}

.theme-calendar .cal-month-box .cal-day-today span[data-cal-date]{
	font-size: 24px;
}

@media (max-width: 768px) {
	.theme-calendar .cal-month-box .cal-day-today span[data-cal-date]{
		font-size: 16px;
	}
}

.theme-calendar .cal-day-today .list-events{
	color: #7f7f7f;
}

.theme-calendar .list-events{
	position: absolute;
	margin-top: auto;
	left: auto;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	width: 100%;
	text-align: center;
	color: #7f7f7f;
}

@media (max-width: 768px) {
	.theme-calendar .list-events{
		font-size: 10px;
		line-height: 1.2;
		bottom: 0;
		letter-spacing: 0;
	}
}

.theme-calendar .cal-month-box [class*="cal-cell"]{
	background: #ffffff;
}

.ds .theme-calendar .cal-month-box [class*="cal-cell"]{
	background: #191f28;
}

.theme-calendar .cal-row-fluid.cal-row-head [class*="cal-cell"]{
	color: #ffffff;
	font-size: 14px;
	font-weight: 300;
	background: #191f28;
	text-transform: uppercase;
	border-right: 1px solid #191f28;
	border-bottom: 1px solid #191f28;
	padding: 14px 0;
}

.theme-calendar .cal-month-box [class*="cal-cell"]{
	border-right: 1px solid rgba(164, 169, 185, 0.3);
}

.theme-calendar .cal-year-box .row-fluid,
.theme-calendar .cal-month-box .cal-row-fluid{
	border-bottom: 1px solid rgba(164, 169, 185, 0.3);
	border-left: 1px solid rgba(164, 169, 185, 0.3);
	border-right: 1px solid rgba(164, 169, 185, 0.3);
}

.theme-calendar .cal-month-box{
	border-bottom: 1px solid rgba(164, 169, 185, 0.3);
}

.theme-calendar .cal-month-box .day-highlight{
	border: none;
	border-right: 1px solid rgba(164, 169, 185, 0.3) !important;
}

.theme-calendar #cal-slide-box{
	border-top: 0 solid #8c8c8c;
	border-bottom: 0 solid #fff;
}

.theme-calendar #cal-slide-content{
	padding: 35px 20px 35px 30px;
	border-left: 1px solid rgba(164, 169, 185, 0.3);
	border-right: 1px solid rgba(164, 169, 185, 0.3);
	border-bottom: 1px solid rgba(164, 169, 185, 0.3);
	background: #ffffff;
}

.ds .theme-calendar #cal-slide-content{
	background: #1f2732;
}

.theme-calendar div .cal-month-day.event-day{
	background-color: #f4f4f5;
}

.ds .theme-calendar div .cal-month-day.event-day{
	background-color: #191f28;
}

.theme-calendar div .cal-month-day.event-day:hover{
	background-color: #a0ce4e;
}

.theme-calendar div .cal-month-day.event-day:hover span[data-cal-date],
.theme-calendar div .cal-month-day.event-day:hover .list-events{
	color: #ffffff;
}

.theme-calendar div .cal-month-day.event-day:hover .text-today{
	color: #ffffff;
}

.theme-calendar div .cal-month-day.event-day span[data-cal-date]{
	color: #191f28;
}

.theme-calendar div .cal-month-day.event-day.cal-day-outmonth{
	background-color: #ffffff;
}

.theme-calendar div .cal-month-day.event-day.cal-day-outmonth:hover{
	background-color: #a0ce4e;
}

.theme-calendar div .cal-month-day.event-day.cal-day-outmonth:hover span[data-cal-date],
.theme-calendar div .cal-month-day.event-day.cal-day-outmonth:hover .list-events{
	color: #ffffff;
}

.theme-calendar .cal-month-day.cal-day-outmonth:hover span[data-cal-date]{
	background-color: transparent;
}

/*
**plugins
*/
/* Owl carousel v 2.1.6 styles */
/* Owl Carousel - Core */
.owl-carousel{
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent; /* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage{
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after{
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer{
	position: relative;
	overflow: hidden; /* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item{
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img{
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled{
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot{
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded{
	display: block;
}

.owl-carousel.owl-loading{
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden{
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item{
	display: none;
}

.owl-carousel.owl-drag .owl-item{
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab{
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl{
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item{
	float: right;
}

.owl-carousel.dots-center .owl-dots{
	text-align: center;
}

.owl-carousel .owl-dots{
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	counter-reset: owl-dots;
}

.owl-carousel .owl-dots .owl-dot{
	display: inline-block;
	zoom: 1;
	*display: inline;
	padding: 0 12px;
}

.owl-carousel .owl-dots .owl-dot span{
	width: 6px;
	height: 6px;
	display: block;
	border-radius: 50%;
	color: transparent;
	background-color: #1f2732;
	cursor: pointer;
	text-indent: -9999px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.cs .owl-carousel .owl-dots .owl-dot span,
.ds .owl-carousel .owl-dots .owl-dot span{
	background-color: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span{
	background-color: black;
}

@media (max-width: 1199px) {
	.owl-carousel.owl-nav-variant-2 .owl-nav{
		display: none;
	}
}

.owl-carousel.owl-nav-variant-2 .owl-nav div{
	width: 40px;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:first-child{
	-webkit-mask: url("../img/icon_carousel_left.png") no-repeat center/contain;
	background-color: #1f2732;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:first-child::before{
	content: none;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:first-child:hover{
	background-color: black;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:first-child:hover::before{
	content: none;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:last-child{
	-webkit-mask: url("../img/icon_carousel_right.png") no-repeat center/contain;
	background-color: #1f2732;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:last-child::before{
	content: none;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:last-child:hover{
	background-color: black;
}

.owl-carousel.owl-nav-variant-2 .owl-nav div:last-child:hover::before{
	content: none;
}

.owl-carousel .owl-nav{
	-webkit-transition: all 0.6s ease 0s;
	transition: all 0.6s ease 0s;
}

.owl-carousel .owl-nav > div{
	color: transparent;
	font-size: 0;
	display: inline-block;
	right: 0;
	text-align: center;
}

.owl-carousel .owl-nav > div.disabled{
	opacity: 0.5;
	cursor: default;
}

.owl-carousel .owl-nav > div:first-child{
	left: 0;
	right: auto;
}

.owl-carousel .owl-nav > div:after{
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	font-family: FontAwesome;
	font-size: 40px;
	line-height: 60px;
	content: "\f105";
	color: #7f7f7f;
}

.owl-carousel .owl-nav > div:first-child:after{
	content: "\f104";
}

.owl-carousel .owl-nav > div:hover:after{
	color: black;
}

@media (max-width: 1299px) {
	.container .owl-carousel[data-nav=true]{
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media (min-width: 1300px) {
	.container .owl-carousel[data-nav=true] .owl-nav{
		left: -60px;
		right: -60px;
	}
}

@media (min-width: 1600px) {
	.container .owl-carousel[data-nav=true] .owl-nav{
		left: -150px;
		right: -150px;
	}
}

.container aside .owl-carousel{
	padding-right: 0;
	padding-left: 0;
}

.container aside .owl-carousel .owl-nav{
	left: 0 !important;
	right: 0 !important;
}

.top-right-nav .owl-nav{
	position: absolute;
	top: -70px;
	right: 0;
}

.top-right-nav .owl-nav > div{
	background: transparent;
}

/* No Js */
.no-js .owl-carousel{
	display: block;
}

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated{
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in{
	z-index: 0;
}

.owl-carousel .owl-animated-out{
	z-index: 1;
}

.owl-carousel .fadeOut{
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0%{
		opacity: 1;
	}

	100%{
		opacity: 0;
	}
}

@keyframes fadeOut {
	0%{
		opacity: 1;
	}

	100%{
		opacity: 0;
	}
}

/* Owl Carousel - Auto Height Plugin */
.owl-height{
	-webkit-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item .owl-lazy{
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy{
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper{
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon{
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("../img/owl.video.play.html") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: -webkit-transform 100ms ease;
	transition: -webkit-transform 100ms ease;
	transition: transform 100ms ease;
	transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover{
	-webkit-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon{
	display: none;
}

.owl-carousel .owl-video-tn{
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame{
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}

/* Owl Default Theme */
.owl-theme:hover .owl-nav{
	opacity: 1;
}

.owl-theme .owl-nav{
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav > div{
	font-size: 0;
	position: absolute;
	right: 0;
	text-align: center;
	margin-top: -30px;
	width: 30px;
}

.owl-theme .owl-nav .disabled{
	opacity: 0.5;
	cursor: default;
}

.owl-theme.owl_custom_nav .owl-dots{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: start;
	justify-content: flex-start;
}

.owl-theme.owl_custom_nav .owl-dots .owl-dot{
	padding-left: 0;
	height: 10px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

.owl-theme.owl_custom_nav .owl-dots .owl-dot.active span{
	width: 10px;
	height: 10px;
}

.owl-theme.owl-custom-nav-2 .owl-dots{
	position: absolute;
	right: -50px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	margin-top: 0;
}

.owl-theme.owl-custom-nav-2 .owl-dots .owl-dot{
	padding: 3px 0;
}

.owl-theme.owl-custom-nav-2 .owl-dots .owl-dot span{
	background-color: #7f7f7f;
}

.owl-theme.owl-custom-nav-2 .owl-dots .owl-dot:first-child span{
	-webkit-mask: url("../img/arrows3.png") no-repeat center/contain;
	width: 21px;
	height: 30px;
	margin-bottom: 15px;
}

.owl-theme.owl-custom-nav-2 .owl-dots .owl-dot:last-child span{
	-webkit-mask: url("../img/arrows3.png") no-repeat center/contain;
	width: 21px;
	height: 30px;
	margin-top: 15px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.owl-theme.owl-custom-nav-2 .owl-dots .owl-dot.active span{
	background-color: #ffffff;
}

@media (max-width: 1400px) {
	.owl-theme.owl-custom-nav-2 .owl-dots{
		display: none;
	}
}

.owl-theme .owl-dots{
	margin-top: 40px;
}

/* .owl-center theme */
.entry-thumbnail-carousel .owl-nav,
.single-fw-portfolio .owl-nav,
.portfolio-shortcode .owl-nav{
	bottom: 0;
	position: static;
	margin-top: 0;
	opacity: 1;
}

.entry-thumbnail-carousel .owl-nav > div,
.single-fw-portfolio .owl-nav > div,
.portfolio-shortcode .owl-nav > div{
	right: -40px;
	width: 80px;
	top: 50%;
	bottom: 0;
	margin: 0;
	height: 80px;
	text-align: center;
	border: 0;
	border-radius: 50% 0 0 50%;
	background-color: black;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.entry-thumbnail-carousel .owl-nav > div:after,
.single-fw-portfolio .owl-nav > div:after,
.portfolio-shortcode .owl-nav > div:after{
	position: absolute;
	top: 50%;
	color: #ffffff;
	font-size: 20px;
	left: 20px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.entry-thumbnail-carousel .owl-nav > div.owl-prev,
.single-fw-portfolio .owl-nav > div.owl-prev,
.portfolio-shortcode .owl-nav > div.owl-prev{
	left: -40px;
	right: auto;
	border-radius: 0 50% 50% 0;
}

.entry-thumbnail-carousel .owl-nav > div.owl-prev:after,
.single-fw-portfolio .owl-nav > div.owl-prev:after,
.portfolio-shortcode .owl-nav > div.owl-prev:after{
	left: auto;
	right: 20px;
}

.entry-thumbnail-carousel .owl-nav > div:hover,
.single-fw-portfolio .owl-nav > div:hover,
.portfolio-shortcode .owl-nav > div:hover{
	right: 0;
	background-color: #ffffff;
}

.entry-thumbnail-carousel .owl-nav > div:hover:after,
.single-fw-portfolio .owl-nav > div:hover:after,
.portfolio-shortcode .owl-nav > div:hover:after{
	color: #191f28;
	left: 40px;
}

.entry-thumbnail-carousel .owl-nav > div.owl-prev:hover,
.single-fw-portfolio .owl-nav > div.owl-prev:hover,
.portfolio-shortcode .owl-nav > div.owl-prev:hover{
	right: auto;
	left: 0;
}

.entry-thumbnail-carousel .owl-nav > div.owl-prev:hover:after,
.single-fw-portfolio .owl-nav > div.owl-prev:hover:after,
.portfolio-shortcode .owl-nav > div.owl-prev:hover:after{
	right: 40px;
	left: auto;
}

@media (max-width: 1199px) {
	.entry-thumbnail-carousel.nav-layout-2 .owl-nav,
	.single-fw-portfolio.nav-layout-2 .owl-nav,
	.portfolio-shortcode.nav-layout-2 .owl-nav{
		display: none;
	}
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div,
.single-fw-portfolio.nav-layout-2 .owl-nav > div,
.portfolio-shortcode.nav-layout-2 .owl-nav > div{
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #ffffff;
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div::after,
.single-fw-portfolio.nav-layout-2 .owl-nav > div::after,
.portfolio-shortcode.nav-layout-2 .owl-nav > div::after{
	right: auto !important;
	left: auto !important;
	color: #1f2732;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 40px;
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div:first-child,
.single-fw-portfolio.nav-layout-2 .owl-nav > div:first-child,
.portfolio-shortcode.nav-layout-2 .owl-nav > div:first-child{
	left: calc(50% - 21.5%);
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div:last-child,
.single-fw-portfolio.nav-layout-2 .owl-nav > div:last-child,
.portfolio-shortcode.nav-layout-2 .owl-nav > div:last-child{
	right: calc(50% - 21.5%);
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div:hover,
.single-fw-portfolio.nav-layout-2 .owl-nav > div:hover,
.portfolio-shortcode.nav-layout-2 .owl-nav > div:hover{
	background-color: black;
}

.entry-thumbnail-carousel.nav-layout-2 .owl-nav > div:hover:after,
.single-fw-portfolio.nav-layout-2 .owl-nav > div:hover:after,
.portfolio-shortcode.nav-layout-2 .owl-nav > div:hover:after{
	color: #ffffff;
}

/* text-nav */
.text-nav{
	padding-right: 30px;
	padding-left: 30px;
}

.text-nav .owl-nav{
	position: static;
}

.text-nav .owl-nav > div{
	top: 0;
	bottom: 0;
	right: 0;
	margin-top: 0;
	background-color: #f4f4f5;
}

.text-nav .owl-nav > div:first-child{
	left: 0;
}

.text-nav .owl-nav > div:after{
	position: absolute;
	top: 50%;
	left: 50%;
	content: "next";
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	line-height: 1;
	color: #1f2732;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
}

.text-nav .owl-nav > div:first-child:after{
	content: "prev";
}

@media (min-width: 600px) {
	.text-nav{
		padding-right: 60px;
		padding-left: 60px;
	}

	.text-nav .owl-nav > div{
		width: 60px;
	}
}

/* owl images fix */
.owl-carousel .owl-item{
	-webkit-transition: opacity 0.2s ease 0.1s;
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

.owl-carousel .owl-item.active{
	opacity: 1;
}

.owl-center .owl-item{
	opacity: 1;
}

.with_shadow_items .owl-stage-outer{
	margin: -20px;
	padding: 20px;
}

/* Flexslider http://www.woothemes.com/flexslider/ Copyright 2012 WooThemes Free to use under the GPLv2 license. http://www.gnu.org/licenses/gpl-2.0.html Contributing author: Tyler Smith (@mbmufffin) */
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus{
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav{
	margin: 0;
	padding: 0;
	list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider{
	margin: 0;
	padding: 0;
}

.flexslider .slides > li{
	display: none;
	-webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img{
	width: 100%;
	display: block;
}

.flex-pauseplay span{
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after{
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides{
	display: block;
}

* html .slides{
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you include js that eliminates this class on page load */
.no-js .slides > li:first-child{
	display: block;
}

.flexslider .slides > li{
	position: relative;
}

/* FlexSlider Default Theme */
.flexslider{
	margin: 0 0;
	position: relative;
	zoom: 1;
}

.flex-viewport{
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	transition: all 1s ease;
}

.loading .flex-viewport{
	max-height: 300px;
}

.flexslider .slides{
	zoom: 1;
	z-index: 1;
}

/* Direction Nav */
.flex-direction-nav{
	*height: 0;
}

.flex-direction-nav a{
	text-decoration: none;
	display: block;
	width: 80px;
	height: 80px;
	margin: -40px 0 0;
	position: absolute;
	border-radius: 50% 0 0 50%;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	opacity: 1;
	cursor: pointer;
	font-size: 0;
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.1);
	text-align: center;
}

.flex-direction-nav .flex-prev{
	left: -40px;
	border-radius: 0 50% 50% 0;
}

.flex-direction-nav .flex-next{
	right: -40px;
	text-align: right;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover{
	opacity: 1;
}

.flex-direction-nav .flex-disabled{
	opacity: 0;
	filter: alpha(opacity=0);
	cursor: default;
}

.flex-direction-nav a:before{
	font-family: "FontAwesome";
	font-size: 20px;
	content: '\f104';
	position: absolute;
	top: 23px;
	right: 20px;
	left: auto;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.flex-direction-nav a.flex-next:before{
	content: '\f105';
	right: auto;
	left: 20px;
}

.flex-direction-nav .flex-prev:hover,
.flex-direction-nav .flex-next:hover{
	background-color: black;
	color: #ffffff;
}

.flex-direction-nav .flex-prev:hover{
	left: 0;
}

.flex-direction-nav .flex-prev:hover:before{
	right: 40px;
}

.flex-direction-nav .flex-next:hover{
	right: 0;
}

.flex-direction-nav .flex-next:hover:before{
	left: 40px;
}

/* Pause/Play */
.flex-pauseplay a{
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}

.flex-pauseplay a:before{
	font-family: 'rt-icons-2';
	font-size: 20px;
	display: inline-block;
	content: "\e6a2";
}

.flex-pauseplay a:hover{
	opacity: 1;
}

.flex-pauseplay a.flex-play:before{
	content: "\e711";
}

/* Control Nav */
.flex-control-nav{
	left: 0;
	right: 0;
	position: absolute;
	bottom: 40px;
	text-align: center;
	z-index: 10;
}

.flex-control-nav li{
	text-align: center;
	display: inline-block;
	padding: 0 5px;
	zoom: 1;
	*display: inline;
}

.flex-control-nav li:first-child{
	padding-left: 0;
}

.flex-control-nav li:last-child{
	padding-right: 0;
}

.flex-control-paging li a{
	width: 10px;
	height: 10px;
	display: block;
	color: transparent;
	border: 2px solid #ffffff;
	cursor: pointer;
	text-indent: -9999px;
	border-radius: 50%;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a{
	border-color: #ffffff;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active{
	background-color: #ffffff;
	border-color: #ffffff;
}

.flex-control-thumbs{
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}

.flex-control-thumbs li{
	width: 25%;
	float: left;
	margin: 0;
}

.flex-control-thumbs img{
	width: 100%;
	display: block;
	opacity: .7;
	cursor: pointer;
}

.flex-control-thumbs img:hover{
	opacity: 1;
}

.flex-control-thumbs .flex-active{
	opacity: 1;
	cursor: default;
}

@media screen and (max-width: 600px) {
	.flex-direction-nav .flex-prev{
		top: 24%;
	}

	.flex-direction-nav .flex-next{
		top: 24%;
	}
}

/* custom nav for flex slider*/
.flex-custom-nav{
	text-align: center;
	position: absolute;
	bottom: 38px;
	right: 0;
	left: 0;
	z-index: 3;
	line-height: 1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.flex-custom-nav .flex-control-nav{
	width: auto;
	display: inline-block;
	position: static;
}

.flex-custom-nav .flex-custom-link{
	font-size: 12px;
	position: relative;
	bottom: 7px;
	margin: 0 8px;
	color: #1f2732;
}

.ds .flex-custom-nav .flex-custom-link,
.cs .flex-custom-nav .flex-custom-link{
	color: #ffffff;
}

.flex-custom-nav .flex-custom-link:hover{
	color: black;
}

.intro_section:hover .flex-custom-nav{
	opacity: 1;
}

/*! fullPage 3.0.0 https://github.com/alvarotrigo/fullPage.js @license GPLv3 for open source use only or Fullpage Commercial License for commercial use http://alvarotrigo.com/fullPage/pricing/ Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo */
html.fp-enabled,
.fp-enabled body{
	margin: 0;
	padding: 0;
	overflow: hidden; /*Avoid flicker on slides transitions for mobile phones #336 */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section{
	position: relative; /* Safari<=5 Android<=3 */ /* <=28 */
	box-sizing: border-box;
}

.fp-slide{
	float: left;
}

.fp-slide,
.fp-slidesContainer{
	height: 100%;
	display: block;
}

.fp-slides{
	z-index: 1;
	height: 100%;
	overflow: hidden;
	position: relative;
	-webkit-transition: all, 0.3s, ease-in-out;
	transition: all, 0.3s, ease-in-out;
}

.fp-section.fp-table,
.fp-slide.fp-table{
	display: table;
	table-layout: fixed;
	width: 100%;
	background-position: center;
}

.fp-tableCell{
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.fp-slidesContainer{
	float: left;
	position: relative;
}

.fp-controlArrow{
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-khtml-user-select: none; /* webkit (konqueror) browsers */
	-ms-user-select: none; /* IE10+ */
	position: absolute;
	z-index: 4;
	top: 50%;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	margin-top: -38px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev{
	left: 15px;
	width: 0;
	border-width: 38.5px 34px 38.5px 0;
	border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next{
	right: 15px;
	border-width: 38.5px 0 38.5px 34px;
	border-color: transparent transparent transparent #fff;
}

.fp-scrollable{
	overflow: hidden;
	position: relative;
}

.fp-scroller{
	overflow: hidden;
}

.iScrollIndicator{
	border: 0 !important;
}

.fp-notransition{
	-webkit-transition: none !important;
	transition: none !important;
}

#fp-nav{
	position: fixed;
	z-index: 2;
	margin-top: -32px;
	top: 50%;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
}

#fp-nav ul{
	position: relative;
}

#fp-nav ul .mouse-icon-2{
	content: '';
	background-image: url("../img/mouse_icon_2.png");
	background-repeat: no-repeat;
	display: block;
	width: 40px;
	height: 60px;
	margin: 0;
	z-index: 4;
	position: absolute;
	bottom: -100px;
	cursor: pointer;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

#fp-nav ul .mouse-icon-2:hover{
	opacity: 0.7;
}

@media (max-width: 480px) {
	#fp-nav ul .mouse-icon-2{
		display: none;
	}
}

#fp-nav.fp-right{
	right: 0;
}

@media (min-width: 480px) {
	#fp-nav.fp-right{
		right: 20px;
	}
}

@media (min-width: 1680px) {
	#fp-nav.fp-right{
		right: 80px;
	}
}

#fp-nav.fp-left{
	left: 0;
}

@media (min-width: 480px) {
	#fp-nav.fp-left{
		left: 20px;
	}
}

@media (min-width: 1680px) {
	#fp-nav.fp-left{
		left: 80px;
	}
}

.fp-slidesNav{
	position: absolute;
	z-index: 4;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	left: 0 !important;
	right: 0;
	margin: 0 auto !important;
}

.fp-slidesNav.fp-bottom{
	bottom: 17px;
}

@media (max-width: 1200px) {
	.fp-slidesNav.fp-bottom{
		bottom: 15%;
	}
}

.fp-slidesNav.fp-top{
	top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul{
	margin: 0;
	padding: 0;
}

.fp-slidesNav ul{
	text-align: center;
}

#fp-nav ul li,
.fp-slidesNav ul li{
	display: block;
	width: 14px;
	height: 13px;
	margin: 14px;
	position: relative;
}

.fp-slidesNav ul li{
	display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a{
	display: block;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
}

#fp-nav ul li a span{
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	height: 6px;
	width: 6px;
	border: 0;
	background: rgba(255, 255, 255, 0.1);
	left: 50%;
	top: 50%;
	margin: -3px 0 0 -3px;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span{
	border-radius: 100%;
	background: black;
}

#fp-nav ul li:hover a span{
	width: 10px;
	height: 10px;
	margin: -5px 0px 0px -5px;
}

.fp-slidesNav ul{
	text-align: center;
	counter-reset: number;
	list-style-type: none;
}

.fp-slidesNav ul li{
	display: inline-block;
	width: auto;
	height: auto;
	margin: 0;
}

.fp-slidesNav ul li span{
	padding: 14px;
	display: block;
	line-height: 1;
	color: #ffffff;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.fp-slidesNav ul li span::before{
	content: counter(number, decimal-leading-zero);
	counter-increment: number;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	z-index: 1;
}

.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a span{
	color: black;
}

#fp-nav ul li .fp-tooltip{
	position: absolute;
	top: -2px;
	color: #fff;
	font-size: 14px;
	font-family: arial, helvetica, sans-serif;
	white-space: nowrap;
	max-width: 220px;
	overflow: hidden;
	display: block;
	opacity: 0;
	width: 0;
	cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip{
	-webkit-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
	width: auto;
	opacity: 1;
}

#fp-nav ul li .fp-tooltip.fp-right{
	right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left{
	left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell{
	height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell{
	height: auto !important;
}

/* Helpdesk plugin */
.wordpress-helpdesk .widget,
.tax-faq_topics .widget{
	margin-bottom: 52px;
}

.wordpress-helpdesk .widgettitle,
.tax-faq_topics .widgettitle{
	font-size: 24px;
	margin-bottom: 27px;
	font-weight: 500;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-column,
.tax-faq_topics .wordpress-helpdesk-faq-column{
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform,
.tax-faq_topics .wordpress-helpdesk-faq-searchform{
	margin: 20px 0 45px;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap{
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .form-control,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .form-control{
	border-radius: 0;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #7f7f7f;
	font-weight: 500;
	padding: 6px 12px 6px 30px;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .form-control.wordpress-helpdesk-faq-searchterm,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .form-control.wordpress-helpdesk-faq-searchterm{
	height: 60px;
	background-color: #f4f4f5;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit{
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	line-height: 20px;
	text-align: center;
	background: transparent;
	opacity: 1;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	outline: 0;
	color: black;
	font-size: 14px;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit:hover,
.wordpress-helpdesk .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit:focus,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit:hover,
.tax-faq_topics .wordpress-helpdesk-faq-searchform-wrap .wordpress-helpdesk-faq-searchform .searchform-submit:focus{
	color: rgba(255, 73, 124, 0.6);
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed,
.tax-faq_topics .wordpress-helpdesk-faq-boxed{
	margin-bottom: 15px;
	background: transparent;
	box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.05);
	border: 0;
	border-radius: 0;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed .icon-wrap,
.tax-faq_topics .wordpress-helpdesk-faq-boxed .icon-wrap{
	margin-top: 20px;
	width: 120px;
	height: 120px;
	background: #ffffff;
	display: inline-block;
	border-radius: 50%;
	border: 5px solid #f4f4f5;
	padding: 5px;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed .icon-wrap .icon-inner,
.tax-faq_topics .wordpress-helpdesk-faq-boxed .icon-wrap .icon-inner{
	background: #f4f4f5;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	position: relative;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed i,
.tax-faq_topics .wordpress-helpdesk-faq-boxed i{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #a0ce4e;
	font-size: 50px !important;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed i.color-1,
.tax-faq_topics .wordpress-helpdesk-faq-boxed i.color-1{
	color: black;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed i.color-2,
.tax-faq_topics .wordpress-helpdesk-faq-boxed i.color-2{
	color: #a0ce4e;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed i.color-3,
.tax-faq_topics .wordpress-helpdesk-faq-boxed i.color-3{
	color: #00bea3;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed i.color-4,
.tax-faq_topics .wordpress-helpdesk-faq-boxed i.color-4{
	color: #f1894c;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed .wordpress-helpdesk-faq-boxed-title,
.tax-faq_topics .wordpress-helpdesk-faq-boxed .wordpress-helpdesk-faq-boxed-title{
	font-size: 30px;
	font-weight: 500;
	margin-top: 15px;
	margin-bottom: 15px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed .wordpress-helpdesk-faq-boxed-count,
.tax-faq_topics .wordpress-helpdesk-faq-boxed .wordpress-helpdesk-faq-boxed-count{
	font-size: 16px;
	font-weight: 300;
	color: #7f7f7f;
	margin-bottom: 20px;
}

.wordpress-helpdesk .wordpress-helpdesk-faq-boxed:hover .wordpress-helpdesk-faq-boxed-title,
.tax-faq_topics .wordpress-helpdesk-faq-boxed:hover .wordpress-helpdesk-faq-boxed-title{
	opacity: 0.6;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-row > *{
	padding-left: 15px;
	padding-right: 15px;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq{
	margin-bottom: 30px;
}

@media (min-width: 1440px) {
	.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq{
		padding-left: 40px;
		position: relative;
	}

	.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-title:before{
		content: "\f24d";
		font-family: FontAwesome;
		color: #a0ce4e;
		position: absolute;
		top: 6px;
		left: 0;
		font-size: 28px;
	}
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-title{
	font-weight: 500;
	padding: 10px 0 15px;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-title i{
	display: none;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-divider{
	display: none;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-link a{
	color: black;
}

.tax-faq_topics .wordpress-helpdesk .wordpress-helpdesk-faq .wordpress-helpdesk-faq-link a:hover{
	opacity: 0.7;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper{
	margin-top: 15px;
}

.wordpress-helpdesk-my-tickets label{
	display: inline-block;
	font-size: 0;
	width: 100%;
	margin-bottom: 20px;
	font-weight: 400 !important;
	line-height: 0;
}

.wordpress-helpdesk-my-tickets label .select_container{
	font-size: 16px !important;
	color: #7f7f7f !important;
	line-height: 1;
}

.wordpress-helpdesk-my-tickets label .select_container > select{
	border-radius: 5px;
	font-size: 12px;
}

.wordpress-helpdesk-my-tickets table.dataTable.no-footer{
	border-bottom: 1px solid #dedfe1;
}

.wordpress-helpdesk-my-tickets table.dataTable thead th,
.wordpress-helpdesk-my-tickets table.dataTable thead td{
	padding: 12px 18px;
	border-bottom: 1px solid #dedfe1;
}

.wordpress-helpdesk-my-tickets table.dataTable thead th,
.wordpress-helpdesk-my-tickets table.dataTable tfoot th{
	font-weight: 400;
}

.wordpress-helpdesk-my-tickets table.dataTable tbody th,
.wordpress-helpdesk-my-tickets table.dataTable tbody td{
	padding: 17px 14px;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_filter input{
	margin-left: 0;
	height: 60px;
	font-size: 12px;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	border-radius: 5px;
}

.wordpress-helpdesk-my-tickets .wordpress-helpdesk-my-tickets-header{
	background-color: #f4f4f5;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_info{
	clear: both;
	float: left;
	padding-top: 1.5em;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate{
	float: right;
	text-align: right;
	padding-top: 1em;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button{
	display: inline-block;
	margin-right: 4px;
	margin-left: 0;
	margin-bottom: 7px;
	padding: 0.5em 1.2em;
	min-width: 3em;
	text-align: center;
	line-height: inherit;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button,
.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.current{
	color: #7f7f7f !important;
	border: 2px solid #dedfe1;
	border-radius: 5px !important;
	background: transparent !important;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.current{
	border: 2px solid black;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button:active{
	box-shadow: none !important;
	border: 2px solid black;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.next{
	font-size: 0;
	width: 50px;
	height: 50px;
	line-height: 45px;
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.previous:before,
.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.next:before{
	font-family: FontAwesome;
	font-size: 18px;
	content: "\f104";
}

.wordpress-helpdesk-my-tickets .dataTables_wrapper .dataTables_paginate .paginate_button.next:before{
	content: "\f105";
}

/* Helpdesk widgets */
.wordpress-helpdesk-sidebar .widget-first .widgettitle{
	margin-top: 15px;
}

.wordpress-helpdesk-faq-list li,
.widget_faq_topics li{
	padding: 8px 0 8px 0;
	margin-bottom: 0.2em;
	list-style: none;
	border-bottom: 1px solid #dedfe1;
}

.wordpress-helpdesk-faq-list li i,
.widget_faq_topics li i{
	color: black;
	margin-right: 18px;
	font-size: 16px;
}

.wordpress-helpdesk-faq-list li,
.widget_faq_topics li{
	padding: 9px 0 9px 0;
	margin-bottom: 0;
	list-style: none;
	border-bottom: 1px solid rgba(76, 76, 76, 0.1);
}

.wordpress-helpdesk-faq-list li:first-child,
.widget_faq_topics li:first-child{
	border-top: 1px solid rgba(76, 76, 76, 0.1);
}

.widget_faq_live_search .wordpress-helpdesk-faq-searchform{
	margin: 0 0 45px;
}

/* Helpdesk single page */
.single-page-article .wordpress-helpdesk .dataTables_length label{
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
}

.single-ticket .wordpress-helpdesk-single-title{
	font-size: 30px;
	line-height: 1.2;
	margin-top: 15px;
	margin-bottom: 25px;
}

.single-ticket .wordpress-helpdesk-single-description-title{
	font-size: 24px;
	margin-top: 30px;
	margin-bottom: 15px;
}

.single-ticket .wordpress-helpdesk-ticket-attachments-title{
	font-size: 24px;
	margin-top: 0;
	margin-bottom: 15px;
}

.single-ticket .wordpress-helpdesk-agent-box,
.single-ticket .wordpress-helpdesk-reporter-box{
	font-size: 12px;
	color: #1f2732;
}

.single-ticket .wordpress-helpdesk-agent-box .wordpress-helpdesk-col-sm-3,
.single-ticket .wordpress-helpdesk-agent-box .wordpress-helpdesk-col-sm-9,
.single-ticket .wordpress-helpdesk-reporter-box .wordpress-helpdesk-col-sm-3,
.single-ticket .wordpress-helpdesk-reporter-box .wordpress-helpdesk-col-sm-9{
	padding-right: 15px;
	padding-left: 15px;
}

.single-ticket .wordpress-helpdesk-agent-box .wordpress-helpdesk-agent-box-title,
.single-ticket .wordpress-helpdesk-agent-box .wordpress-helpdesk-reporter-box-title,
.single-ticket .wordpress-helpdesk-reporter-box .wordpress-helpdesk-agent-box-title,
.single-ticket .wordpress-helpdesk-reporter-box .wordpress-helpdesk-reporter-box-title{
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 0.5em;
}

.wordpress-helpdesk-comments .comment-form p.form-submit{
	margin-top: 34px;
	margin-bottom: 0;
}

.wordpress-helpdesk-comments .comment-form p.form-submit .btn{
	color: #ffffff !important;
}

.wordpress-helpdesk-comments .comment-form p.form-submit .btn span{
	font-size: 12px;
	line-height: 1.6em;
	font-weight: 500;
	text-transform: uppercase;
}

.wordpress-helpdesk-comments .comment-form p.form-submit .btn:hover,
.wordpress-helpdesk-comments .comment-form p.form-submit .btn:focus{
	color: #1f2732;
}

.wordpress-helpdesk-comments .wp-editor-wrap{
	position: relative;
	width: 100%;
}

.wordpress-helpdesk-comments .comments-area{
	margin-top: 30px;
}

.wordpress-helpdesk-comments .comments-area .quicktags-toolbar .ed_button{
	width: auto;
	display: inline-block;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

.wordpress-helpdesk-comments .comments-area .quicktags-toolbar .ed_button:hover{
	background-color: black;
}

/*
prettyPhoto
*/
div.facebook .pp_description{
	margin: 0 37px 0 0;
}

div.pp_pic_holder a:focus{
	outline: none;
}

div.pp_overlay{
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder{
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_content{
	height: 40px;
	min-width: 40px;
	overflow: hidden;
}

* html .pp_content{
	width: 40px;
}

.pp_content_container{
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left{
	padding-left: 20px;
}

.pp_content_container .pp_right{
	padding-right: 20px;
}

.pp_content_container .pp_details{
	float: left;
	padding: 8px 10px;
	line-height: 1;
	background-color: #ffffff;
}

.pp_description{
	display: none;
	margin: 0;
}

.pp_social{
	float: left;
	margin: 0 0 0 30px;
}

.pp_social .facebook{
	float: left;
	margin-left: 5px;
	width: auto;
	overflow: hidden;
}

.pp_social .twitter{
	float: left;
}

.pp_nav p{
	float: left;
	white-space: nowrap;
	margin: 4px 6px;
	font-weight: bold;
	opacity: 0.7;
}

.pp_nav .pp_play,
.pp_nav .pp_pause,
a.pp_arrow_previous,
a.pp_arrow_next{
	float: left;
	text-align: center;
	font-size: 0;
	color: transparent;
}

.pp_nav .pp_play:before,
.pp_nav .pp_pause:before,
a.pp_arrow_previous:before,
a.pp_arrow_next:before{
	line-height: 20px;
	display: inline-block;
	width: 20px;
	font-family: 'FontAwesome';
	font-size: 12px;
	color: #1f2732;
	opacity: 0.7;
	content: "\f04b";
}

.pp_nav .pp_play:hover:before,
.pp_nav .pp_pause:hover:before,
a.pp_arrow_previous:hover:before,
a.pp_arrow_next:hover:before{
	opacity: 1;
}

.pp_nav .pp_pause:before{
	content: "\f04c";
}

a.pp_arrow_previous:before{
	content: "\f04a";
}

a.pp_arrow_next:before{
	content: "\f04e";
}

a.pp_arrow_previous.disabled,
a.pp_arrow_next.disabled{
	visibility: hidden;
	cursor: default;
}

.pp_hoverContainer{
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery{
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery div{
	float: left;
	overflow: hidden;
	position: relative;
}

.pp_gallery ul{
	float: left;
	height: 35px;
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 5px;
	padding: 0;
}

.pp_gallery ul a{
	border: 1px rgba(0, 0, 0, 0.1) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery ul .selected a{
	border-color: black;
}

.pp_gallery ul a img{
	border: 0;
}

.pp_gallery li{
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 0;
}

.pp_gallery li.default a{
	background: url("../img/prettyPhoto/facebook/default_thumbnail.html") 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

div.facebook .pp_gallery .pp_arrow_previous,
div.facebook .pp_gallery .pp_arrow_next{
	margin-top: 7px;
}

a.pp_next,
a.pp_previous{
	position: relative;
	display: block;
	float: right;
	height: 100%;
	width: 49%;
	font-size: 0;
	color: transparent;
	opacity: 0;
}

a.pp_next:before,
a.pp_previous:before{
	line-height: 1;
	background-color: rgba(31, 39, 50, 0.3);
	font-size: 18px;
	padding: 10px 15px 10px 18px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f105";
	position: absolute;
	top: 50%;
	margin-top: -3px;
	right: 30px;
}

a.pp_next:hover,
a.pp_previous:hover{
	opacity: 1;
}

a.pp_previous{
	float: left;
}

a.pp_previous:before{
	content: "\f104";
	right: auto;
	padding: 10px 18px 10px 15px;
	left: 30px;
}

a.pp_expand,
a.pp_contract{
	cursor: pointer;
	display: none;
	position: absolute;
	right: 30px;
	top: 19px;
	z-index: 20000;
	font-size: 0;
	color: transparent;
	text-align: center;
}

a.pp_expand:before,
a.pp_contract:before{
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f0b2";
	background-color: rgba(31, 39, 50, 0.3);
	padding: 12px 13px;
}

a.pp_contract:before{
	content: "\f066";
}

a.pp_close{
	display: none;
	position: absolute;
	right: -7px;
	top: -25px;
	font-size: 0;
	color: transparent;
}

a.pp_close:before{
	background-color: #191f28;
	padding: 6px 8px;
	font-size: 14px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f00d";
}

.pp_loaderIcon{
	height: 50px;
	width: 50px;
	left: 50%;
	top: 50%;
	position: absolute;
	margin: -25px 0 0 -25px;
	text-align: center;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}

.pp_loaderIcon:before{
	display: block;
	font-family: 'FontAwesome';
	content: "\f1ce";
	color: #ffffff;
	font-size: 50px;
	line-height: 50px;
	opacity: 0.6;
}

#pp_full_res{
	line-height: 1;
}

#pp_full_res .pp_inline{
	text-align: left;
}

#pp_full_res .pp_inline p{
	margin: 0 0 15px;
}

div.ppt{
	color: #ffffff;
	display: none;
	font-size: 18px;
	z-index: 9999;
	margin: 0 0 5px 20px;
}

.pp_top,
.pp_bottom{
	position: relative;
}

* html .pp_top,
* html .pp_bottom{
	padding: 0 20px;
}

.pp_top .pp_left,
.pp_bottom .pp_left{
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle,
.pp_bottom .pp_middle{
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle,
* html .pp_bottom .pp_middle{
	left: 0;
	position: static;
}

.pp_top .pp_right,
.pp_bottom .pp_right{
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_fade,
.pp_gallery li.default a img{
	display: none;
}

/*
easy pie chart
*/
.chart{
	position: relative;
	display: inline-block;
	min-height: 150px;
	min-width: 150px;
	margin-top: 0;
	margin-bottom: 10px;
	text-align: center;
}

.chart .chart-meta{
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -32px;
}

.chart .chart-meta .title{
	margin-top: 0;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	color: #1f2732;
}

.ds .chart .chart-meta .title{
	color: #ffffff;
}

.percent{
	display: inline-block;
	font-size: 54px;
	font-weight: 500;
	z-index: 2;
	color: #1f2732;
}

.ds .percent{
	color: #ffffff;
}

.percent:after{
	content: '%';
}

.angular{
	margin-top: 100px;
}

.angular .chart{
	margin-top: 0;
}

/*
timetable
*/
#timetable span{
	display: block;
}

#timetable th,
#timetable td{
	vertical-align: middle;
	font-weight: normal;
	text-align: center;
	padding: 25px 5px;
}

#timetable thead{
	color: #ffffff;
}

#timetable thead th{
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-width: 0 1px;
}

#timetable tbody th,
#timetable tbody td{
	border: 1px solid #dedfe1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current{
	background-color: black;
	border-color: black;
}

#timetable tbody .current a{
	color: #ffffff;
}

/*
totop
*/
#toTop{
	bottom: 10px;
	right: 10px;
	display: none;
	width: 50px;
	height: 50px;
	overflow: hidden;
	position: fixed;
	text-decoration: none;
	z-index: 9999;
	font-size: 0;
	color: transparent;
	background-color: black;
	border-radius: 50%;
	opacity: 0.7;
}

#toTop:after{
	color: #ffffff;
	content: '\f106';
	font-size: 16px;
	line-height: 47px;
	font-family: 'FontAwesome';
	text-align: center;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

#toTopHover,
#toTop:hover{
	bottom: 8px;
	opacity: 1;
}

#toTop:hover:after{
	color: #ffffff;
}

#toTop:active,
#toTop:focus{
	outline: medium none;
}

.modal-open #toTop{
	visibility: hidden;
}

/* Accordion slider */
.wpos-tab-slider{
	position: relative;
	margin-left: 0;
	margin-right: 0;
}

.container-fluid .col-xs-12.col-12{
	padding-left: 0;
	padding-right: 0;
}

.wpos-tab-slider .as-layer.as-padding{
	width: 100%;
	position: absolute;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	background: black;
	color: #ffffff;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 24px;
	padding: 15px 20px;
	opacity: 0 !important;
	text-align: center;
}

.wpos-tab-slider.as-horizontal .as-wposslide:hover .as-layer.as-padding,
.wpos-tab-slider.as-horizontal .as-wposslide.as-opened .as-layer.as-padding{
	opacity: 1 !important;
	visibility: visible !important;
}

.wpos-tab-slider .as-pagination-button{
	border-color: #ffffff;
}

.wpos-tab-slider .as-pagination-buttons .as-selected{
	background-color: #ffffff;
}

/* custom classes */
p.inline-section{
	display: inline-block;
}

@media (max-width: 1440px) {
	.wpos-tab-slider .as-layer.as-padding{
		font-size: 20px;
	}
}

@media (max-width: 1199px) {
	.wpos-tab-slider .as-layer.as-padding{
		font-size: 18px;
	}

	.card .card-body{
		margin-bottom: 0;
	}
}

@media (max-width: 1024px) {
	.wpos-tab-slider .as-layer.as-padding{
		font-size: 16px;
		padding: 10px 5px;
	}
}

/*
**icons
*/
a[class*='soc-']:before{
	font-family: 'socicon';
	font-size: 14px;
}

a.social-icon{
	display: inline-block;
	line-height: 1;
	text-align: center;
	vertical-align: middle;
}

a.social-icon span{
	opacity: 0.7;
	display: block;
	font-size: 14px;
	text-transform: uppercase;
}

a.social-icon span:first-child{
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon [class*="rt-icon"] + span{
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon{
	margin-left: 16px;
}

@media (min-width: 1400px) {
	a.social-icon + a.social-icon,
	.tooltip + a.social-icon{
		margin-left: 25px;
	}
}

a.social-icon.bg-icon + a.social-icon.bg-icon{
	margin-left: 7px;
}

a.social-icon.color-bg-icon + a.social-icon.color-bg-icon{
	margin-left: 1px;
}

a.social-icon.block-icon{
	width: 100%;
}

a[class*='soc-'].rounded-icon{
	border-radius: 50%;
}

a.big-icon{
	width: 112px;
	height: 112px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
}

a.big-icon[class*='soc-']:before{
	font-size: 32px;
}

a.big-icon [class*='rt-icon']:before{
	font-size: 32px;
}

/*corporate colors*/
a.color-icon.soc-twitter,
a[class*="soc-"].soc-twitter:hover{
	color: #00abef;
	border-color: #00abef;
}

a.color-icon.soc-facebook,
a[class*="soc-"].soc-facebook:hover{
	color: #507cbe;
	border-color: #507cbe;
}

a.color-icon.soc-google,
a[class*="soc-"].soc-google:hover{
	color: #d93e2d;
	border-color: #d93e2d;
}

a.color-icon.soc-pinterest,
a[class*="soc-"].soc-pinterest:hover{
	color: #c92619;
	border-color: #c92619;
}

a.color-icon.soc-foursquare,
a[class*="soc-"].soc-foursquare:hover{
	color: #23b7e0;
	border-color: #23b7e0;
}

a.color-icon.soc-yahoo,
a[class*="soc-"].soc-yahoo:hover{
	color: #6e2a85;
	border-color: #6e2a85;
}

a.color-icon.soc-skype,
a[class*="soc-"].soc-skype:hover{
	color: #28abe3;
	border-color: #28abe3;
}

a.color-icon.soc-yelp,
a[class*="soc-"].soc-yelp:hover{
	color: #c83218;
	border-color: #c83218;
}

a.color-icon.soc-feedburner,
a[class*="soc-"].soc-feedburner:hover{
	color: #ffcc00;
	border-color: #ffcc00;
}

a.color-icon.soc-linkedin,
a[class*="soc-"].soc-linkedin:hover{
	color: #3371b7;
	border-color: #3371b7;
}

a.color-icon.soc-viadeo,
a[class*="soc-"].soc-viadeo:hover{
	color: #e4a000;
	border-color: #e4a000;
}

a.color-icon.soc-xing,
a[class*="soc-"].soc-xing:hover{
	color: #005a60;
	border-color: #005a60;
}

a.color-icon.soc-myspace,
a[class*="soc-"].soc-myspace:hover{
	color: #323232;
	border-color: #323232;
}

a.color-icon.soc-soundcloud,
a[class*="soc-"].soc-soundcloud:hover{
	color: #fe3801;
	border-color: #fe3801;
}

a.color-icon.soc-spotify,
a[class*="soc-"].soc-spotify:hover{
	color: #7bb342;
	border-color: #7bb342;
}

a.color-icon.soc-grooveshark,
a[class*="soc-"].soc-grooveshark:hover{
	color: #f67e03;
	border-color: #f67e03;
}

a.color-icon.soc-lastfm,
a[class*="soc-"].soc-lastfm:hover{
	color: #d41316;
	border-color: #d41316;
}

a.color-icon.soc-youtube,
a[class*="soc-"].soc-youtube:hover{
	color: #d12121;
	border-color: #d12121;
}

a.color-icon.soc-vimeo,
a[class*="soc-"].soc-vimeo:hover{
	color: #51b5e7;
	border-color: #51b5e7;
}

a.color-icon.soc-dailymotion,
a[class*="soc-"].soc-dailymotion:hover{
	color: #004e72;
	border-color: #004e72;
}

a.color-icon.soc-vine,
a[class*="soc-"].soc-vine:hover{
	color: #00b389;
	border-color: #00b389;
}

a.color-icon.soc-flickr,
a[class*="soc-"].soc-flickr:hover{
	color: #0062dd;
	border-color: #0062dd;
}

a.color-icon.soc-500px,
a[class*="soc-"].soc-500px:hover{
	color: #58a9de;
	border-color: #58a9de;
}

a.color-icon.soc-instagram,
a[class*="soc-"].soc-instagram:hover{
	color: #9c7c6e;
	border-color: #9c7c6e;
}

a.color-icon.soc-wordpress,
a[class*="soc-"].soc-wordpress:hover{
	color: #464646;
	border-color: #464646;
}

a.color-icon.soc-tumblr,
a[class*="soc-"].soc-tumblr:hover{
	color: #45556c;
	border-color: #45556c;
}

a.color-icon.soc-blogger,
a[class*="soc-"].soc-blogger:hover{
	color: #ec661c;
	border-color: #ec661c;
}

a.color-icon.soc-technorati,
a[class*="soc-"].soc-technorati:hover{
	color: #5cb030;
	border-color: #5cb030;
}

a.color-icon.soc-reddit,
a[class*="soc-"].soc-reddit:hover{
	color: #e74a1e;
	border-color: #e74a1e;
}

a.color-icon.soc-dribbble,
a[class*="soc-"].soc-dribbble:hover{
	color: #e84d88;
	border-color: #e84d88;
}

a.color-icon.soc-stumbleupon,
a[class*="soc-"].soc-stumbleupon:hover{
	color: #e64011;
	border-color: #e64011;
}

a.color-icon.soc-digg,
a[class*="soc-"].soc-digg:hover{
	color: #1d1d1b;
	border-color: #1d1d1b;
}

a.color-icon.soc-envato,
a[class*="soc-"].soc-envato:hover{
	color: #597c3a;
	border-color: #597c3a;
}

a.color-icon.soc-behance,
a[class*="soc-"].soc-behance:hover{
	color: #007eff;
	border-color: #007eff;
}

a.color-icon.soc-delicious,
a[class*="soc-"].soc-delicious:hover{
	color: #31a9ff;
	border-color: #31a9ff;
}

a.color-icon.soc-deviantart,
a[class*="soc-"].soc-deviantart:hover{
	color: #c5d200;
	border-color: #c5d200;
}

a.color-icon.soc-forrst,
a[class*="soc-"].soc-forrst:hover{
	color: #5b9a68;
	border-color: #5b9a68;
}

a.color-icon.soc-play,
a[class*="soc-"].soc-play:hover{
	color: #5acdbd;
	border-color: #5acdbd;
}

a.color-icon.soc-zerply,
a[class*="soc-"].soc-zerply:hover{
	color: #9dbc7a;
	border-color: #9dbc7a;
}

a.color-icon.soc-wikipedia,
a[class*="soc-"].soc-wikipedia:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-apple,
a[class*="soc-"].soc-apple:hover{
	color: #b9bfc1;
	border-color: #b9bfc1;
}

a.color-icon.soc-flattr,
a[class*="soc-"].soc-flattr:hover{
	color: #f67c1a;
	border-color: #f67c1a;
}

a.color-icon.soc-github,
a[class*="soc-"].soc-github:hover{
	color: #221e1b;
	border-color: #221e1b;
}

a.color-icon.soc-chimein,
a[class*="soc-"].soc-chimein:hover{
	color: #888688;
	border-color: #888688;
}

a.color-icon.soc-friendfeed,
a[class*="soc-"].soc-friendfeed:hover{
	color: #2f72c4;
	border-color: #2f72c4;
}

a.color-icon.soc-newsvine,
a[class*="soc-"].soc-newsvine:hover{
	color: #075b2f;
	border-color: #075b2f;
}

a.color-icon.soc-identica,
a[class*="soc-"].soc-identica:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-bebo,
a[class*="soc-"].soc-bebo:hover{
	color: #ef1011;
	border-color: #ef1011;
}

a.color-icon.soc-zynga,
a[class*="soc-"].soc-zynga:hover{
	color: #dc0606;
	border-color: #dc0606;
}

a.color-icon.soc-steam,
a[class*="soc-"].soc-steam:hover{
	color: #8f8d8a;
	border-color: #8f8d8a;
}

a.color-icon.soc-xbox,
a[class*="soc-"].soc-xbox:hover{
	color: #92c83e;
	border-color: #92c83e;
}

a.color-icon.soc-windows,
a[class*="soc-"].soc-windows:hover{
	color: #00bdf6;
	border-color: #00bdf6;
}

a.color-icon.soc-outlook,
a[class*="soc-"].soc-outlook:hover{
	color: #0072c6;
	border-color: #0072c6;
}

a.color-icon.soc-coderwall,
a[class*="soc-"].soc-coderwall:hover{
	color: #3e8dcc;
	border-color: #3e8dcc;
}

a.color-icon.soc-tripadvisor,
a[class*="soc-"].soc-tripadvisor:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-appnet,
a[class*="soc-"].soc-appnet:hover{
	color: #494949;
	border-color: #494949;
}

a.color-icon.soc-goodreads,
a[class*="soc-"].soc-goodreads:hover{
	color: #1abc9c;
	border-color: #1abc9c;
}

a.color-icon.soc-tripit,
a[class*="soc-"].soc-tripit:hover{
	color: #1982c3;
	border-color: #1982c3;
}

a.color-icon.soc-lanyrd,
a[class*="soc-"].soc-lanyrd:hover{
	color: #3c80c9;
	border-color: #3c80c9;
}

a.color-icon.soc-slideshare,
a[class*="soc-"].soc-slideshare:hover{
	color: #4ba3a6;
	border-color: #4ba3a6;
}

a.color-icon.soc-buffer,
a[class*="soc-"].soc-buffer:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-rss,
a[class*="soc-"].soc-rss:hover{
	color: #f26109;
	border-color: #f26109;
}

a.color-icon.soc-vkontakte,
a[class*="soc-"].soc-vkontakte:hover{
	color: #5a7fa6;
	border-color: #5a7fa6;
}

a.color-icon.soc-disqus,
a[class*="soc-"].soc-disqus:hover{
	color: #2e9fff;
	border-color: #2e9fff;
}

a.color-icon.soc-houzz,
a[class*="soc-"].soc-houzz:hover{
	color: #7cc04b;
	border-color: #7cc04b;
}

a.color-icon.soc-mail,
a[class*="soc-"].soc-mail:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-patreon,
a[class*="soc-"].soc-patreon:hover{
	color: #e44727;
	border-color: #e44727;
}

a.color-icon.soc-paypal,
a[class*="soc-"].soc-paypal:hover{
	color: #009cde;
	border-color: #009cde;
}

a.color-icon.soc-playstation,
a[class*="soc-"].soc-playstation:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-smugmug,
a[class*="soc-"].soc-smugmug:hover{
	color: #acfd32;
	border-color: #acfd32;
}

a.color-icon.soc-swarm,
a[class*="soc-"].soc-swarm:hover{
	color: #fc9d3c;
	border-color: #fc9d3c;
}

a.color-icon.soc-triplej,
a[class*="soc-"].soc-triplej:hover{
	color: #e53531;
	border-color: #e53531;
}

a.color-icon.soc-yammer,
a[class*="soc-"].soc-yammer:hover{
	color: #1175c4;
	border-color: #1175c4;
}

a.color-icon.soc-stackoverflow,
a[class*="soc-"].soc-stackoverflow:hover{
	color: #fd9827;
	border-color: #fd9827;
}

a.color-icon.soc-drupal,
a[class*="soc-"].soc-drupal:hover{
	color: #00598e;
	border-color: #00598e;
}

a.color-icon.soc-odnoklassniki,
a[class*="soc-"].soc-odnoklassniki:hover{
	color: #f48420;
	border-color: #f48420;
}

a.color-icon.soc-android,
a[class*="soc-"].soc-android:hover{
	color: #8ec047;
	border-color: #8ec047;
}

a.color-icon.soc-meetup,
a[class*="soc-"].soc-meetup:hover{
	color: #e2373c;
	border-color: #e2373c;
}

a.color-icon.soc-persona,
a[class*="soc-"].soc-persona:hover{
	color: #e6753d;
	border-color: #e6753d;
}

a.color-icon.soc-amazon,
a[class*="soc-"].soc-amazon:hover{
	color: #ff9900;
	border-color: #ff9900;
}

a.color-icon.soc-ello,
a[class*="soc-"].soc-ello:hover{
	color: #000000;
	border-color: #000000;
}

a.color-icon.soc-mixcloud,
a[class*="soc-"].soc-mixcloud:hover{
	color: #314359;
	border-color: #314359;
}

a.color-icon.soc-8tracks,
a[class*="soc-"].soc-8tracks:hover{
	color: #122c4b;
	border-color: #122c4b;
}

a.color-icon.soc-twitch,
a[class*="soc-"].soc-twitch:hover{
	color: #6441a5;
	border-color: #6441a5;
}

a.color-icon.soc-airbnb,
a[class*="soc-"].soc-airbnb:hover{
	color: #ff5a5f;
	border-color: #ff5a5f;
}

a.color-icon.soc-pocket,
a[class*="soc-"].soc-pocket:hover{
	color: #ed4055;
	border-color: #ed4055;
}

/* Team Slider Shortcode */
.ls .shortcode-team-slider a.color-icon.soc-twitter,
.ls .shortcode-team-slider a[class*="soc-"].soc-twitter:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-facebook,
.ls .shortcode-team-slider a[class*="soc-"].soc-facebook:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-google,
.ls .shortcode-team-slider a[class*="soc-"].soc-google:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-pinterest,
.ls .shortcode-team-slider a[class*="soc-"].soc-pinterest:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-foursquare,
.ls .shortcode-team-slider a[class*="soc-"].soc-foursquare:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-yahoo,
.ls .shortcode-team-slider a[class*="soc-"].soc-yahoo:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-skype,
.ls .shortcode-team-slider a[class*="soc-"].soc-skype:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-yelp,
.ls .shortcode-team-slider a[class*="soc-"].soc-yelp:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-feedburner,
.ls .shortcode-team-slider a[class*="soc-"].soc-feedburner:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-linkedin,
.ls .shortcode-team-slider a[class*="soc-"].soc-linkedin:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-viadeo,
.ls .shortcode-team-slider a[class*="soc-"].soc-viadeo:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-xing,
.ls .shortcode-team-slider a[class*="soc-"].soc-xing:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-myspace,
.ls .shortcode-team-slider a[class*="soc-"].soc-myspace:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-soundcloud,
.ls .shortcode-team-slider a[class*="soc-"].soc-soundcloud:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-spotify,
.ls .shortcode-team-slider a[class*="soc-"].soc-spotify:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-grooveshark,
.ls .shortcode-team-slider a[class*="soc-"].soc-grooveshark:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-lastfm,
.ls .shortcode-team-slider a[class*="soc-"].soc-lastfm:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-youtube,
.ls .shortcode-team-slider a[class*="soc-"].soc-youtube:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-vimeo,
.ls .shortcode-team-slider a[class*="soc-"].soc-vimeo:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-dailymotion,
.ls .shortcode-team-slider a[class*="soc-"].soc-dailymotion:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-vine,
.ls .shortcode-team-slider a[class*="soc-"].soc-vine:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-flickr,
.ls .shortcode-team-slider a[class*="soc-"].soc-flickr:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-500px,
.ls .shortcode-team-slider a[class*="soc-"].soc-500px:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-instagram,
.ls .shortcode-team-slider a[class*="soc-"].soc-instagram:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-wordpress,
.ls .shortcode-team-slider a[class*="soc-"].soc-wordpress:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-tumblr,
.ls .shortcode-team-slider a[class*="soc-"].soc-tumblr:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-blogger,
.ls .shortcode-team-slider a[class*="soc-"].soc-blogger:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-technorati,
.ls .shortcode-team-slider a[class*="soc-"].soc-technorati:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-reddit,
.ls .shortcode-team-slider a[class*="soc-"].soc-reddit:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-dribbble,
.ls .shortcode-team-slider a[class*="soc-"].soc-dribbble:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-stumbleupon,
.ls .shortcode-team-slider a[class*="soc-"].soc-stumbleupon:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-digg,
.ls .shortcode-team-slider a[class*="soc-"].soc-digg:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-envato,
.ls .shortcode-team-slider a[class*="soc-"].soc-envato:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-behance,
.ls .shortcode-team-slider a[class*="soc-"].soc-behance:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-delicious,
.ls .shortcode-team-slider a[class*="soc-"].soc-delicious:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-deviantart,
.ls .shortcode-team-slider a[class*="soc-"].soc-deviantart:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-forrst,
.ls .shortcode-team-slider a[class*="soc-"].soc-forrst:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-play,
.ls .shortcode-team-slider a[class*="soc-"].soc-play:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-zerply,
.ls .shortcode-team-slider a[class*="soc-"].soc-zerply:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-wikipedia,
.ls .shortcode-team-slider a[class*="soc-"].soc-wikipedia:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-apple,
.ls .shortcode-team-slider a[class*="soc-"].soc-apple:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-flattr,
.ls .shortcode-team-slider a[class*="soc-"].soc-flattr:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-github,
.ls .shortcode-team-slider a[class*="soc-"].soc-github:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-chimein,
.ls .shortcode-team-slider a[class*="soc-"].soc-chimein:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-friendfeed,
.ls .shortcode-team-slider a[class*="soc-"].soc-friendfeed:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-newsvine,
.ls .shortcode-team-slider a[class*="soc-"].soc-newsvine:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-identica,
.ls .shortcode-team-slider a[class*="soc-"].soc-identica:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-bebo,
.ls .shortcode-team-slider a[class*="soc-"].soc-bebo:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-zynga,
.ls .shortcode-team-slider a[class*="soc-"].soc-zynga:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-steam,
.ls .shortcode-team-slider a[class*="soc-"].soc-steam:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-xbox,
.ls .shortcode-team-slider a[class*="soc-"].soc-xbox:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-windows,
.ls .shortcode-team-slider a[class*="soc-"].soc-windows:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-outlook,
.ls .shortcode-team-slider a[class*="soc-"].soc-outlook:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-coderwall,
.ls .shortcode-team-slider a[class*="soc-"].soc-coderwall:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-tripadvisor,
.ls .shortcode-team-slider a[class*="soc-"].soc-tripadvisor:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-appnet,
.ls .shortcode-team-slider a[class*="soc-"].soc-appnet:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-goodreads,
.ls .shortcode-team-slider a[class*="soc-"].soc-goodreads:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-tripit,
.ls .shortcode-team-slider a[class*="soc-"].soc-tripit:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-lanyrd,
.ls .shortcode-team-slider a[class*="soc-"].soc-lanyrd:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-slideshare,
.ls .shortcode-team-slider a[class*="soc-"].soc-slideshare:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-buffer,
.ls .shortcode-team-slider a[class*="soc-"].soc-buffer:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-rss,
.ls .shortcode-team-slider a[class*="soc-"].soc-rss:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-vkontakte,
.ls .shortcode-team-slider a[class*="soc-"].soc-vkontakte:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-disqus,
.ls .shortcode-team-slider a[class*="soc-"].soc-disqus:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-houzz,
.ls .shortcode-team-slider a[class*="soc-"].soc-houzz:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-mail,
.ls .shortcode-team-slider a[class*="soc-"].soc-mail:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-patreon,
.ls .shortcode-team-slider a[class*="soc-"].soc-patreon:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-paypal,
.ls .shortcode-team-slider a[class*="soc-"].soc-paypal:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-playstation,
.ls .shortcode-team-slider a[class*="soc-"].soc-playstation:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-smugmug,
.ls .shortcode-team-slider a[class*="soc-"].soc-smugmug:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-swarm,
.ls .shortcode-team-slider a[class*="soc-"].soc-swarm:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-triplej,
.ls .shortcode-team-slider a[class*="soc-"].soc-triplej:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-yammer,
.ls .shortcode-team-slider a[class*="soc-"].soc-yammer:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-stackoverflow,
.ls .shortcode-team-slider a[class*="soc-"].soc-stackoverflow:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-drupal,
.ls .shortcode-team-slider a[class*="soc-"].soc-drupal:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-odnoklassniki,
.ls .shortcode-team-slider a[class*="soc-"].soc-odnoklassniki:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-android,
.ls .shortcode-team-slider a[class*="soc-"].soc-android:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-meetup,
.ls .shortcode-team-slider a[class*="soc-"].soc-meetup:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-persona,
.ls .shortcode-team-slider a[class*="soc-"].soc-persona:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-amazon,
.ls .shortcode-team-slider a[class*="soc-"].soc-amazon:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-ello,
.ls .shortcode-team-slider a[class*="soc-"].soc-ello:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-mixcloud,
.ls .shortcode-team-slider a[class*="soc-"].soc-mixcloud:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-8tracks,
.ls .shortcode-team-slider a[class*="soc-"].soc-8tracks:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-twitch,
.ls .shortcode-team-slider a[class*="soc-"].soc-twitch:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-airbnb,
.ls .shortcode-team-slider a[class*="soc-"].soc-airbnb:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

.ls .shortcode-team-slider a.color-icon.soc-pocket,
.ls .shortcode-team-slider a[class*="soc-"].soc-pocket:hover{
	border-color: rgba(25, 31, 40, 0.1);
}

/*corporate background colors*/
.color-bg-icon{
	color: #ffffff;
}

.color-bg-icon.soc-twitter,
a.soc-twitter.bg-icon:hover,
.soc-twitter.color-icon.border-icon:hover{
	background-color: #00abef;
}

.color-bg-icon.soc-facebook,
a.soc-facebook.bg-icon:hover,
.soc-facebook.color-icon.border-icon:hover{
	background-color: #507cbe;
}

.color-bg-icon.soc-google,
a.soc-google.bg-icon:hover,
.soc-google.color-icon.border-icon:hover{
	background-color: #d93e2d;
}

.color-bg-icon.soc-pinterest,
a.soc-pinterest.bg-icon:hover,
.soc-pinterest.color-icon.border-icon:hover{
	background-color: #c92619;
}

.color-bg-icon.soc-foursquare,
a.soc-foursquare.bg-icon:hover,
.soc-foursquare.color-icon.border-icon:hover{
	background-color: #23b7e0;
}

.color-bg-icon.soc-yahoo,
a.soc-yahoo.bg-icon:hover,
.soc-yahoo.color-icon.border-icon:hover{
	background-color: #6e2a85;
}

.color-bg-icon.soc-skype,
a.soc-skype.bg-icon:hover,
.soc-skype.color-icon.border-icon:hover{
	background-color: #28abe3;
}

.color-bg-icon.soc-yelp,
a.soc-yelp.bg-icon:hover,
.soc-yelp.color-icon.border-icon:hover{
	background-color: #c83218;
}

.color-bg-icon.soc-feedburner,
a.soc-feedburner.bg-icon:hover,
.soc-feedburner.color-icon.border-icon:hover{
	background-color: #ffcc00;
}

.color-bg-icon.soc-linkedin,
a.soc-linkedin.bg-icon:hover,
.soc-linkedin.color-icon.border-icon:hover{
	background-color: #3371b7;
}

.color-bg-icon.soc-viadeo,
a.soc-viadeo.bg-icon:hover,
.soc-viadeo.color-icon.border-icon:hover{
	background-color: #e4a000;
}

.color-bg-icon.soc-xing,
a.soc-xing.bg-icon:hover,
.soc-xing.color-icon.border-icon:hover{
	background-color: #005a60;
}

.color-bg-icon.soc-myspace,
a.soc-myspace.bg-icon:hover,
.soc-myspace.color-icon.border-icon:hover{
	background-color: #323232;
}

.color-bg-icon.soc-soundcloud,
a.soc-soundcloud.bg-icon:hover,
.soc-soundcloud.color-icon.border-icon:hover{
	background-color: #fe3801;
}

.color-bg-icon.soc-spotify,
a.soc-spotify.bg-icon:hover,
.soc-spotify.color-icon.border-icon:hover{
	background-color: #7bb342;
}

.color-bg-icon.soc-grooveshark,
a.soc-grooveshark.bg-icon:hover,
.soc-grooveshark.color-icon.border-icon:hover{
	background-color: #f67e03;
}

.color-bg-icon.soc-lastfm,
a.soc-lastfm.bg-icon:hover,
.soc-lastfm.color-icon.border-icon:hover{
	background-color: #d41316;
}

.color-bg-icon.soc-youtube,
a.soc-youtube.bg-icon:hover,
.soc-youtube.color-icon.border-icon:hover{
	background-color: #d12121;
}

.color-bg-icon.soc-vimeo,
a.soc-vimeo.bg-icon:hover,
.soc-vimeo.color-icon.border-icon:hover{
	background-color: #51b5e7;
}

.color-bg-icon.soc-dailymotion,
a.soc-dailymotion.bg-icon:hover,
.soc-dailymotion.color-icon.border-icon:hover{
	background-color: #004e72;
}

.color-bg-icon.soc-vine,
a.soc-vine.bg-icon:hover,
.soc-vine.color-icon.border-icon:hover{
	background-color: #00b389;
}

.color-bg-icon.soc-flickr,
a.soc-flickr.bg-icon:hover,
.soc-flickr.color-icon.border-icon:hover{
	background-color: #0062dd;
}

.color-bg-icon.soc-500px,
a.soc-500px.bg-icon:hover,
.soc-500px.color-icon.border-icon:hover{
	background-color: #58a9de;
}

.color-bg-icon.soc-instagram,
a.soc-instagram.bg-icon:hover,
.soc-instagram.color-icon.border-icon:hover{
	background-color: #9c7c6e;
}

.color-bg-icon.soc-wordpress,
a.soc-wordpress.bg-icon:hover,
.soc-wordpress.color-icon.border-icon:hover{
	background-color: #464646;
}

.color-bg-icon.soc-tumblr,
a.soc-tumblr.bg-icon:hover,
.soc-tumblr.color-icon.border-icon:hover{
	background-color: #45556c;
}

.color-bg-icon.soc-blogger,
a.soc-blogger.bg-icon:hover,
.soc-blogger.color-icon.border-icon:hover{
	background-color: #ec661c;
}

.color-bg-icon.soc-technorati,
a.soc-technorati.bg-icon:hover,
.soc-technorati.color-icon.border-icon:hover{
	background-color: #5cb030;
}

.color-bg-icon.soc-reddit,
a.soc-reddit.bg-icon:hover,
.soc-reddit.color-icon.border-icon:hover{
	background-color: #e74a1e;
}

.color-bg-icon.soc-dribbble,
a.soc-dribbble.bg-icon:hover,
.soc-dribbble.color-icon.border-icon:hover{
	background-color: #e84d88;
}

.color-bg-icon.soc-stumbleupon,
a.soc-stumbleupon.bg-icon:hover,
.soc-stumbleupon.color-icon.border-icon:hover{
	background-color: #e64011;
}

.color-bg-icon.soc-digg,
a.soc-digg.bg-icon:hover,
.soc-digg.color-icon.border-icon:hover{
	background-color: #1d1d1b;
}

.color-bg-icon.soc-envato,
a.soc-envato.bg-icon:hover,
.soc-envato.color-icon.border-icon:hover{
	background-color: #597c3a;
}

.color-bg-icon.soc-behance,
a.soc-behance.bg-icon:hover,
.soc-behance.color-icon.border-icon:hover{
	background-color: #007eff;
}

.color-bg-icon.soc-delicious,
a.soc-delicious.bg-icon:hover,
.soc-delicious.color-icon.border-icon:hover{
	background-color: #31a9ff;
}

.color-bg-icon.soc-deviantart,
a.soc-deviantart.bg-icon:hover,
.soc-deviantart.color-icon.border-icon:hover{
	background-color: #c5d200;
}

.color-bg-icon.soc-forrst,
a.soc-forrst.bg-icon:hover,
.soc-forrst.color-icon.border-icon:hover{
	background-color: #5b9a68;
}

.color-bg-icon.soc-play,
a.soc-play.bg-icon:hover,
.soc-play.color-icon.border-icon:hover{
	background-color: #5acdbd;
}

.color-bg-icon.soc-zerply,
a.soc-zerply.bg-icon:hover,
.soc-zerply.color-icon.border-icon:hover{
	background-color: #9dbc7a;
}

.color-bg-icon.soc-wikipedia,
a.soc-wikipedia.bg-icon:hover,
.soc-wikipedia.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-apple,
a.soc-apple.bg-icon:hover,
.soc-apple.color-icon.border-icon:hover{
	background-color: #b9bfc1;
}

.color-bg-icon.soc-flattr,
a.soc-flattr.bg-icon:hover,
.soc-flattr.color-icon.border-icon:hover{
	background-color: #f67c1a;
}

.color-bg-icon.soc-github,
a.soc-github.bg-icon:hover,
.soc-github.color-icon.border-icon:hover{
	background-color: #221e1b;
}

.color-bg-icon.soc-chimein,
a.soc-chimein.bg-icon:hover,
.soc-chimein.color-icon.border-icon:hover{
	background-color: #888688;
}

.color-bg-icon.soc-friendfeed,
a.soc-friendfeed.bg-icon:hover,
.soc-friendfeed.color-icon.border-icon:hover{
	background-color: #2f72c4;
}

.color-bg-icon.soc-newsvine,
a.soc-newsvine.bg-icon:hover,
.soc-newsvine.color-icon.border-icon:hover{
	background-color: #075b2f;
}

.color-bg-icon.soc-identica,
a.soc-identica.bg-icon:hover,
.soc-identica.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-bebo,
a.soc-bebo.bg-icon:hover,
.soc-bebo.color-icon.border-icon:hover{
	background-color: #ef1011;
}

.color-bg-icon.soc-zynga,
a.soc-zynga.bg-icon:hover,
.soc-zynga.color-icon.border-icon:hover{
	background-color: #dc0606;
}

.color-bg-icon.soc-steam,
a.soc-steam.bg-icon:hover,
.soc-steam.color-icon.border-icon:hover{
	background-color: #8f8d8a;
}

.color-bg-icon.soc-xbox,
a.soc-xbox.bg-icon:hover,
.soc-xbox.color-icon.border-icon:hover{
	background-color: #92c83e;
}

.color-bg-icon.soc-windows,
a.soc-windows.bg-icon:hover,
.soc-windows.color-icon.border-icon:hover{
	background-color: #00bdf6;
}

.color-bg-icon.soc-outlook,
a.soc-outlook.bg-icon:hover,
.soc-outlook.color-icon.border-icon:hover{
	background-color: #0072c6;
}

.color-bg-icon.soc-coderwall,
a.soc-coderwall.bg-icon:hover,
.soc-coderwall.color-icon.border-icon:hover{
	background-color: #3e8dcc;
}

.color-bg-icon.soc-tripadvisor,
a.soc-tripadvisor.bg-icon:hover,
.soc-tripadvisor.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-appnet,
a.soc-appnet.bg-icon:hover,
.soc-appnet.color-icon.border-icon:hover{
	background-color: #494949;
}

.color-bg-icon.soc-goodreads,
a.soc-goodreads.bg-icon:hover,
.soc-goodreads.color-icon.border-icon:hover{
	background-color: #1abc9c;
}

.color-bg-icon.soc-tripit,
a.soc-tripit.bg-icon:hover,
.soc-tripit.color-icon.border-icon:hover{
	background-color: #1982c3;
}

.color-bg-icon.soc-lanyrd,
a.soc-lanyrd.bg-icon:hover,
.soc-lanyrd.color-icon.border-icon:hover{
	background-color: #3c80c9;
}

.color-bg-icon.soc-slideshare,
a.soc-slideshare.bg-icon:hover,
.soc-slideshare.color-icon.border-icon:hover{
	background-color: #4ba3a6;
}

.color-bg-icon.soc-buffer,
a.soc-buffer.bg-icon:hover,
.soc-buffer.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-rss,
a.soc-rss.bg-icon:hover,
.soc-rss.color-icon.border-icon:hover{
	background-color: #f26109;
}

.color-bg-icon.soc-vkontakte,
a.soc-vkontakte.bg-icon:hover,
.soc-vkontakte.color-icon.border-icon:hover{
	background-color: #5a7fa6;
}

.color-bg-icon.soc-disqus,
a.soc-disqus.bg-icon:hover,
.soc-disqus.color-icon.border-icon:hover{
	background-color: #2e9fff;
}

.color-bg-icon.soc-houzz,
a.soc-houzz.bg-icon:hover,
.soc-houzz.color-icon.border-icon:hover{
	background-color: #7cc04b;
}

.color-bg-icon.soc-mail,
a.soc-mail.bg-icon:hover,
.soc-mail.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-patreon,
a.soc-patreon.bg-icon:hover,
.soc-patreon.color-icon.border-icon:hover{
	background-color: #e44727;
}

.color-bg-icon.soc-paypal,
a.soc-paypal.bg-icon:hover,
.soc-paypal.color-icon.border-icon:hover{
	background-color: #009cde;
}

.color-bg-icon.soc-playstation,
a.soc-playstation.bg-icon:hover,
.soc-playstation.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-smugmug,
a.soc-smugmug.bg-icon:hover,
.soc-smugmug.color-icon.border-icon:hover{
	background-color: #acfd32;
}

.color-bg-icon.soc-swarm,
a.soc-swarm.bg-icon:hover,
.soc-swarm.color-icon.border-icon:hover{
	background-color: #fc9d3c;
}

.color-bg-icon.soc-triplej,
a.soc-triplej.bg-icon:hover,
.soc-triplej.color-icon.border-icon:hover{
	background-color: #e53531;
}

.color-bg-icon.soc-yammer,
a.soc-yammer.bg-icon:hover,
.soc-yammer.color-icon.border-icon:hover{
	background-color: #1175c4;
}

.color-bg-icon.soc-stackoverflow,
a.soc-stackoverflow.bg-icon:hover,
.soc-stackoverflow.color-icon.border-icon:hover{
	background-color: #fd9827;
}

.color-bg-icon.soc-drupal,
a.soc-drupal.bg-icon:hover,
.soc-drupal.color-icon.border-icon:hover{
	background-color: #00598e;
}

.color-bg-icon.soc-odnoklassniki,
a.soc-odnoklassniki.bg-icon:hover,
.soc-odnoklassniki.color-icon.border-icon:hover{
	background-color: #f48420;
}

.color-bg-icon.soc-android,
a.soc-android.bg-icon:hover,
.soc-android.color-icon.border-icon:hover{
	background-color: #8ec047;
}

.color-bg-icon.soc-meetup,
a.soc-meetup.bg-icon:hover,
.soc-meetup.color-icon.border-icon:hover{
	background-color: #e2373c;
}

.color-bg-icon.soc-persona,
a.soc-persona.bg-icon:hover,
.soc-persona.color-icon.border-icon:hover{
	background-color: #e6753d;
}

.color-bg-icon.soc-amazon,
a.soc-amazon.bg-icon:hover,
.soc-amazon.color-icon.border-icon:hover{
	background-color: #ff9900;
}

.color-bg-icon.soc-ello,
a.soc-ello.bg-icon:hover,
.soc-ello.color-icon.border-icon:hover{
	background-color: #000000;
}

.color-bg-icon.soc-mixcloud,
a.soc-mixcloud.bg-icon:hover,
.soc-mixcloud.color-icon.border-icon:hover{
	background-color: #314359;
}

.color-bg-icon.soc-8tracks,
a.soc-8tracks.bg-icon:hover,
.soc-8tracks.color-icon.border-icon:hover{
	background-color: #122c4b;
}

.color-bg-icon.soc-twitch,
a.soc-twitch.bg-icon:hover,
.soc-twitch.color-icon.border-icon:hover{
	background-color: #6441a5;
}

.color-bg-icon.soc-airbnb,
a.soc-airbnb.bg-icon:hover,
.soc-airbnb.color-icon.border-icon:hover{
	background-color: #ff5a5f;
}

.color-bg-icon.soc-pocket,
a.soc-pocket.bg-icon:hover,
.soc-pocket.color-icon.border-icon:hover{
	background-color: #ed4055;
}

/*with_background corporate background colors*/
.with_background .color-bg-icon{
	color: #ffffff;
}

.with_background .color-bg-icon.soc-twitter,
.with_background a.soc-twitter.bg-icon:hover,
.with_background .soc-twitter.color-icon.border-icon:hover{
	background-color: #00abef;
}

.with_background .color-bg-icon.soc-facebook,
.with_background a.soc-facebook.bg-icon:hover,
.with_background .soc-facebook.color-icon.border-icon:hover{
	background-color: #507cbe;
}

.with_background .color-bg-icon.soc-google,
.with_background a.soc-google.bg-icon:hover,
.with_background .soc-google.color-icon.border-icon:hover{
	background-color: #d93e2d;
}

.with_background .color-bg-icon.soc-pinterest,
.with_background a.soc-pinterest.bg-icon:hover,
.with_background .soc-pinterest.color-icon.border-icon:hover{
	background-color: #c92619;
}

.with_background .color-bg-icon.soc-foursquare,
.with_background a.soc-foursquare.bg-icon:hover,
.with_background .soc-foursquare.color-icon.border-icon:hover{
	background-color: #23b7e0;
}

.with_background .color-bg-icon.soc-yahoo,
.with_background a.soc-yahoo.bg-icon:hover,
.with_background .soc-yahoo.color-icon.border-icon:hover{
	background-color: #6e2a85;
}

.with_background .color-bg-icon.soc-skype,
.with_background a.soc-skype.bg-icon:hover,
.with_background .soc-skype.color-icon.border-icon:hover{
	background-color: #28abe3;
}

.with_background .color-bg-icon.soc-yelp,
.with_background a.soc-yelp.bg-icon:hover,
.with_background .soc-yelp.color-icon.border-icon:hover{
	background-color: #c83218;
}

.with_background .color-bg-icon.soc-feedburner,
.with_background a.soc-feedburner.bg-icon:hover,
.with_background .soc-feedburner.color-icon.border-icon:hover{
	background-color: #ffcc00;
}

.with_background .color-bg-icon.soc-linkedin,
.with_background a.soc-linkedin.bg-icon:hover,
.with_background .soc-linkedin.color-icon.border-icon:hover{
	background-color: #3371b7;
}

.with_background .color-bg-icon.soc-viadeo,
.with_background a.soc-viadeo.bg-icon:hover,
.with_background .soc-viadeo.color-icon.border-icon:hover{
	background-color: #e4a000;
}

.with_background .color-bg-icon.soc-xing,
.with_background a.soc-xing.bg-icon:hover,
.with_background .soc-xing.color-icon.border-icon:hover{
	background-color: #005a60;
}

.with_background .color-bg-icon.soc-myspace,
.with_background a.soc-myspace.bg-icon:hover,
.with_background .soc-myspace.color-icon.border-icon:hover{
	background-color: #323232;
}

.with_background .color-bg-icon.soc-soundcloud,
.with_background a.soc-soundcloud.bg-icon:hover,
.with_background .soc-soundcloud.color-icon.border-icon:hover{
	background-color: #fe3801;
}

.with_background .color-bg-icon.soc-spotify,
.with_background a.soc-spotify.bg-icon:hover,
.with_background .soc-spotify.color-icon.border-icon:hover{
	background-color: #7bb342;
}

.with_background .color-bg-icon.soc-grooveshark,
.with_background a.soc-grooveshark.bg-icon:hover,
.with_background .soc-grooveshark.color-icon.border-icon:hover{
	background-color: #f67e03;
}

.with_background .color-bg-icon.soc-lastfm,
.with_background a.soc-lastfm.bg-icon:hover,
.with_background .soc-lastfm.color-icon.border-icon:hover{
	background-color: #d41316;
}

.with_background .color-bg-icon.soc-youtube,
.with_background a.soc-youtube.bg-icon:hover,
.with_background .soc-youtube.color-icon.border-icon:hover{
	background-color: #d12121;
}

.with_background .color-bg-icon.soc-vimeo,
.with_background a.soc-vimeo.bg-icon:hover,
.with_background .soc-vimeo.color-icon.border-icon:hover{
	background-color: #51b5e7;
}

.with_background .color-bg-icon.soc-dailymotion,
.with_background a.soc-dailymotion.bg-icon:hover,
.with_background .soc-dailymotion.color-icon.border-icon:hover{
	background-color: #004e72;
}

.with_background .color-bg-icon.soc-vine,
.with_background a.soc-vine.bg-icon:hover,
.with_background .soc-vine.color-icon.border-icon:hover{
	background-color: #00b389;
}

.with_background .color-bg-icon.soc-flickr,
.with_background a.soc-flickr.bg-icon:hover,
.with_background .soc-flickr.color-icon.border-icon:hover{
	background-color: #0062dd;
}

.with_background .color-bg-icon.soc-500px,
.with_background a.soc-500px.bg-icon:hover,
.with_background .soc-500px.color-icon.border-icon:hover{
	background-color: #58a9de;
}

.with_background .color-bg-icon.soc-instagram,
.with_background a.soc-instagram.bg-icon:hover,
.with_background .soc-instagram.color-icon.border-icon:hover{
	background-color: #9c7c6e;
}

.with_background .color-bg-icon.soc-wordpress,
.with_background a.soc-wordpress.bg-icon:hover,
.with_background .soc-wordpress.color-icon.border-icon:hover{
	background-color: #464646;
}

.with_background .color-bg-icon.soc-tumblr,
.with_background a.soc-tumblr.bg-icon:hover,
.with_background .soc-tumblr.color-icon.border-icon:hover{
	background-color: #45556c;
}

.with_background .color-bg-icon.soc-blogger,
.with_background a.soc-blogger.bg-icon:hover,
.with_background .soc-blogger.color-icon.border-icon:hover{
	background-color: #ec661c;
}

.with_background .color-bg-icon.soc-technorati,
.with_background a.soc-technorati.bg-icon:hover,
.with_background .soc-technorati.color-icon.border-icon:hover{
	background-color: #5cb030;
}

.with_background .color-bg-icon.soc-reddit,
.with_background a.soc-reddit.bg-icon:hover,
.with_background .soc-reddit.color-icon.border-icon:hover{
	background-color: #e74a1e;
}

.with_background .color-bg-icon.soc-dribbble,
.with_background a.soc-dribbble.bg-icon:hover,
.with_background .soc-dribbble.color-icon.border-icon:hover{
	background-color: #e84d88;
}

.with_background .color-bg-icon.soc-stumbleupon,
.with_background a.soc-stumbleupon.bg-icon:hover,
.with_background .soc-stumbleupon.color-icon.border-icon:hover{
	background-color: #e64011;
}

.with_background .color-bg-icon.soc-digg,
.with_background a.soc-digg.bg-icon:hover,
.with_background .soc-digg.color-icon.border-icon:hover{
	background-color: #1d1d1b;
}

.with_background .color-bg-icon.soc-envato,
.with_background a.soc-envato.bg-icon:hover,
.with_background .soc-envato.color-icon.border-icon:hover{
	background-color: #597c3a;
}

.with_background .color-bg-icon.soc-behance,
.with_background a.soc-behance.bg-icon:hover,
.with_background .soc-behance.color-icon.border-icon:hover{
	background-color: #007eff;
}

.with_background .color-bg-icon.soc-delicious,
.with_background a.soc-delicious.bg-icon:hover,
.with_background .soc-delicious.color-icon.border-icon:hover{
	background-color: #31a9ff;
}

.with_background .color-bg-icon.soc-deviantart,
.with_background a.soc-deviantart.bg-icon:hover,
.with_background .soc-deviantart.color-icon.border-icon:hover{
	background-color: #c5d200;
}

.with_background .color-bg-icon.soc-forrst,
.with_background a.soc-forrst.bg-icon:hover,
.with_background .soc-forrst.color-icon.border-icon:hover{
	background-color: #5b9a68;
}

.with_background .color-bg-icon.soc-play,
.with_background a.soc-play.bg-icon:hover,
.with_background .soc-play.color-icon.border-icon:hover{
	background-color: #5acdbd;
}

.with_background .color-bg-icon.soc-zerply,
.with_background a.soc-zerply.bg-icon:hover,
.with_background .soc-zerply.color-icon.border-icon:hover{
	background-color: #9dbc7a;
}

.with_background .color-bg-icon.soc-wikipedia,
.with_background a.soc-wikipedia.bg-icon:hover,
.with_background .soc-wikipedia.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-apple,
.with_background a.soc-apple.bg-icon:hover,
.with_background .soc-apple.color-icon.border-icon:hover{
	background-color: #b9bfc1;
}

.with_background .color-bg-icon.soc-flattr,
.with_background a.soc-flattr.bg-icon:hover,
.with_background .soc-flattr.color-icon.border-icon:hover{
	background-color: #f67c1a;
}

.with_background .color-bg-icon.soc-github,
.with_background a.soc-github.bg-icon:hover,
.with_background .soc-github.color-icon.border-icon:hover{
	background-color: #221e1b;
}

.with_background .color-bg-icon.soc-chimein,
.with_background a.soc-chimein.bg-icon:hover,
.with_background .soc-chimein.color-icon.border-icon:hover{
	background-color: #888688;
}

.with_background .color-bg-icon.soc-friendfeed,
.with_background a.soc-friendfeed.bg-icon:hover,
.with_background .soc-friendfeed.color-icon.border-icon:hover{
	background-color: #2f72c4;
}

.with_background .color-bg-icon.soc-newsvine,
.with_background a.soc-newsvine.bg-icon:hover,
.with_background .soc-newsvine.color-icon.border-icon:hover{
	background-color: #075b2f;
}

.with_background .color-bg-icon.soc-identica,
.with_background a.soc-identica.bg-icon:hover,
.with_background .soc-identica.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-bebo,
.with_background a.soc-bebo.bg-icon:hover,
.with_background .soc-bebo.color-icon.border-icon:hover{
	background-color: #ef1011;
}

.with_background .color-bg-icon.soc-zynga,
.with_background a.soc-zynga.bg-icon:hover,
.with_background .soc-zynga.color-icon.border-icon:hover{
	background-color: #dc0606;
}

.with_background .color-bg-icon.soc-steam,
.with_background a.soc-steam.bg-icon:hover,
.with_background .soc-steam.color-icon.border-icon:hover{
	background-color: #8f8d8a;
}

.with_background .color-bg-icon.soc-xbox,
.with_background a.soc-xbox.bg-icon:hover,
.with_background .soc-xbox.color-icon.border-icon:hover{
	background-color: #92c83e;
}

.with_background .color-bg-icon.soc-windows,
.with_background a.soc-windows.bg-icon:hover,
.with_background .soc-windows.color-icon.border-icon:hover{
	background-color: #00bdf6;
}

.with_background .color-bg-icon.soc-outlook,
.with_background a.soc-outlook.bg-icon:hover,
.with_background .soc-outlook.color-icon.border-icon:hover{
	background-color: #0072c6;
}

.with_background .color-bg-icon.soc-coderwall,
.with_background a.soc-coderwall.bg-icon:hover,
.with_background .soc-coderwall.color-icon.border-icon:hover{
	background-color: #3e8dcc;
}

.with_background .color-bg-icon.soc-tripadvisor,
.with_background a.soc-tripadvisor.bg-icon:hover,
.with_background .soc-tripadvisor.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-appnet,
.with_background a.soc-appnet.bg-icon:hover,
.with_background .soc-appnet.color-icon.border-icon:hover{
	background-color: #494949;
}

.with_background .color-bg-icon.soc-goodreads,
.with_background a.soc-goodreads.bg-icon:hover,
.with_background .soc-goodreads.color-icon.border-icon:hover{
	background-color: #1abc9c;
}

.with_background .color-bg-icon.soc-tripit,
.with_background a.soc-tripit.bg-icon:hover,
.with_background .soc-tripit.color-icon.border-icon:hover{
	background-color: #1982c3;
}

.with_background .color-bg-icon.soc-lanyrd,
.with_background a.soc-lanyrd.bg-icon:hover,
.with_background .soc-lanyrd.color-icon.border-icon:hover{
	background-color: #3c80c9;
}

.with_background .color-bg-icon.soc-slideshare,
.with_background a.soc-slideshare.bg-icon:hover,
.with_background .soc-slideshare.color-icon.border-icon:hover{
	background-color: #4ba3a6;
}

.with_background .color-bg-icon.soc-buffer,
.with_background a.soc-buffer.bg-icon:hover,
.with_background .soc-buffer.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-rss,
.with_background a.soc-rss.bg-icon:hover,
.with_background .soc-rss.color-icon.border-icon:hover{
	background-color: #f26109;
}

.with_background .color-bg-icon.soc-vkontakte,
.with_background a.soc-vkontakte.bg-icon:hover,
.with_background .soc-vkontakte.color-icon.border-icon:hover{
	background-color: #5a7fa6;
}

.with_background .color-bg-icon.soc-disqus,
.with_background a.soc-disqus.bg-icon:hover,
.with_background .soc-disqus.color-icon.border-icon:hover{
	background-color: #2e9fff;
}

.with_background .color-bg-icon.soc-houzz,
.with_background a.soc-houzz.bg-icon:hover,
.with_background .soc-houzz.color-icon.border-icon:hover{
	background-color: #7cc04b;
}

.with_background .color-bg-icon.soc-mail,
.with_background a.soc-mail.bg-icon:hover,
.with_background .soc-mail.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-patreon,
.with_background a.soc-patreon.bg-icon:hover,
.with_background .soc-patreon.color-icon.border-icon:hover{
	background-color: #e44727;
}

.with_background .color-bg-icon.soc-paypal,
.with_background a.soc-paypal.bg-icon:hover,
.with_background .soc-paypal.color-icon.border-icon:hover{
	background-color: #009cde;
}

.with_background .color-bg-icon.soc-playstation,
.with_background a.soc-playstation.bg-icon:hover,
.with_background .soc-playstation.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-smugmug,
.with_background a.soc-smugmug.bg-icon:hover,
.with_background .soc-smugmug.color-icon.border-icon:hover{
	background-color: #acfd32;
}

.with_background .color-bg-icon.soc-swarm,
.with_background a.soc-swarm.bg-icon:hover,
.with_background .soc-swarm.color-icon.border-icon:hover{
	background-color: #fc9d3c;
}

.with_background .color-bg-icon.soc-triplej,
.with_background a.soc-triplej.bg-icon:hover,
.with_background .soc-triplej.color-icon.border-icon:hover{
	background-color: #e53531;
}

.with_background .color-bg-icon.soc-yammer,
.with_background a.soc-yammer.bg-icon:hover,
.with_background .soc-yammer.color-icon.border-icon:hover{
	background-color: #1175c4;
}

.with_background .color-bg-icon.soc-stackoverflow,
.with_background a.soc-stackoverflow.bg-icon:hover,
.with_background .soc-stackoverflow.color-icon.border-icon:hover{
	background-color: #fd9827;
}

.with_background .color-bg-icon.soc-drupal,
.with_background a.soc-drupal.bg-icon:hover,
.with_background .soc-drupal.color-icon.border-icon:hover{
	background-color: #00598e;
}

.with_background .color-bg-icon.soc-odnoklassniki,
.with_background a.soc-odnoklassniki.bg-icon:hover,
.with_background .soc-odnoklassniki.color-icon.border-icon:hover{
	background-color: #f48420;
}

.with_background .color-bg-icon.soc-android,
.with_background a.soc-android.bg-icon:hover,
.with_background .soc-android.color-icon.border-icon:hover{
	background-color: #8ec047;
}

.with_background .color-bg-icon.soc-meetup,
.with_background a.soc-meetup.bg-icon:hover,
.with_background .soc-meetup.color-icon.border-icon:hover{
	background-color: #e2373c;
}

.with_background .color-bg-icon.soc-persona,
.with_background a.soc-persona.bg-icon:hover,
.with_background .soc-persona.color-icon.border-icon:hover{
	background-color: #e6753d;
}

.with_background .color-bg-icon.soc-amazon,
.with_background a.soc-amazon.bg-icon:hover,
.with_background .soc-amazon.color-icon.border-icon:hover{
	background-color: #ff9900;
}

.with_background .color-bg-icon.soc-ello,
.with_background a.soc-ello.bg-icon:hover,
.with_background .soc-ello.color-icon.border-icon:hover{
	background-color: #000000;
}

.with_background .color-bg-icon.soc-mixcloud,
.with_background a.soc-mixcloud.bg-icon:hover,
.with_background .soc-mixcloud.color-icon.border-icon:hover{
	background-color: #314359;
}

.with_background .color-bg-icon.soc-8tracks,
.with_background a.soc-8tracks.bg-icon:hover,
.with_background .soc-8tracks.color-icon.border-icon:hover{
	background-color: #122c4b;
}

.with_background .color-bg-icon.soc-twitch,
.with_background a.soc-twitch.bg-icon:hover,
.with_background .soc-twitch.color-icon.border-icon:hover{
	background-color: #6441a5;
}

.with_background .color-bg-icon.soc-airbnb,
.with_background a.soc-airbnb.bg-icon:hover,
.with_background .soc-airbnb.color-icon.border-icon:hover{
	background-color: #ff5a5f;
}

.with_background .color-bg-icon.soc-pocket,
.with_background a.soc-pocket.bg-icon:hover,
.with_background .soc-pocket.color-icon.border-icon:hover{
	background-color: #ed4055;
}

a.social-icon{
	color: #7f7f7f;
}

a.border-icon{
	border: 1px solid #7f7f7f;
	border-color: rgba(127, 127, 127, 0.4);
	padding: 10px 11px;
	margin-bottom: 4px;
}

.page_footer a.border-icon{
	border: 1px solid #ffffff !important;
	color: #ffffff !important;
}

.page_footer a.border-icon:hover{
	background-color: transparent !important;
	color: black !important;
}

a.bg-icon{
	color: #ffffff;
	background-color: #7f7f7f;
	padding: 11px 12px 10px;
	margin-bottom: 4px;
	line-height: 1.2;
}

a.social-icon.color-icon:hover{
	color: #1f2732;
}

a.social-icon.bg-icon:hover{
	color: #ffffff;
}

a.color-bg-icon{
	color: #ffffff;
	background-color: #1f2732;
	margin-bottom: 4px;
	padding: 11px 12px;
}

a.social-icon.color-bg-icon:hover{
	background-color: black;
	color: #ffffff;
}

a.color-icon:hover{
	color: #1f2732;
}

a.color-icon.border-icon:hover{
	color: #ffffff;
}

.demo-icon{
	display: inline-block;
	text-align: center;
	padding: 15px 20px 10px;
}

.demo-icon span{
	display: block;
}

.demo-icon .name{
	font-size: 1.01em;
	font-weight: 700;
}

.demo-icon a[class*="soc-"]{
	font-size: 24px;
	margin: 10px 0 0;
}

.social-icons-demo [class*='col-'] a[class*="soc-"]{
	margin: 10px;
}

/*
isotope portfolio and gallery pos
*/
.gallery-item{
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
}

.gallery-columns-1 .gallery-item{
	width: 100%;
}

.gallery-columns-2 .gallery-item{
	width: 50%;
}

.gallery-columns-3 .gallery-item{
	width: 33.33%;
}

.gallery-columns-4 .gallery-item{
	width: 25%;
}

.gallery-columns-5 .gallery-item{
	width: 20%;
}

.gallery-columns-6 .gallery-item{
	width: 16.66%;
}

.gallery-columns-7 .gallery-item{
	width: 14.28%;
}

.gallery-columns-8 .gallery-item{
	width: 12.5%;
}

.gallery-columns-9 .gallery-item{
	width: 11.11%;
}

.gallery-icon img{
	display: block;
	margin: 0 auto;
	width: 100%;
}

.gallery-caption{
	display: block;
	padding: 0.5em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption{
	display: none;
}

@media (max-width: 600px) {
	.gallery-columns-4 .gallery-item,
	.gallery-columns-5 .gallery-item,
	.gallery-columns-6 .gallery-item,
	.gallery-columns-7 .gallery-item,
	.gallery-columns-8 .gallery-item,
	.gallery-columns-9 .gallery-item{
		width: 33%;
	}

	.gallery-columns-4 .gallery-caption,
	.gallery-columns-5 .gallery-caption,
	.gallery-columns-6 .gallery-caption,
	.gallery-columns-7 .gallery-caption,
	.gallery-columns-8 .gallery-caption,
	.gallery-columns-9 .gallery-caption{
		display: none;
	}
}

.isotope_container{
	-webkit-transition: height 0.2s ease-in-out 0s;
	transition: height 0.2s ease-in-out 0s;
}

.single-post .isotope_container{
	margin: 20px 0;
	clear: both;
}
