/* Table Of Contents bootstrap overrides vertical margins and paddings general styles styled lists icons buttons common sections styles: light section - .ls grey section - .ls.ms dark sections - .ds color section - .cs boxed layout template styles main menu (min 768px) styles main menu mobile styles subpages widgets shortcodes utilites plugins - Owl Carousel - FlexSlider - prettyPhoto - Easy pieChart - toTop */
/*
**shop
*/
input[disabled]{
	opacity: .4;
	cursor: not-allowed;
}

.woocommerce-breadcrumb,
.woocommerce-products-header{
	display: none;
}

/* sort panel */
.storefront-sorting{
	line-height: 1;
	display: none;
	padding: 0;
	margin-bottom: 55px;
}

.storefront-sorting .form-group{
	margin-bottom: 15px;
}

.storefront-sorting label{
	padding-right: 10px;
}

.woocommerce-ordering{
	display: inline-block;
	float: right;
}

@media (max-width: 768px) {
	.woocommerce-ordering{
		float: none;
		display: inline-block;
		text-align: left;
	}
}

.woocommerce-message .wc-forward{
	margin-left: 10px;
	margin-bottom: 40px;
	display: block;
}

.woocommerce-result-count{
	float: left;
	line-height: 60px;
	font-weight: 300;
	font-size: 16px;
	color: #7f7f7f;
}

.ds .woocommerce-result-count{
	color: #a4a9b9;
}

@media (max-width: 767px) {
	.woocommerce-result-count{
		float: none;
		display: inline-block;
		text-align: left;
		width: 100%;
	}
}

#toggle_shop_view{
	margin-left: 14px;
}

#toggle_shop_view:before,
#toggle_shop_view:after{
	font-family: 'FontAwesome';
	display: inline-block;
	background-color: #1f2732;
	color: #ffffff;
	padding: 12px 12px 13px;
	font-size: 14px;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

#toggle_shop_view:before{
	content: "\f00a";
	margin-right: 5px;
}

#toggle_shop_view:after{
	content: "\f03a";
	background-color: #ff497c;
}

#toggle_shop_view.grid-view:before{
	background-color: #ff497c;
}

#toggle_shop_view.grid-view:after{
	background-color: #1f2732;
}

#sort_view{
	color: #1f2732;
	display: inline-block;
	padding: 12px 16px 13px;
	font-size: 14px;
	background-color: #ffffff;
}

.columns-1 .product{
	width: 100%;
	margin-right: 0;
	float: none;
}

@media (min-width: 768px) {
	.columns-2 .product,
	.columns-3 .product,
	.columns-4 .product{
		width: 50%;
		float: left;
	}
}

@media (min-width: 992px) {
	.columns-2 .product{
		width: 50%;
		float: left;
	}

	.columns-3 .product{
		width: 33.33333333%;
		float: left;
	}

	.columns-4 .product{
		width: 25%;
		float: left;
	}
}

.product .thumbnails a,
.product .item-media{
	overflow: hidden;
	display: block;
}

.product .thumbnails a img,
.product .item-media img{
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	width: 100%;
}

.product .thumbnails a:hover img,
.product .item-media:hover img{
	opacity: 0.8;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
}

.product figure{
	max-width: none;
}

.product .thumbnails-wrap{
	margin-top: 30px;
}

.product .vertical-item .item-media + .item-content{
	padding-top: 30px;
}

.products{
	margin-left: -15px;
	margin-right: -15px;
}

.products:before,
.products:after{
	content: '';
	clear: both;
	display: block;
}

.upsells .products,
.related .products{
	margin-left: 0;
	margin-right: 0;
}

.products .item-content h4{
	display: none;
}

/* for products feed */
li.product{
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 45px;
}

/* product */
.owl-item .product{
	padding: 0;
}

.product h1.entry-title{
	font-size: 30px;
	font-weight: 500;
	margin: 0 0 5px;
}

.product h3{
	margin-bottom: 20px;
}

.product .rating-title{
	font-size: 1.2em;
}

.product [class*="col-"]:first-child h2,
.product [class*="col-"]:first-child h3{
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	margin: 18px 0 24px;
	position: relative;
}

.product .product-media{
	position: relative;
}

.product .images{
	position: relative;
	background: #f4f4f5;
	border-radius: 0;
	overflow: hidden;
}

.product [class*="col-"]:first-child a span,
.product [class*="col-"]:first-child .onsale,
.product [class*="col-"]:first-child .product-media span{
	position: absolute;
	background-color: #ff497c;
	color: #ffffff;
	padding: 8px 18px;
	text-transform: uppercase;
	top: 20px;
	right: 20px;
}

.product [class*="col-"]:first-child a span.onsale,
.product [class*="col-"]:first-child .onsale.onsale,
.product [class*="col-"]:first-child .product-media span.onsale{
	background-color: #ff497c;
	left: 20px;
	bottom: 20px;
	right: auto;
	top: auto;
	z-index: 1;
}

.related,
.upsells{
	margin-top: 60px;
	padding-right: 15px;
	padding-left: 15px;
}

.related > h2,
.upsells > h2{
	margin: 20px 0 30px;
	font-size: 28px;
}

.related .item-content p,
.upsells .item-content p{
	display: none;
}

.related .owl-theme .owl-nav,
.upsells .owl-theme .owl-nav{
	top: 0 !important;
	left: auto;
	width: 40px;
}

.related .owl-theme .owl-nav > div,
.upsells .owl-theme .owl-nav > div{
	margin-top: -55px;
	height: 30px;
	width: 20px;
}

.related .owl-theme .owl-nav > div:after,
.upsells .owl-theme .owl-nav > div:after{
	font-size: 30px;
	line-height: 30px;
	color: #1f2732;
}

.related .owl-theme .owl-nav > div.owl-prev,
.upsells .owl-theme .owl-nav > div.owl-prev{
	left: 0;
	right: auto;
}

.related .owl-theme .owl-nav > div.owl-next,
.upsells .owl-theme .owl-nav > div.owl-next{
	left: auto;
	right: 0;
}

.related .added_to_cart,
.upsells .added_to_cart{
	display: none;
}

.upsells + .related{
	margin-top: 10px;
}

.related .owl-theme:hover .owl-nav{
	top: 25%;
}

.product-buttons{
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.product-buttons a{
	float: left;
	width: 40px;
	height: 40px;
	font-size: 0;
	color: transparent;
	text-align: center;
	background-color: #ff497c; /* for wp - before - no simple way to add i inside button */
}

.product-buttons a.loading [class*='rt-icon'],
.product-buttons a.loading:before{
	display: inline-block;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}

.product-buttons a.loading [class*='rt-icon']:before,
.product-buttons a.loading:before:before{
	content: "\e61b";
}

.product-buttons a.loading:before{
	content: "\e61b";
}

.product-buttons a i,
.product-buttons a:before{
	line-height: 40px;
	font-size: 16px;
	color: #ffffff;
}

.product-buttons a:before{
	font-family: 'rt-icons-2';
	content: "\e626";
}

.product-buttons a.favorite_button{
	background-color: #ffffff;
	color: #1f2732;
}

.product-buttons a:hover{
	background-color: #1f2732;
	color: #ffffff;
}

.product-buttons a.added_to_cart{
	background-color: #ffffff;
	font-size: 0;
	display: none;
	color: transparent;
}

.product-buttons a.added_to_cart:hover{
	background-color: #ff497c;
}

.product-buttons a.added_to_cart:before{
	content: "\e6c0";
	line-height: 40px;
	color: #1f2732;
	font-family: 'rt-icons-2';
	font-size: 26px;
}

.product-buttons a.product_type_variable:before{
	font-size: 20px;
	content: "\e63f";
}

/* media query min 767 */
.grid-view{
	text-align: left;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
}

.grid-view .row{
	position: relative;
}

.grid-view .product-meta{
	display: block;
	text-align: center;
}

.grid-view .product-meta .star-rating{
	margin: 0 auto;
	width: 72px;
}

.grid-view .product-description{
	display: none;
}

.grid-view [class*="col-"]{
	width: 100%;
	float: none;
}

.grid-view .item-media .onsale{
	display: none;
}

.grid-view .item-content p{
	display: none;
}

.grid-view .item-content a{
	color: inherit;
}

.grid-view .item-content a > h2{
	font-size: 24px;
	text-transform: none;
	margin-top: 0;
	font-weight: 500;
	margin-bottom: 8px;
	line-height: 30px;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

.grid-view .item-content a > h2:hover{
	color: #ff497c;
}

.grid-view .item-content .stock{
	color: #ff497c;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 7px;
}

.grid-view .item-content .price{
	color: #1f2732;
	padding: 0;
	width: 100%;
	display: block;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 20px;
}

.ds .grid-view .item-content .price{
	color: #a4a9b9;
}

.grid-view .item-content .price del{
	margin-right: 10px;
	color: #7f7f7f;
}

.grid-view .item-content .price ins{
	text-decoration: none;
	color: #ff497c;
}

.grid-view .item-content .added_to_cart{
	position: absolute;
	right: 30px;
	bottom: 70px;
	font-size: 0;
	color: transparent;
}

.grid-view .item-content .added_to_cart:before{
	content: "\e6c0";
	line-height: 40px;
	color: #1f2732;
	font-family: 'rt-icons-2';
	font-size: 26px;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

.ds .grid-view .item-content .added_to_cart:before{
	color: #ffffff;
}

.grid-view .item-content .added_to_cart:hover:before{
	color: #ff497c;
}

.grid-view .item-content .button.product_type_grouped,
.grid-view .item-content .button.product_type_simple,
.grid-view .item-content .add_to_cart_button{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.2em;
	padding: 22px 30px 22px;
	min-width: 200px;
	margin-bottom: 4px;
	line-height: 1;
	display: inline-block;
	text-align: center;
	color: #1f2732;
	border: 0;
	background-color: #f4f4f5;
	border-radius: 40px;
	position: relative;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

@media (max-width: 768px) {
	.grid-view .item-content .button.product_type_grouped,
	.grid-view .item-content .button.product_type_simple,
	.grid-view .item-content .add_to_cart_button{
		padding: 22px 25px 22px;
		min-width: 150px;
	}
}

.grid-view .item-content .button.product_type_grouped:hover,
.grid-view .item-content .button.product_type_simple:hover,
.grid-view .item-content .add_to_cart_button:hover{
	color: #ffffff;
	background-color: #ff497c;
}

.grid-view .item-content .add_to_cart_button:before{
	content: "\f291";
	font-family: FontAwesome;
	font-size: 14px;
	margin-right: 5px;
	color: #ff497c;
	-webkit-transition: all 0.4s linear 0s;
	transition: all 0.4s linear 0s;
}

.grid-view .item-content .add_to_cart_button:hover:before{
	color: #ffffff;
}

.grid-view .item-content .button.product_type_variable:before{
	display: none;
}

/* single-product */
.summary h1{
	margin-bottom: 3px;
}

.summary .variations .value select,
.summary .variations .value option{
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 0.2em;
	line-height: 1;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 30px;
}

.summary .product-rating,
.summary .woocommerce-product-rating{
	margin-bottom: 10px;
	overflow: hidden;
}

.summary .woocommerce-product-details__short-description p{
	margin-bottom: 20px;
}

.summary .price{
	position: static;
	display: block;
	padding: 20px 0 20px;
	font-size: 16px;
	font-weight: 500;
	background-color: transparent;
	line-height: 1;
	color: #ff497c;
	word-wrap: break-word;
	border-bottom: 0;
	margin-bottom: 0;
}

.summary .price ins{
	color: #ff497c;
	text-decoration: none;
}

.summary .woocommerce-variation-price .price{
	padding-top: 0;
}

.summary .star-rating{
	display: inline-block;
	top: 4px;
	right: 0;
	float: right;
	width: 72px;
	margin-right: 0;
}

.summary .stock{
	margin-bottom: 0;
}

.summary .email-to{
	margin-bottom: 10px;
}

.summary .product-option-name{
	display: inline-block;
	margin: 10px 0;
}

.summary .theme_button{
	margin-top: 0;
}

.summary .theme_button.wc-variation-selection-needed{
	opacity: .4;
	cursor: not-allowed;
}

.summary .posted_in{
	margin: 10px 0;
}

.summary .posted_in .small_button{
	margin-left: 5px;
	padding-left: 6px;
	padding-right: 6px;
}

.summary .product_meta{
	margin-top: 20px;
}

.summary .product_meta > span{
	display: block;
	font-weight: 300;
	font-size: 16px;
	color: #1f2732;
	padding: 10px 0 10px 3px;
	border-bottom: 1px solid #dedfe1;
}

.summary .product_meta > span .sku{
	color: #7f7f7f;
}

.summary .product_meta > span:first-child{
	border-top: 1px solid #dedfe1;
}

@media (min-width: 1200px) {
	.summary .product_meta .meta-name{
		min-width: 100px;
		display: inline-block;
		font-weight: 500;
	}
}

.summary [itemprop="description"]{
	margin: 30px 0;
}

.summary ul.list1 li{
	padding-top: 15px;
	padding-bottom: 20px;
}

.summary .reset_variations{
	color: transparent;
	font-size: 0;
	padding: 0 5px;
}

.summary .reset_variations:before{
	font-family: 'rt-icons-2';
	content: "\e117";
	font-size: 18px;
	color: #1f2732;
}

.summary label{
	margin: 0;
	text-transform: capitalize;
	font-weight: 500;
	font-size: 16px;
}

.summary [class*='add-to-cart']{
	margin: 10px 0;
}

.summary [class*='add-to-cart'] .theme_button{
	float: left;
}

@media (min-width: 768px) and (max-width: 992px) {
	.summary [class*='add-to-cart'] .theme_button{
		float: left;
	}
}

.quantity{
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
	margin-right: 10px;
	float: left;
}

.quantity [type="button"]{
	position: absolute;
	right: 5px;
	top: 3px;
	line-height: 1;
	border: none;
	width: 22px;
	height: 24px;
	background-color: transparent;
}

.quantity [type="button"].minus{
	top: auto;
	bottom: 3px;
}

.quantity [type="number"]{
	padding-right: 30px;
	padding-left: 20px;
	max-width: 70px;
	min-width: 70px;
}

.variations td{
	display: block;
	border: 0;
	padding: 3px 0;
}

.variations td.value{
	margin-bottom: 10px;
}

/* tags */
.product_meta_bottom{
	margin: 0 0 20px;
}

/* tabs */
.product-tabs{
	margin-top: 30px;
}

.owl-carousel.thumbnails{
	margin: 30px 0 30px;
}

.owl-carousel.thumbnails .owl-dots{
	margin: 20px 0 5px;
}

.owl-carousel.thumbnails .owl-dots .owl-dot span{
	width: 8px;
	height: 8px;
	border-width: 1px;
}

.owl-carousel.thumbnails .owl-nav{
	margin-top: -18px;
}

.owl-carousel.thumbnails .owl-nav > div{
	right: 0;
}

.owl-carousel.thumbnails .owl-nav > div:first-child{
	left: 0;
}

.owl-carousel.thumbnails .owl-nav > div:after{
	font-size: 16px;
	color: #1f2732;
}

.product-icons .teaser [class*="_bg_color"]{
	padding: 10px;
}

.product + .nav-tabs,
.woocommerce-tabs{
	margin-top: 50px;
}

/* stars */
.star-rating,
p.stars a{
	font-weight: 400;
	height: 1em;
}

.star-rating span:before,
.star-rating:before{
	content: "\f005\f005\f005\f005\f005";
	top: 0;
	left: 0;
}

.star-rating{
	overflow: hidden;
	position: relative;
	line-height: 1;
	width: 100px;
	font-size: 14px;
	font-family: FontAwesome;
	color: #e3e1e1;
}

.star-rating:before{
	opacity: .5;
	float: left;
	letter-spacing: 0.1em;
	position: absolute;
}

.star-rating span{
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	padding-top: 1.5em;
	letter-spacing: 0.1em;
}

.star-rating span:before{
	position: absolute;
	color: #ff497c;
}

p.stars a:before,
p.stars a:hover ~ a:before{
	color: #e3e1e1;
	content: "\f005";
}

p.stars{
	display: inline-block;
	margin: 0 0 30px;
	line-height: 1;
}

p.stars a{
	display: inline-block;
	position: relative;
	width: 1em;
	text-indent: -999em;
	margin-right: 1px;
}

p.stars a:before{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	line-height: 1;
	font-family: FontAwesome;
	text-indent: 0;
}

p.stars.selected a.active:before,
p.stars:hover a:before{
	content: "\f005";
	color: #ff497c;
	opacity: 1;
}

p.stars.selected a.active ~ a:before{
	content: "\f005";
	color: #e3e1e1;
	opacity: .75;
}

p.stars.selected a:not(.active):before{
	content: "\f005";
	color: #ff497c;
	opacity: 1;
}

/*shop comments*/
.commentlist{
	list-style: none;
	margin: 0;
	padding: 0;
}

.commentlist li{
	margin-bottom: 40px;
}

.commentlist li img{
	display: block;
	float: left;
	overflow: hidden;
}

@media (max-width: 400px) {
	.commentlist li img{
		display: none;
	}
}

.commentlist .comment-text{
	overflow: hidden;
	position: relative;
	padding: 26px 30px 26px 30px;
	border: 1px solid #e1e1e1;
}

.commentlist p.meta{
	font-size: 0;
	margin: 0 0 40px; /* author */
}

.commentlist p.meta strong{
	color: #1f2732;
	font-weight: 300;
	font-size: 24px;
	line-height: 1;
	display: block;
}

.commentlist p.meta time{
	font-size: 14px;
}

.commentlist .star-rating{
	position: absolute;
	top: 93px;
}

@media (max-width: 330px) {
	.commentlist .star-rating{
		top: 110px;
	}
}

.woocommerce-tabs input[type="text"],
.woocommerce-tabs input[type="email"],
.woocommerce-tabs input[type="url"],
.woocommerce-tabs input[type="password"],
.woocommerce-tabs input[type="search"],
.woocommerce-tabs input[type="tel"],
.woocommerce-tabs input[type="number"],
.woocommerce-tabs textarea,
.woocommerce-tabs select,
.woocommerce-tabs .form-control{
	background-color: #ffffff;
}

.woocommerce-tabs .commentlist p.meta{
	font-size: 16px;
}

.woocommerce-tabs .comment-text .star-rating{
	top: 85px;
}

.woocommerce-tabs .tab-content{
	padding-top: 40px;
}

.woocommerce-tabs .tab-content .woocommerce-Tabs-panel--description h2{
	display: none;
}

.woocommerce-tabs .tab-content .woocommerce-Reviews-title{
	display: none;
}

.woocommerce-tabs .tab-content .comment-form-rating{
	margin-bottom: 40px;
}

.woocommerce-tabs .tab-content .comment-form-rating .selectize-control{
	display: none;
}

.woocommerce-tabs .tab-content .comment-respond{
	margin: 30px 0 0;
}

.woocommerce-tabs .tab-content .comment-respond h3{
	font-size: 28px;
}

.woocommerce-tabs .tab-content .comment-respond h4{
	margin-bottom: 0;
	font-size: 28px;
}

/*comment respond form*/
#review_form textarea,
#review_form [type*='text'],
#review_form [type*='email']{
	width: 100%;
	display: block;
	margin-bottom: 10px;
}

.ds #review_form textarea,
.ds #review_form [type*='text'],
.ds #review_form [type*='email']{
	background-color: #1f2732;
	border-color: #1f2732;
}

#review_form .form-submit{
	padding-top: 20px;
}

@media (min-width: 768px) {
	#review_form .form-submit .submit{
		min-width: 200px;
	}
}

@media (min-width: 992px) {
	#review_form .comment-form-author{
		float: left;
		width: 50%;
		padding-right: 5px;
	}

	#review_form .comment-form-email{
		float: left;
		width: 50%;
		padding-left: 5px;
	}

	#review_form .form-submit{
		clear: both;
	}
}

.comment-form-rating label[for]{
	display: block;
}

.woocommerce-info.alert{
	border-left: 4px solid #a0ce4e;
}

/* cart */
.woocommerce-cart .content{
	margin-bottom: 105px;
}

.cross-sells{
	display: none;
}

.woocommerce-message .wc-forward{
	margin-left: 10px;
}

.shop_table ul{
	list-style: none;
	padding: 0;
}

.shop_table .input-text{
	margin-left: 0;
}

.shop_table .actions{
	padding: 18px 0;
}

.shop_table .actions input[type="submit"]{
	padding: 20px 30px 20px;
	min-width: 150px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.shop_table .actions input[type="submit"]:hover,
.shop_table .actions input[type="submit"]:focus{
	color: #ff497c;
}

.shop_table .actions .coupon input[type="submit"]{
	margin-left: 10px;
}

@media (max-width: 767px) {
	.shop_table .actions .coupon input[type="submit"]{
		margin-left: 0;
	}
}

.shop_table .actions .button.apply_coupon{
	color: #1f2732;
	background-color: transparent;
}

.shop_table .actions .button.apply_coupon:hover,
.shop_table .actions .button.apply_coupon:focus{
	color: #ffffff;
	background-color: #ff497c;
}

.shop_table .actions .button.update_cart{
	color: #1f2732;
	border-color: #1f2732;
	background-color: transparent;
	float: right;
	margin-top: 10px;
	margin-bottom: 10px;
}

@media (max-width: 767px) {
	.shop_table .actions .button.update_cart{
		float: left;
	}
}

.shop_table .actions .button.update_cart:hover,
.shop_table .actions .button.update_cart:focus{
	color: #ff497c;
	background-color: transparent;
	border-color: #ff497c;
}

.order-total strong,
.cart_totals strong{
	color: #ff497c;
}

.shop-adds{
	font-size: 20px;
	font-weight: 300;
	line-height: 1.4;
}

.shop-adds .fa{
	padding: 0 10px;
}

.shop-adds.teaser{
	margin-bottom: 0;
}

.shop-adds.teaser [class*="_bg_color"]{
	padding: 12px;
}

table.cart{
	margin-bottom: 30px;
}

table.cart thead th{
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #1f2732 !important;
}

@media (max-width: 992px) {
	table.cart{
		display: block;
		overflow-x: auto;
	}
}

table.cart .media{
	margin-left: -15px;
}

table.cart .product-thumbnail img{
	max-width: 80px;
	background: #f4f4f5;
}

table.cart h4{
	margin-bottom: 12px;
}

table.cart .cart_item{
	border-bottom: 1px solid #dedfe1;
}

table.cart .cart_item:first-child{
	border-top: 1px solid #dedfe1;
}

table.cart td,
table.cart th{
	vertical-align: middle;
	padding: 18px 10px;
	border: 0;
}

table.cart .product-remove{
	padding: 18px 0;
}

table.cart .product-thumbnail{
	width: 70px;
}

table.cart .product-subtotal{
	width: 100px;
	text-align: right;
	color: #ff497c;
	font-weight: 500;
}

table.cart .product-quantity{
	width: 90px;
	font-weight: 500;
}

table.cart .product-quantity .quantity{
	margin: 0;
}

table.cart .product-price{
	width: 100px;
	color: #ff497c;
	font-weight: 500;
}

table.cart .coupon{
	display: inline-block;
}

table.cart .coupon label{
	display: none;
}

table.cart a{
	color: #1f2732;
	font-weight: 500;
}

.ds table.cart a{
	color: #ff497c;
}

table.cart td.product-name{
	font-size: 20px;
}

table.cart .product-remove{
	text-align: center;
	font-size: 1.4em;
	font-weight: 300;
}

@media (min-width: 767px) {
	.cart_totals{
		max-width: 465px;
	}
}

.cart_totals > h2{
	font-size: 24px;
	font-weight: 500;
	text-transform: none;
	margin-bottom: 25px;
}

.cart_totals h4:first-child{
	margin: 60px 0 30px;
}

.cart_totals table{
	margin: 0 0 50px;
}

.cart_totals table th,
.cart_totals table td{
	padding: 15px 0;
	border: 0;
	font-weight: 500;
}

.cart_totals table th strong,
.cart_totals table td strong{
	font-weight: 500;
}

.cart_totals table th{
	width: 140px;
}

.cart_totals table tr{
	border-bottom: 1px solid #dedfe1;
}

.cart_totals table tr:first-child{
	border-top: 1px solid #dedfe1;
}

.cart_totals table .cart-subtotal td,
.cart_totals table .order-total td{
	text-align: right;
}

.cart_totals table .cart-subtotal .woocommerce-Price-amount.amount{
	color: #ff497c;
}

.wc-proceed-to-checkout .wc-forward{
	float: left;
}

/* chekcout */
.shop-info{
	margin-bottom: 15px;
}

#createaccount{
	margin-top: 0;
}

#ship-to-different-address label{
	display: inline-block;
}

#ship-to-different-address label + input{
	margin: 0 12px;
}

.checkout [class*='col-'],
.shop-register [class*='col-']{
	margin-top: 0;
	margin-bottom: 0;
}

/* wp - form-row, html - form.checkout */
.woocommerce-checkout .content{
	margin-top: 23px;
	margin-bottom: 100px;
}

.woocommerce-checkout h3{
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 35px;
}

.woocommerce-checkout .woocommerce-checkout-review-order-table th,
.woocommerce-checkout .woocommerce-checkout-review-order-table td{
	border: 0;
}

.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered{
	color: #7f7f7f;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2em;
}

.woocommerce-checkout .select2-results__option{
	padding: 6px 20px;
}

.woocommerce-checkout .woocommerce-additional-fields{
	margin-top: 30px !important;
}

.woocommerce-checkout .selectize-input{
	width: 100%;
}

@media (min-width: 768px) {
	.woocommerce-checkout .selectize-input{
		width: auto;
		min-width: 503px;
	}
}

@media (min-width: 992px) {
	.woocommerce-checkout .selectize-input{
		min-width: 422px;
	}
}

@media (min-width: 1200px) {
	.woocommerce-checkout .selectize-input{
		min-width: 522px;
	}
}

.woocommerce form:before,
.woocommerce form:after{
	content: '';
	display: block;
	clear: both;
}

.woocommerce form input[type="radio"]{
	margin-right: 6px;
}

.woocommerce form input[type="checkbox"]{
	margin-right: 6px;
	position: relative;
	top: 2px;
}

.woocommerce form p:last-child{
	margin-bottom: 10px;
}

.form-row{
	margin: 0 0 20px;
}

.form-row:before,
.form-row:after{
	content: '';
	display: block;
	clear: both;
}

.form-row .checkbox{
	margin: -5px 0 0;
}

.form-row .lost_password{
	margin: 15px 0 -15px;
}

.form-row label{
	display: block;
	margin-top: 10px;
}

.form-row [type="email"],
.form-row [type="number"],
.form-row [type="password"],
.form-row [type="text"],
.form-row select,
.form-row textarea{
	display: block;
	width: 100%;
}

.woocommerce-billing-fields label{
	display: none;
}

.woocommerce-billing-fields #billing_phone{
	width: 100%;
}

@media (min-width: 992px) {
	.woocommerce-billing-fields #billing_first_name_field,
	.woocommerce-billing-fields #billing_last_name_field,
	.woocommerce-billing-fields #billing_phone_field,
	.woocommerce-billing-fields #billing_email_field{
		width: 50%;
		display: inline-block;
	}

	.woocommerce-billing-fields #billing_first_name_field,
	.woocommerce-billing-fields #billing_phone_field{
		padding-right: 10px;
	}

	.woocommerce-billing-fields #billing_last_name_field,
	.woocommerce-billing-fields #billing_email_field{
		padding-left: 10px;
	}
}

.woocommerce-billing-fields .form-row .select2 .select2-selection{
	width: 100%;
	height: 60px;
	padding-left: 20px;
	padding-right: 40px;
	padding-top: 15px;
	padding-bottom: 14px;
	font-size: 16px;
	background-color: #f4f4f5;
	border: 0;
	border-radius: 0;
}

.woocommerce-billing-fields .form-row .select2 .select2-selection b{
	margin-left: -10px;
	margin-top: 14px;
}

#account_password_field label{
	display: none;
}

.woocommerce-account-fields .checkbox input[type=checkbox]{
	margin-left: 0;
}

.woocommerce-additional-fields label{
	display: none;
}

.select2-dropdown{
	background-color: #f4f4f5;
	border-color: #f4f4f5;
	color: #1f2732;
}

.select2-dropdown input[type="text"],
.select2-dropdown input[type="email"],
.select2-dropdown input[type="url"],
.select2-dropdown input[type="password"],
.select2-dropdown input[type="search"],
.select2-dropdown input[type="tel"],
.select2-dropdown input[type="number"],
.select2-dropdown textarea,
.select2-dropdown select,
.select2-dropdown .form-control{
	background-color: #f4f4f5;
	border-color: #f4f4f5;
}

/* login form */
.woocommerce form.login label,
.woocommerce form.login input{
	float: none;
	text-align: left;
	width: auto !important;
}

.woocommerce form.login [type="submit"]{
	margin-top: 10px;
	text-align: center;
}

@media (min-width: 992px) {
	.col2-set{
		width: 70%;
		float: left;
		padding-right: 50px;
	}

	.col2-set.addresses{
		width: 100%;
		float: none;
		padding: 0;
		margin: 0 -15px;
	}

	.col2-set.addresses:before,
	.col2-set.addresses:after{
		display: block;
		content: '';
		clear: both;
	}

	.col2-set.addresses .col-1,
	.col2-set.addresses .col-2{
		float: left;
		width: 50%;
		padding: 0 15px;
	}

	#order_review_heading,
	#order_review{
		width: 30%;
		float: left;
		margin-top: 0;
	}
}

#order_review.woocommerce-checkout-review-order{
	margin-top: -15px;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table{
	margin: 0;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table thead{
	display: none;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table th,
#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table td{
	padding: 13px 0;
	font-weight: 500;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table th strong,
#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table td strong{
	font-weight: 500;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table .product-name{
	font-weight: 300;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table .product-name strong{
	font-weight: 300;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table .product-total{
	color: #1f2732;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table .cart-subtotal{
	color: #ff497c;
	border-top: 1px solid #dedfe1;
	border-bottom: 1px solid #dedfe1;
}

#order_review.woocommerce-checkout-review-order .woocommerce-checkout-review-order-table .order-total{
	border-bottom: 1px solid #dedfe1;
}

/* shop single*/
.cart-button{
	margin: 50px 0 55px;
}

.share-title{
	font-size: 20px;
	margin: 56px 0 18px;
}

/* shop */
.shop-item .item-content{
	padding: 30px 40px;
}

.shop-item .item-price{
	margin: 9px 0 16px;
	font-size: 30px;
	line-height: 1;
	font-weight: 800;
}

.shop-item .item-price span{
	font-size: 40px;
}

.shop-item .cart-button{
	margin: 50px 0 28px;
}

.related .owl-carousel .owl-stage-outer{
	overflow: visible;
}

.related .item-content{
	padding: 35px 15px !important;
}

.related .item-content .woocommerce-loop-product__title{
	font-size: 16px;
}

.related .item-content .add_to_cart_button{
	padding: 20px 20px 20px;
}

.related h2{
	font-size: 20px;
	font-weight: 800;
	line-height: 1;
}

.select2-container .select2-choice{
	border: none;
	padding: 10px 20px;
	border-radius: 0;
}

.select2-drop-active{
	border-color: #dedfe1;
}

.woocommerce-checkout-payment{
	margin-top: 20px;
}

.woocommerce-checkout-payment .radio{
	margin: 2px 0;
}

.woocommerce-checkout-payment ul{
	list-style: none;
	padding: 0;
}

.woocommerce-checkout-payment li{
	padding: 6px 0 6px;
}

.woocommerce-checkout-payment p{
	margin: 0;
}

.woocommerce-checkout-payment img{
	display: none;
}

.woocommerce-checkout-payment [type="submit"]{
	margin-top: 20px;
	width: 100%;
}

/*messages*/
.wc-forward{
	float: right;
}

.row > .alert{
	margin-left: 15px;
	margin-right: 15px;
}

/*my account*/
.woocommerce-MyAccount-navigation{
	margin-bottom: 40px;
}

.woocommerce-MyAccount-navigation ul{
	padding: 0;
}

.woocommerce-MyAccount-navigation li{
	display: inline-block;
}

.woocommerce-MyAccount-navigation .is-active a{
	background-color: #ff497c;
	border-color: #ff497c;
}

.wc-proceed-to-checkout .checkout-button{
	color: #ffffff;
}

.wc-proceed-to-checkout .checkout-button:hover{
	background: transparent;
}

.woocommerce-MyAccount-content h2{
	margin: 40px 0;
}

.woocommerce-Message{
	background: #ffffff;
	padding: 30px;
}

.woocommerce-Message .woocommerce-Button{
	float: right;
}

.order-actions{
	text-align: center;
}

.customer_details{
	margin-bottom: 60px;
}

.addresses address{
	margin-bottom: 0;
}

/* shop widgets */
/* cart widget */
.widget_shopping_cart .product_list_widget img{
	max-width: 70px;
	margin-right: 20px;
}

.widget_shopping_cart .product_list_widget a + a{
	color: #1f2732;
	font-size: 16px;
	text-transform: none;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 10px;
	display: block;
}

.widget_shopping_cart .product_list_widget .mini_cart_item{
	position: relative;
	margin-bottom: 20px;
	overflow: hidden;
}

.widget_shopping_cart .product_list_widget .quantity{
	display: block;
	font-size: 12px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	margin-bottom: 0;
	color: #ff497c;
}

.widget_shopping_cart .product_list_widget .quantity .woocommerce-Price-amount{
	color: #ff497c;
}

.widget_shopping_cart .cart_list{
	border-bottom: 0;
	padding-bottom: 4px;
	margin: -10px 0 0 0;
}

.widget_shopping_cart .woocommerce-mini-cart__total{
	font-size: 16px;
	text-transform: none;
	font-weight: 500;
	margin-bottom: 25px;
	color: #1f2732;
}

.widget_shopping_cart .woocommerce-mini-cart__total strong{
	font-weight: 500;
}

.ds .widget_shopping_cart .woocommerce-mini-cart__total strong{
	color: #ffffff;
}

.widget_shopping_cart .woocommerce-mini-cart__total .woocommerce-Price-amount{
	color: #ff497c !important;
}

.widget_shopping_cart .woocommerce-mini-cart__buttons{
	text-align: center;
}

.widget_shopping_cart .remove{
	float: right;
	font-size: 0;
}

.ds .widget_shopping_cart .remove{
	color: #ff497c;
}

.ds .widget_shopping_cart .remove:hover{
	color: #ffffff;
}

.widget_shopping_cart .remove:before{
	font-family: FontAwesome;
	font-size: 14px;
	content: "\f00d";
	color: #1f2732;
}

.widget_shopping_cart .wc-forward{
	float: none;
	width: 100%;
	color: #ffffff;
	margin-left: 0 !important;
	margin-bottom: 15px !important;
}

.widget_shopping_cart .wc-forward:hover{
	color: #1f2732;
}

.widget_shopping_cart .wc-forward.checkout{
	margin-bottom: 0 !important;
}

/* widget_top_rated_products */
.widget_top_rated_products .product_list_widget .product-title{
	font-size: 16px;
	font-weight: 500;
}

.widget_top_rated_products .product_list_widget .star-rating{
	margin: 0;
	width: 70px;
}

.widget_top_rated_products .product_list_widget .woocommerce-Price-amount{
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.2em;
}

.widget_top_rated_products .product_list_widget ins{
	text-decoration: none;
}

/* filter */
.widget_layered_nav.widget_categories > ul > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_layered_nav.widget_categories > ul > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_layered_nav.widget_categories .count.dark{
	color: #1f2732;
}

/* price filter */
.widget_price_filter input[readonly]{
	background-color: transparent;
	padding-right: 3px;
	padding-left: 3px;
	max-width: 89px;
	margin-bottom: 4px;
	display: inline-block;
}

.widget_price_filter .ui-slider-range{
	background-color: #ff497c;
}

.widget_price_filter .ui-slider-horizontal{
	margin-bottom: 10px;
}

.widget_price_filter label[for]{
	display: inline-block;
	padding-right: 4px;
}

.widget_price_filter .price_label{
	color: #1f2732;
	margin-left: 0;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.2em;
	text-align: center;
	margin-bottom: 30px;
}

.widget_price_filter .form-group{
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.widget_price_filter button{
	margin: 0 auto;
	text-transform: uppercase;
	padding: 20px 15px 20px;
	width: 100%;
	float: none;
}

.widget_price_filter button:hover{
	color: #1f2732;
}

.widget_price_filter .price_slider_amount{
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: start;
	justify-content: flex-start;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	flex-direction: column-reverse;
}

.widget_price_filter .price_slider_amount:after{
	content: '';
	display: block;
	clear: both;
}

/* widget_layered_nav dropdown */
.widget_layered_nav .select2-container{
	margin-top: -6px;
	outline: none !important;
}

.widget_layered_nav .select2-container:hover,
.widget_layered_nav .select2-container:focus{
	border: 0;
}

.widget_layered_nav .select2-container .dropdown-wrapper option{
	padding: 0 2px 10px;
}

.widget_layered_nav .select2-selection__clear{
	display: none;
}

.widget_layered_nav .select2-container .select2-selection--single{
	height: 60px;
	margin: 0 0 -4px;
	outline: none !important;
}

.widget_layered_nav .select2-container--default .select2-selection--single .select2-selection__rendered{
	color: #7f7f7f;
	line-height: 40px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 500;
}

.widget_layered_nav .select2-container--default .select2-selection--single .select2-selection__arrow{
	height: 60px;
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	text-align: left;
}

.widget_layered_nav .select2-container--default .select2-selection--single .select2-selection__arrow b{
	display: none;
}

.widget_layered_nav .select2-container--default .select2-selection--single .select2-selection__arrow:after{
	content: "";
	font-family: FontAwesome;
	font-size: 14px;
	content: "\f107";
	color: #ff497c;
	position: relative;
	top: 15px;
}

.widget_layered_nav .select2-container--default .select2-selection{
	display: block;
	padding: 10px 20px 10px 22px;
	overflow: hidden;
	border: 0;
	border-radius: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	background-color: #f4f4f5;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ui-slider{
	position: relative;
	text-align: left;
	background-color: #fafafa;
	margin: 0 5px 0 8px;
}

.ui-slider .ui-slider-handle{
	position: absolute;
	z-index: 2;
	width: 10px;
	height: 10px;
	cursor: pointer;
	border-radius: 50%;
	touch-action: none;
	background-color: #ff497c;
}

.ui-slider .ui-slider-range{
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range{
	-webkit-filter: inherit;
	filter: inherit;
}

.ui-slider-horizontal{
	height: 4px;
}

.ui-slider-horizontal .ui-slider-handle{
	top: -3px;
	margin-left: -.2em;
}

.ui-slider-horizontal .ui-slider-range{
	top: 0;
	height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min{
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max{
	right: 0;
}

.ui-slider-vertical{
	width: .8em;
	height: 100px;
}

.ui-slider-vertical .ui-slider-handle{
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range{
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min{
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max{
	top: 0;
}

/* color layered nav */
.widget_layered_nav .color-filters li{
	display: inline-block;
	margin: 0 29px 14px 0;
}

.widget_layered_nav .color-filters a{
	display: inline-block;
	width: 23px;
	height: 23px;
	font-size: 0;
	background-color: #ccc;
	color: transparent;
	position: relative;
}

.widget_layered_nav .color-filters a:before{
	content: '';
	display: block;
	position: absolute;
	border: 1px solid rgba(164, 169, 185, 0.3);
	top: -2px;
	left: -2px;
	bottom: -2px;
	right: -2px;
}

.widget_layered_nav .color-filters span{
	position: relative;
	top: 2px;
	left: 5px;
}

/* widget_product_categories */
.woocommerce-widget-layered-nav.widget_categories{
	background-color: transparent;
	padding: 0;
}

.widget_product_categories.widget_categories{
	background-color: transparent;
	padding: 0;
}

.widget_product_categories.widget_categories > ul > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_product_categories.widget_categories > ul > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_product_categories.widget_categories ul.children > li{
	border-bottom: 1px solid #dedfe1;
}

.widget_product_categories.widget_categories ul.children > li:first-child{
	border-top: 1px solid #dedfe1;
}

.widget_product_categories.widget_categories ul.children > li:last-child{
	border-bottom: 0;
}

.widget_product_categories.widget_categories .count.dark{
	color: #1f2732;
}

/* widget_products */
.widget_products img{
	max-width: 80px;
}

.widget_products .media h3{
	font-size: inherit;
	line-height: 1.4;
	margin: 3px 0 10px;
}

.widget_products .star-rating{
	margin-bottom: 10px;
}

.widget_products .price{
	position: static;
	background-color: transparent;
	padding: 0;
}

.widget_products .add_to_cart_button{
	display: inline-block;
	width: 20px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	background-color: #ff497c;
	color: #ffffff;
	margin-right: 5px;
}

.widget_products .add_to_cart_button:hover{
	color: #ffffff;
	background-color: #1f2732;
}

/*widget_top_rated_products*/
.widget .product-title{
	display: block;
}

.widget .woocommerce-Price-amount{
	color: #1f2732;
}

.widget del .woocommerce-Price-amount{
	color: inherit;
}

.widget .star-rating{
	margin: 5px 0 7px;
}

/*widget_recent_reviews*/
.widget_recent_reviews li:before,
.widget_recent_reviews li:after,
.product_list_widget li:before,
.product_list_widget li:after{
	content: '';
	display: block;
	clear: both;
}

.widget_recent_reviews img,
.product_list_widget img{
	display: block;
	float: left;
	max-width: 70px;
	margin-right: 20px;
}

/*widget_rating_filter*/
.widget_rating_filter{
	text-align: right;
}

.widget_rating_filter li{
	clear: both;
	margin-bottom: 8px;
}

.widget_rating_filter .star-rating{
	float: left;
}

/* widget_products */
.widget_product_search .widget-title{
	margin: 0 0 25px;
}

/* woocommerce placeholders */
.woocommerce-checkout ::-moz-placeholder,
.widget_product_search ::-moz-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2em;
}

.woocommerce-checkout :-ms-input-placeholder,
.widget_product_search :-ms-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2em;
}

.woocommerce-checkout ::-webkit-input-placeholder,
.widget_product_search ::-webkit-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2em;
}

.woocommerce-checkout :-moz-placeholder,
.widget_product_search :-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

.woocommerce-checkout ::-moz-placeholder,
.widget_product_search ::-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

.woocommerce-checkout :-ms-input-placeholder,
.widget_product_search :-ms-input-placeholder{
	color: #7f7f7f;
}

.woocommerce-checkout ::-webkit-input-placeholder,
.widget_product_search ::-webkit-input-placeholder{
	color: #7f7f7f;
}

.woocommerce-checkout :focus:-moz-placeholder,
.widget_product_search :focus:-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

.woocommerce-checkout :focus::-moz-placeholder,
.widget_product_search :focus::-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

.ds .summary .product_meta > span{
	color: #ffffff;
}
