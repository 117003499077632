/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.um .picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #fff;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.um .picker__box ul li[role="presentation"] {
  margin: 10px 0 0 0;
}

/**
 * The picker input element.
 */
.um .picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is “activated”.
 */
.um .picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * The holder is the only “scrollable” top-level container element.
 */
.um .picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
/**
 * Make the holder and frame fullscreen.
 */
.um .picker__holder,
.um .picker__frame {
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
}
/**
 * The holder should overlay the entire screen.
 */
.um .picker__holder {
  position: fixed;
  -webkit-transition: background 0.15s ease-out, top 0s 0.15s;
  -moz-transition: background 0.15s ease-out, top 0s 0.15s;
  transition: background 0.15s ease-out, top 0s 0.15s;
}
/**
 * The frame that bounds the box contents of the picker.
 */
.um .picker__frame {
  position: absolute;
  margin: 0 auto;
  min-width: 256px;
  max-width: 400px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
@media (min-height: 33.875em) {
  .um .picker__frame {
    overflow: visible;
    top: auto;
    bottom: -100%;
    max-height: 80%;
  }
}
@media (min-height: 40.125em) {
	.um .picker__frame {
		margin-bottom: 15% !important;
	}
}
/**
 * The wrapper sets the stage to vertically align the box contents.
 */
.um .picker__wrap {
  display: table;
  width: 100%;
  height: 100%;
}
@media (min-height: 33.875em) {
  .um .picker__wrap {
    display: block;
  }
}
/**
 * The box contains all the picker contents.
 */
.um .picker__box {
  display: table-cell;
  vertical-align: middle;
}
@media (min-height: 26.5em) {
  .um .picker__box {

  }
}
@media (min-height: 33.875em) {
  .um .picker__box {
    display: block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}
@media (min-height: 40.125em) {
  .um .picker__box {

    border-bottom-width: 1px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}
/**
 * When the picker opens...
 */
.um .picker--opened .picker__holder {
  top: 0;
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
  zoom: 1;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: background 0.15s ease-out;
  -moz-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
}
.um .picker--opened .picker__frame {
  top: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-height: 33.875em) {
  .um .picker--opened .picker__frame {
    top: auto;
    bottom: 0;
  }
}
/**
 * For `large` screens, transform into an inline picker.
 */
