/*Ultimate Member styles*/
#canvas .um{
	color: #7f7f7f;
	font-size: inherit;
}

#canvas .um a.um-link-alt{
	color: #a4a9b9 !important;
}

#canvas .um a.um-link-alt:hover{
	text-decoration: none !important;
	color: #7f7f7f !important;
}

#canvas .um .um-form input[type=text]::-moz-placeholder,
#canvas .um .um-form input[type=search]::-moz-placeholder,
#canvas .um .um-form input[type=tel]::-moz-placeholder,
#canvas .um .um-form input[type=number]::-moz-placeholder,
#canvas .um .um-form input[type=password]::-moz-placeholder,
#canvas .um .um-form textarea::-moz-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

#canvas .um .um-form input[type=text]:-ms-input-placeholder,
#canvas .um .um-form input[type=search]:-ms-input-placeholder,
#canvas .um .um-form input[type=tel]:-ms-input-placeholder,
#canvas .um .um-form input[type=number]:-ms-input-placeholder,
#canvas .um .um-form input[type=password]:-ms-input-placeholder,
#canvas .um .um-form textarea:-ms-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

#canvas .um .um-form input[type=text]::-webkit-input-placeholder,
#canvas .um .um-form input[type=search]::-webkit-input-placeholder,
#canvas .um .um-form input[type=tel]::-webkit-input-placeholder,
#canvas .um .um-form input[type=number]::-webkit-input-placeholder,
#canvas .um .um-form input[type=password]::-webkit-input-placeholder,
#canvas .um .um-form textarea::-webkit-input-placeholder{
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
}

#canvas .um .um-form input[type=text]:-moz-placeholder,
#canvas .um .um-form input[type=search]:-moz-placeholder,
#canvas .um .um-form input[type=tel]:-moz-placeholder,
#canvas .um .um-form input[type=number]:-moz-placeholder,
#canvas .um .um-form input[type=password]:-moz-placeholder,
#canvas .um .um-form textarea:-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

#canvas .um .um-form input[type=text]::-moz-placeholder,
#canvas .um .um-form input[type=search]::-moz-placeholder,
#canvas .um .um-form input[type=tel]::-moz-placeholder,
#canvas .um .um-form input[type=number]::-moz-placeholder,
#canvas .um .um-form input[type=password]::-moz-placeholder,
#canvas .um .um-form textarea::-moz-placeholder{
	color: #7f7f7f;
	opacity: 1;
}

#canvas .um .um-form input[type=text]:-ms-input-placeholder,
#canvas .um .um-form input[type=search]:-ms-input-placeholder,
#canvas .um .um-form input[type=tel]:-ms-input-placeholder,
#canvas .um .um-form input[type=number]:-ms-input-placeholder,
#canvas .um .um-form input[type=password]:-ms-input-placeholder,
#canvas .um .um-form textarea:-ms-input-placeholder{
	color: #7f7f7f;
}

#canvas .um .um-form input[type=text]::-webkit-input-placeholder,
#canvas .um .um-form input[type=search]::-webkit-input-placeholder,
#canvas .um .um-form input[type=tel]::-webkit-input-placeholder,
#canvas .um .um-form input[type=number]::-webkit-input-placeholder,
#canvas .um .um-form input[type=password]::-webkit-input-placeholder,
#canvas .um .um-form textarea::-webkit-input-placeholder{
	color: #7f7f7f;
}

#canvas .um .um-form input[type=text]:focus:-moz-placeholder,
#canvas .um .um-form input[type=search]:focus:-moz-placeholder,
#canvas .um .um-form input[type=tel]:focus:-moz-placeholder,
#canvas .um .um-form input[type=number]:focus:-moz-placeholder,
#canvas .um .um-form input[type=password]:focus:-moz-placeholder,
#canvas .um .um-form textarea:focus:-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

#canvas .um .um-form input[type=text]:focus::-moz-placeholder,
#canvas .um .um-form input[type=search]:focus::-moz-placeholder,
#canvas .um .um-form input[type=tel]:focus::-moz-placeholder,
#canvas .um .um-form input[type=number]:focus::-moz-placeholder,
#canvas .um .um-form input[type=password]:focus::-moz-placeholder,
#canvas .um .um-form textarea:focus::-moz-placeholder{
	color: transparent !important;
	opacity: 1;
}

#canvas .um .um-form input[type=text]:focus:-ms-input-placeholder,
#canvas .um .um-form input[type=search]:focus:-ms-input-placeholder,
#canvas .um .um-form input[type=tel]:focus:-ms-input-placeholder,
#canvas .um .um-form input[type=number]:focus:-ms-input-placeholder,
#canvas .um .um-form input[type=password]:focus:-ms-input-placeholder,
#canvas .um .um-form textarea:focus:-ms-input-placeholder{
	color: transparent !important;
}

#canvas .um .um-form input[type=text]:focus::-webkit-input-placeholder,
#canvas .um .um-form input[type=search]:focus::-webkit-input-placeholder,
#canvas .um .um-form input[type=tel]:focus::-webkit-input-placeholder,
#canvas .um .um-form input[type=number]:focus::-webkit-input-placeholder,
#canvas .um .um-form input[type=password]:focus::-webkit-input-placeholder,
#canvas .um .um-form textarea:focus::-webkit-input-placeholder{
	color: transparent !important;
}

#canvas .um .um-form input[type=text],
#canvas .um .um-form input[type=search],
#canvas .um .um-form input[type=tel],
#canvas .um .um-form input[type=number],
#canvas .um .um-form input[type=password],
#canvas .um .um-form textarea,
#canvas .um .upload-progress,
#canvas .um .select2-container .select2-choice,
#canvas .um .select2-drop,
#canvas .um .select2-container-multi .select2-choices,
#canvas .um .select2-drop-active,
#canvas .um .select2-drop.select2-drop-above{
	color: #7f7f7f;
	background-color: #f4f4f5;
	border-color: transparent !important;
	height: 60px !important;
	padding-left: 1.5em !important;
	padding-right: 1.5em !important;
}

#canvas .um .um-field-label{
	color: #1f2732;
}

#canvas .um .um-field-label label{
	font-weight: inherit;
	font-size: .92em !important;
	line-height: 1.3 !important;
}

#canvas .um .um-field-checkbox-option,
#canvas .um .um-field-radio-option{
	color: #1f2732 !important;
}

#canvas .um .um-field-checkbox:hover i,
#canvas .um .um-field-radio:hover i{
	color: #1f2732 !important;
}

#canvas .um .um-tip:hover,
#canvas .um .um-field-radio.active:not(.um-field-radio-state-disabled) i,
#canvas .um .um-field-checkbox.active:not(.um-field-radio-state-disabled) i,
#canvas .um .um-member-name a:hover,
#canvas .um .um-member-more a:hover,
#canvas .um .um-member-less a:hover,
#canvas .um .um-members-pagi a:hover,
#canvas .um .um-cover-add:hover,
#canvas .um .um-profile-subnav a.active,
#canvas .um .um-item-meta a,
#canvas .um-account-name a:hover,
#canvas .um-account-nav a.current,
#canvas .um-account-side li a.current span.um-account-icon,
#canvas .um-account-side li a.current:hover span.um-account-icon,
#canvas .um-dropdown li a:hover,
#canvas i.um-active-color,
#canvas span.um-active-color{
	color: #1f2732 !important;
}

#canvas .um-button{
	font-size: 12px !important;
	font-weight: bold !important;
	text-transform: uppercase !important;
	padding: 20px 30px 20px !important;
	margin-bottom: 4px !important;
	line-height: 1 !important;
	display: inline-block !important;
	letter-spacing: 0.2em !important;
	text-indent: 0.2em !important;
	height: 60px !important;
	text-align: center !important;
	color: #ffffff !important;
	border: 4px solid #ff497c !important;
	background-color: #ff497c !important;
	border-radius: 50px !important;
	position: relative !important;
	box-shadow: none !important;
	-webkit-transition: color .25s, background-color .25s, border-color .25s ease-out, opacity .25s ease-out !important;
	transition: color .25s, background-color .25s, border-color .25s ease-out, opacity .25s ease-out !important;
}

#canvas .um-button:hover{
	background-color: transparent !important;
	color: #1f2732 !important;
}

#canvas .um-alt,
#canvas input[type=submit].um-button.um-alt{
	color: #1f2732 !important;
	background-color: #f4f4f5 !important;
	border-color: #f4f4f5 !important;
}

#canvas .um-alt:before,
#canvas input[type=submit].um-button.um-alt:before{
	color: #1f2732 !important;
}

#canvas .um-request-button{
	background-color: #ff497c;
	border-radius: 60px;
	color: #ffffff;
	padding-left: 2em;
	padding-right: 2em;
}

#canvas .um-request-button:hover{
	background-color: #1f2732;
}

#canvas .um-member-directory-header .um-member-directory-header-row .um-member-directory-nav-line .um-member-directory-view-type .um-member-directory-view-type-a{
	color: #a4a9b9;
}

#canvas .um-directory .um-member-directory-header .um-member-directory-header-row:not(.um-member-directory-filters-bar){
	margin-bottom: 2em;
}

#canvas .um-dropdown,
#canvas .um-new-dropdown{
	background-color: #ffffff;
	border-color: #dedfe1;
	border-radius: 2px;
	font-size: .92em;
	padding-top: .25em;
	padding-bottom: .25em;
	box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.15);
}

#canvas .um-dropdown ul{
	background-color: #ffffff;
}

#canvas .um-dropdown li a{
	font-size: inherit;
	color: #1f2732 !important;
}

#canvas .um-dropdown li a:hover{
	color: #ff497c !important;
}

#canvas .um-dropdown li:last-child a{
	border-color: #dedfe1;
}

#canvas .um-dropdown-arr{
	display: none;
	color: #ffffff;
}

#canvas .um-members-wrapper .um-members .um-member{
	border: none;
	background-color: #f4f4f5;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member{
	padding: 2em;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-container .um-member-photo{
	margin-right: 2em;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-container .um-member-card .um-member-card-content .um-member-card-header .um-member-name a{
	font-size: 1.1em;
	line-height: inherit;
	color: #1f2732;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-container .um-member-card .um-member-card-content .um-member-tagline{
	font-size: .92em;
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-container .um-member-card .um-member-card-actions .um-member-cog .um-member-actions-a{
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-meta{
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-meta .um-member-metaline{
	font-size: .92em;
	line-height: inherit;
}

#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-footer .um-member-card-reveal-buttons .um-member-more a,
#canvas .um-members-wrapper .um-members.um-members-list .um-member .um-member-card-footer .um-member-card-reveal-buttons .um-member-less a{
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members .um-member .um-member-card-footer .um-member-card-reveal-buttons a i,
#canvas .um-members-wrapper .um-members .um-member .um-member-card .um-member-meta-main a i{
	font-size: 18px;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member{
	background-color: #f4f4f5;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-cover{
	background-color: #dedfe1;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-photo a img{
	background-color: #ffffff;
	border-color: #ffffff;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card{
	margin-bottom: 1.5em;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-meta-main .um-member-meta{
	border-color: #dedfe1;
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-meta-main .um-member-meta .um-member-metaline{
	font-size: .92em;
	line-height: inherit;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-meta-main .um-member-more a,
#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-meta-main .um-member-less a{
	color: #a4a9b9;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-name a{
	font-size: 1.1em;
	line-height: inherit;
	color: #1f2732;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-member-tagline{
	font-size: .92em;
	line-height: inherit;
	color: #a4a9b9;
	padding-right: 2em;
	padding-left: 2em;
}

#canvas .um-members-wrapper .um-members.um-members-grid .um-member .um-member-card .um-members-edit-btn{
	height: auto;
}

#canvas .um-directory .um-member-directory-header .um-member-directory-header-row .um-member-directory-search-line{
	width: 100%;
}

#canvas .um-directory .um-member-directory-header .um-member-directory-header-row .um-member-directory-search-line .um-do-search{
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

#canvas .um-directory .um-members-pagination-box{
	margin-top: 3em;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi{
	color: #1f2732 !important;
	background-color: transparent;
	box-sizing: border-box;
	padding: 0;
	text-align: center;
	border: 4px solid #f4f4f5 !important;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.2em;
	text-indent: 0.2em;
	line-height: 54px;
	margin: 0 5px 10px;
	min-width: 60px;
	height: 60px;
	border-radius: 50%;
	text-transform: uppercase;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi.current{
	background-color: #ff497c;
	border-color: #ff497c !important;
	color: #ffffff !important;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi.disabled{
	opacity: 1;
	cursor: not-allowed;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi.pagi-arrow{
	line-height: 54px;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi.pagi-arrow i:before{
	font-size: 14px;
}

#canvas .um-directory .um-members-pagination-box .um-members-pagi .pagi:hover{
	background-color: #1f2732;
	border-color: #1f2732 !important;
	color: #ffffff !important;
}

#canvas .um .um-field-group-head,
#canvas .um .um-members-pagi span.current,
#canvas .um .um-members-pagi span.current:hover,
#canvas div.uimob800 .um-account-side li a.current,
#canvas div.uimob800 .um-account-side li a.current:hover{
	background-color: #ff497c;
}

#canvas .um-cover{
	background-color: #f4f4f5;
}

#canvas .um-cover-add{
	color: #a4a9b9;
}

#canvas .um-profile-photo a.um-profile-photo-img{
	border-color: #ffffff;
	border-bottom-color: #ffffff !important;
}

#canvas .um-profile.um .um-name a{
	color: #1f2732;
}

#canvas .um-profile.um .um-profile-headericon a{
	color: #a4a9b9;
}

#canvas .um-profile.um .um-profile-headericon a:hover{
	color: #1f2732;
}

#canvas .um-profile.um .um-profile-meta{
	color: #7f7f7f;
}

#canvas .um-profile.um .um-field{
	display: -webkit-box;
	display: flex;
}

#canvas .um-profile.um .um-field .um-field-label{
	border: none;
	padding: 0;
	margin: 0;
	width: 10em;
	flex-wrap: wrap;
}

#canvas .um-profile.um .um-field .um-field-label label{
	font-size: inherit !important;
	line-height: inherit !important;
}

#canvas .um-profile.um .um-field .um-field-area{
	-webkit-box-flex: 1;
	flex: 1 1;
}

#canvas .um-profile-edit{
	font-size: inherit;
}

#canvas .um-header{
	border: none;
}

#canvas .um-profile-nav{
	padding: 0;
	background-color: transparent;
}

#canvas .um-profile-nav-item a{
	color: #1f2732 !important;
	background-color: #f4f4f5;
	line-height: 1.6;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-weight: 600;
	padding: 1.25em 2.5em 1.25em 3.5em;
	border-radius: 50px;
}

@media (max-width: 991px) {
	#canvas .um-profile-nav-item a{
		padding-left: 2.5em;
	}
}

#canvas .um-profile-nav-item a:hover{
	background: #1f2732;
	color: #ffffff !important;
}

#canvas .um-profile-nav-item i{
	top: .75em;
	left: 1em;
}

#canvas .um-profile-nav-item.active a,
#canvas .um-profile-nav-item.active a:hover{
	background-color: #1f2732;
	color: #ffffff !important;
}

#canvas .um-main-meta{
	padding: 1em 0 .5em;
}

#canvas .um-meta{
	font-size: .92em;
}

#canvas .um-meta-text{
	font-size: .92em;
	color: #7f7f7f;
}

#canvas .um-profile-note{
	color: #7f7f7f;
}

#canvas .um-profile-note span{
	font-size: inherit;
	color: #7f7f7f;
}

#canvas .um-profile-note a:hover{
	text-decoration: none !important;
}

#canvas .um-item{
	border: none;
}

#canvas .um-item-img a{
	padding: 0;
	border-radius: 0;
	border: none;
}

#canvas .um-item-link a,
#canvas .um-item-link i{
	color: #1f2732;
}

#canvas .um-item-link a:hover{
	text-decoration: none !important;
	color: #ff497c;
}

#canvas .um-item-meta{
	color: #a4a9b9;
}

#canvas .um-item-meta a{
	border: none;
	font-weight: inherit;
}

#canvas .um-item-meta a:hover{
	color: #ff497c !important;
}

#canvas .um-item-meta span{
	font-style: inherit;
	font-weight: inherit;
	font-size: .92em;
}

#canvas .um-account-main div.um-account-heading,
#canvas .um-account-name a{
	color: #1f2732;
}

#canvas .um-account-side li{
	background: #f4f4f5;
}

#canvas .um-account-side li a{
	color: #1f2732;
	padding: 10px 0;
}

#canvas .um-account-side li a:hover{
	background-color: #dedfe1;
}

#canvas .um-account-side li a span.um-account-title{
	color: #1f2732;
}

#canvas .um-account-side li a span.um-account-icon,
#canvas .um-account-side li a.current span.um-account-icon,
#canvas .um-account-side li a.current:hover span.um-account-icon{
	color: #1f2732;
	border-color: #dedfe1;
}

#canvas .um-account-side li a span.um-account-arrow{
	top: 15px;
	right: 15px;
	font-size: 14px;
}

#canvas .um-field-block{
	font-size: inherit;
	color: inherit;
}

#canvas .um-misc-with-img{
	font-size: inherit;
	border-bottom: 1px solid #dedfe1;
}

#canvas .um-misc-ul{
	list-style: none;
	padding: 0;
	text-align: center;
	font-size: .92em;
}

#canvas .um-misc-ul li{
	display: inline-block;
	padding: 0 .5em;
}

#canvas .picker__box{
	background-color: #ffffff;
	color: #7f7f7f;
}

#canvas .picker__select--year{
	height: 40px;
	padding: 2px 10px;
	margin: 0;
}

#canvas .picker__weekday{
	border: none;
	padding-top: 1em !important;
	color: #1f2732;
}

#canvas .picker__nav--prev,
#canvas .picker__nav--next{
	top: 18px;
}

#canvas .picker__nav--prev:hover,
#canvas .picker__nav--prev:hover,
#canvas .picker__nav--next:hover,
#canvas .picker__nav--next:hover{
	background-color: #ff497c;
}

#canvas .picker__footer,
#canvas .picker__header,
#canvas .picker__day--infocus:hover,
#canvas .picker__day--outfocus:hover,
#canvas .picker__day--highlighted:hover,
#canvas .picker--focused .picker__day--highlighted,
#canvas .picker__list-item:hover,
#canvas .picker__list-item--highlighted:hover,
#canvas .picker--focused .picker__list-item--highlighted,
#canvas .picker__list-item--selected,
#canvas .picker__list-item--selected:hover,
#canvas .picker--focused .picker__list-item--selected{
	background-color: #1f2732;
	color: #ffffff;
}

#canvas .picker__table{
	margin-top: 0;
}

.um-modal .upload,
.um-modal .um-modal-header,
.um-modal .um-modal-btn,
.um-modal .um-modal-btn.disabled,
.um-modal .um-modal-btn.disabled:hover{
	background-color: #ff497c;
}
