/*
Theme Name: DeepDigital
Theme URI: http://webdesign-finder.com/deepdigital
Author: MWTemplates
Author URI: http://modernwebtemplates.com/
Description: Deepdigital - IT Agency Wordpress Theme
Version: 2.7.2
License: Commercial
License URI: http://themeforest.net/licenses/regular_extended
Tags: custom-header, left-sidebar, right-sidebar, post-formats, full-width-template
Text Domain: deepdigital
Domain Path: /languages

This theme uses great Unyson Framework to make best user experience

see css/main.css for all of the styles
*/


/*
** WordPress Required
*/
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption .wp-caption-dt {
    background: transparent;
    border: 0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}
.wp-caption .wp-caption-dt:empty {
    display: none;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    clip: auto !important;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

aside .widget label {
    display: none;
}

.sticky,
.bypostauthor{position: relative;}

/*gutenberg*/
p[class*="-font-size"],
p[style*="font-size"] {
    line-height: 1.3em;
}
.wp-block-quote cite,
.wp-block-pullquote cite {
    margin-top: .5em;
}
.wp-block-quote cite:before,
.wp-block-pullquote cite:before {
    margin-top: 0;
}

ul.wp-block-gallery.alignwide {
    width: 100%;
}

.entry-content .wp-block-table.is-style-stripes td {
    border-color: grey;
}

.selectize-control.single {
    margin-bottom: 15px;
}

blockquote.wp-block-quote[style*="text-align:right"] cite {
    text-align: right;
}

.wp-block-archives.wp-block-archives-dropdown {
    margin-bottom: 20px;
}

.item-content .entry-content::after{
    content: '';
    clear: both;
    display: block;
}

.wp-block-column .wp-block-quote:first-child{
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#attachment_612{
    display: none;
}

#attachment_612 + p{
    display: none;
}

.post-navigation .media-body a .nav-link{
    word-break: break-word;
}

.entry-header .entry-title{
    word-break: break-word;
}

.blog .wp-block-quote{
    max-width: inherit;
}