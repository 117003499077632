/* access_press */
.apsc-facebook-icon {
    background-color: #3b5998;
}

.apsc-twitter-icon {
    background-color: #1dcaff;
}

.apsc-youtube-icon {
    background-color: #e52d27;
}

.apsc-soundcloud-icon {
    background-color: #ff8800;
}

.apsc-dribble-icon {
    background-color: #ea4c89;
}

.apsc-comment-icon {
    background-color: #464646;
}

.apsc-edit-icon {
    background-color: #837d7d;
}

.apsc-google-plus-icon {
    background-color: #dd4b39;
}

.apsc-instagram-icon {
    background-color: #3f729b;
}

.apsc-each-profile {
    width: 33.2%;
    padding: 3px;
}

.apsc-each-profile a {
    color: white !important;
}

.apsc-each-profile a:hover {
    color: white !important;
    text-decoration: none !important;
}

.apsc-icons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -3px;
}

.apsc-icons-wrapper .social-icon {
    display: inline-block;
    position: relative;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}

.apsc-icons-wrapper .social-icon .media-name {
    display: none;
}

.apsc-icons-wrapper .apsc-each-profile a {
    padding: 0;
    display: flex;
    height: 110px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    position: relative;
    text-align: center;
}

.apsc-icons-wrapper .apsc-each-profile a:hover {
    opacity: 0.8;
}

.apsc-icons-wrapper .apsc-each-profile a .fa {
    margin-top: 5px;
    text-align: center;
    font-size: 24px;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}

.apsc-icons-wrapper .apsc-each-profile a:hover .fa {
    transform: none;
    -webkit-transform: snone;
    -ms-transform: none;
}

.apsc-icons-wrapper .media-name {
    display: block;
}

.apsc-icons-wrapper .apsc-count {
    font-family: "Open sans", sans-serif;
    border-radius: 0;
    color: #fff;
    display: block;
    font-size: inherit;
    text-align: center;
    position: relative;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 5px;
    font-weight: 400;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}

.apsc-icons-wrapper .apsc-media-type {
    display: none;
}

@media (max-width: 1199px) {
    .apsc-each-profile {
        width: 33%;
    }
}
@media (max-width: 767px) {
    .apsc-each-profile {
        width: 16.6%;
    }
}
@media (max-width: 479px) {
    .apsc-each-profile {
        width: 33.3%;
    }
}
